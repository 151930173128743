
/* eslint-disable no-unused-expressions */
import React from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from "@chakra-ui/react";

export default function SelectedConfirmModal(props) {
    const { isOpen, onClose, message, handleSettlement, loading } = props;


    const onCloseHandler = () => {
        onClose();
    };

    const onClickHandler = async () => {
        onClose();
        handleSettlement();
    };

    return (
        <Modal isOpen={isOpen} onClose={onCloseHandler} size={"xl"}>
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>Confirm Settlement</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={2}>
                    <Text color={'red'}>{message}</Text>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClickHandler} colorScheme="blue" mr={3} isLoading={loading}>
                        Okay
                    </Button>
                    <Button onClick={onCloseHandler}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}