import React, { useState, useRef } from "react";
import Loader from "react-js-loader";
import { Box, Button, useDisclosure, Badge, Text, HStack, Tooltip } from "@chakra-ui/react";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { startCase, join, slice, round } from "lodash";
import { FiFilter } from "react-icons/fi";
import { BiEdit, BiRefresh } from "react-icons/bi";

import AddSubAccountModal from "./AddSubAccountModal";
import TransfiGrid from "../../utils/TransfiGrid";
import DrawerFilter from "./Filter";
import EditSubAccountModal from "./EditSubAccountModal";
import { useTranslation } from "react-i18next";

export default function SubAccount(props) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [filters, setFilters] = useState({});
    const [currencyList, setCurrencyList] = useState([]);
    const [details, setDetails] = useState("");
    const { isOpen: isOpenFilter, onOpen: onOpenFilter, onClose: onCloseFilter } = useDisclosure();
    const {
        isOpen,
        onOpen,
        onClose,
    } = useDisclosure({ defaultIsOpen: false });
    const {
        isOpen: isOpenEditModal,
        onOpen: onOpenEditModal,
        onClose: onCloseEditModal,
    } = useDisclosure({ defaultIsOpen: false });

    const gridRef = useRef();

    const handleOnClick = (value) => {
        onOpenEditModal();
        setDetails(value);
    };

    const refreshGrid = () => {
        gridRef.current.refreshGrid();
    };

    const columns = [
        {
            Header: t("sub-accounts:email"),
            accessor: "email",
            width: "170",
        },
        {
            Header: t("sub-accounts:currency"),
            accessor: "fiat",
            width: "120",
            Cell: ({ value = [] }) => value.join(", ")
        },
        {
            Header: t("sub-accounts:total_transactioned_volume"),
            accessor: "totalTransactionedVolume",
            width: "190",
            Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : 0
        },
        {
            Header: t("sub-accounts:current_balance"),
            accessor: "currentBalance",
            width: "170",
            Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : 0
        },
        {
            Header: t("sub-accounts:total_processed_volume"),
            accessor: "totalProcessedVolume",
            width: "170",
            Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : 0
        },
        {
            Header: t("sub-accounts:status"),
            accessor: "status",
            width: "100",
            Cell: ({ value }) =>
                value === "sandbox" || value === "live" ? (
                    <Badge colorScheme={"green"}>{startCase("active")} </Badge>
                ) : (
                    <Badge colorScheme={"gray"}>{startCase("inactive")}</Badge>
                ),
        },
        {
            Header: t("sub-accounts:total_completed_transactions_amount"),
            accessor: "totalCompletedTransactionedVolume",
            width: "200",
            Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : 0
        },
        {
            Header: t("sub-accounts:total_failed_transactions"),
            accessor: "totalFailedOrders",
            Cell: ({ value }) => value || 0,
            width: "170"
        },
        {
            Header: t("sub-accounts:additional_note"),
            width: "250",
            Cell: ({ row }) => {
                const note1 = slice(row?.original.note, 0, 25).join("");
                const joinednote = row?.original.note ? join([note1, "..."], "") : "N/A";
                return (
                    <HStack>
                        <Tooltip
                            label={row?.original.note}
                            fontSize="0.8rem"
                            placeContent={'top'}
                            hasArrow
                            bgColor={'black'}
                        >
                            <Text style={{ width: "90%" }} >
                                {joinednote}</Text>
                        </Tooltip>
                        <BiEdit
                            style={{
                                fontSize: "22px",
                                color: "#2b6cb0",
                                cursor: "pointer",
                            }}
                            onClick={() => handleOnClick(row?.original)}
                        ></BiEdit>
                    </HStack>
                )
            }
        },
        {
            Header: t("sub-accounts:api_key"),
            accessor: "apiKeys[0]",
            width: "170",
            Cell: ({ value }) => value || "N/A",
        }
    ];

    return !loading ? (
        <Box style={{ background: "white" }}>
            <div style={{ float: "right", marginTop: "2%", marginRight: "5%" }}>
                <Button
                    leftIcon={<AiOutlineUsergroupAdd />}
                    colorScheme="blue"
                    style={{ margin: "0 8px" }}
                    size="sm"
                    onClick={onOpen}
                >
                    {t("sub-accounts:add_sub_account")}
                </Button>
                <Button
                    leftIcon={<BiRefresh style={{ fontSize: "20px" }} />}
                    onClick={() => refreshGrid()}
                    variant={"outline"}
                    colorScheme="blue"
                    style={{ margin: "0 0px" }}
                    size="sm"
                >
                    {t("default:refresh")}
                </Button>
                <Button
                    leftIcon={<FiFilter style={{ fontSize: "20px" }} />}
                    onClick={onOpenFilter}
                    variant={"outline"}
                    colorScheme="blue"
                    style={{ margin: "0 8px" }}
                    size="sm"
                >
                    {t("default:filter")}
                </Button>
            </div>
            <TransfiGrid
                ref={gridRef}
                columns={columns}
                dataUrl={"/api/customers/wpay/list"}
                selectRow={false}
                filters={{ filters }}
            ></TransfiGrid>
            <AddSubAccountModal
                isLoading={loading}
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                refreshGrid={refreshGrid}
                currencyList={currencyList}
                setCurrencyList={setCurrencyList}
            />
            {details && <EditSubAccountModal
                isLoading={loading}
                isOpen={isOpenEditModal}
                onOpen={onOpenEditModal}
                onClose={onCloseEditModal}
                refreshGrid={refreshGrid}
                details={details}
            />}
            <DrawerFilter
                onClose={onCloseFilter}
                isOpen={isOpenFilter}
                formValues={formValues}
                setFormValues={setFormValues}
                filters={filters}
                setFilters={setFilters}
                currencyList={currencyList}
            ></DrawerFilter>
        </Box >
    ) : (
        <Loader
            type="spinner-cub"
            bgColor={"#FFFFFF"}
            title={"Loading..."}
            color={"cyan.400"}
            size={100}
        />
    );
}
