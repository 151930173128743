import React, { useState, useEffect } from "react";
import Loader from "react-js-loader";
import { Link } from "react-router-dom";
import { Steps } from "antd";
import { upperCase, isEmpty, startCase, snakeCase, groupBy, map } from "lodash";
import { HStack, useClipboard, useDisclosure, Tooltip, Box, Text } from "@chakra-ui/react";
import { CgPen } from "react-icons/cg";

import { Badge, Stat, StatLabel, StatNumber, StatHelpText, Button } from "@chakra-ui/react";
import { callApiWithToken } from "../../../utils/utils";
import { statusMap, colorMap } from "../../../utils/config";
import StatusEditModal from "./StatusEditModal";
import { getOrgRoles } from "../../App/useToken";
import TimezoneFormatter from "../../../utils/TimezoneFormatter";

export default function OrderDetails(props) {
    const { orderId, setOrderData, orderData } = props || {};
    const { type } = orderData;
    const timestamps = orderData?.timestamps || {};

    const [loading, setLoading] = useState(false);

    const { onCopy, value, setValue, hasCopied } = useClipboard("");
    const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
    const orgRoles = getOrgRoles();

    useEffect(() => {
        getOrderDetails();
    }, [orderId]);

    const getOrderDetails = async () => {
        setLoading(true);
        const response = (await callApiWithToken("GET", `/api/orders/getOrderDetails?orderId=${orderId}`)) || {};
        const { data = [] } = response;
        setOrderData(data);
        setLoading(false);
    };

    const timestampsItems =
        timestamps &&
        Object.keys(timestamps).map((key) => {
            const status = snakeCase(key.slice(0, -2));
            const newKey = (statusMap[type][status]);
            return {
                title: `${startCase(newKey)}` || startCase(status),
                description: <TimezoneFormatter date={timestamps[key]} format={"MMMM DD YYYY, h:mm:ss A"} />
            };
        });

    const groupedStatus = groupBy(timestampsItems, 'title');
    const uniqueTimestamps = groupedStatus && map(groupedStatus, (group) => Object.assign({}, group[0], group.length > 1 ? group[1] : null));

    const newStatus = !isEmpty(orderData) ? statusMap[orderData?.type][orderData?.status] : "";
    const color = !isEmpty(orderData) ? colorMap[orderData?.type][orderData?.status] : "blue";

    const onCloseHandler = async () => {
        await getOrderDetails();
        onClose();
    };

    return !loading ? (
        <>
            <div style={{ display: "inline-flex", width: "100%" }}>
                <div style={{ float: "left", width: "50%" }}>
                    <Stat float={"left"}>
                        <StatNumber>{orderData?.orderId}</StatNumber>
                        <StatLabel>
                            External Id: {orderData?.externalOrderId}{" "}
                            {/* <Button size="sm" onClick={onCopy}>{hasCopied ?
                            <CgCheck /> : <CgCopy />}
                        </Button> */}
                        </StatLabel>
                        <StatLabel>
                            <Link style={{ color: "#4ACDED", fontWeight: "bold" }} to={`/users/${orderData?.userId}`}>
                                {orderData?.userId}
                            </Link>
                        </StatLabel>
                        <StatHelpText>
                            <HStack>
                                <Tooltip label={startCase(orderData?.status)}>
                                    <Badge colorScheme={color || "grey"}>{newStatus || orderData?.status}</Badge>
                                </Tooltip>
                                {orgRoles.includes("transfi_admin") && <CgPen onClick={onOpen} />}
                            </HStack>
                        </StatHelpText>
                    </Stat>
                </div>
                <div style={{ float: "right", width: "50%" }}>
                    <Stat float={"right"}>
                        <StatNumber fontSize={"md"}>Order Created On</StatNumber>
                        <StatLabel><TimezoneFormatter date={orderData?.createdAt} format={"lll"} /></StatLabel>
                        <StatHelpText>Order Type: {upperCase(orderData?.type)}</StatHelpText>
                    </Stat>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <div style={{ display: "inline-flex", width: "100%" }}>
                <div style={{ float: "left", width: "50%" }}>
                    <Stat float={"left"}>
                        <StatNumber>Fund Details</StatNumber>
                        <StatLabel>
                            Fiat Currency: <b>{orderData?.fiatTicker || "N/A"}</b>
                        </StatLabel>
                        <StatLabel>
                            Fiat Amount: <b>{orderData?.metaData?.fiatName + " " + (orderData?.fiatAmount || "N/A")}</b>
                        </StatLabel>
                        <StatLabel>
                            Network Fees:{" "}
                            <b>{orderData?.metaData?.fiatName + " " + (orderData?.fees?.networkFee || "N/A")}</b>
                        </StatLabel>
                        <StatLabel>
                            Processing Fees:{" "}
                            <b>{orderData?.metaData?.fiatName + " " + (orderData?.fees?.processingFee || "N/A")}</b>
                        </StatLabel>
                    </Stat>
                </div>
                <div style={{ float: "right", width: "50%" }}>
                    <Stat float={"right"} textAlign={"right"}>
                        <StatNumber>Crypto Details</StatNumber>
                        <StatLabel>
                            Crypto Currency: <b>{orderData?.metaData?.cryptoName || "N/A"}</b>
                        </StatLabel>
                        <StatLabel>
                            Crypto Amount: <b>{(orderData?.cryptoAmount || "N/A") + " " + orderData?.cryptoTicker}</b>
                        </StatLabel>
                        <StatLabel>
                            Crypto Price:{" "}
                            <b>{orderData?.metaData?.fiatName + " " + (orderData?.cryptoUnitPrice || "N/A")}</b>
                        </StatLabel>
                        <StatLabel>
                            Wallet: <b style={{ color: "#4ACDED" }}>{orderData?.walletAddress || "N/A"}</b>
                        </StatLabel>
                    </Stat>
                </div>
            </div>
            <br />
            <br />
            <Box>
                <Text fontSize={"20px"} fontWeight={700} color="#38B2AC" letterSpacing={0.2}>Timestamps</Text><br />
                {!isEmpty(timestamps) ? (
                    <Steps
                        style={{ fontWeight: 500 }}
                        progressDot
                        current={10}
                        items={uniqueTimestamps}
                    />
                ) : (
                    <Text>No timestamps found.</Text>
                )}
            </Box>
            <StatusEditModal
                isLoading={loading}
                orderId={orderId}
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={() => onCloseHandler()}
            />
        </>
    ) : (
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={"#FFFFFF"} size={100} />
    );
}
