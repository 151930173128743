import React, { useEffect, useState } from "react";
import Loader from "react-js-loader";
import { FaEye } from "react-icons/fa";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Button,
  Divider,
  Input,
  InputGroup,
  useClipboard,
  useDisclosure,
  Heading,
  useToast,
  HStack,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";

import Select from "react-select";
import { binance_fiat_ticker, binance_crypto_ticker, binance_product_type, country_code } from "./docs";
import BinanceWidgetModal from "./BinanceWidgetModal";
import { callApiWithToken } from "../../utils/utils";
import { getOrgId } from '../../components/App/useToken';
import ViewPayloadModal from "./ViewPayloadModal";

const BinanceWidget = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenViewPayload, onOpen: onOpenViewPayload, onClose: onCloseViewPayload } = useDisclosure();

  const [userId, setUserId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [countryCode, setCountryCode] = useState({ value: "PH", label: "PH" });
  const [fiatTickerValue, setFiatTickerValue] = useState({ value: 'PHP', label: 'PHP' });
  const [cryptoTickerValue, setCryptoTickerValue] = useState({ value: "USDC", label: "USDC" });
  const [fiatAmount, setFiatAmount] = useState(0);
  const [cryptoAmount, setCryptoAmount] = useState(0);
  const [redirectionUrl, setRedirectionUrl] = useState("");
  const [orderType, setOrderType] = useState({ value: 'buy', label: 'Buy' });
  const [orderResponse, setOrderResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState();
  const [selectedPaymentCode, setSelectedPaymentCode] = useState();
  const [loadPaymentMethods, setLoadPaymentMethods] = useState(true);
  const [bankCodes, setBankCodes] = useState([]);
  const [paymentAccountNumber, setPaymentAccountNumber] = useState("");

  const { onCopy: onOrderIdCopy, value: orderIdValue, setValue: setOrderIdValue, hasCopied: hasOrderIdCopied } = useClipboard("");
  const { onCopy: onPaymentUrlCopy, value: paymentUrlValue, setValue: setPaymentUrlValue, hasCopied: hasPaymentUrlCopied } = useClipboard("");

  useEffect(() => {
    if (orderType?.value === 'sell') {
      getPaymentCodes(fiatTickerValue.value);
    }
  }, [orderType, selectedPaymentType]);

  const currencyCodeMap = {
    PHP: "+63",
    IDR: "+62",
    EGP: "+20",
  };

  const getPaymentCodes = async (fiatTicker) => {
    setLoadPaymentMethods(true);
    const response = await callApiWithToken("GET", `/api/widget/paymentMethods?currency=${fiatTicker}&product=${orderType.value}`);
    const { data = [] } = response || {};
    let dataRespone = data;
    if (selectedPaymentType.value === "local") {
      dataRespone = data.filter((records) => records.type === "local_wallet");
    }
    if (selectedPaymentType.value === "bank") {
      dataRespone = data.filter((records) => records.type === "bank_transfer" && records.countries.includes(countryCode?.value) && records.bankCode);
    }
    const methods = dataRespone.map((metaData) => {
      return {
        label: metaData.name,
        value: metaData.referenceName,
      }
    });
    setSelectedPaymentCode("");
    setBankCodes(methods);
    setLoadPaymentMethods(false);
  }

  const toast = useToast();
  const onSubmit = async () => {
    setLoading(true);
    const body = {
      "userId": userId,
      "orderId": orderId,
      "countryCode": countryCode?.value,
      "fiatAmount": fiatAmount,
      "fiatTicker": fiatTickerValue?.value,
      "cryptoAmount": cryptoAmount,
      "cryptoTicker": cryptoTickerValue?.value,
      "redirectUrl": redirectionUrl,
      "product": orderType,
      "paymentType": selectedPaymentType.value,
      firstName,
      lastName,
      ...(orderType?.value === 'sell' ? { paymentAccountNumber, paymentCode: selectedPaymentCode.value } : {})
    }

    const response = await callApiWithToken("POST", "/api/widget/getBinanceOrderDetails", { orderDetails: body, orgId: getOrgId() });
    if (response?.message) {
      toast({
        title: 'Error!',
        description: response?.message || "Failed to fetch order details.",
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      })
    } else {
      const { data } = response || {};
      setOrderResponse(data);
      setOrderIdValue(data?.orderId);
      setPaymentUrlValue(data?.paymentUrl);
    }
    setLoading(false);
  }

  return (
    !loading ? <>
      <div style={{ display: "block", marginBottom: "20px" }}>
        <Heading size={"sm"} marginTop="4" paddingLeft="5">User Details:</Heading>
        <Flex
          justifyContent={"space-evenly"}
          alignItems={"flex-end"}
          gap="5"
          p={5}
          width="100%"
        >
          <FormControl>
            <FormLabel>First Name:</FormLabel>
            <Input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Last Name:</FormLabel>
            <Input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormControl>

          <FormControl>
            <FormLabel>User Id:</FormLabel>

            <Input
              type="text"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Country Code:</FormLabel>

            <Select
              options={country_code}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,

                }),
              }}
              value={countryCode}
              onChange={value => setCountryCode(value)}
            />
          </FormControl>
        </Flex>
        <Divider opacity={1} />
        <Heading size={"sm"} marginTop="4" paddingLeft="5">Order Details:</Heading>
        <Flex
          justifyContent={"space-evenly"}
          alignItems={"flex-end"}
          gap="5"
          p={5}
          width="100%"
        >
          <FormControl>
            <FormLabel>Order Id:</FormLabel>

            <Input
              type="text"
              value={orderId}
              onChange={(e) => setOrderId(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Order Type:</FormLabel>
            <Select
              options={binance_product_type}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,

                }),
              }}
              value={orderType}
              onChange={value => setOrderType(value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Redirection Url:</FormLabel>

            <Input
              type="text"
              value={redirectionUrl}
              onChange={(e) => setRedirectionUrl(e.target.value)}
            />
          </FormControl>
        </Flex>
        <Flex
          justifyContent={"space-evenly"}
          alignItems={"flex-end"}
          gap="5"
          p={5}
          width="100%"
        >
          <FormControl>
            <FormLabel>Fiat Ticker:</FormLabel>
            <Select
              options={binance_fiat_ticker}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,

                }),
              }}
              value={fiatTickerValue}
              onChange={value => setFiatTickerValue(value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Payment Type:</FormLabel>
            <Select
              options={[
                {
                  label: 'Local Wallet',
                  value: 'local'
                }, {
                  label: 'Online Banking',
                  value: 'bank'
                }
              ]}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,

                }),
              }}
              value={selectedPaymentType}
              onChange={value => setSelectedPaymentType(value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Fiat Amount:</FormLabel>

            <Input
              type="number"
              value={fiatAmount}
              onChange={(e) => setFiatAmount(parseInt(e.target.value))}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Crypto Ticker:</FormLabel>
            <Select
              options={binance_crypto_ticker}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,

                }),
              }}
              value={cryptoTickerValue}
              onChange={value => setCryptoTickerValue(value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Crypto Amount:</FormLabel>

            <Input
              type="number"
              value={cryptoAmount}
              onChange={(e) => setCryptoAmount(parseInt(e.target.value))}
            />
          </FormControl>
        </Flex>


        {orderType?.value === 'sell' && selectedPaymentType?.value === 'bank' ?
        <><Divider opacity={1} />
            <Heading size={"sm"} marginTop="4" paddingLeft="5">Bank Details:</Heading>
            <Flex
              justifyContent={"space-evenly"}
              alignItems={"flex-end"}
              gap="5"
              p={5}
              width="100%"
            >
              <FormControl>
                <FormLabel>Account No.:</FormLabel>
                <Input
                  type="text"
                  value={paymentAccountNumber}
                  onChange={(e) => setPaymentAccountNumber(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Payment Code:</FormLabel>
                <Select
                  options={bankCodes}
                  isLoading={loadPaymentMethods}
                  styles={{
                    control: (baseStyles, 
                        state) => ({
                      ...baseStyles,
                    }),
                  }}
                  value={selectedPaymentCode}
                  onChange={(value) => setSelectedPaymentCode(value)}
                />
              </FormControl>
            </Flex>
          </> :
          <></>
        }

        {orderType?.value === "sell" && selectedPaymentType?.value === "local" ? 
          <>
            <Divider opacity={1} />
            <Heading size={"sm"} marginTop="4" paddingLeft="5">
              Local Wallet Details:
            </Heading>
            <Flex
              justifyContent={"space-evenly"}
              alignItems={"flex-end"}
              gap="5"
              p={5}
              width="100%"
            >
              <FormControl>
                <FormLabel>Mobile Number:</FormLabel>
                <HStack>
                  <InputGroup width={"20%"}>
                    <Input
                      bg="#f6f6f6"
                      color="#000000"
                      value={currencyCodeMap[fiatTickerValue?.value]}
                      textStyle={"DmSans"}
                      disabled
                    />
                  </InputGroup>
                  <InputGroup>
                    <Input
                      type="text"
                      value={paymentAccountNumber}
                      onChange={(e) => setPaymentAccountNumber(e.target.value)}
                    />
                  </InputGroup>
                </HStack>
              </FormControl>
              <FormControl>
                <FormLabel>Payment Code:</FormLabel>
                <Select
                  options={bankCodes}
                  isLoading={loadPaymentMethods}
                  styles={{
                    control: (baseStyles, state) => ({
 ...baseStyles,
                    }),
                  }}
                  value={selectedPaymentCode}
                  onChange={value => setSelectedPaymentCode(value)}
                />
              </FormControl>
            </Flex></> : <></>
        }

        <Box textAlign={"right"} paddingRight="20px">
          <Button 
          onClick={e => onSubmit()} colorScheme="blue"
          >
            Submit
          </Button>
        </Box>
      </div>
      {!isEmpty(orderResponse) ? <div>
          <Divider opacity={1} />
          <Flex
            justifyContent={"space-evenly"}
            alignItems={"flex-end"}
            gap="5"
            p={5}
            width="100%"
          >
            <FormControl>
              <FormLabel> Transfi Order Id:</FormLabel>
              <Flex>
                <Input
                  type="text"
                  value={orderIdValue}
                  disabled
                  onChange={(e) => setOrderIdValue(e.target.value)}
                />
                <Button marginLeft={1} onClick={onOrderIdCopy}> {hasOrderIdCopied ? "Copied!" : "Copy"}</Button>
              </Flex>
            </FormControl>
            <FormControl>
              {!isEmpty(paymentUrlValue) && <><FormLabel>Payment Url:</FormLabel><Flex>
                    <Input
                      type="text"
                      value={paymentUrlValue}
                      disabled
                      onChange={(e) => setPaymentUrlValue(e.target.value)} />
                    <Button marginLeft={1} onClick={onPaymentUrlCopy}>{hasPaymentUrlCopied ? "Copied!" : "Copy"}</Button>
                  </Flex></>}
            </FormControl>
          </Flex>
          <Box textAlign={"right"} paddingRight="20px">
            <Button leftIcon={<FaEye />} colorScheme="teal" variant="solid" marginRight={2} onClick={e => onOpenViewPayload()}>
              View Payload
            </Button>
            {!isEmpty(paymentUrlValue) && <Button 
            onClick={e => onOpen()} colorScheme="blue"
            >
                Render Widget
              </Button>}
            <BinanceWidgetModal isOpen={isOpen} onClose={onClose} paymentUrl={paymentUrlValue} orderId={orderIdValue} />
            <ViewPayloadModal
              isOpen={isOpenViewPayload}
              onClose={onCloseViewPayload}
              jsonPayload={{
                "userId": userId,
                "orderId": orderId,
                "countryCode": countryCode?.value,
                "orderType": orderType?.value,
                "fiatAmount": fiatAmount,
                "fiatTicker": fiatTickerValue?.value,
                "cryptoAmount": cryptoAmount,
                "cryptoTicker": cryptoTickerValue?.value
              }}
              redirectionUrl={redirectionUrl}
            />
          </Box>
        </div> : <></>
}
    </>
    : <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={'#FFFFFF'} size={100} />
  );
};

export default BinanceWidget;
