import { Button, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text, useToast } from '@chakra-ui/react';
import React, { useState, useContext } from 'react';
import { startCase } from 'lodash';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { callApiWithTokenV2 } from '../../../utils/utils';
import DateRangePicker from '../../Common/DateRangePicker';
import { NavBarContext } from '../../../context/NavBarContext';

const PayinSettlementModal = ({ isOpen, onClose, data, setSelect, handleReset }) => {

    const [loading, setLoading] = useState(false);
    const [settlementData, setSettlementData] = useState({});
    const [dateRange, setDateRange] = useState({
        startDate: moment(new Date()).subtract(6, 'days').toISOString(),
        endDate: moment(new Date()).toISOString()
    });

    const toast = useToast();

    const navigate = useNavigate();

    const handleCloseModal = () => {
        setLoading(false);
        setSelect(false);
        onClose();
    }

    const onChangeDateRange = (start, end) => {
        setDateRange({ startDate: moment(start).toISOString(), endDate: moment(end).toISOString() });
    }

    const { selectedOrg } = useContext(NavBarContext);

    const getOrderData = async () => {
        try {
            setLoading(true);
            if (selectedOrg[0] === 'all' || selectedOrg.length > 1) {
                toast({
                    title: "Caution",
                    description: "Select one particular organization",
                    status: "warning",
                    duration: 3000,
                    isClosable: true,
                });
                handleCloseModal();
                setLoading(false);
                return;
            }
            const { data } = await callApiWithTokenV2.post(`/api/settlement/tfpaySettlement/details`, { impersonatedOrg: selectedOrg }, {
                params: {
                    startDate: dateRange?.startDate,
                    endDate: dateRange?.endDate,
                }
            })
            setSettlementData(data?.data);
            setLoading(false);
            if (data?.data?.finalResult && data?.data?.finalResult?.length === 0) {
                toast({
                    description: "No unsettled order was found in selected range",
                    status: "info",
                    duration: 3000,
                    isClosable: true,
                });
                handleCloseModal();
                setLoading(false);
                return;
            }
            if (data?.data) {
                const settlementData = { ...data?.data, orgName: selectedOrg[0] }
                navigate('/payin-orders/settlements/create', { state: settlementData })
            }
            return data?.data;
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const handleGetSettlementDetails = async () => {
        getOrderData()
    }

    return (
        <Modal isOpen={isOpen} onClose={handleCloseModal} motionPreset='slideInBottom' size={'3xl'} >
            <ModalOverlay />
            <ModalContent width={'7xl'} bg={'#FAFAFA'} p={5} minH={'sm'}>
                <ModalHeader >
                    <Text style={{ color: "#1A4FD6", fontFamily: "arial" }}>Create Settlement</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack direction={"column"} justifyContent="space-between" flexGrow={1}>
                        <Text style={{ fontFamily: "arial" }} my={2}>Select a date range to get orders details of <Text as={'span'} fontWeight={'semibold'} color={"#1A4FD6"}>{startCase(selectedOrg[0])}</Text>{' '}organization</Text>
                        <DateRangePicker
                            width={"100%"}
                            onChangeDateRange={onChangeDateRange}
                            start={dateRange?.startDate}
                            end={dateRange?.endDate}
                        />
                    </Stack>
                </ModalBody>
                <ModalFooter justifyContent={'center'}>
                    <HStack mt={2} justify={'center'}>
                        <Button
                            w={"150px"}
                            bg="#ffffff"
                            color="#525C76"
                            border={"1px"}
                            borderColor={"#525C76"}
                            variant={"outline"}
                            onClick={handleCloseModal}
                            _hover={{ bg: '#ffffff' }}
                        >
                            Cancel
                        </Button>
                        <Button
                            isLoading={loading}
                            bg="#1A4FD6"
                            w={"150px"}
                            color={"#ffffff"}
                            _hover={{ bg: "#232d95" }}
                            onClick={handleGetSettlementDetails}
                        >
                            Create
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default PayinSettlementModal;