import React, { useEffect, useState } from "react";
import { Box, useToast } from "@chakra-ui/react";

//Custom Conmponents or functions
import OTPInputBoxes from "./OTPInputBoxes";
import Loader from "../../../utils/Loader";
import { callApi } from "../../../utils/utils";

//css
import "../Login.css"
import "./PasswordLogin.css"

//icons
import png from '../../../icons/background.png';
import Logo from "./icons/Logo.png";

const OTPVerify = (props) => {
    const toast = useToast();

    const { email, setOTPVerified, setMfaStatus, reset } = props || {};
    const [otp, setOtp] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [counter, setCounter] = useState(60);
    const [disableResend, setDisableResend] = useState(true);
    const [passwordAreadySetteled, setPasswordSettled] = useState(false);

    useEffect(() => {
        if (counter === 0) {
            setDisableResend(false);
            return;
        }
        const timer = setTimeout(() => {
            setCounter(counter - 1);
        }, 1000);
        return () => clearTimeout(timer);
    }, [counter]);

    const sendOtpToEmail = async () => {
        try {
            setIsLoading(true);
            const data = await callApi("POST", `/api/customers/verifyEmail`, JSON.stringify({ email, resetPassword: reset }));
            const { status = false, message, alreadyPresent = false } = data;

            if (alreadyPresent) {
                setPasswordSettled(true);
                setIsLoading(false);
                return;
            }

            if (status) {
                setIsLoading(false);
            } else {
                throw new Error(message);
            }

            handleToast("Otp sent successfully", 'success');
        } catch (e) {
            handleToast(e?.message || "Something went wrong", 'error');
            setIsLoading(false);
        }
    }

    const handleResendOTP = () => {
        sendOtpToEmail();
        setDisableResend(true);
        setCounter(60);
    };

    const handleToast = (title, type) => {
        toast({
            title,
            status: type,
            duration: 5000,
            isClosable: true
        });
    };

    const verifyOtp = async () => {
        if (otp.length < 6) {
            handleToast("Please Enter Valid OTP", "error");
            return;
        }
        try {
            setIsLoading(true);
            const data = await callApi("POST", `/api/customers/verifyNewPasswordOTP`, JSON.stringify({ email, otp }));
            const { otpVerified = false, message, mfaStatus } = data;

            if (otpVerified) {
                if (mfaStatus) {
                    setMfaStatus(mfaStatus);
                } else {
                    setOTPVerified(otpVerified);
                }
                setIsLoading(false);
            } else {
                throw new Error(message);
            }

            handleToast("Otp Successfully Verified", 'success');
        } catch (e) {
            handleToast(e?.message || "Something went wrong", 'error');
            setIsLoading(false);
        }
    }

    return (
        isLoading ? <Loader /> :
            <Box className="pa-login-container" backgroundImage={png}>
                <div className="pa-login-card">
                    {!passwordAreadySetteled ? <>
                        <img className="pa-login-logo-trouble" style={{ marginTop: '158px' }} src={Logo} />
                        <p className="pa-login-heading">Verify OTP</p>
                        <p className="pa-login-sub-heading" style={{ marginTop: '84px' }}>Enter 6 digit OTP sent to your email<span style={{ color: "#1A4FD6" }}>{` ${email}`}</span></p>
                        <OTPInputBoxes setOtp={setOtp} />
                        <p className="pa-login-sub-heading" style={{ marginTop: '32px' }}>Didn’t receive your code?</p>
                        <p className={disableResend ? 'pa-forget-password' : 'pa-forget-password-enabled'} style={{ marginTop: '8px' }}>
                            <a onClick={() => { !disableResend && handleResendOTP() }}>Resend OTP {disableResend ? `(${counter})` : ''}</a>
                        </p>
                        <button className="pa-button" style={{ marginTop: '60px' }} onClick={() => verifyOtp()}>
                            Continue
                        </button>
                        <div className="pa-transfi-banner" style={{ marginTop: '96px' }}>
                            <p className="pa-powered-by">Powered by</p>
                            <img style={{ width: '24px', height: '17px', marginLeft: '8px', marginRight: '8px' }} src={Logo} />
                            <p className="pa-powered-transfi">TransFi</p>
                        </div>
                    </> :
                        <>
                            <img className="pa-login-logo-trouble" style={{ marginTop: '96px' }} src={Logo} />
                            <p className="pa-login-heading">Set New Password</p>
                            <p className="pa-login-sub-heading" style={{ marginTop: '84px', color: "#1A4FD6", fontSize: '18px' }}>
                                Password already exist. Please use the login screen to access the dashboard or to reset your password.
                            </p>
                            <div className="pa-transfi-banner" style={{ marginTop: '96px', marginBottom: '96px' }}>
                                <p className="pa-powered-by">Powered by</p>
                                <img style={{ width: '24px', height: '17px', marginLeft: '8px', marginRight: '8px' }} src={Logo} />
                                <p className="pa-powered-transfi">TransFi</p>
                            </div>
                        </>
                    }

                </div>
            </Box>
    );
};

export default OTPVerify;
