
/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  FormControl,
  FormLabel,
  chakra,
  Input,
  FormErrorMessage
} from "@chakra-ui/react";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { callApiWithToken } from "../../utils/utils";

export default function ApproveModal(props) {
  const { isOpen, onClose, selectedRow, refreshGrid } = props;
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState({});


  const onCloseHandler = () => {
    onClose();
    setNotes({});
    setLoading(false);
  };

  const closeModal = () => {
    formik.resetForm();
    onClose();
    setLoading(false);
  }

  const formik = useFormik({
    initialValues: {
      notes: ''
    },
    validationSchema: Yup.object({
      notes: Yup.string().required("Please provide description!!"),
    }),
    onSubmit: async (formValues) => {
      try {
        setLoading(true);
        const response = await callApiWithToken("POST", "/api/refund/update", {
          rfId: selectedRow?.rfId,
          orderId: selectedRow?.orderId,
          notes: formValues?.notes
        });
        if (response?.message) {
          toast({
            title: 'Success',
            description: response?.message,
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top-right'
          })
        }
        if (response?.error) {
          toast({
            title: 'Error!',
            description: response?.error,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right'
          })
        }
        setLoading(false);
        refreshGrid();
        closeModal();
      } catch (err) {
        toast({
          title: 'Error!',
          description: "Failed to fetch order details.",
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right'
        })
        setLoading(false);
        closeModal();
      }
      formik.setValues({
        notes: '',
      });
    },
  });

  return (
    <chakra.form onSubmit={formik.handleSubmit} display="flex" flexGrow={1}>
      <Modal isOpen={isOpen} onClose={() => closeModal()} size={"xl"}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Confirm Refund</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={2}>
            <FormControl mt={4} isRequired isInvalid={formik.touched.notes && formik.errors.notes} >
              <FormLabel>Description</FormLabel>
              <Input
                id="notes"
                type="text"
                placeholder={"Please provide the description"}
                size="md"
                value={formik.values.notes}
                onChange={formik.handleChange}
              />
                <FormErrorMessage>{formik.errors.notes}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={formik.handleSubmit} colorScheme="blue" mr={3} isLoading={loading}>
              Okay
            </Button>
            <Button onClick={() => closeModal()}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </chakra.form>
  );
}