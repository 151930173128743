import React from 'react'
import { Box, Text, OrderedList, ListItem } from '@chakra-ui/react'

const Instruction = () => {
  return (
    <Box boxShadow={"md"} width={"100%"} p={5} borderRadius="10">
            <Text fontWeight="bold">User Guide</Text>
            <OrderedList lineHeight={'40px'}>
              <ListItem>
                Complete the User Journey, Country, and Payment Methods fields.
              </ListItem>
              <ListItem>
                The widget will generate dummy details and instructions on the
                right side.
              </ListItem>
              <ListItem>
                Utilize the generated dummy details and instructions to create
                test users and orders.
              </ListItem>
              <ListItem>
                You can modify the above information at any time to create new
                test cases and repeat the process from step 1.
              </ListItem>
            </OrderedList>
          </Box>
  )
}

export default Instruction