export const kycDocs = [
  {
    label: "Driver’s license",
    value: "Driverslic.png",
    path: "driving_license.jpeg",
  },
  {
    label: "Passport",
    value: "passportfile1.png",
    path: "ashwariya_rai_passport.png",
  },
  {
    label: "Passport 2",
    value: "passportfile2.png",
    path: "salaudin_ansari_passport.jpeg",
  },
  {
    label: "Identity Card",
    value: "govtidphoto.png",
    path: "government.jpeg",
  },
];

export const cardDetails = [
  {
    label: "Card Holder’s Name:",
    value: "FL-BRW1",
  },
  {
    label: "Card Number:",
    value: "4000027891380961",
  },
  {
    label: "Expiry Date",
    value: "11/25",
  },
  {
    label: "CVV :",
    value: "123",
  },
];

export const user_journey = [
  { value: "new_user", label: "First Time User" },
  { value: "existing_user", label: "Returning User" },
];

export const product_type = [
  { value: 'buy', label: 'Buy' },
  { value: 'sell', label: 'Sell' },
  { value: 'both', label: 'Both' }
]
export const country_code = [
  { value: 'PH', label: 'PH' },
  { value: 'ID', label: 'ID' }
]

export const binance_fiat_ticker = [
  { value: 'PHP', label: 'PHP' },
  { value: 'IDR', label: 'IDR' }
]

export const binance_crypto_ticker = [
  { value: 'USDC', label: 'USDC' },
  { value: 'USDT', label: 'USDT' }
]

export const binance_product_type = [
  { value: 'buy', label: 'Buy' },
  { value: 'sell', label: 'Sell' }
]