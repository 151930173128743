import React, { useContext, useEffect, useState } from "react";
import { Box, Center, useToast,Stack, Link ,Modal,ModalCloseButton , FormControl,chakra ,InputGroup,Input, HStack, useDisclosure,ModalFooter, ModalBody, ModalHeader, ModalOverlay, ModalContent,Button, FormLabel, Flex, Text,Select as ChakraSelect, Spinner } from "@chakra-ui/react";
import { isEmpty, join, startCase } from "lodash";
import { Form, Field,useFormik ,FormikProvider } from "formik";
import TransfiGrid from "../../../utils/TransfiGrid";
import { NavBarContext } from "../../../context/NavBarContext";
import { BiEdit, BiRefresh } from "react-icons/bi";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { getEnv } from "../../App/useToken";
import { callApiWithToken } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { IoAddOutline } from "react-icons/io5";


export default function Pricing() {
    const gridRef = React.useRef();
    const { selectedOrg } = useContext(NavBarContext);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
    const { isOpen: isOpenEditModal, onOpen: onOpenEditModal,onClose:onCloseEditModal}= useDisclosure({ defaultIsOpen: false });
    const env = getEnv();
    const shouldRenderData = selectedOrg.length === 1 && selectedOrg[0] !== 'All';
    const toast = useToast();
    const [loading, setLoading] = useState(false);
    const navigate=useNavigate()
    const [isRefreshing, setIsRefreshing] = useState(true);
    const [api, setApi] = useState(null);
    const [rowData, setRowData] = useState([]);




    const handleRowClick = (rowData) => {
        setSelectedRowData(rowData);
        onOpenEditModal();
        
    };

    const confirmDelete = async (rowData) => {
        setSelectedRowData(rowData);
        setDeleteConfirmationModalOpen(true);
      };

      const deleteRoute = async () => {


        try{
            setLoading(true);
            const response = await callApiWithToken("POST", "/api/admin/finance/feeConfigs/deleteFeeConfig", {
                id:selectedRowData?.id,
                selectedOrg
            });
            if (response?.message) {
                toast({
                    title: 'Success',
                    description: response?.message || "Fee config deleted successfully",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
            }
            setLoading(false);
            setDeleteConfirmationModalOpen(false);
            refreshGrid();
        }
        catch(err){
            toast({
                title: 'Error!',
                description: response?.message || "Failed to fetch",
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right'
            })
            refreshGrid();
        }
    }

    const onGridReady = (params) => {
        console.log('Grid is ready!');
        setApi(params.api);
        console.log(api)
      };

      const refreshGrid = async () => {
        try {
          setIsRefreshing(true);
          const response = await callApiWithToken('POST', '/api/admin/finance/feeConfigs',{
            selectedOrg
          });
          setRowData(response?.data);
        } catch (error) {
          console.error('Error refreshing payment methods:', error);
        }
        setIsRefreshing(false);
      };
      useEffect(() => {
        setIsRefreshing(true);
        const fetchData = async () => {
          try {
            const response = await callApiWithToken('POST', '/api/admin/finance/feeConfigs',{
                selectedOrg
            });
            setRowData(response?.data);
            console.log("rowData",rowData);
          } catch (error) {
            console.error('Error fetching payment methods:', error);
          }
          setIsRefreshing(false);
        };
    
        fetchData();
      }, [selectedOrg]);
      

      const EditConfigs = ({ data, handleRowClick }) => {
        const handleEditClick = () => {
          handleRowClick(data);
        };

        const handleDeleteClick = () => {
          confirmDelete(data);
        };
      
        return (
           <div className="flex items-center justify-center gap-4 my-2">
            <button onClick={handleEditClick} className="flex items-center justify-center gap-1 tracking-wider font-semibold text-sm text-black hover:scale-110 duration-200 transition-all active:scale-90">
            <BiEdit /> 
            <span>Edit</span>
           
          </button>
            <button onClick={handleDeleteClick} className="flex items-center justify-center gap-1 tracking-wider font-semibold  text-white p-2 bg-red-500 text-sm hover:text-bg-500 rounded-md hover:scale-110 duration-200 transition-all active:scale-90">
            < DeleteIcon/> 
            <span>Delete</span>
           
          </button>
           </div>
        );
      };

      const colDefs = [

          { headerName: "Product", field: "type" , filter: "agSetColumnFilter" ,pinned:"left", width: 100, cellRenderer: ({ value }) => startCase(value) },
        { headerName: "Currency", field: "currencies" ,filter: "agSetColumnFilter" ,pinned:"left", width: 130, cellRenderer: ({ value }) => value.join(", ") === "DEFAULT" ? "All" : join(value.map((b) => startCase(b)), ", ") },
        { headerName: "Payment Type", field: "paymentTypes" , filter: "agSetColumnFilter" , width: 200,
        headerClass: 'center-align-header', cellClass: 'right-align-cell' , filter: "agSetColumnFilter" , cellRenderer: ({ value }) => value.join(", ") === "DEFAULT" ? "All" : join(value.map((b) => startCase(b)), ", ") },
        { headerName: "Payment Method", field: "paymentCodes", width: 200,
        headerClass: 'center-align-header', cellClass: 'right-align-cell',filter: "agSetColumnFilter" , cellRenderer: ({ value }) => value.join(", ") === "DEFAULT" ? "All" : join(value.map((b) => startCase(b)), ", ") },
        {
            headerName: "Fee To Customer",
            field: "cxFee",
            width: 200,
            headerClass: 'center-align-header', cellClass: 'right-align-cell',
            cellRenderer: ({ data, value }) => {
              if (!value) return <strong>-</strong>;
              return data.feeMode === "fixed" ? `${value} ${data.fiatTicker}` : `${(value * 100).toFixed(2)} % ${data.fixedCxFee ? ` + ${data.fixedCxFee} ${data.fiatTicker}` : ""}`;
            }
          },
          {
            headerName: "Fee To Transfi",
            field: "tfFee",
            width: 200,
            headerClass: 'center-align-header', cellClass: 'right-align-cell',
            cellRenderer: ({ data, value }) => {
              if (!value) return <strong>0</strong>;
              return data.feeMode === "fixed" ? `${value} ${data.fiatTicker}` : `${(value * 100).toFixed(2)} % ${data.fixedTfFee ? ` + ${data.fixedTfFee} ${data.fiatTicker}` : ""}`;
            }
          },
          {
            headerName: "Fee To User",
            field: "fee",
            width: 200,
            headerClass: 'center-align-header', cellClass: 'right-align-cell',
            cellRenderer: ({ data, value }) => {
              if (!value) return <strong>0</strong>;
              return data.feeMode === "fixed" ? `${value} ${data.fiatTicker}` : `${(value * 100).toFixed(2)} % ${data.fixedFee ? ` + ${data.fixedFee} ${data.fiatTicker}` : ""}`;
            }
          },
          {
            headerName: "Treasury Markup",
            field: "markupFees",
            width: 170,
            filter: 'agSetColumnFilter',
            filterParams: {
            cellRenderer:  params => {
                const value = params.value;
               
                if(value === "(Select All)")
                {
                 return value;
                }
                if (!value) return 0;
                return `${(value*100).toFixed(3)}`;
            },
            cellRendererParams: { isFilterRenderer: true },
            } ,
            headerClass: 'center-align-header', cellClass: 'right-align-cell',
            cellRenderer: ({ value }) => {
              if (!value) return <strong>0</strong>;
              return `${(value * 100).toFixed(2)} %`;
            }
          },
          {
            headerName: "Discount",
            field: "discount",
            width: 120,
            headerClass: 'center-align-header', cellClass: 'right-align-cell',
            filter: 'agSetColumnFilter',
            filterParams: {
            cellRenderer:  params => {
                const value = params.value;
              
                if(value === "(Select All)")
                {
                    return value;
                }
                if (!value) return 0;
                return `${(value*100).toFixed(3)}`;
            },
            cellRendererParams: { isFilterRenderer: true },
            } ,
            cellRenderer: ({ value }) => {
                if (!value) return <strong>0</strong>;
                return `${(value * 100).toFixed(2)} %`;
            }
          },
          {
            headerName: "Slabs",
            field: "min",
            width: 100,
            headerClass: 'center-align-header', cellClass: 'right-align-cell',
            cellRenderer: ({ data }) => {
              if (data?.min) {
                return `${data.min} to ${data.max}`;
              } else {
                return '';
              }
            }
          },
          {
            headerName: "Fee Type",
            field: "feeMode",
            width: 120,
            filter: "agSetColumnFilter",
            headerClass: 'center-align-header', cellClass: 'right-align-cell',
            cellRenderer: ({ value }) => {
              if (!value) return <strong>0</strong>;
              return value.toUpperCase();
            }
          },
          {
            field: 'Action',
            cellRenderer: EditConfigs,
            headerClass: 'center-align-header', cellClass: 'right-align-cell',
            cellRendererParams: { handleRowClick },
            headerComponentParams: { textAlign: 'center' }
          }
          
      ];

    

    const closeModal = () => {
        formik.resetForm();
        onCloseEditModal();
        setLoading(false);
    }




    const formik = useFormik({
        initialValues: {
                    baseFee:selectedRowData?.feeMode!=="fixed"? ((Number(selectedRowData?.cxFee) + Number(selectedRowData?.tfFee)) * 100).toFixed(2) : selectedRowData?.fee,
                    transfiFee: selectedRowData?.feeMode!=="fixed"? Number(selectedRowData?.tfFee * 100).toFixed(2) : selectedRowData?.tfFee ,
                    customerFee: selectedRowData?.feeMode!=="fixed"? Number(selectedRowData?.cxFee * 100).toFixed(2) : selectedRowData?.cxFee ,
                    discount:selectedRowData?.discount ? Number( selectedRowData?.discount * 100).toFixed(2) :0,
                    fxFee: selectedRowData?.markupFees ? Number(selectedRowData?.markupFees * 100).toFixed(2) : 0,
                    fixedCxFee:Number(selectedRowData?.fixedCxFee) ,
                    fixedBaseFee:Number(selectedRowData?.fixedFee) ,
                    fixedTfFee:Number(selectedRowData?.fixedTfFee ),
                    paymentTypes:selectedRowData?.paymentTypes,
                    
                   
        },
        onSubmit: async (formValues) => {
            try {
                
                const response = await callApiWithToken("PUT", "/api/admin/finance/feeConfigs/update", {
                 
                    id:selectedRowData?.id,
                    fee:selectedRowData?.feeMode!=="fixed" ? String(((Number(formValues?.customerFee) + Number(formValues?.transfiFee))/100)): String(Number(formValues?.customerFee) + Number(formValues?.transfiFee)),
                    cxFee:selectedRowData?.feeMode!=="fixed" ? String((formValues?.customerFee/100)) :String( formValues?.customerFee) ,
                    tfFee: selectedRowData?.feeMode!=="fixed" ? String((formValues?.transfiFee/100)) : String(formValues?.transfiFee),
                    discount:String((formValues?.discount/100)),
                    fxFee:String((formValues?.fxFee/100)),
                    ...(selectedRowData?.feeMode === "hybrid" && { fixedCxFee: String(formValues?.fixedCxFee) }),
                    ...(selectedRowData?.feeMode === "hybrid" && { fixedTfFee:String(formValues?.fixedTfFee)}),
                    ...(selectedRowData?.feeMode === "hybrid" && { fixedBaseFee:String(Number(formValues?.fixedCxFee) + Number(formValues?.fixedTfFee))}),
                    paymentTypes:formValues?.paymentTypes,
                    currencies:selectedRowData?.currencies,
                    paymentCodes:selectedRowData?.paymentCodes,
                    selectedOrg:selectedOrg,
                    feeMode:selectedRowData?.feeMode,
                    type:selectedRowData?.type
                });
                if (response?.message) {
                    toast({
                        title: 'Success',
                        description: response?.message,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                        position: 'top-right'
                    })
                }
                closeModal();
                refreshGrid();
            } catch (err) {
                toast({
                    title: 'Error!',
                    description: response?.message || "Failed to fetch order details.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
                closeModal();
            }
        },
        enableReinitialize:true,
    });

    
    return (
        <Center py={5} bg="white.100">
      {shouldRenderData ? (
        <Stack spacing={2} w="100%" >
          <Box bg="white" p={4}>
                      <div className="w-full flex items-center justify-end">
                      <Button
                        leftIcon={<AddIcon style={{ fontSize: "20px" }} />}
                        variant={"outline"}
                        colorScheme="blue"
                        style={{ margin: "0 4px" }}
                        size="sm"
                        onClick={()=>navigate("/admin/add-new-currency")}
                      >
                        Add
                      </Button>
                  <Button
                        leftIcon={<BiRefresh style={{ fontSize: "20px" }} />}
                        onClick={() => refreshGrid()}
                        variant={"outline"}
                        colorScheme="blue"
                        style={{ margin: "0 4px" }}
                        size="sm"
                    >
                        Refresh
                    </Button>
                      </div>
            {isRefreshing ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 700, width: '100%', marginTop: '20px' }}>
                <Spinner size="xl" />
              </div>
            ) : (
              <div className="ag-theme-quartz" style={{ height: 600, width: '100%', marginTop: '20px' }}>
                <AgGridReact
                  rowData={rowData}
                  columnDefs={colDefs}
                  pagination={true}
                  paginationPageSize={50}
                  paginationPageSizeSelector={[50, 100, 150, 200]}
                  onGridReady={onGridReady}
                />
              </div>
            )}
          </Box>
        </Stack>
      ) : (
        <Text>Please select only one organisation to manage the payment routes configuration..</Text>
      )}
            <Modal isOpen={deleteConfirmationModalOpen} onClose={() => setDeleteConfirmationModalOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Confirm Delete</ModalHeader>
                <ModalCloseButton />
                <ModalBody>Are you sure you want to delete this route?</ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={deleteRoute} isLoading={loading}>
                    Confirm
                    </Button>
                    <Button onClick={() => setDeleteConfirmationModalOpen(false)}>Cancel</Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
          
            <Modal isOpen={isOpenEditModal} onClose={onCloseEditModal} onOpen={onOpenEditModal} >
            <ModalOverlay/>
            <ModalContent>
            <ModalHeader className="" style={{textTransform:"uppercase"}}>Fee configs</ModalHeader>
            <ModalCloseButton />
            <ModalBody>

  <FormControl isRequired isInvalid={formik.touched && formik.errors.customerFee} >
                <FormLabel> Fee to customer </FormLabel>
                
            <Flex>
            {selectedRowData?.feeMode === "hybrid" ? (
                        <>
                          <Input
                            
                            id="customerFee"
                            borderColor={"gray.400"}
                            type="number"
                            value={(formik?.values?.customerFee )}
                            color={'black'}
                            onChange={formik.handleChange}
                            
                          />
                          <Input  value="%" isDisabled color={'black'}  />
                           
                          <Input isDisabled  value="+" fontSize={"2xl"} color={'black'}  />


                          <Input
                            borderColor={"gray.400"}
                            id="fixedCxFee"
                            type="number"
                            value={formik?.values?.fixedCxFee}
                            color={'black'}
                            onChange={formik.handleChange}
                          />
                        </>
               ) : (
              <>
                    <Input  id="customerFee" borderColor={"gray.400"} type="number" value={ formik?.values?.customerFee } color={'black'} onChange={formik.handleChange} />

                 {!(selectedRowData?.feeMode ==="fixed") && <Input  type='text' value={"%"} isDisabled  color={'black'}  />}
              </>
               )}            {!(selectedRowData?.feeMode==="percentage") && <Input  type='text' value={selectedRowData?.fiatTicker} isDisabled  color={'black'}  />}
            </Flex>

            </FormControl>

<br />

            <FormControl isRequired isInvalid={formik.touched.transfiFee && formik.errors.transfiFee}>
                <FormLabel> Fee to transfi </FormLabel>
               <Flex>
  
               {selectedRowData?.feeMode === "hybrid" ? (
                        <>
                          <Input
                            borderColor={"gray.400"}
                            id="transfiFee"
                            type="number"
                            value={formik?.values?.transfiFee }
                            color={'black'}
                            onChange={formik.handleChange}
                          />
                          <Input  value="%" isDisabled color={'black'}  />
                          <Input isDisabled  value="+" fontSize={"2xl"} color={'black'}  />

                          <Input
                            
                            id="fixedTfFee"
                            borderColor={"gray.400"}

                            type="number"
                            value={formik?.values?.fixedTfFee}
                            color={'black'}
                            onChange={formik.handleChange}
                          />
                        </>
               ) : (
                <>
                             <Input  id="transfiFee" borderColor={"gray.400"} type='number' value={ formik?.values?.transfiFee } color={'black'} onChange={formik.handleChange} />

                 { !(selectedRowData?.feeMode ==="fixed") && <Input  value="%" isDisabled color={'black'}  />}
                </>
               )}
               {!(selectedRowData?.feeMode==="percentage" ) && <Input  type="text" value={selectedRowData?.fiatTicker} isDisabled  color={'black'}  />} 
            

               </Flex>
            </FormControl>

<br />
            
            <FormControl isRequired isInvalid={formik.touched.baseFee && formik.errors.baseFee}>
    <FormLabel> Fee to user </FormLabel>  
    <Flex>
        {
            selectedRowData?.feeMode==="hybrid" ? ( <>
                <Input
                  
                  id="baseFee"
                  type="number"
                  borderColor={"gray.400"}

                  value={(formik?.values?.baseFee  )}
                  isDisabled
                  color={'black'}
                  onChange={formik.handleChange}
                />
                <Input  value="%" isDisabled color={'black'}  />
                
                <Input isDisabled  value="+" fontSize={"2xl"} color={'black'}  />


                <Input
                  
                  id="fixedBaseFee"
                  type="number"
                  borderColor={"gray.400"}

                  value={formik?.values?.fixedBaseFee}
                  color={'black'}
                  onChange={formik.handleChange}
                  isDisabled

                />
              </>)   :  
              
              <>
              <Input isDisabled id="baseFee" borderColor={"gray.400"} type='number' value={(selectedRowData?.feeMode==="fixed") ? formik?.values?.baseFee :(formik?.values?.baseFee ) } color={'black'} onChange={formik.handleChange} />
                             { !(selectedRowData?.feeMode ==="fixed")  && <Input  value="%" isDisabled color={'black'}  />}
              </>

        }
     {!(selectedRowData?.feeMode==="percentage") && <Input  type='text' value={selectedRowData?.fiatTicker} isDisabled  color={'black'}  />} 
    </Flex>

  
   </FormControl>
  
  <br />
          
           <Flex>
           <FormControl isRequired isInvalid={formik.touched.fxFee && formik.errors.fxFee} marginRight={2}>
                <FormLabel> Fx rate </FormLabel>
                
                <Flex>
                <Input                             borderColor={"gray.400"}
  id="fxFee" type='number' value={(formik?.values?.fxFee )}  color={'black'} onChange={formik.handleChange} />
                { <Input  type='text'  value={"%"} isDisabled  color={'black'}  />}
                </Flex>
            
            </FormControl>
            <br />
            <FormControl isRequired isInvalid={formik.touched.discount && formik.errors.discount}>
                <FormLabel> Discount </FormLabel>
                <Flex>
                <Input                             borderColor={"gray.400"}
  id="discount" type='number' value={formik?.values?.discount}  color={'black'} onChange={formik.handleChange} />
                { <Input  type='text' value={"%"} isDisabled  color={'black'}  />}
                </Flex>
            
            </FormControl>
            <br />
           </Flex>

<br />

            {formik.values.product==="buy" ?<>
          
            </>:<>
            <FormControl>
            <FormLabel>Payment Type</FormLabel>
            <ChakraSelect
                    id="paymentType"
                    value={formik?.values?.paymentTypes}  // Ensure you are using correct field name
                    onChange={(e) => formik.setFieldValue('paymentType', e.target.value)}
                  >

                    {selectedRowData?.paymentTypes?.length === 2 &&  <option value={`Both`}>Both</option>}

                    
                    {formik?.values?.paymentTypes && formik?.values?.paymentTypes.map((paymentType) => (
                        <>

                      <option key={paymentType} value={paymentType}>{paymentType}</option>

                        </>
                    ))}
                  </ChakraSelect>
                  
            </FormControl>
                
            </>}
            
            </ModalBody>
            <ModalFooter>
                    <Button
                        type ="submit"
                        isLoading={loading}
                        onClick={formik.handleSubmit}
                        colorScheme='blue' mr={3}
                    >
                        Submit
                    </Button>
                    <Button
                        onClick={() => closeModal()}
                        colorScheme="red"
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
            </Modal>



    

        </Center >
    );
}
