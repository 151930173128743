export const constants = {
    SUPPORTED_FORMATS: [
        "image/jpg",
        "image/jpeg",
        "image/png",
        "application/pdf"
    ],
    COUNTRY_CODE_MAPPING: [
        {
            "country": "United States of America",
            "symbol": "US"
        },
        {
            "country": "Aland Islands",
            "symbol": "AX"
        },
        {
            "country": "American Samoa",
            "symbol": "AS"
        },
        {
            "country": "Andorra",
            "symbol": "AD"
        },
        {
            "country": "Angola",
            "symbol": "AO"
        },
        {
            "country": "Anguilla",
            "symbol": "AI"
        },
        {
            "country": "Antigua and Barbuda",
            "symbol": "AG"
        },
        {
            "country": "Argentina",
            "symbol": "AR"
        },
        {
            "country": "Armenia",
            "symbol": "AM"
        },
        {
            "country": "Aruba",
            "symbol": "AW"
        },
        {
            "country": "Australia",
            "symbol": "AU"
        },
        {
            "country": "Austria",
            "symbol": "AT"
        },
        {
            "country": "Azerbaijan",
            "symbol": "AZ"
        },
        {
            "country": "Bahamas",
            "symbol": "BS"
        },
        {
            "country": "Bahrain",
            "symbol": "BH"
        },
        {
            "country": "Bangladesh",
            "symbol": "BD"
        },
        {
            "country": "Belgium",
            "symbol": "BE"
        },
        {
            "country": "Belize",
            "symbol": "BZ"
        },
        {
            "country": "Benin",
            "symbol": "BJ"
        },
        {
            "country": "Bermuda",
            "symbol": "BM"
        },
        {
            "country": "Bhutan",
            "symbol": "BT"
        },
        {
            "country": "Bonaire, Sint Eustatius and Saba",
            "symbol": "BQ"
        },
        {
            "country": "Botswana",
            "symbol": "BW"
        },
        {
            "country": "Brazil",
            "symbol": "BR"
        },
        {
            "country": "Brunei Darussalam",
            "symbol": "BN"
        },
        {
            "country": "Bulgaria",
            "symbol": "BG"
        },
        {
            "country": "Cabo Verde",
            "symbol": "CV"
        },
        {
            "country": "Cameroon",
            "symbol": "CM"
        },
        {
            "country": "Canada",
            "symbol": "CA"
        },
        {
            "country": "Chad",
            "symbol": "TD"
        },
        {
            "country": "Chile",
            "symbol": "CL"
        },
        {
            "country": "Colombia",
            "symbol": "CO"
        },
        {
            "country": "Comoros",
            "symbol": "KM"
        },
        {
            "country": "Costa Rica",
            "symbol": "CR"
        },
        {
            "country": "Croatia",
            "symbol": "HR"
        },
        {
            "country": "Curaçao",
            "symbol": "CW"
        },
        {
            "country": "Cyprus",
            "symbol": "CY"
        },
        {
            "country": "Czechia",
            "symbol": "CZ"
        },
        {
            "country": "Denmark",
            "symbol": "DK"
        },
        {
            "country": "Djibouti",
            "symbol": "DJ"
        },
        {
            "country": "Dominica",
            "symbol": "DM"
        },
        {
            "country": "Dominican Republic",
            "symbol": "DO"
        },
        {
            "country": "El Salvador",
            "symbol": "SV"
        },
        {
            "country": "Equatorial Guinea",
            "symbol": "GQ"
        },
        {
            "country": "Eritrea",
            "symbol": "ER"
        },
        {
            "country": "Estonia",
            "symbol": "EE"
        },
        {
            "country": "Ethiopia",
            "symbol": "ET"
        },
        {
            "country": "Faroe Islands",
            "symbol": "FO"
        },
        {
            "country": "Fiji",
            "symbol": "FJ"
        },
        {
            "country": "Finland",
            "symbol": "FI"
        },
        {
            "country": "France",
            "symbol": "FR"
        },
        {
            "country": "Gabon",
            "symbol": "GA"
        },
        {
            "country": "Gambia",
            "symbol": "GM"
        },
        {
            "country": "Georgia",
            "symbol": "GE"
        },
        {
            "country": "Germany",
            "symbol": "DE"
        },
        {
            "country": "Ghana",
            "symbol": "GH"
        },
        {
            "country": "Greece",
            "symbol": "GR"
        },
        {
            "country": "Greenland",
            "symbol": "GL"
        },
        {
            "country": "Grenada",
            "symbol": "GD"
        },
        {
            "country": "Guadeloupe",
            "symbol": "GP"
        },
        {
            "country": "Guam",
            "symbol": "GU"
        },
        {
            "country": "Guatemala",
            "symbol": "GT"
        },
        {
            "country": "Guernsey",
            "symbol": "GG"
        },
        {
            "country": "Guinea",
            "symbol": "GN"
        },
        {
            "country": "Guyana",
            "symbol": "GY"
        },
        {
            "country": "Heard Island and McDonald Islands",
            "symbol": "HM"
        },
        {
            "country": "Honduras",
            "symbol": "HN"
        },
        {
            "country": "Hong Kong",
            "symbol": "HK"
        },
        {
            "country": "Hungary",
            "symbol": "HU"
        },
        {
            "country": "Iceland",
            "symbol": "IS"
        },
        {
            "country": "India",
            "symbol": "IN"
        },
        {
            "country": "Philippines",
            "symbol": "PH"
        },
        {
            "country": "Indonesia",
            "symbol": "ID"
        },
        {
            "country": "Ireland",
            "symbol": "IE"
        },
        {
            "country": "Isle of Man",
            "symbol": "IM"
        },
        {
            "country": "Israel",
            "symbol": "IL"
        },
        {
            "country": "Italy",
            "symbol": "IT"
        },
        {
            "country": "Ivory Coast",
            "symbol": "CI"
        },
        {
            "country": "Jersey",
            "symbol": "JE"
        },
        {
            "country": "Kazakhstan",
            "symbol": "KZ"
        },
        {
            "country": "Kenya",
            "symbol": "KE"
        },
        {
            "country": "Kiribati",
            "symbol": "KI"
        },
        {
            "country": "Kuwait",
            "symbol": "KW"
        },
        {
            "country": "Lao People's Democratic Republic",
            "symbol": "LA"
        },
        {
            "country": "Latvia",
            "symbol": "LV"
        },
        {
            "country": "Lesotho",
            "symbol": "LS"
        },
        {
            "country": "Liberia",
            "symbol": "LR"
        },
        {
            "country": "Liechtenstein",
            "symbol": "LI"
        },
        {
            "country": "Lithuania",
            "symbol": "LT"
        },
        {
            "country": "Luxembourg",
            "symbol": "LU"
        },
        {
            "country": "Macao",
            "symbol": "MO"
        },
        {
            "country": "Madagascar",
            "symbol": "MG"
        },
        {
            "country": "Malawi",
            "symbol": "MW"
        },
        {
            "country": "Malaysia",
            "symbol": "MY"
        },
        {
            "country": "Maldives",
            "symbol": "MV"
        },
        {
            "country": "Malta",
            "symbol": "MT"
        },
        {
            "country": "Marshall Islands",
            "symbol": "MH"
        },
        {
            "country": "Mauritania",
            "symbol": "MR"
        },
        {
            "country": "Mauritius",
            "symbol": "MU"
        },
        {
            "country": "Mexico",
            "symbol": "MX"
        },
        {
            "country": "Micronesia (Federated States of)",
            "symbol": "FM"
        },
        {
            "country": "Moldova, Republic of",
            "symbol": "MD"
        },
        {
            "country": "Monaco",
            "symbol": "MC"
        },
        {
            "country": "Mongolia",
            "symbol": "MN"
        },
        {
            "country": "Montserrat",
            "symbol": "MS"
        },
        {
            "country": "Mozambique",
            "symbol": "MZ"
        },
        {
            "country": "Namibia",
            "symbol": "NA"
        },
        {
            "country": "Netherlands",
            "symbol": "NL"
        },
        {
            "country": "New Caledonia",
            "symbol": "NC"
        },
        {
            "country": "New Zealand",
            "symbol": "NZ"
        },
        {
            "country": "Niger",
            "symbol": "NE"
        },
        {
            "country": "Nigeria",
            "symbol": "NG"
        },
        {
            "country": "Norfolk Island",
            "symbol": "NF"
        },
        {
            "country": "Northern Mariana Islands",
            "symbol": "MP"
        },
        {
            "country": "Norway",
            "symbol": "NO"
        },
        {
            "country": "Oman",
            "symbol": "OM"
        },
        {
            "country": "Palau",
            "symbol": "PW"
        },
        {
            "country": "Palestine, State of",
            "symbol": "PS"
        },
        {
            "country": "Papua New Guinea",
            "symbol": "PG"
        },
        {
            "country": "Paraguay",
            "symbol": "PY"
        },
        {
            "country": "Peru",
            "symbol": "PE"
        },
        {
            "country": "Pitcairn",
            "symbol": "PN"
        },
        {
            "country": "Poland",
            "symbol": "PL"
        },
        {
            "country": "Portugal",
            "symbol": "PT"
        },
        {
            "country": "Puerto Rico",
            "symbol": "PR"
        },
        {
            "country": "Qatar",
            "symbol": "QA"
        },
        {
            "country": "Romania",
            "symbol": "RO"
        },
        {
            "country": "Rwanda",
            "symbol": "RW"
        },
        {
            "country": "Saint Barthélemy",
            "symbol": "BL"
        },
        {
            "country": "Saint Kitts and Nevis",
            "symbol": "KN"
        },
        {
            "country": "Saint Lucia",
            "symbol": "LC"
        },
        {
            "country": "Saint Pierre and Miquelon",
            "symbol": "PM"
        },
        {
            "country": "Saint Vincent and the Grenadines",
            "symbol": "VC"
        },
        {
            "country": "Samoa",
            "symbol": "WS"
        },
        {
            "country": "San Marino",
            "symbol": "SM"
        },
        {
            "country": "Sao Tome and Principe",
            "symbol": "ST"
        },
        {
            "country": "Seychelles",
            "symbol": "SC"
        },
        {
            "country": "Sierra Leone",
            "symbol": "SL"
        },
        {
            "country": "Singapore",
            "symbol": "SG"
        },
        {
            "country": "Sint Maarten (Dutch part)",
            "symbol": "SX"
        },
        {
            "country": "Slovakia",
            "symbol": "SK"
        },
        {
            "country": "Slovenia",
            "symbol": "SI"
        },
        {
            "country": "South Africa",
            "symbol": "ZA"
        },
        {
            "country": "Spain",
            "symbol": "ES"
        },
        {
            "country": "Sri Lanka",
            "symbol": "LK"
        },
        {
            "country": "Sweden",
            "symbol": "SE"
        },
        {
            "country": "Switzerland",
            "symbol": "CH"
        },
        {
            "country": "Suriname",
            "symbol": "SR"
        },
        {
            "country": "Svalbard and Jan Mayen",
            "symbol": "SJ"
        },
        {
            "country": "Tajikistan",
            "symbol": "TJ"
        },
        {
            "country": "Taiwan",
            "symbol": "TW"
        },
        {
            "country": "Timor-Leste",
            "symbol": "TL"
        },
        {
            "country": "Togo",
            "symbol": "TG"
        },
        {
            "country": "Tonga",
            "symbol": "TO"
        },
        {
            "country": "Turkmenistan",
            "symbol": "TM"
        },
        {
            "country": "Turks and Caicos Islands",
            "symbol": "TC"
        },
        {
            "country": "Uganda",
            "symbol": "UK"
        },
        {
            "country": "United Arab Emirates",
            "symbol": "AE"
        },
        {
            "country": "United Kingdom",
            "symbol": "GB"
        },
        {
            "country": "Uruguay",
            "symbol": "UY"
        },
        {
            "country": "Uzbekistan",
            "symbol": "UZ"
        },
        {
            "country": "Vietnam",
            "symbol": "VN"
        },
        {
            "country": "Virgin Islands (British)",
            "symbol": "VG"
        },
        {
            "country": "Virgin Islands (U.S.)",
            "symbol": "VI"
        },
        {
            "country": "Wallis and Futuna",
            "symbol": "WF"
        },
        {
            "country": "Zambia",
            "symbol": "ZM"
        }
    ],
    LANGUAGE_OPTIONS: [
        {
            label: "English",
            value: "en"
        },
        {
            label: "Bahasa",
            value: "id"
        },
        {
            label: 'Vietnam',
            value: 'vi'
        },
        {
            label: 'Thailand',
            value: 'th'
        }
    ],
    statusMap: {
        draft: "Draft",
        initiated: "Initiated",
        fund_processing: "Fund Processing",
        fund_settled: "Payment Received",
        fund_failed: "Payment Not Received",
        asset_deposited: "Withdraw Processing",
        asset_settled: "Withdraw Completed",
        partial_asset_settled: "Withdraw Partially Completed",
        asset_failed: "Withdraw Failed",
        failed: "Order Failed",
        partially_completed: 'Partially Completed',
        expired: 'Expired',
        payment_pending: "Payment Pending",
    },
    colorMap: {
        draft: "orange",
        initiated: "purple",
        fund_processing: "orange",
        asset_processing: "orange",
        approval_pending: "orange",
        fund_settled: "green",
        fund_failed: "red",
        asset_deposited: "green",
        asset_settled: "green",
        partial_asset_settled: "",
        asset_failed: "green",
        failed: 'red',
        partially_completed: 'blue',
        cancelled: 'red',
        expired: 'red',
        completed: 'green',
        rejected: 'red',
        approved: "black"
    },
    fiatPayoutStatusMap: {
        initiated: "Payout Initiated",
        fund_settled: "Payout Successful",
        failed: "Failed",
        asset_settled: "Payment Released",
        asset_deposited: "Payment Released",
        fund_processing: "Payment Released",
        asset_processing: "Payment Released",
        completed: "Successful",
        approval_pending: "Approval Pending",
        rejected: "Rejected",
        expired: "Expired",
        fund_failed: "Failed",
        approved: "Approval Accepted",
    },
    prefundingStatusMap: {
        initiated: "Prefunding Initiated",
        fund_settled: "Fiat Recieved",
        failed: "Failed",
        asset_deposited: "Fiat Recieving",
        fund_processing: "Fiat Processing",
        completed: "Prefunding Successful",
    },
};