/* eslint-disable no-unused-expressions */
import React, { useContext, useState } from 'react';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  useToast,
  chakra,
  Stack
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import Select from "react-select";
import * as Yup from "yup";
import { round } from 'lodash';

import { callApiWithToken } from "../../utils/utils";
import { NavBarContext } from "../../context/NavBarContext";

const SettlePrefund = (props) => {
  const { isOpen, onClose, isLoading, refreshGrid } = props;

  const [loading, setLoading] = useState(false)
  const toast = useToast();
  const { selectedOrg } = useContext(NavBarContext);

  const currencyOptions = [{ label: "PHP", value: "PHP" }, { label: "IDR", value: "IDR" }, { label: "GHS", value: "GHS" }, { label: "TZS", value: "TZS" }, { label: "ZMW", value: "ZMW" }, { label: "UGX", value: "UGX" }]

  const closeModal = () => {
    formik.resetForm();
    onClose();
    setLoading(false);
  }

  const formik = useFormik({
    initialValues: {
      currency: '',
      conversionRate: '',
      description: "",
      amount: "",
    },
    validationSchema: Yup.object({
      currency: Yup.object().required("Please select the currency"),
      amount: Yup.string().required("Please enter the Amount"),
      conversionRate: Yup.string().required("Please enter the Conversion Rate"),
      description: Yup.string().required("Please provide the description")
    }),
    onSubmit: async (formValues) => {
      try {
        setLoading(true);
        const response = await callApiWithToken("POST", "/api/prefund/settlePrefund", {
          currency: formValues?.currency?.value,
          amount: formValues?.amount,
          conversionRate: formValues?.conversionRate,
          description: formValues?.description,
          convertedAmount: round(formValues?.amount / formValues?.conversionRate, 2),
        orgName:selectedOrg
        });
        if (response?.message) {
          toast({
            title: 'Success',
            description: response?.message,
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top-right'
          })
        }
        refreshGrid();
        closeModal();
      } catch (err) {
        setLoading(false);
        toast({
          title: 'Error!',
          description: response?.message || "Failed to fetch order details.",
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right'
        })
      }
    },
  });

  return (
    <chakra.form onSubmit={formik.handleSubmit} display="flex" flexGrow={1}>
      <Modal isOpen={isOpen} onClose={() => closeModal()}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Settle Prefund</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>

            <Stack direction={"column"} justifyContent="space-between" flexGrow={1}>

              <FormControl isRequired isInvalid={formik.touched.currency && formik.errors.currency}>
                <FormLabel>Currency</FormLabel>
                <Select
                  id="currency"
                  isSearchable={true}
                  value={formik.values.currency}
                  placeholder='Select Currency'
                  options={currencyOptions}
                  onChange={(value) => {
                    formik.setFieldValue("currency", value);
                  }}
                />
                <FormErrorMessage>{formik.errors.currency}</FormErrorMessage>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={formik.touched.amount && formik.errors.amount}
              >
                <FormLabel htmlFor="name">Amount</FormLabel>
                <Input
                  id="amount"
                  type="number"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>{formik.errors.amount}</FormErrorMessage>
              </FormControl>

              <FormControl
                isRequired
                isInvalid={formik.touched.conversionRate && formik.errors.conversionRate}
              >
                <FormLabel htmlFor="name">Conversion Rate </FormLabel>
                <Input
                  id="conversionRate"
                  type="number"
                  value={formik.values.conversionRate}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>{formik.errors.conversionRate}</FormErrorMessage>
              </FormControl>

              <FormControl

              >
                <FormLabel htmlFor="name">Converted Amount </FormLabel>
                <Input
                  id="convertedAmount"
                  value={(formik?.values?.amount && formik?.values?.conversionRate) ? round(formik?.values?.amount / formik?.values?.conversionRate, 2) : 0}
                  // onChange={formik.handleChange}
                  isDisabled
                />
              </FormControl>

              <FormControl
                isRequired
                isInvalid={formik.touched.description && formik.errors.description}
              >
                <FormLabel htmlFor="name">Notes</FormLabel>
                <Input
                  id="description"
                  type="text"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={loading}
              onClick={formik.handleSubmit}
              colorScheme='blue' mr={3}
            >
              Settle
            </Button>
            <Button
              onClick={() => closeModal()}
            >
              Cancel
            </Button>
          </ModalFooter>

        </ModalContent>
      </Modal>
    </chakra.form>
  )
}

export default SettlePrefund;