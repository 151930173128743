import { Badge, Box, Button, HStack, Stack, Text, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { BiReset } from 'react-icons/bi';
import { IoAddCircleSharp } from "react-icons/io5";
import moment from 'moment';
import { round, startCase } from 'lodash';

import Loader from '../../utils/Loader';
import { callApiWithTokenV2 } from '../../utils/utils';
import Grid from '../../utils/agTable';
import { constants } from "../../constants";
import PayinSettlementModal from './helper/PayinSettlementModal';
import { NavBarContext } from '../../context/NavBarContext';
import { areCurrenciesSame, areOrgSame, isStatusSettled } from '../../utils/validations';

const PayinOrders = () => {
    const { colorMap, statusMap } = constants;

    const [isLoading, setIsLoading] = useState(false);
    const [selectedData, setSelectedData] = useState([])
    const [select, setSelect] = useState(false);
    const [refreshGrid, setRefreshGrid] = useState(false);

    const { isOpen: settlementIsOpen, onOpen: settlementOnOpen, onClose: settlementOnClose } = useDisclosure()

    const toast = useToast();

    const { selectedOrg } = useContext(NavBarContext);

    useEffect(() => {
        setRefreshGrid(!refreshGrid);
    }, [selectedOrg])

    const USformatter = new Intl.NumberFormat('en-US');

    const getOrderData = async (filters) => {
        const responseData = await callApiWithTokenV2.post(`/api/orders/tfpay/list?`, { impersonatedOrg: selectedOrg }, {
            params: {
                filters
            }
        })
        return responseData;
    }
    const handleReset = () => {
        setRefreshGrid(!refreshGrid);
    };

    useEffect(() => {

        if (!areCurrenciesSame(selectedData)) {
            toast({
                title: 'Multiple Currency Selected!',
                status: 'error',
                duration: 5000,
                isClosable: true
            })
            settlementOnClose();
            setSelectedData([]);
            setSelect(false);
        } else if (isStatusSettled(selectedData)) {
            toast({
                title: 'Already Settled Order Selected!',
                status: 'error',
                duration: 5000,
                isClosable: true
            })
            settlementOnClose();
            setSelectedData([]);
            setSelect(false);
        } else if (!areOrgSame(selectedData)) {
            toast({
                title: "Different Organization's Orders Selected!",
                status: 'error',
                duration: 5000,
                isClosable: true
            })
            settlementOnClose();
            setSelectedData([]);
            setSelect(false);
        }

    }, [selectedData])

    var filterParams = {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            if (cellValue === null) return -1;
            let cellDate = new Date(cellValue);
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
        browserDatePicker: true
    }

    const columns = [
        {
            headerName: "Order ID TransFi",
            field: "orderId",
            getQuickFilterText: params => {
                return params.value.name;
            },
            width: '250',
            cellRenderer: ({ value }) => {
                return <p style={{ color: "#1A4FD6", fontWeight: "bold", fontSize: '14px' }} >{value}</p>;
            },
            checkboxSelection: true,
            headerCheckboxSelection: true,
        },
        {
            headerName: "Initiated Date",
            field: "createdAt",
            filter: 'agDateColumnFilter',
            filterParams: filterParams,
            width: '220',
            cellDataType: 'date',
            cellRenderer: ({ value }) => (
                <Text>{moment(value).format("DD/MM/YYYY")}</Text>
            ),
        },
        {
            headerName: "Order Status",
            field: "status",
            enableRowGroup: true,
            filter: 'agSetColumnFilter',
            filterParams: {
                applyMiniFilterWhileTyping: true,
            },
            cellRenderer: ({ value }) => {
                const newStatus = statusMap[value] || value;
                const color = colorMap[value];
                return <Badge width={"100%"} textAlign={"center"} colorScheme={color}>{newStatus}</Badge>;
            },
        },
        {
            headerName: "Settlement Status",
            field: "settlementStatus",
            enableRowGroup: true,
            filterParams: {
                applyMiniFilterWhileTyping: true,
            },
            cellRenderer: ({ value }) => {
                if (value === 'settled') {
                    return <Badge width={"100%"} textAlign={"center"} colorScheme={'green'}>{value}</Badge>;
                }
                return <Badge width={"100%"} textAlign={"center"} colorScheme={'red'}>Unsettled</Badge>;
            },
        },
        { headerName: "Fiat Currency", field: "fiatTicker", width: '200' },
        {
            headerName: "Fiat Amount",
            field: "fiatAmount",
            width: '200',
            filter: 'agSetColumnFilter',
            cellDataType: 'number',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Processing Fees %",
            field: "processingFeesPercentage",
            customerVisibility: true,
            aggFunc: 'avg',
            filter: 'agNumberColumnFilter',
            cellDataType: 'number',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value * 100) + '%' : '0'}</Text>
            )
        },
        {
            headerName: "Fixed Fees", field: "fixedFeeDetails", cellRenderer: ({ value }) => (
                <Text>{value ? value : 'N/A'}</Text>
            )
        },
        {
            headerName: "Processing Fees Amount", field: "processingFeesAmountFiat1", aggFunc: 'sum', filter: 'agNumberColumnFilter', cellDataType: 'number', cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Net Amount",
            field: "netAmount",
            aggFunc: 'sum',
            filter: 'agNumberColumnFilter',
            cellDataType: 'number',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Customer Name", field: "customer.formattedName", enableRowGroup: true, customerVisibility: true, width: '200', cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : 'N/A'}</Text>
            )
        },
        { headerName: "Customer User ID/Email", field: "customer.email", enableRowGroup: true, customerVisibility: false, width: '300' },
        { headerName: "Sender Name", field: "senderName.firstName", customerVisibility: true, width: '200' },
        { headerName: "Sender Email", field: "email", enableRowGroup: true, customerVisibility: true, width: '200' },
        {
            headerName: "Purpose of Transaction", field: "purposeCode", enableRowGroup: true, customerVisibility: true, cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : 'N/A'}</Text>
            )
        },
        {
            headerName: "Nature of Sender", field: "senderDetails.type", enableRowGroup: true, customerVisibility: true, cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : 'N/A'}</Text>
            )
        },
        {
            headerName: "Nature of Recipient", field: "", enableRowGroup: true, customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : 'N/A'}</Text>
            )
        },
        { headerName: "Jurisdiction of Sender", field: "userCountry", enableRowGroup: true, customerVisibility: true, width: '200' },
        {
            headerName: "Jurisdiction of Recipient", field: "", enableRowGroup: true, customerVisibility: false, width: '200', cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : 'N/A'}</Text>
            )
        },
        {
            headerName: "Updated Date",
            field: "updatedAt",
            filter: 'agDateColumnFilter',
            filterParams: filterParams,
            enableRowGroup: true,
            cellDataType: 'date',
            width: '220',
            cellRenderer: ({ value }) => (
                <Text>{moment(value).format("DD/MM/YYYY")}</Text>
            ),
        },
        {
            headerName: "Fiat Payment Partner", field: "paymentGateway", customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : 'N/A'}</Text>
            )
        },
        {
            headerName: "Fiat Payment Partner Order ID", field: "fiat.orderId", customerVisibility: false, width: '200', cellRenderer: ({ value }) => (
                <Text>{value ? value : 'N/A'}</Text>
            )
        },
        {
            headerName: "Fiat Payment Method", field: "paymentFormattedName", customerVisibility: true, cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : 'N/A'}</Text>
            )
        },
        {
            headerName: "Gross Fiat Amount",
            field: "fiatAmount",
            customerVisibility: true,
            cellDataType: 'number',
            filter: 'agNumberColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Net Amount",
            field: "netAmount",
            customerVisibility: true,
            cellDataType: 'number',
            aggFunc: 'sum',
            filter: 'agNumberColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Amount Received in Sub-Account",
            field: "amountReceivedSubAccountFiat1",
            cellDataType: 'number',
            filter: 'agNumberColumnFilter',
            aggFunc: 'sum',
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Payment Partner Fees",
            field: "paymentPartnerFeesFiat1",
            filter: 'agNumberColumnFilter',
            cellDataType: 'number',
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Sub-Account Receipt Date", field: "subAmountReceiptDate", customerVisibility: false, cellDataType: 'date', cellRenderer: ({ value }) => (
                <Text>{value ? value : "N/A"}</Text>
            )
        },
        {
            headerName: "Conversion Rate to USD",
            field: "conversionRateToUsd",
            filter: 'agNumberColumnFilter',
            cellDataType: 'number',
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ? round(value, 8) : '0'}</Text>
            )
        },
        {
            headerName: "GTV",
            field: "gtv",
            customerVisibility: false,
            aggFunc: 'sum',
            cellDataType: 'number',
            filter: 'agNumberColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Amount Received in Sub-Account",
            field: "amountReceivedSubAccountUsd",
            aggFunc: 'sum',
            filter: 'agNumberColumnFilter',
            cellDataType: 'number',
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Crypto to be sold if zero forex markup",
            field: "cryptoSoldIfZeroForexMarkup",
            aggFunc: 'sum',
            filter: 'agNumberColumnFilter',
            cellDataType: 'number',
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Conversion rate charged by TransFi",
            field: "conversionRatesChargedTransfi",
            filter: 'agNumberColumnFilter',
            cellDataType: 'number',
            customerVisibility: true,
            cellRenderer: ({ value }) => (
                <Text>{value ? round(value, 8) : '0'}</Text>
            )
        },
        {
            headerName: "Crypto To Be Sold",
            field: "cryptoToBeSold",
            aggFunc: 'sum',
            cellDataType: 'number',
            customerVisibility: false,
            filter: 'agNumberColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Revenue from Forex", field: "revenueFromForex", cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Crypto Payment Partner", field: "crypto.partner", customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : 'N/A'}</Text>
            )
        },
        {
            headerName: "Crypto Partner Order ID", field: "crypto.orderId", customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? value : 'N/A'}</Text>
            )
        },
        {
            headerName: "Digital Asset Sent",
            field: "crypto.orderAmount",
            cellDataType: 'number',
            customerVisibility: true,
            filter: 'agNumberColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Crypto Ticker", field: "crypto.currency", customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? value : 'N/A'}</Text>
            )
        },
        {
            headerName: "Gas Fees Actual", field: "", customerVisibility: false, filter: 'agNumberColumnFilter', cellDataType: 'number', cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : 'N/A'}</Text>
            )
        },
        {
            headerName: "Net (of gas fees) Crypto paid", field: "", filter: 'agNumberColumnFilter', cellDataType: 'number', aggFunc: 'sum', customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : 'N/A'}</Text>
            )
        },
        {
            headerName: "Crypto Wallet Address", field: "walletAddress", customerVisibility: true, cellRenderer: ({ value }) => (
                <Text>{value ? value : "N/A"}</Text>
            )
        },
        {
            headerName: "Transaction hash", field: "metaData.txnIdForCryptoReceived[0]", customerVisibility: true, cellRenderer: ({ value }) => (
                <Text>{value ? value : "N/A"}</Text>
            )
        },
    ]


    const handleCreateSettlement = () => {
        setSelect(true);
        settlementOnOpen()
    };

    return (
        <Stack
            direction={"column"}
            justifyContent={"space-between"}
            flexGrow={1}
            p={5}
        >
            <Box >
                <HStack pb={5} justifyContent={"flex-end"}>
                    <Button
                        onClick={handleCreateSettlement}
                        leftIcon={<IoAddCircleSharp style={{ fontSize: "20px" }} />}
                        color="#1A4FD6"
                        border={'1px'}
                        borderColor={"#1A4FD6"}
                        _hover={{ bg: "#1A4FD6", textColor: "white" }}
                    >
                        Create Settlement
                    </Button>
                    <Button
                        leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                        color={"#525C76"}
                        variant={"outline"}
                        borderColor={"#d2d2d2"}
                        _hover={{ bg: "#ffffff" }}
                        onClick={handleReset}
                    >
                        Reset
                    </Button>

                </HStack>
                {!isLoading ?
                    <Grid columns={columns} key={refreshGrid.toString()} getOrderData={getOrderData} setSelectedData={setSelectedData} select={select} type="payin" />

                    : <Loader />}
            </Box>
            <PayinSettlementModal isOpen={settlementIsOpen} onClose={settlementOnClose} data={selectedData} setSelect={setSelect} handleReset={handleReset} />
            <div >



            </div>
        </Stack>
    );
};

export default PayinOrders;