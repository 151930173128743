import React, { useEffect } from "react";
import { isEmpty, unset, find } from "lodash";

import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent } from "@chakra-ui/react";
import { DrawerCloseButton, Input, Button } from "@chakra-ui/react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const DrawerFilter = (props) => {
    const { t } = useTranslation();
    const { isOpen, onClose, setFilters, currencyList } = props;
    const { formValues, setFormValues, filters } = props;

    useEffect(() => {
        setFormValues(filters);
    }, [filters]);

    const orderStatus = [
        { label: "Active", value: "sandbox" },
        { label: "Inactive", value: "inactive" }
    ]

    const handleOnChanges = (key, value) => {
        if (["status", "currency"].includes(key)) {
            setFormValues({
                ...formValues,
                [key]: value.value,
            });
        } else {
            setFormValues({
                ...formValues,
                [key]: value,
            });
        }
    };

    const handleOnFilter = () => {
        Object.keys(formValues).map((key) => {
            if (isEmpty(formValues[key])) unset(formValues, key);
        });
        setFilters(formValues);
        onClose();
    };

    const handleOnClear = () => {
        setFilters({});
        setFormValues({});
        onClose();
    };

    return (
        <>
            <Drawer onClose={onClose} isOpen={isOpen} size={"sm"}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader style={{ color: "#3182ce", fontFamily: "arial" }}>Filters</DrawerHeader>
                    <DrawerBody>
                        <div style={{ margin: "12px 0" }}>
                            <Input
                                placeholder={t("sub-accounts:email")}
                                style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                                type="text"
                                value={formValues.email}
                                onChange={(e) => handleOnChanges("email", e.target.value)}
                            />
                        </div>

                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isSearchable={true}
                                placeholder={t("sub-accounts:currency")}
                                options={currencyList}
                                value={find(currencyList, { value: formValues.currency })}
                                onChange={(e) => handleOnChanges("currency", e)}
                            />
                        </div>

                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isSearchable={true}
                                placeholder={t("sub-accounts:status")}
                                options={orderStatus}
                                value={find(orderStatus, { value: formValues.status })}
                                onChange={(e) => handleOnChanges("status", e)}
                            />
                        </div>

                        <div style={{ marginTop: "30px" }}>
                            <Button onClick={() => handleOnFilter()} variant={"outline"} colorScheme="blue">
                                {t("default:filter")}
                            </Button>
                            <Button
                                onClick={() => handleOnClear()}
                                variant={"outline"}
                                colorScheme="red"
                                style={{ marginLeft: "30px" }}
                            >
                                {t("default:clear")}
                            </Button>
                        </div>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default DrawerFilter;
