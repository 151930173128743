/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Input,
    useToast,
    chakra,
    Stack
} from '@chakra-ui/react';
import Select from "react-select";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useClipboard } from '@chakra-ui/react'

import { callApiWithToken } from "../../utils/utils";
import { getOrgId } from '../../components/App/useToken';

const NewRefundModal = (props) => {
    const { isOpen, onClose, isLoading, refreshGrid } = props;

    const [validation, setValidation] = useState({});
    const [bankOptions, setBankOptions] = useState({});
    const [gateway, setGateway] = useState({});
    const [paymentType, setPaymentType] = useState({});
    const [currency, setCurrency] = useState({});

    const { setValue } = useClipboard("");
    const toast = useToast();

    const paymentTypeOptions = [{ label: "Online Banking", value: "bank" }, { label: "Local Wallet", value: "local" }];
    const currencyOptions = [{ label: "IDR", value: "IDR", gateway: "wowpay" }, { label: "PHP", value: "PHP", gateway: "xendit" }]

    const formik = useFormik({
        initialValues: {
            orderId: '',
            paymentType: '',
            bankName: '',
            bankAccount: '',
            currency: '',
            gateway: ''
        },
        validationSchema: Yup.object({
            orderId: Yup.string().required("Please enter the order Id"),
            paymentType: Yup.object().required("Please select the payment type"),
            currency: Yup.object().required("Please select the currency type"),
            ...validation
        }),
        onSubmit: async (formValues) => {
            try {
                const response = await callApiWithToken("POST", "/api/refund/create", {
                    orderId: formValues?.orderId,
                    paymentType: formValues?.paymentType?.value,
                    currency: formValues?.currency?.value,
                    bankName: formValues?.bankName?.value,
                    bankAccount: formValues?.bankAccount,
                    gateway,
                    pmId: formValues?.bankName?.pmId,
                    orgId: getOrgId()
                });
                console.log(response)
                if (response?.message) {
                    toast({
                        title: 'Success',
                        description: response?.message,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                        position: 'top-right'
                    })
                }
                if (response?.error) {
                    toast({
                        title: 'Error!',
                        description: response?.error,
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                        position: 'top-right'
                    })
                }
                refreshGrid();
                closeModal();
            } catch (err) {
                toast({
                    title: 'Error!',
                    description: response?.message || "Failed to fetch order details.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
            }
            formik.setValues({
                orderId: '',
                paymentType: '',
                bankName: '',
                bankAccount: '',
            });
        },
    });

    useEffect(() => {
        setValue(formik.values.paymentType)
    }, [formik.values.paymentType])

    useEffect(() => {
        getBankList()
    }, [paymentType, currency])

    const closeModal = () => {
        formik.resetForm();
        onClose();
        setGateway({});
        setPaymentType({});
        setCurrency({});
    }

    const getBankList = async (search) => {
        const response = await callApiWithToken(
            "GET",
            `/api/refund/pmList?currency=${formik.values.currency.value}&type=${formik.values.paymentType.value}&gateway=${gateway}`
        );
        const coList = response?.data;
        const bankList = coList.map((ls) => {
            return { value: ls.referenceName, label: ls.name, pmId: ls.pmId };
        });
        setBankOptions(bankList);
    };

    return (
        <chakra.form onSubmit={formik.handleSubmit} display="flex" flexGrow={1}>
            <Modal isOpen={isOpen} onClose={() => closeModal()}>
                <ModalOverlay />

                <ModalContent>
                    <ModalHeader>Initiate Refund</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>

                        <Stack direction={"column"} justifyContent="space-between" flexGrow={1}>
                            <FormControl
                                isRequired
                                isInvalid={formik.touched.orderId && formik.errors.orderId}
                            >
                                <FormLabel htmlFor="name">Order Id</FormLabel>
                                <Input
                                    id="orderId"
                                    type="text"
                                    placeholder='Enter the order Id'
                                    value={formik.values.orderId}
                                    onChange={formik.handleChange}
                                />
                                <FormErrorMessage>{formik.errors.orderId}</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formik.touched.currency && formik.errors.currency}>
                                <FormLabel>Currency</FormLabel>
                                <Select
                                    id="currency"
                                    isSearchable={true}
                                    value={formik.values.currency}
                                    placeholder='Select Currency'
                                    options={currencyOptions}
                                    onChange={(value) => {
                                        formik.setFieldValue("currency", value);
                                        setGateway(value.gateway)
                                        setCurrency(value.value)
                                    }}
                                />
                                <FormErrorMessage>{formik.errors.currency}</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formik.touched.paymentType && formik.errors.paymentType}>
                                <FormLabel>Payment Type</FormLabel>
                                <Select
                                    id="paymentType"
                                    isSearchable={true}
                                    value={formik.values.paymentType}
                                    placeholder='Select Payment Type'
                                    options={paymentTypeOptions}
                                    onChange={(value) => {
                                        formik.setFieldValue("paymentType", value);
                                        if (value.value === "local") {
                                            setValidation({
                                                bankName: Yup.object().required("Please enter the ewallet bank name"),
                                                bankAccount: Yup.string().required("Please enter the ewallet account number")
                                            })
                                        }
                                        if (value.value === "bank") {
                                            setValidation({
                                                bankName: Yup.object().required("Please enter the bank name"),
                                                bankAccount: Yup.string().required("Please enter the bank account number")
                                            })
                                        }
                                        setPaymentType(value.value)
                                    }}
                                />
                                <FormErrorMessage>{formik.errors.paymentType}</FormErrorMessage>
                            </FormControl>
                            {formik?.values?.paymentType?.value === "local" ? <>
                                <FormControl isRequired isInvalid={formik.touched.bankName && formik.errors.bankName}>
                                    <FormLabel>Ewallet Name</FormLabel>
                                    <Select
                                        id="bankName"
                                        isSearchable={true}
                                        value={formik.values.bankName}
                                        placeholder='Select Ewallet Bank'
                                        options={bankOptions}
                                        onChange={(value) => {
                                            formik.setFieldValue("bankName", value);
                                        }}
                                    />
                                    <FormErrorMessage>{formik.errors.bankName}</FormErrorMessage>
                                </FormControl>
                                <FormControl isRequired isInvalid={formik.touched.bankAccount && formik.errors.bankAccount}>
                                    <FormLabel>Ewallet Account Number</FormLabel>
                                    <Input
                                        id="bankAccount"
                                        type="text"
                                        placeholder='Enter the Ewallet account number'
                                        value={formik.values.bankAccount}
                                        onChange={formik.handleChange}
                                    />
                                    <FormErrorMessage>{formik.errors.bankAccount}</FormErrorMessage>
                                </FormControl>
                            </> : <></>}
                            {formik?.values?.paymentType?.value === "bank" ? <>
                                <FormControl isRequired isInvalid={formik.touched.bankName && formik.errors.bankName}>
                                    <FormLabel>Bank Name</FormLabel>
                                    <Select
                                        id="bankName"
                                        isSearchable={true}
                                        value={formik.values.bankName}
                                        placeholder='Select Bank Name'
                                        options={bankOptions}
                                        onChange={(value) => {
                                            formik.setFieldValue("bankName", value);
                                        }}
                                    />
                                    <FormErrorMessage>{formik.errors.bankName}</FormErrorMessage>
                                </FormControl>
                                <FormControl isRequired isInvalid={formik.touched.bankAccount && formik.errors.bankAccount}>
                                    <FormLabel>Bank Account Number</FormLabel>
                                    <Input
                                        id="bankAccount"
                                        type="text"
                                        placeholder='Enter the Bank account number'
                                        value={formik.values.bankAccount}
                                        onChange={formik.handleChange}
                                    />
                                    <FormErrorMessage>{formik.errors.bankAccount}</FormErrorMessage>
                                </FormControl>
                            </> : <></>}
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            isLoading={isLoading}
                            onClick={formik.handleSubmit}
                            colorScheme='blue' mr={3}
                        >
                            Create
                        </Button>
                        <Button
                            onClick={() => closeModal()}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>
        </chakra.form>
    )
}

export default NewRefundModal;