import React, { useState, useRef } from "react";
import Loader from "react-js-loader";
import { Box, Button, useDisclosure, Badge, Text } from "@chakra-ui/react";
import { MenuButton, MenuList, MenuItem, Menu } from "@chakra-ui/react";
import { HiPlusSm } from "react-icons/hi";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { BiRefresh, BiReset } from "react-icons/bi";
import { FiFilter } from "react-icons/fi";
import { BiShow } from "react-icons/bi";
import { startCase, uniqBy, isEmpty, get, round } from "lodash";
import TimezoneFormatter from "../../utils/TimezoneFormatter";

import TransfiGrid from "../../utils/TransfiGrid";
import { refundStatusMap, refundColorMap } from "../../utils/config";

import NewRefundModal from "./NewRefundModal";
import ApproveModal from "./ApproveModal"
import { getOrgRoles, getCxRoles } from "../App/useToken";
import RefundFilter from "./RefundFilter"

import { SettingsIcon } from "@chakra-ui/icons";
import moment from "moment";

export default function Refund(props) {
    const [loading, setLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState("");
    const [viewDetails, setViewDetails] = useState("");
    const [inputData, setInputData] = useState({});
    const [formValues, setFormValues] = useState({});
    const defaultDate = { startDate: moment(new Date()).subtract(2, "months"), endDate: moment(new Date()) };
    const defaultFilters = {
        dateRange: defaultDate,
    }
    const [filters, setFilters] = useState(defaultFilters);
    const [dateRange, setDateRange] = useState(defaultDate);

    const orgRoles = getOrgRoles();
    const showColumn = orgRoles.includes("transfi_admin");
    const cxRole = getCxRoles();
    const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
    const {
        isOpen: isOpenApproveModal,
        onOpen: onOpenApproveModal,
        onClose: onCloseApproveModal,
    } = useDisclosure({ defaultIsOpen: false });
    const {
        isOpen: isOpenFilterModal,
        onOpen: onOpenFilterModal,
        onClose: onCloseFilterModal,
    } = useDisclosure({ defaultIsOpen: false });

    const gridRef = useRef();

    const refreshGrid = () => {
        gridRef.current.refreshGrid();
    };

    const numberFormatter = (value) => {
        const number = round(value, 2).toFixed(2)
        const result = parseFloat(number).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
        return result === "NaN" ? "0.00" : result;
    };

    const columns = [
        {
            Header: "Date",
            accessor: "createdAt",
            width: "190",
            Cell: ({ value }) => {
                return <TimezoneFormatter date={value} format={"lll"} />
            },
        },
        {
            Header: "Refund Id",
            accessor: "rfId",
            width: "190"
        },
        {
            Header: "Order Id TransFi",
            accessor: "orderId",
            width: "190"
        },
        {
            Header: "Order Id Customer",
            accessor: "customer.orderId",
            width: "200"
        },
        {
            Header: "Customer Name",
            accessor: "customer.formattedName",
            width: "120",
            show: showColumn
        },
        {
            Header: "User ID / Email",
            width: "120",
            Cell: ({ row }) => {
                if (row?.original?.email) {
                    return row?.original?.email
                }
                else {
                    return row?.original?.userId
                }
            },
        },
        {
            Header: "Date Order Initiated",
            width: "120",
            Cell: ({ row }) => {
                return <TimezoneFormatter date={row?.original?.orderDate} format={"MM/DD/YYYY"} />
            }, // accessor: "convertedCurrency"
        },
        {
            Header: "Time Order Initiated",
            width: "120",
            Cell: ({ row }) => {
                return <TimezoneFormatter date={row?.original?.orderDate} format={'LT'} />
            },
        },
        {
            Header: "Fiat Payment Partner",
            width: "150",
            accessor: "paymentPartner",
            show: showColumn,
            Cell: ({ value }) => startCase(value) || '-'
        },
        {
            Header: "Fiat Payment Partner Order Id",
            width: "180",
            accessor: "fiat.orderId",
            show: showColumn,
            Cell: ({ value }) => value || "-"
        },
        {
            Header: "Fiat Payment Method",
            accessor: "paymentMethod",
            width: "150",
            Cell: ({ value }) => value || "N//A"
        },
        {
            Header: "Fiat Currency",
            accessor: "fiatTicker"
        },
        {
            Header: "Gross Fiat Amount (Received from the user)",
            width: "230",
            Cell: ({ row }) => {
                return numberFormatter(row?.original?.fiat?.orderAmount)
            }
        },
        {
            Header: "Amt. received in sub-account",
            Cell: ({ row }) => {
                const fiatAmount = Number(row?.original?.fiat?.orderAmount || 0);
                const buyRate = Number(get(row.original, "fiat.partnerFee", 0))
                if (row?.original?.fiat?.partnerFee) {
                    if (row?.original?.fiat?.partnerFee === 0 || row?.original?.fiat?.partnerFee === "NaN") {
                        return numberFormatter(fiatAmount);
                    } else {
                        return numberFormatter(fiatAmount - buyRate);
                    }
                } else {
                    return numberFormatter(fiatAmount);
                }
            },
            show: showColumn
        },
        {
            Header: "Payment partner fees",
            Cell: ({ row }) => {
                if (row?.original?.fiat?.partnerFee) {
                    if (row?.original?.fiat?.partnerFee === 0 || row?.original?.fiat?.partnerFee === "NaN") {
                        return "0.00";
                    } else {
                        return numberFormatter(row?.original?.fiat?.partnerFee);
                    }
                } else {
                    return "0.00";
                }
            },
            show: showColumn
        },
        {
            Header: "Conversion rate to USD",
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate)) {
                    return "0.00";
                } else {
                    return numberFormatter(1 / row?.original?.fiat?.conversionRate);
                }
            },
            show: showColumn
        },
        {
            Header: "GTV(USD)",
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate)) {
                    return "0.00";
                } else {
                    const fiatAmount = Number(row?.original?.fiat?.orderAmount || 0);
                    const conversionRate = round(Number(row?.original?.fiat?.conversionRate || 0), 6)
                    return numberFormatter(fiatAmount * conversionRate);
                }
            },
            show: showColumn
        },
        {
            Header: "Amount received in Sub account (USD)",
            width: "190",
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate)) {
                    return "0.00";
                } else {
                    const fiatAmount = Number(row?.original?.fiat?.orderAmount || 0);
                    const buyRate = Number(row?.original?.fiat?.partnerFee || 0)
                    const subAcc = fiatAmount - buyRate;
                    const conversionRate = Number(row?.original?.fiat?.conversionRate)
                    return numberFormatter(subAcc * conversionRate);
                }
            },
            show: showColumn
        },
        {
            Header: "Payment Partner Fees (USD)",
            width: "180",
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate) && row?.original?.fiat?.partnerFee === 0 || row?.original?.fiat?.partnerFee === "NaN") {
                    return "0.00";
                } else {
                    const conversionRate = Number(row?.original?.fiat?.conversionRate)
                    const partnerFee = Number(row?.original?.fiat?.partnerFee || 0);
                    return numberFormatter(conversionRate * partnerFee);
                }
            },
            show: showColumn
        },
        {
            Header: "Fiat Payment Partner (Refund)",
            accessor: "fiatRefund.paymentPartner",
            show: showColumn,
            width: "180",
            Cell: ({ value }) => startCase(value) || "-"
        },
        {
            Header: "Refund Status",
            accessor: "status",
            Cell: ({ row }) => {
                const { status } = row?.original;
                const newStatus = refundStatusMap[status] || status;
                const color = refundColorMap[status] || "blue";
                return <Badge colorScheme={color}> {newStatus} </Badge>;
            },
            width: "180",
        },
        {
            Header: "Fiat Payment Partner Order Id (Refund)",
            accessor: "fiatRefund.orderId",
            show: showColumn,
            width: "200",
            Cell: ({ value }) => value || "-"
        },
        {
            Header: "Fiat Payment Method (Refund)",
            accessor: "fiatRefund.paymentMethod",
            width: "180",
            Cell: ({ value }) => startCase(value) || "-"
        },
        {
            Header: "Fiat Currency (Refund)",
            accessor: "fiatRefund.currency",
            Cell: ({ value }) => value || "-"
        },
        {
            Header: "Fiat delivered to user (Refund)",
            accessor: "refundedAmount",
            width: "180",
            Cell: ({ row }) => {
                const refundedAmount = Number(get(row.original, "refundedAmount", 0))
                return numberFormatter(refundedAmount)

            }
        },
        {
            Header: "Payment partner fees (Refund)",
            accessor: "fiatRefund.partnerFees",
            show: showColumn,
            width: "180",
            Cell: ({ row }) => {
                const partnerFees = Number(get(row.original, "fiatRefund.partnerFees", 0))
                return numberFormatter(partnerFees)
            },
        },
        {
            Header: "Total amount deducted from Payment partner account (Refund)",
            show: showColumn,
            width: "300",
            Cell: ({ row }) => {
                const partnerFees = Number(get(row.original, "fiatRefund.partnerFees", 0))
                const refundedAmount = Number(get(row.original, "refundedAmount", 0))
                const totalAmount = partnerFees + refundedAmount
                return numberFormatter(totalAmount)
            },
        },
        {
            Header: "Refund Date",
            accessor: "refundDate",
            Cell: ({ row }) => {
                return <TimezoneFormatter date={row?.original?.refundDate} format={"MM/DD/YYYY"} />
            },
        },
        {
            Header: "Refund gain/loss",
            show: showColumn,
            Cell: ({ row }) => {
                const fiatAmount = Number(row?.original?.fiat?.orderAmount || 0);
                const buyRate = Number(get(row.original, "fiat.partnerFee", 0))
                let totalAmt = 0;
                if (row?.original?.fiat?.partnerFee) {
                    if (row?.original?.fiat?.partnerFee === 0 || row?.original?.fiat?.partnerFee === "NaN") {
                        totalAmt = fiatAmount;
                    } else {
                        totalAmt = fiatAmount - buyRate;
                    }
                } else {
                    totalAmt = fiatAmount;
                }
                const partnerFees = Number(get(row.original, "fiatRefund.partnerFees", 0))
                const refundedAmount = Number(get(row.original, "refundedAmount", 0))
                const totalAmount = partnerFees + refundedAmount;
                const gain = totalAmt - totalAmount;
                return numberFormatter(gain)
            },
        },
        {
            Header: "Notes",
            accessor: "description",
            show: showColumn,
            Cell: ({ value }) => value || "-"
        },
    ];
    if (orgRoles.includes("transfi_admin")) {
        columns.push({
            Header: "Actions",
            Cell: (cellProps) => {
                const { row = {}, data = [] } = cellProps;

                const { index } = row;
                const record = data?.[index];
                const status = row?.original?.status;
                return (
                    <Menu>
                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} isDisabled={["initiated"].includes(status) ? false :true}>
                            <SettingsIcon />
                        </MenuButton>
                        <MenuList>
                                <MenuItem
                                    onClick={() => {
                                        setSelectedRow(record);
                                        onOpenApproveModal();
                                    }}
                                >
                                    Approve
                                </MenuItem>
                        </MenuList>
                    </Menu>
                );
            },
        });
    }

    const handleOnReset = () => {
        setDateRange(defaultDate);
        setFilters(defaultFilters);
        setFormValues({});
        setInputData({});
    };

    return !loading ? (
        <Box style={{ background: "white" }}>
            <div style={{ float: "right", marginTop: "2%" }}>
                <Button
                    leftIcon={<HiPlusSm />}
                    colorScheme="blue"
                    style={{ margin: "0 8px" }}
                    size="sm"
                    onClick={onOpen}
                >
                    Create Refund
                </Button>
                <Button
                    leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                    variant={"outline"}
                    colorScheme="blue"
                    onClick={() => handleOnReset()}
                    style={{ margin: "0 8px" }}
                    size="sm"
                >
                    Reset
                </Button>
                <Button
                    leftIcon={<BiRefresh style={{ fontSize: "20px" }} />}
                    onClick={() => refreshGrid()}
                    variant={"outline"}
                    colorScheme="blue"
                    style={{ margin: "0 0px" }}
                    size="sm"
                >
                    Refresh
                </Button>
                <Button
                    leftIcon={<FiFilter style={{ fontSize: "20px" }} />}
                    onClick={onOpenFilterModal}
                    variant={"outline"}
                    colorScheme="blue"
                    style={{ margin: "0 8px" }}
                    size="sm"
                >
                    Filter
                </Button>
            </div>
            <TransfiGrid
                ref={gridRef}
                columns={columns}
                dataUrl={"/api/refund/list"}
                filters={{ filters }}
            ></TransfiGrid>
            <NewRefundModal
                isLoading={loading}
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                refreshGrid={refreshGrid}
            />
            <ApproveModal
                isLoading={loading}
                isOpen={isOpenApproveModal}
                onOpen={onOpenApproveModal}
                onClose={onCloseApproveModal}
                selectedRow={selectedRow}
                refreshGrid={refreshGrid}
            />
            <RefundFilter
                isOpen={isOpenFilterModal}
                onClose={onCloseFilterModal}
                inputData={inputData}
                setInputData={setInputData}
                dateRange={dateRange}
                setDateRange={setDateRange}
                filters={filters}
                setFilters={setFilters}
                formValues={formValues}
                setFormValues={setFormValues}
                handleOnReset={handleOnReset}
            />
        </Box >
    ) : (
        <Loader
            type="spinner-cub"
            bgColor={"#FFFFFF"}
            title={"Loading..."}
            color={"cyan.400"}
            size={100}
        />
    );
}
