import React, { useEffect, useState, useContext } from "react";
import { IconButton, Box, CloseButton, Flex, Icon, useColorModeValue } from "@chakra-ui/react";
import { Link, Drawer, DrawerContent, useDisclosure, HStack, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { TiWarningOutline } from "react-icons/ti";
import { FiMenu, FiInfo } from "react-icons/fi";
import { IoDocumentTextOutline } from "react-icons/io5";
import { BsCartPlusFill } from "react-icons/bs";
import { MdDeveloperMode } from "react-icons/md";
import { IoAppsOutline, IoCartOutline } from "react-icons/io5";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { HiOutlineCog } from "react-icons/hi";
import { RxDashboard } from "react-icons/rx";
import { AiOutlineTeam, AiOutlineTransaction } from "react-icons/ai";
import { MdOutlineSupervisorAccount } from "react-icons/md";
import { BiMoneyWithdraw } from "react-icons/bi";
import { TbWebhook } from "react-icons/tb";
import { FaHandsHelping, FaWallet } from "react-icons/fa";
import { BsFillCartPlusFill, BsCartDashFill, BsCartPlus, BsCartDash } from "react-icons/bs";
import { useTranslation } from "react-i18next";

import { getOrgRoles, getCxRoles, getOrgId, getEnv } from "../App/useToken";
import { callApiWithToken } from "../../utils/utils";
import { getKybStatus } from "../../apiService/kybForm-service";
import { NavBarContext } from "../../context/NavBarContext";

import png from "../../icons/logo.png";

export default function SidebarWithHeader({ children }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isKybPending, setIsKybPending] = useState(false);
    const [kybReviewing, setKybReviewing] = useState(false);
    const [orgName, setOrgName] = useState();
    const [grpId, setGrpId] = useState();
    const orgRoles = getOrgRoles();
    const cxRoles = getCxRoles();
    const env = getEnv();

    useEffect(() => {
        getOrgDetails();
    }, []);

    const getOrgDetails = async () => {
        const orgId = getOrgId()
        const orgData = (await callApiWithToken("POST", `/api/organization/details`, { orgId })) || {};
        const { formattedName, grpOrgId } = orgData.orgResponse || {};
        setOrgName(formattedName);
        setGrpId(grpOrgId)
    };

    const { kybStatusValue } = useContext(NavBarContext);

    const getKybStatusFromApi = async () => {
        const { kybStatus = "" } = await getKybStatus();
        checkAndSetStatus(kybStatus);
    };

    useEffect(() => {
        getKybStatusFromApi();
    }, []);

    useEffect(() => {
        checkAndSetStatus(kybStatusValue);
    }, [kybStatusValue]);

    const checkAndSetStatus = (status) => {
        if (status === "pending") {
            setIsKybPending(true);
            setKybReviewing(false);
        } else if (status === "reviewing") {
            setIsKybPending(false);
            setKybReviewing(true);
        }
    };

    return (
        <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
            <SidebarContent
                onClose={() => onClose}
                display={{ base: "none", md: "block" }}
                isKybPending={isKybPending}
                kybReviewing={kybReviewing}
                orgRoles={orgRoles}
                cxRoles={cxRoles}
                orgName={orgName}
                grpId={grpId}
                env={env}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full"
            >
                <DrawerContent>
                    <SidebarContent onClose={onClose} />
                </DrawerContent>
            </Drawer>
            <MobileNav onOpen={onOpen} style={{ width: "0" }} />
            <Box ml={{ base: 0, md: 60 }} p="4">
                {children}
            </Box>
        </Box>
    );
}

const SidebarContent = ({ onClose, isKybPending, kybReviewing, cxRoles, orgRoles, orgName, grpId, env, ...rest }) => {
    const { t } = useTranslation();

    const binanceOrgs = ["binance", "binance_qa", "binance_prod", "binance_africa","openpayd"].includes(orgName);
    const oceanGaming = (orgName === "ocean_gaming" || grpId === "OG-220923101501950");
    const orgsPermission = ["roshi","ptnarrativemediabaru","ptklikteknologiindonesia"].includes(orgName);
    const LinkItems = [
        { name: "Admin", icon: BsCartPlusFill, to: "admin", visible: orgRoles.includes("transfi_admin") },
        { name: "Dashboard", icon: IoAppsOutline, to: "dashboard", visible: (!binanceOrgs && (orgName !== "ocean_gaming" && (grpId ? grpId !== "OG-220923101501950" : true))) },
        { name: "Orders", icon: IoAppsOutline, to: "orders", visible: orgRoles.includes("transfi_admin") },
        { name: "Payin Orders", icon: BsFillCartPlusFill, to: "otc-payin-orders", visible: orgsPermission},
        { name: "Buy Orders", icon: BsFillCartPlusFill, to: "buy-orders", visible: (!orgsPermission && !binanceOrgs && (orgName !== "ocean_gaming" && (grpId ? grpId !== "OG-220923101501950" : true)) && !orgRoles.includes("transfi_admin")) },
        { name: "Sell Orders", icon: BsCartDashFill, to: "sell-orders", visible: !orgsPermission && !binanceOrgs && (orgName !== "ocean_gaming" && (grpId ? grpId !== "OG-220923101501950" : true)) && !orgRoles.includes("transfi_admin") },
        { name: "Compliance", icon: IoAppsOutline, to: "compliance", visible: orgRoles.includes("transfi_admin") },
        { name: t("navbar:sub_accounts"), icon: MdOutlineSupervisorAccount, to: "sub-accounts", visible: (orgName === 'ocean_gaming') },
        { name: t("navbar:settlements"), icon: FaHandsHelping, to: "wpay-settlement", visible: (orgName === "ocean_gaming" || (grpId && grpId === "OG-220923101501950")) },
        { name: "Finance", icon: FaWallet, to: "finance", visible:(!orgsPermission && orgName !== "ocean_gaming" && (grpId ? grpId !== "OG-220923101501950" : true) && orgName !== "brickkenDashboard") },
        { name: t("navbar:transactions"), icon: AiOutlineTransaction, to: "transactions", visible: (oceanGaming || binanceOrgs) },
        { name: t("navbar:users"), icon: HiOutlineUserGroup, to: "users", visible: !binanceOrgs },
        { name: t("navbar:developers"), icon: MdDeveloperMode, to: "/developers", visible: true },
        { name: "Widget", icon: RxDashboard, to: "Widget", visible: (!orgsPermission && orgName !== "ocean_gaming" && (grpId ? grpId !== "OG-220923101501950" : true)) },
        { name: "Webhooks", icon: TbWebhook, to: "webhooks", visible: (!orgsPermission && orgName !== "ocean_gaming" && (grpId ? grpId !== "OG-220923101501950" : true)) },
        { name: t("navbar:settings"), icon: HiOutlineCog, to: "settings", visible: !orgsPermission},
        { name: "Read Docs", icon: IoDocumentTextOutline, visible: (orgName !== "ocean_gaming" && (grpId ? grpId !== "OG-220923101501950" : true)) },
        { name: t("navbar:teams"), icon: AiOutlineTeam, to: "/teams", visible: true },
    ];

    return (
        <Box
            overflowY={"auto"}
            transition="3s ease"
            bg={useColorModeValue("white", "gray.900")}
            borderRight="1px"
            borderRightColor={useColorModeValue("gray.200", "gray.700")}
            w={{ base: "full", md: 60 }}
            pos="fixed"
            h="full"
            {...rest}
        >
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <img src={png} alt="" width="130" height="200" />
                <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
            </Flex>
            {orgName && LinkItems.map(({ name, icon, to, visible }, i) => (
                <Box key={i}>
                    {visible && (
                        <NavItem key={name} icon={icon} path={to} name={name}>
                            <HStack p={1} width={"full"}>
                                <Text>{name}</Text>
                            </HStack>
                        </NavItem>
                    )}

                    {visible && (
                        <HStack justifyContent={"center"}>
                            {to === "/developers" && isKybPending ? (
                                <HStack p={1} bgColor={"yellow.200"} rounded="md">
                                    <TiWarningOutline fontSize={"1.5em"} />
                                    <Text fontSize={"xs"}>Your KYB is still pending</Text>
                                </HStack>
                            ) : to === "/developers" && kybReviewing ? (
                                <HStack p={2} bgColor={"blue.100"} rounded="md">
                                    <FiInfo />
                                    <Text fontSize={"xs"}>Your KYB under review</Text>
                                </HStack>
                            ) : null}
                        </HStack>
                    )}
                </Box>
            ))}
        </Box>
    );
};

const NavItem = ({ name, icon, children, path, ...rest }) => {
    const navigate = useNavigate();
    const currentTab = window.location.pathname;
    const isSelected = currentTab.includes(path);

    return (
        <Link onClick={() => {
            if (name === "Read Docs") return window.open('https://ramp-docs.transfi.com/', '_blank');
            return navigate(path);
        }
        }
            style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
            <Flex
                color={isSelected ? "cyan.400" : ""}
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: "cyan.400",
                    color: "white",
                }}
                {...rest}
            >
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="24"
                        _groupHover={{
                            color: "white",
                        }}
                        as={icon}
                    />
                )}
                <Text fontSize="20" fontWeight="bold">
                    {children}
                </Text>
            </Flex>
        </Link>
    );
};

const MobileNav = ({ onOpen, ...rest }) => {
    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue("white", "gray.900")}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue("gray.200", "gray.700")}
            justifyContent={{ base: "space-between", md: "flex-end" }}
            {...rest}
        >
            <IconButton
                display={{ base: "flex", md: "none" }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />
        </Flex>
    );
};
