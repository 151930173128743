import React, { useState, useEffect } from 'react';
import {
    Box, Center, Text, Stack, Button, FormControl, FormLabel, Select, FormErrorMessage, useToast, Container, Input, HStack, IconButton 
} from "@chakra-ui/react";
import { notify } from "react-notify-toast";
import Loader from "../../../utils/Loader";
import { callApiWithToken } from '../../../utils/utils';
import { Formik, Form, Field, FieldArray  } from "formik";
import { CloseIcon, AddIcon } from '@chakra-ui/icons';

export default function AddPaymentMethods() {
    const [loading, setLoading] = useState(false);
    const [countries,setCountries] = useState([]);
    const [currencies,setCurrencies] = useState([]);
    const [paymentGateways, setPaymentGateways] = useState([]);
    useEffect(() => {
        async function fetchData() {
            const response = await callApiWithToken("GET", `/api/admin/getPaymentGateways`);
            console.log(response.data);
            if (response.status){
                const values = response.data.paymentGateways;
                setPaymentGateways(values);
            }
        }
        fetchData();
    }, [loading]);
    const feeTypes = [
        { value: 'percentage', label: 'Percentage' },
        { value: 'fixed', label: 'Fixed' },
        { value: 'hybrid', label: 'Hybrid' }
    ];
    const initialValues = {
        name: "", formattedName: "", minLimit: "", maxLimit: "",
        fees: "", isAsiaPay: "", isPreferredMethod: "", isAsiaBank: "", status: "", type: "",
        paymentGateway: "", referenceName: "", product: "", bankCode: "", countries: "", currencies: "",
        gatewayName: "", gatewayReferenceName: "", externalImageName: "", feeType: feeTypes[0].value, fixedAmount: 0, fixedCurrency: currencies[0],
        metaData: []
    };



    useEffect(() => {
        async function fetchData() {
            const response = await callApiWithToken("GET", `/api/admin/getCountries`);
            console.log(response.data);
            console.log("countries", response.data.twoDigitCodes);
            console.log("currency",response.data.currencies)
            initialValues.fixedCurrency = response.data.currencies[0];
            if(response.status){
                setCountries(response.data.twoDigitCodes);
                setCurrencies(response.data.currencies);
            }
        }
        fetchData();
    },[loading]);

    async function handleSubmit(values) {
        Object.filter = (obj, predicate) =>
            Object.fromEntries(Object.entries(obj).filter(predicate));
        const value = Object.filter(values, ([key, val]) => !(key === "countries" || key === "currencies" || (key === "bankCode" && val === "")));
        setLoading(true);
        const metaData = {};
        values.metaData.forEach(({key, value})=> metaData[key] = value);
        const response = await callApiWithToken("POST", `/api/admin/addPaymentMethods`, { ...value, metaData, countries: values.countries.split(" "), currencies: values.currencies.split(" ") }) || {};
        setLoading(false);
        if (response.status) {
            notify.show("Payment Method Added Successfully", "custom", 5000, { background: "#4BB543", text: "white" });
        }
        else {
            notify.show("Error in Saving New Payment Method", "custom", 5000, { background: "#cc0000", text: "white" });
        }
    }

    function validate(value) {
        let error
        if (!value) {
            error = 'Required*'
        }
        return error
    }

    return (
        loading ? <Loader /> :
            <Center py={5} bg="white.100">
                <Stack spacing={2} w="90%">
                    <Box bg="white" p={4}>
                        <Text fontSize="2xl" p={5} color="black" fontWeight={"bold"}>
                            Add Payment Method
                        </Text>
                        <Container
                            border={"1px"}
                            borderColor="gray.200"
                            p={5}
                            borderRadius={10}
                            maxWidth={"full"}
                            width={"full"}
                        >
                            <Stack w={"100%"}>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={handleSubmit}
                                >
                                    {(formik) => {
                                        const { isValid, dirty, values } = formik;
                                        return (
                                            <Form>
                                                <HStack gap="5" marginBottom={"10px"}>
                                                    <Field name='name' validate={validate}>
                                                        {({ field, form }) => (
                                                            <FormControl isRequired isInvalid={form.errors.name && form.touched.name}>
                                                                <FormLabel>Name</FormLabel>
                                                                <Input {...field} />
                                                                <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                    <Field name='formattedName' validate={validate}>
                                                        {({ field, form }) => (
                                                            <FormControl isRequired isInvalid={form.errors.formattedName && form.touched.formattedName}>
                                                                <FormLabel>
                                                                    Formatted Name
                                                                </FormLabel>
                                                                <Input
                                                                    {...field}
                                                                />
                                                                <FormErrorMessage>{form.errors.formattedName}</FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                    <Field name='paymentGateway' validate={validate}>
                                                        {({ field, form }) => (
                                                            <FormControl isRequired isInvalid={form.errors.paymentGateway && form.touched.paymentGateway}>
                                                                <FormLabel>
                                                                    Payment Gateway
                                                                </FormLabel>
                                                                <Select
                                                                    {...field}
                                                                >
                                                                    <option value={""} >Select</option>
                                                                    {paymentGateways.map((method) =>
                                                                        <option value={method.referenceName} >{method.name}</option>
                                                                    )}
                                                                </Select>
                                                                <FormErrorMessage>{form.errors.paymentGateway}</FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                </HStack>
                                                <HStack gap="5" marginBottom={"10px"}>
                                                    
                                                </HStack>
                                                <HStack gap="5" marginBottom={"10px"}>
                                                    <Field name='minLimit' validate={validate}>
                                                        {({ field, form }) => (
                                                            <FormControl isRequired isInvalid={form.errors.minLimit && form.touched.minLimit}>
                                                                <FormLabel>
                                                                    Minimum Limit
                                                                </FormLabel>
                                                                <Input
                                                                    {...field}
                                                                />
                                                                <FormErrorMessage>{form.errors.minLimit}</FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                    <Field name='maxLimit' validate={validate}>
                                                        {({ field, form }) => (
                                                            <FormControl isRequired isInvalid={form.errors.maxLimit && form.touched.maxLimit}>
                                                                <FormLabel>
                                                                    Maximum Limit
                                                                </FormLabel>
                                                                <Input
                                                                    {...field}
                                                                />
                                                                <FormErrorMessage>{form.errors.maxLimit}</FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                    <Field name='product' validate={validate}>
                                                        {({ field, form }) => (
                                                            <FormControl isRequired isInvalid={form.errors.product && form.touched.product}>
                                                                <FormLabel>
                                                                    Product
                                                                </FormLabel>
                                                                <Select
                                                                    {...field}
                                                                >
                                                                    <option value={""} >Select</option>
                                                                    <option value={"buy"} >Buy</option>
                                                                    <option value={"sell"} >Sell</option>
                                                                </Select>
                                                                <FormErrorMessage>{form.errors.product}</FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>
           
                                                </HStack>
                                                <HStack gap="5" marginBottom={"10px"}>
                                                                                           {/* <h2>What is this {JSON.stringify(values.feeType)}</h2>  */}
                                                    <Field name='feeType' validate={validate}>
                                                        {({ field, form }) => {
                                                            return (
                                                                <FormControl isRequired >
                                                                    <FormLabel>
                                                                        Fee Type
                                                                    </FormLabel>
                                                                    <Select
                                                                        {...field}
                                                                    >
                                                                        {feeTypes.map(({value, label}) =>
                                                                            <option value={value} key={value} >{label}</option>
                                                                        )}
                                                                    </Select>
                                                                    <FormErrorMessage>{form.errors.feeType}</FormErrorMessage>
                                                                </FormControl>
                                                            )
                                                        }}
                                                    </Field>
                                                    {values.feeType === "fixed" ? 
                                                    <>
                                                   <FixedFees values={values}  currencies={currencies} validate={validate}/>
                                                    </>
                                                          
                                                       
                                                    : values.feeType === "hybrid" ? 
                                                    <>
                                                     <FixedFees values={values}  currencies={currencies} validate={validate}/>
                                                     <Field name='fees' validate={validate}>
                                                        {({ field, form }) => (
                                                            <FormControl isRequired isInvalid={form.errors.fees && form.touched.fees}>
                                                                <FormLabel>
                                                                    Fees %
                                                                </FormLabel>
                                                                <Input
                                                                    {...field}
                                                                />
                                                                <FormErrorMessage>{form.errors.fees}</FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                    </>
                                                     : 
                                                    
                                                     <Field name='fees' validate={validate}>
                                                        {({ field, form }) => (
                                                            <FormControl isRequired isInvalid={form.errors.fees && form.touched.fees}>
                                                                <FormLabel>
                                                                    Fees %
                                                                </FormLabel>
                                                                <Input
                                                                    {...field}
                                                                />
                                                                <FormErrorMessage>{form.errors.fees}</FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                   }
                                                   
                                                    </HStack>
                                                <HStack gap="5" marginBottom={"10px"}>
                                                    <Field name='countries' validate={validate}>
                                                        {({ field, form }) => (
                                                            <FormControl isRequired isInvalid={form.errors.countries && form.touched.countries}>
                                                                <FormLabel>
                                                                    Countries
                                                                </FormLabel>
                                                                <Select
                                                                    {...field}
                                                                >
                                                                    <option value={""} >Select</option>
                                                                    {countries.map((name) =>
                                                                        <option value={name} >{name}</option>
                                                                    )}
                                                                </Select>
                                                                <FormErrorMessage>{form.errors.countries}</FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                    <Field name='currencies' validate={validate}>
                                                        {({ field, form }) => (
                                                            <FormControl isRequired isInvalid={form.errors.currencies && form.touched.currencies}>
                                                                <FormLabel>
                                                                    Currencies
                                                                </FormLabel>
                                                                <Select
                                                                    {...field}
                                                                >
                                                                    <option value={""} >Select</option>
                                                                    {currencies.map((name) =>
                                                                        <option value={name} >{name}</option>
                                                                    )}
                                                                </Select>
                                                                <FormErrorMessage>{form.errors.currencies}</FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                    <Field name='externalImageName' validate={validate}>
                                                        {({ field, form }) => (
                                                            <FormControl isRequired isInvalid={form.errors.externalImageName && form.touched.externalImageName}>
                                                                <FormLabel>
                                                                    External Image URL
                                                                </FormLabel>
                                                                <Input
                                                                    {...field}
                                                                />
                                                                <FormErrorMessage>{form.errors.externalImageName}</FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>

                                                </HStack>
                                                <HStack gap="5" marginBottom={"10px"}>
                                                    <Field name='isAsiaPay' validate={validate}>
                                                        {({ field, form }) => (
                                                            <FormControl isRequired isInvalid={form.errors.isAsiaPay && form.touched.isAsiaPay}>
                                                                <FormLabel>
                                                                    Asia Pay?
                                                                </FormLabel>
                                                                <Select
                                                                    {...field}
                                                                >
                                                                    <option value={""} >Select</option>
                                                                    <option value={true} >True</option>
                                                                    <option value={false} >False</option>
                                                                </Select>
                                                                <FormErrorMessage>{form.errors.isAsiaPay}</FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                    <Field name='isPreferredMethod' validate={validate}>
                                                        {({ field, form }) => (
                                                            <FormControl isRequired isInvalid={form.errors.isPreferredMethod && form.touched.isPreferredMethod}>
                                                                <FormLabel>
                                                                    Preferred Method?
                                                                </FormLabel>
                                                                <Select
                                                                    {...field}
                                                                >
                                                                    <option value={""} >Select</option>
                                                                    <option value={true} >True</option>
                                                                    <option value={false} >False</option>
                                                                </Select>
                                                                <FormErrorMessage>{form.errors.isPreferredMethod}</FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                    <Field name='isAsiaBank' validate={validate}>
                                                        {({ field, form }) => (
                                                            <FormControl isRequired isInvalid={form.errors.isAsiaBank && form.touched.isAsiaBank}>
                                                                <FormLabel>
                                                                    Asia Bank?
                                                                </FormLabel>
                                                                <Select
                                                                    {...field}
                                                                >
                                                                    <option value={""} >Select</option>
                                                                    <option value={true} >True</option>
                                                                    <option value={false} >False</option>
                                                                </Select>
                                                                <FormErrorMessage>{form.errors.isAsiaBank}</FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                    <Field name='status' validate={validate}>
                                                        {({ field, form }) => (
                                                            <FormControl isRequired isInvalid={form.errors.status && form.touched.status}>
                                                                <FormLabel>
                                                                    Status
                                                                </FormLabel>
                                                                <Select
                                                                    {...field}
                                                                >
                                                                    <option value={""} >Select</option>
                                                                    <option value={"active"} >Active</option>
                                                                    <option value={"inactive"} >Inactive</option>
                                                                    <option value={"unstable"} >Unstable</option>
                                                                </Select>
                                                                <FormErrorMessage>{form.errors.status}</FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                </HStack>
                                                <HStack gap="5" marginBottom={"10px"}>
  
                                                    <Field name='bankCode'>
                                                        {({ field, form }) => (
                                                            <FormControl>
                                                                <FormLabel>
                                                                    Bank Code
                                                                </FormLabel>
                                                                <Input
                                                                    {...field}
                                                                    isReadOnly={values.product !== "sell"}
                                                                    isDisabled={values.product !== "sell"}
                                                                />
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                    <Field name='type' validate={validate}>
                                                        {({ field, form }) => (
                                                            <FormControl isRequired isInvalid={form.errors.type && form.touched.type}>
                                                                <FormLabel>
                                                                    Type
                                                                </FormLabel>
                                                                <Select
                                                                    {...field}
                                                                >
                                                                    <option value={""} >Select</option>
                                                                    <option value={"bank_transfer"} >Bank Transfer</option>
                                                                    <option value={"card"} >Card</option>
                                                                    <option value={"local_wallet"} >Local Wallet</option>
                                                                    <option value={"netbanking"} >Netbanking</option>
                                                                    <option value={"otc"} >otc</option>
                                                                    <option value={"qr_code_aggregator"} >QR Code Aggregator</option>
                                                                </Select>
                                                                <FormErrorMessage>{form.errors.type}</FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                    <Field name='referenceName' validate={validate}>
                                                        {({ field, form }) => (
                                                            <FormControl isRequired isInvalid={form.errors.referenceName && form.touched.referenceName}>
                                                                <FormLabel>
                                                                    Reference Name
                                                                </FormLabel>
                                                                <Input
                                                                    {...field}
                                                                />
                                                                <FormErrorMessage>{form.errors.referenceName}</FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                </HStack>
                                                
                                                
                                                <HStack gap="5" marginBottom={"10px"}>
                                                <Field name='supportedDevice' validate={validate}>
                                                        {({ field, form }) => (
                                                            <FormControl isRequired isInvalid={form.errors.supportedDevice && form.touched.supportedDevice}>
                                                                <FormLabel>
                                                                    Supported Device
                                                                </FormLabel>
                                                                <Select
                                                                    {...field}
                                                                >
                                                                    <option value={""} >Select</option>
                                                                    <option value={"mobile_only"} >Mobile Only</option>
                                                                    <option value={"desktop_only"} >Desktop Only</option>
                                                                    <option value={"both"} >Both</option>
                                                                </Select>
                                                                <FormErrorMessage>{form.errors.supportedDevice}</FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                </HStack>

                                                <HStack gap="5" marginBottom={"10px"} marginTop={"20px"}>
                                                    <FormControl>
                                                        <FormLabel>
                                                            Add Metadata
                                                        </FormLabel>
                                                        <FieldArray name="metaData">
                                                            {({remove, push}) => (
                                                                <Stack>
                                                                    {values?.metaData.map((item, index) => (
                                                                        <HStack key={index} >
                                                                            <FormControl>
                                                                                <FormLabel>Key</FormLabel>
                                                                                <Input name={`metaData.${index}.key`} value={item.key} onChange={formik.handleChange} />
                                                                            </FormControl>
                                                                            <FormControl>
                                                                                <FormLabel>Value</FormLabel>
                                                                                <Input name={`metaData.${index}.value`} value={item.value} onChange={formik.handleChange} />
                                                                            </FormControl>
                                                                            <div style={{ marginTop: "auto" }}>
                                                                                <IconButton
                                                                                    aria-label="Remove"
                                                                                    icon={<CloseIcon />}
                                                                                    onClick={() => remove(index)}
                                                                                />
                                                                            </div>
                                                                        </HStack>
                                                                    ))}
                                                                    <Button leftIcon={<AddIcon />} onClick={() => push({ key: '', value: '' })}>
                                                                        {values?.metaData?.length ? "Add More Metadata" : "Add Metadata"}
                                                                    </Button>
                                                                </Stack>
                                                            )}
                                                        </FieldArray>

                                                    </FormControl>
                                                </HStack>
                                                <HStack alignItems={"center"} marginTop={"2%"}>
                                                    <Button
                                                        variant={"solid"}
                                                        colorScheme="blue"
                                                        width={"20%"}
                                                        type='submit'
                                                        className={!(dirty && isValid) ? "disabled-btn" : ""}
                                                        disabled={!(dirty && isValid)}
                                                    >
                                                        Add Method
                                                    </Button>
                                                </HStack>
                                            </Form>
                                        );
                                    }}

                                </Formik>
                            </Stack>
                        </Container>
                    </Box>
                </Stack>
            </Center>
    );
}

function FixedFees({ validate, currencies }) {
    return (
        <>
            <Field name='fixedAmount' validate={validate}>
                {({ field, form }) => (
                    <FormControl isRequired isInvalid={form.errors.fixedAmount && form.touched.fixedAmount}>
                        <FormLabel>
                            Fixed Amount
                        </FormLabel>
                        <Input
                            {...field}
                        />
                        <FormErrorMessage>{form.errors.fixedAmount}</FormErrorMessage>
                    </FormControl>
                )}
            </Field>

            <Field name='fixedCurrency' validate={validate}>
                {({ field, form }) => (
                    <FormControl isRequired isInvalid={form.errors.fixedCurrency && form.touched.fixedCurrency}>
                        <FormLabel>
                            Fixed Fee's Currency
                        </FormLabel>
                        <Select
                            {...field}
                        >
                            {/* <option value={""} >Select</option> */}
                            {currencies.map((name) =>
                                <option value={name} >{name}</option>
                            )}
                        </Select>
                        <FormErrorMessage>{form.errors.fixedCurrency}</FormErrorMessage>
                    </FormControl>
                )}
            </Field>
        </>
    )
}