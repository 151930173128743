/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Textarea,
    useToast,
    chakra,
    Stack,
    Flex,
    HStack,
    Box,
    VStack,
    Grid,
    Input
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import { callApiWithToken } from "../../../utils/utils";

const SettlementModal = (props) => {
    const { t } = useTranslation();
    const { isOpen, onClose, isLoading, refreshGrid } = props;
    // const { orgId, note } = details;

    const [loading, setLoading] = useState(false)
    const [emailList, setEmailList] = useState("");

    const toast = useToast();

    useEffect(() => {
        getOrganizationList();
    }, []);

    const getOrganizationList = async (search) => {
        const response = await callApiWithToken(
            "GET",
            `/api/settlement/wpay/orgList`
        );
        const coList = response?.data;
        const emails = coList.map((ls) => {
            return { value: ls.email, label: ls.email, address: ls.orgId };
        });
        setEmailList(emails);
    };

    const getCurrencyList = async (search) => {
        const response = await callApiWithToken(
            "POST",
            `/api/settlement/wpay/subAccountCurreny`, {
                orgId: search
            }
        );
       
        const coList = response?.data?.fiat[0];
        formik.setFieldValue("currency", coList);
    };

    const closeModal = () => {
        formik.resetForm();
        onClose();
        setLoading(false);
    }


    const formik = useFormik({
        initialValues: {
            email: "",
            currency: "",
            amount: "",
            walletAddress: "",
            note: "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            note: Yup.string().required("Additional Notes is Manadatory!"),
            email: Yup.object().required("Email is Manadatory!"),
            amount: Yup.string().required("Amount is Manadatory!"),
            walletAddress: Yup.string().required("Wallet Address is Manadatory!"),
        }),
        onSubmit: async (formValues) => {
            try {
                setLoading(true);
                const response = await callApiWithToken("POST", "/api/settlement/wpay/create", {
                    note: formValues?.note,
                    email: formValues?.email?.value,
                    amount: formValues?.amount,
                    walletAddress: formValues?.walletAddress,
                    currency: formValues?.currency
                });
                if (response?.message && response?.batchId) {
                    toast({
                        title: 'Success',
                        description: response?.message,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                        position: 'top-right'
                    })
                } else throw new Error(response?.message);
                refreshGrid();
                closeModal();
            } catch (err) {
                setLoading(false);
                toast({
                    title: 'Error!',
                    description: err?.message || "Failed to fetch order details.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
            }
        },

    });

    return (
        <chakra.form onSubmit={formik.handleSubmit} display="flex" flexGrow={1}>
            <Modal isOpen={isOpen} onClose={() => closeModal()} size={'4xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t("transactions:create_new_settlement")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>

                        <Stack direction={"column"} justifyContent="space-between" flexGrow={1}>
                            <Grid templateColumns='repeat(3, 0.5fr)' gap={4}>
                                <FormControl isRequired isInvalid={formik.touched.email && formik.errors.email} >
                                    <FormLabel>{t("default:email_id")}</FormLabel>
                                    <Select
                                        id="email"
                                        isSearchable={true}
                                        options={emailList}
                                        value={formik.values.email}
                                        placeholder={t("default:select_email")}
                                        onChange={(value) => {
                                            formik.setFieldValue("email", value);
                                            getCurrencyList(value.address)
                                        }}
                                    />
                                    <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                                </FormControl>
                                <FormControl isRequired  >
                                    <FormLabel>{t("transactions:payment_type")}</FormLabel>
                                    <Input
                                        id="currency"
                                        value={formik.values.currency}
                                        isDisabled background='gray.200' color={'black'}
                                    />
                                </FormControl>
                                <FormControl isRequired isInvalid={formik.touched.amount && formik.errors.amount}>
                                    <FormLabel>{t("transactions:amount")}</FormLabel>
                                    <Input
                                        id="amount"
                                        type="number"
                                        placeholder={t("default:enter_amount")}
                                        value={formik.values.amount}
                                        onChange={formik.handleChange}
                                    />
                                    <FormErrorMessage>{formik.errors.amount}</FormErrorMessage>
                                </FormControl>
                                <FormControl isRequired isInvalid={formik.touched.walletAddress && formik.errors.walletAddress}>
                                    <FormLabel>{t("transactions:wallet_address")}</FormLabel>
                                    <Input
                                        id="walletAddress"
                                        type="text"
                                        placeholder={t("default:enter_wallet_address")}
                                        value={formik.values.walletAddress}
                                        onChange={formik.handleChange}
                                    />
                                    <FormErrorMessage>{formik.errors.walletAddress}</FormErrorMessage>
                                </FormControl>
                            </Grid>
                            <FormControl
                                isRequired
                                isInvalid={formik.touched.note && formik.errors.note}>
                                <FormLabel htmlFor="name">{t("sub-accounts:additional_note")}</FormLabel>
                                <Textarea
                                    id="note"
                                    type="text"
                                    value={formik.values.note}
                                    onChange={formik.handleChange}
                                    size='xl'
                                    rows={5}
                                />
                                <FormErrorMessage>{formik.errors.note}</FormErrorMessage>
                            </FormControl>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={() => closeModal()}
                            mr={3}
                        >
                            {t("sub-accounts:cancel")}
                        </Button>
                        <Button
                            isLoading={loading}
                            onClick={formik.handleSubmit}
                            colorScheme='blue'
                        >
                            {t("default:create")}
                        </Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>
        </chakra.form>
    )
}

export default SettlementModal;