/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Input,
    useToast,
    chakra,
    Stack,
    Flex,
    HStack,
    Box,
    VStack,
} from '@chakra-ui/react';
import Select from "react-select";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { filter, isEmpty, map, uniqBy, sortBy } from 'lodash';

import { AddIcon } from '@chakra-ui/icons';
import SaveIcon from "../../icons/save.svg";
import DeleteIcon from "../../icons/delete.svg";
import EditIcon from "../../icons/edit.svg";

import { callApiWithToken } from "../../utils/utils";
import { getOrgId } from '../../components/App/useToken';
import { isUndefined } from 'lodash';
import { useTranslation } from "react-i18next";

const AddSubAccountModal = (props) => {
    const { t } = useTranslation();
    const { isOpen, onClose, isLoading, refreshGrid, currencyList, setCurrencyList } = props;
    const [paymentMethodList, setPaymentMethodList] = useState("");
    const [loading, setLoading] = useState(false)
    const [configs, setConfigs] = useState([{ id: 1, tfFee: 2 }, { id: 2, tfFee: 2 }, { id: 3, tfFee: 2 }, { id: 4, tfFee: 2 }, { id: 5, tfFee: 2 }]);
    const [feeErrorMsg, setFeeErrorMsg] = useState("");

    const toast = useToast();

    useEffect(() => {
        getCurrencyList();
    }, []);

    const getCurrencyList = async (search) => {
        const response = await callApiWithToken(
            "GET",
            `/api/orders/configList?name=fiat&module=og_supported_currency`
        );
        const coList = response?.data?.fiat;

        const fiatList = coList.map((ls) => {
            return { value: ls.description, label: ls.description };
        });
        setCurrencyList(uniqBy(fiatList, (obj) => obj.value));
    };

    const getPaymentMethods = async (value) => {
        let currency = value.value;
        const resp = await callApiWithToken("GET", `/api/customers/wpay/paymentMethods?currency=${currency}`)
        const { data = [] } = resp;
        const dataList = sortBy(data, ['name'], ['asc']);
        let paymentOptions = dataList.map((pm) => { return { value: pm.referenceName, label: pm.name } })
        setPaymentMethodList(uniqBy(paymentOptions, (obj) => obj.value));
    }

    const closeModal = () => {
        setPaymentMethodList();
        setConfigs([{ id: 1, tfFee: 2 }, { id: 2, tfFee: 2 }, { id: 3, tfFee: 2 }, { id: 4, tfFee: 2 }, { id: 5, tfFee: 2 }]);
        formik.resetForm();
        setFeeErrorMsg("");
        onClose();
        setLoading(false);
    }


    const formik = useFormik({
        initialValues: {
            email: '',
            currency: '',
            note: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email().required("Please enter the Email"),
            currency: Yup.object().required("Please select the region"),
            note: Yup.string().required("Please provide the notes")
        }),
        onSubmit: async (formValues) => {
            try {
                const formattedConfigs = filter(configs, (config) => !isEmpty(config.paymentMethods));
                const wrongFees = filter(formattedConfigs, (config) => isUndefined(config.cxFee) || config.cxFee < 0);

                if (isEmpty(formattedConfigs)) {
                    setFeeErrorMsg({ 1: "Please provide the valid payment method and fees" })
                } else if (!isEmpty(wrongFees)) {
                    const errObj = wrongFees.reduce((acc, value, index) => {
                        acc[value.id] = "Please provide the valid payment method and fees";
                        return acc;
                    }, {});
                    setFeeErrorMsg(errObj);
                } else {
                    setFeeErrorMsg("");
                    setLoading(true);
                    const response = await callApiWithToken("POST", "/api/customers/wpay/signup", {
                        email: formValues?.email,
                        currency: [formValues?.currency.value],
                        configs: formattedConfigs,
                        note: formValues?.note,
                        grpOrgId: getOrgId()
                    });
                    if (response?.message && response?.status) {
                        toast({
                            title: 'Success',
                            description: response?.message,
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                            position: 'top-right'
                        })
                    } else {
                        toast({
                            title: 'Error',
                            description: response?.message,
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                            position: 'top-right'
                        })
                    }
                    refreshGrid();
                    closeModal();
                }
            } catch (err) {
                setLoading(false);
                toast({
                    title: 'Error!',
                    description: response?.message || "Failed to fetch order details.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
            }
        },
    });

    return (
        <chakra.form onSubmit={formik.handleSubmit} display="flex" flexGrow={1}>
            <Modal isOpen={isOpen} onClose={() => closeModal()}>
                <ModalOverlay />
                <ModalContent
                    minWidth="1200px"
                    height="fit-content">
                    <ModalHeader>{t("sub-accounts:add_sub_account")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>

                        <Stack direction={"column"} justifyContent="space-between" flexGrow={1}>
                            <HStack spacing='64px'>
                                <FormControl isRequired
                                    isInvalid={formik.touched.email && formik.errors.email}>
                                    <FormLabel>{t("sub-accounts:email")}</FormLabel>
                                    <Input
                                        id="email"
                                        type="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                    />
                                    <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    isRequired
                                    isInvalid={formik.touched.currency && formik.errors.currency}
                                >
                                    <FormLabel>{t("sub-accounts:currency")}</FormLabel>
                                    <Select
                                        id="currency"
                                        isDisabled={(filter(configs, (config) => !isEmpty(config.paymentMethods)).length)}
                                        isSearchable={true}
                                        placeholder="Currency"
                                        options={currencyList}
                                        onChange={(value) => {
                                            formik.setFieldValue("currency", value);
                                            getPaymentMethods(value);
                                        }}
                                        value={formik.values.currency}
                                    />
                                    <FormErrorMessage>{formik.errors.currency}</FormErrorMessage>
                                </FormControl>
                            </HStack>
                            <VStack>
                                {configs.map((config, idx) =>
                                    <HStack id={idx} mt="50px" justifyContent="space-between" width={"1150px"} flexGrow={1}>
                                        <Box flex="2">
                                            <FormControl
                                                isRequired
                                                isInvalid={feeErrorMsg[idx + 1]}
                                            >
                                                <FormLabel>{t("sub-accounts:payment_method")}</FormLabel>
                                                <Select
                                                    isDisabled={config.isDisabled || isEmpty(paymentMethodList)}
                                                    isSearchable={true}
                                                    placeholder={t("sub-accounts:payment_method")}
                                                    options={paymentMethodList}
                                                    value={config.paymentMethodList}
                                                    onChange={(value) => {
                                                        const obj = [...configs];
                                                        config.paymentMethods = value.value
                                                        config.paymentMethodList = value;
                                                        obj[idx] = config;
                                                        setConfigs(obj)
                                                    }}
                                                />
                                                <FormErrorMessage>{feeErrorMsg[idx + 1]}</FormErrorMessage>
                                            </FormControl>
                                        </Box>
                                        <Box flex="0.5">
                                            <FormControl
                                                isRequired
                                                isInvalid={formik.touched.rates && formik.errors.rates}
                                            >
                                                <FormLabel htmlFor="name">{t("sub-accounts:tf_fee")}(%)</FormLabel>
                                                <Input
                                                    disabled={true}
                                                    id="rates"
                                                    type="number"
                                                    value={!isEmpty(config.paymentMethods) && config.tfFee}
                                                    onChange={formik.handleChange}
                                                />
                                                <FormErrorMessage>{formik.errors.rates}</FormErrorMessage>
                                            </FormControl>
                                        </Box>
                                        <Box flex="0.5">
                                            <FormControl
                                                isRequired
                                                isInvalid={config?.errorMsg}
                                            >
                                                <FormLabel htmlFor="name">{t("sub-accounts:my_fee")}(%)</FormLabel>
                                                <Input
                                                    isDisabled={config.isDisabled}
                                                    id="rates"
                                                    type="number"
                                                    value={configs[idx].cxFee}
                                                    onChange={(e) => {
                                                        config.cxFee = Number(e.target.value)
                                                        const obj = [...configs];
                                                        config.cxFee = Number(e.target.value);
                                                        obj[idx] = config;
                                                        setConfigs(obj)
                                                    }}
                                                />
                                                <FormErrorMessage>{feeErrorMsg}</FormErrorMessage>
                                            </FormControl>
                                        </Box>
                                        <HStack >
                                            <Box onClick={() => {
                                                const obj = [...configs];
                                                config.isDisabled = !config.isDisabled;
                                                obj[idx] = config;
                                                setConfigs(obj)
                                            }}
                                            >
                                                <Flex align="center" mt={30} _hover={{ cursor: "pointer" }}>
                                                    {config.isDisabled ? <img src={EditIcon} /> : <img src={SaveIcon} />}
                                                </Flex>
                                            </Box>
                                            <Box  >
                                                <Flex align="center" mt={30} _hover={{ cursor: "pointer" }} onClick={() => {
                                                    const obj = [...configs];
                                                    obj[idx] = { id: idx + 1, cxFee: "", tfFee: 2, paymentMethods: [], paymentMethodList: [] };
                                                    setConfigs(obj)
                                                }} >
                                                    <img src={DeleteIcon} />
                                                </Flex>
                                            </Box>
                                        </HStack>
                                    </HStack>)}
                            </VStack>
                            <Box position="relative" p={4}>
                                <Button onClick={() => setConfigs([...configs, { id: configs.length + 1, tfFee: 2 }])} position="absolute" left={0} leftIcon={<AddIcon />} bg={"#1A4FD6"} color={"white"}>
                                    {t("sub-accounts:add_new")}
                                </Button>
                            </Box>
                            <br />
                            <FormControl
                                isRequired
                                isInvalid={formik.touched.note && formik.errors.note}>
                                <FormLabel htmlFor="name">{t("sub-accounts:additional_note")}</FormLabel>
                                <Input
                                    id="note"
                                    type="text"
                                    value={formik.values.note}
                                    onChange={formik.handleChange}
                                />
                                <FormErrorMessage>{formik.errors.note}</FormErrorMessage>
                            </FormControl>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            isLoading={loading}
                            onClick={formik.handleSubmit}
                            colorScheme='blue' mr={3}
                        >
                            {t("sub-accounts:add")}
                        </Button>
                        <Button
                            onClick={() => closeModal()}
                        >
                            {t("sub-accounts:cancel")}
                        </Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>
        </chakra.form>
    )
}

export default AddSubAccountModal;