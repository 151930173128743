import React, { useEffect, useState, useContext, useRef } from "react";
import { round } from "lodash";
import Loader from "react-js-loader";
import Select from "react-select";
import { Box } from "@chakra-ui/react";
import moment from "moment";

import TransfiGrid from "../../../utils/TransfiGrid";
import DateRangePicker from '../../Common/DateRangePicker';

const FinanceBalance = (props) => {
  const [dateRange, setDateRange] = useState({
    startDate: moment(new Date()).subtract(6, 'days'),
    endDate: moment(new Date())
  });
  const [partners, setPartners] = useState({
    label: "Payment Partners",
    value: "paymentPartners",
  });
  const [paymentPartners, setPaymentPartners] = useState({
    label: "All",
    value: "all",
  });
  const [liquidityPartners, setLiquidityPartners] = useState({
    label: "All",
    value: "all",
  });
  const [currency, setCurrency] = useState({ label: "USD", value: "USD" });
  const [currencyOptions, setCurrencyOptions] = useState([
    { label: "USD", value: "USD" },
  ]);
  const [loading, setLoading] = useState(false);

  const gridRef = useRef();

  useEffect(() => {
    refreshGrid;
  }, []);

  const onChangeDateRange = (start, end) => {
    setDateRange({ startDate: start, endDate: end });
  }

  const refreshGrid = () => {
    gridRef.current.refreshGrid();
  };

  const partnerOptions = [
    { label: "Payment Partners", value: "paymentPartners" },
    { label: "Liquidity Partners", value: "liquidityPartners" },
  ];

  const paymentPartnersOptions = [
    { label: "Thaiking Pay", value: "thaikingpay", currencies: ["PHP", "USD"] },
    // { label: "Soon Pay", value: "soonpay", currencies: ["PHP", "USD"] },
    { label: "Cloud Pay", value: "cloudpay", currencies: ["PHP", "USD"] },
    { label: "Kolapay", value: "kolapay", currencies: ["VND", "USD"] },
    // { label: "5Pay", value: "5pay", currencies: ["VND", "USD"] },
    // {
    //   label: "Directa24",
    //   value: "directa24",
    //   currencies: ["THB", "SGD", "JPY", "VND", "USD"],
    // },
    // { label: "All Pay", value: "allpay", currencies: ["MYR", "USD"] },
    { label: "Xendit", value: "xendit", currencies: ["PHP", "USD"] },
    { label: "Bitssa", value: "bitssa", currencies: ["PHP", "USD"] },
    { label: "Rainbow Pay", value: "rainbowpay", currencies: ["PHP", "USD"] },
    { label: "Link Pay", value: "linkpay", currencies: ["PHP", "USD"] },
    { label: "Top Pay", value: "toppay", currencies: ["IDR", "USD"] },
    { label: "E2Pay Bank Tranfer", value: "e2pay_bank_transfer", currencies: ["IDR", "USD"] },
    { label: "E2Pay Local Wallet", value: "e2pay_local_wallet", currencies: ["IDR", "USD"] },
    { label: "Fasku", value: "fasku", currencies: ["IDR", "USD"] },
  ];

  const liquidityPartnersOptions = [
    { label: "OKX UAB", value: "okx_uab", currencies: ["USDC", "USDT", "USD"] },
    { label: "OKX HK", value: "okx_hk", currencies: ["USDC", "USDT", "USD"] },
    { label: "Huobi HK", value: "huobi_hk", currencies: ["USDC", "USDT", "USD"] },
    { label: "Huobi UAB", value: "huobi_uab", currencies: ["USDC", "USDT", "USD"] },
    { label: "Binance", value: "binance", currencies: ["USDC", "USDT", "USD"] },
    { label: "Bitpace", value: "bitpace", currencies: ["EUR", "USD"] },
  ];

  const columns = [
    {
      Header: "Date",
      accessor: "date",
      width: "220",
    },
    {
      Header: "Partner",
      accessor: "partner",
      width: "170",
    },
    {
      Header: "Currency",
      accessor: "currency",
      width: "150",
    },
    {
      Header: "Starting Balance",
      accessor: "startingBalance",
      width: "150",
      Cell: ({ value }) => <>{round(value)}</>,
    },
    {
      Header: "Payin",
      accessor: "payin",
      width: "150",
      Cell: ({ value }) => <>{round(value) || 0}</>,
    },
    {
      Header: "Payout",
      accessor: "payout",
      width: "140",
      Cell: ({ value }) => <>{round(value) || 0}</>,
    },
    {
      Header: "Closing Balance",
      accessor: "closingBalance",
      width: "150",
      Cell: ({ value }) => <>{round(value)}</>,
    },
  ];

  return !loading ? (
    <Box style={{ background: "white" }} heading="Payment Method">
      <div
        style={{
          float: "left",
          width: "20%",
          paddingLeft: "20px",
          paddingTop: "5px",
        }}
      >
        <strong style={{ fontSize: "15px" }}>Partners :</strong>
        <Select
          isSearchable={false}
          value={partners}
          options={partnerOptions}
          onChange={(value) => {
            setPartners(value);
            setPaymentPartners({ label: "All", value: "all" });
            setLiquidityPartners({ label: "All", value: "all" });
          }}
        />
      </div>
      {partners.value === "paymentPartners" ? (
        <>
          <div
            style={{
              float: "left",
              width: "20%",
              paddingLeft: "20px",
              paddingTop: "5px",
            }}
          >
            <strong style={{ fontSize: "15px" }}>Partners :</strong>
            <Select
              isSearchable={false}
              value={paymentPartners}
              options={paymentPartnersOptions}
              onChange={(value) => {
                setPaymentPartners(value);
                const currencies =
                  value?.currencies?.length > 0
                    ? value?.currencies.map((currency) => {
                      return { label: currency, value: currency };
                    })
                    : [{ label: "USD", value: "USD" }];

                setCurrencyOptions(currencies);
                setCurrency({ label: "USD", value: "USD" });
              }}
            />
          </div>

          <div
            style={{
              float: "left",
              width: "20%",
              paddingLeft: "20px",
              paddingTop: "5px",
            }}
          >
            <strong style={{ fontSize: "15px" }}>Currency :</strong>
            <Select
              isSearchable={false}
              value={currency}
              options={currencyOptions}
              onChange={(value) => setCurrency(value)}
            />
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              float: "left",
              width: "20%",
              paddingLeft: "20px",
              paddingTop: "5px",
            }}
          >
            <strong style={{ fontSize: "15px" }}>Liquidity Partners :</strong>
            <Select
              isSearchable={false}
              value={liquidityPartners}
              options={liquidityPartnersOptions}
              onChange={(value) => {
                setLiquidityPartners(value);
                const currencies =
                  value?.currencies?.length > 0
                    ? value?.currencies.map((currency) => {
                      return { label: currency, value: currency };
                    })
                    : [{ label: "USD", value: "USD" }];
                setCurrencyOptions(currencies);
                setCurrency({ label: "USD", value: "USD" });
              }}
            />
          </div>

          <div
            style={{
              float: "left",
              width: "20%",
              paddingLeft: "20px",
              paddingTop: "5px",
            }}
          >
            <strong style={{ fontSize: "15px" }}>Currency :</strong>
            <Select
              isSearchable={false}
              value={currency}
              options={currencyOptions}
              onChange={(value) => setCurrency(value)}
            />
          </div>
        </>
      )}
      <div style={{
        float: "left",
        paddingLeft: "20px",
        paddingTop: "24px",
      }}>

        <DateRangePicker
          onChangeDateRange={onChangeDateRange}
          start={dateRange.startDate}
          end={dateRange.endDate}
        />
      </div>


      <TransfiGrid
        ref={gridRef}
        columns={columns}
        dataUrl={"/api/admin/finance/balance"}
        filters={{
          partnerType: partners?.value === "paymentPartners" ? "fiat" : "crypto",
          partnerName: partners?.value === "paymentPartners" ? paymentPartners?.value : liquidityPartners?.value,
          currency: currency?.value,
          dateRange
        }}
        selectRow={false}
      ></TransfiGrid>
    </Box>
  ) : (
    <Loader
      type="spinner-cub"
      bgColor={"#FFFFFF"}
      title={"Loading..."}
      color={"cyan.400"}
      size={100}
    />
  );
};

export default FinanceBalance;
