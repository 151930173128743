import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Center, HStack, Stack, useDisclosure } from "@chakra-ui/react";
import { startCase } from "lodash";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import TransfiGrid from "../../../utils/TransfiGrid";
import { BiEdit, BiShow } from "react-icons/bi";
import { EnvTypeContext } from "../../../context/EnvTypeContext";
import ViewModal from "./ViewModal";
import { callApiWithToken } from "../../../utils/utils";
import Loader from "../../../utils/Loader";
import { Divider } from "antd";

export default function Configurations() {
    const gridRef = React.useRef();
    const [jsonData, setJsonData] = useState({});
    const [loading, setLoading] = useState(false);
    const [module, setModule] = useState({ label: "All", value: "all" });
    const [name, setName] = useState({ label: "All", value: "all" });
    const [version, setVersion] = useState({ label: "All", value: "all" });
    const [moduleOptions, setModuleOptions] = useState([]);
    const [nameOptions, setNameOptions] = useState([]);
    const [versionOptions, setVersionOptions] = useState([]);

    console.log(moduleOptions);
    const { env } = useContext(EnvTypeContext);
    const { isOpen: isOpenView, onOpen: onOpenView, onClose: onCloseView } = useDisclosure();

    const navigate = useNavigate();

    useEffect(() => {
        refreshGrid;
    }, [env]);

    useEffect(() => {
        fetchOptions();
    }, []);

    const refreshGrid = () => {
        gridRef.current.refreshGrid();
    };

    const handleOnClickView = (value) => {
        onOpenView();
        setJsonData(value);
    };

    const fetchOptions = async () => {
        setLoading(true);
        const response = await callApiWithToken("GET", "/api/admin/getConfigOptions");
        const { moduleList, nameList, versionList } = response;
        setModuleOptions([...moduleList, { label: "All", value: "all" }]);
        setNameOptions([...nameList, { label: "All", value: "all" }]);
        setVersionOptions([...versionList, { label: "All", value: "all" }]);
        setLoading(false);
    };

    const columns = [
        {
            Header: "Module",
            accessor: "module",
            width: 100,
            Cell: ({ value }) => startCase(value),
        },
        {
            Header: "Name",
            accessor: "name",
            width: 100,
            Cell: ({ value }) => startCase(value),
        },
        {
            Header: "Version",
            accessor: "version",
            width: 100,
            Cell: ({ value }) => value || "-",
        },
        {
            Header: "Action",
            accessor: "",
            width: 100,
            Cell: ({ row }) => {
                return (
                    <HStack>
                        {/* <BiEdit
                            style={{
                                fontSize: "22px",
                                color: "#2b6cb0",
                                cursor: "pointer",
                            }}
                            onClick={() => navigate(`/admin/configs/edit?module=${row?.original?.module}&name=${row?.original?.name}&version=${row?.original?.version}`)}
                        ></BiEdit> */}
                        <BiShow
                            style={{
                                fontSize: "22px",
                                color: "#2b6cb0",
                                cursor: "pointer",
                            }}
                            onClick={() => handleOnClickView(row?.original)}
                        ></BiShow>
                    </HStack>
                )
            }
        },
        {
            Header: "Updated At",
            accessor: "updatedAt",
            width: 100,
            Cell: ({ value }) => moment(value).format("lll")
        },
        {
            Header: "Updated By",
            accessor: "updatedBy",
            width: 100,
            Cell: ({ value }) => value || "-",
        }
    ];

    return (
        loading ? <Loader /> :
            <Center py={5} bg="white.100">
                <Stack spacing={2} w="90%">
                    <Box bg="white" p={4}>
                        <HStack>
                            <HStack spacing={2} width="80%" justifyContent="flex-start">
                                <div style={{ float: "left", width: "20%", paddingLeft: "20px", paddingTop: "5px" }}>
                                    <strong style={{ fontSize: "15px" }}>Module :</strong>
                                    <Select
                                        zIndex={3}
                                        value={module}
                                        options={moduleOptions}
                                        onChange={(value) => setModule(value)}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                </div>
                                <div style={{ float: "left", width: "20%", paddingLeft: "20px", paddingTop: "5px" }}>
                                    <strong style={{ fontSize: "15px" }}>Name :</strong>
                                    <Select
                                        zIndex={3}
                                        value={name}
                                        options={nameOptions}
                                        onChange={(value) => setName(value)}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                </div>
                                <div style={{ float: "left", width: "20%", paddingLeft: "20px", paddingTop: "5px" }}>
                                    <strong style={{ fontSize: "15px" }}>Version :</strong>
                                    <Select
                                        zIndex={3}
                                        value={version}
                                        options={versionOptions}
                                        onChange={(value) => setVersion(value)}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                </div>
                            </HStack>
                            <HStack spacing={2} w="20%" justifyContent="flex-end" paddingRight={'0.7rem'} paddingTop={'1rem'}>
                                <Button
                                    variant="outline"
                                    colorScheme="blue"
                                    onClick={() => {
                                        navigate(`/admin/configs/add`);
                                    }}
                                >
                                    Add New Config
                                </Button>
                            </HStack>
                        </HStack>
                        <Divider />
                        <TransfiGrid
                            filters={{
                                filters: {
                                    ...(module.value !== "all" && { module: module.value }),
                                    ...(name.value !== "all" && { name: name.value }),
                                    ...(version.value !== "all" && { version: version.value })
                                }
                            }}
                            ref={gridRef}
                            columns={columns}
                            dataUrl={"/api/admin/listConfigurations"}
                        ></TransfiGrid>
                        <ViewModal isOpen={isOpenView} onClose={onCloseView} jsonData={jsonData} />
                    </Box>
                </Stack>
            </Center >
    );
}
