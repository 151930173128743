/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import {
    Button,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Input,
    useToast,
    Stack
} from '@chakra-ui/react';

import { callApiWithToken } from "../../utils/utils";
import { round } from 'lodash';

const AddPrefundModal = (props) => {
    const { isOpen, onClose, isLoading, refreshGrid, batchId, data } = props;

    const toast = useToast();
    const [loading, setLoading] = useState(false)

    const movePrefund = async () => {
        const currency = ["PHP","IDR"].includes(data?.fiatCurrency) ? data?.fiatCurrency : "USDT"
        const amount = ["PHP","IDR"].includes(data?.fiatCurrency) ? data?.amount : data?.usdAmount
        let body = {
            sendCurrency: data?.fiatCurrency,
            sendAmount: data?.amount,
            orgId: data?.orgId,
            batchId,
            sendCurrencyUsdt: currency,
            sendAmountUsdt : amount
        };
        try {
            setLoading(true)
            const response = await callApiWithToken("POST", '/api/settlement/movePrefund', body);
            toast({
                title: response.message,
                status: 'success',
                isClosable: true,
                position: 'top-right',
                duration: 5000,
            });
            setLoading(false);
            refreshGrid();
            onClose();

        } catch (error) {
            setLoading(false)
            toast({
                title: 'Error!',
                description: response?.message || "Failed to fetch order details.",
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right'
            });
            refreshGrid();
            onClose();
        }


    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>Add to Prefunds - {batchId}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>

                    <Stack direction={"column"} justifyContent="space-between" flexGrow={1}>
                        <FormControl
                            isRequired
                        >
                            <FormLabel htmlFor="name">Total Amount</FormLabel>
                            <Input
                                id="conversionRate"
                                type="number"
                                value={["PHP","IDR"].includes(data?.fiatCurrency) ? round(data?.amount,2) : round(data?.usdAmount,2)}
                                isDisabled
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="name">Currency</FormLabel>
                            <Input
                                id="description"
                                type="text"
                                value={["PHP","IDR"].includes(data?.fiatCurrency) ? data?.fiatCurrency : "USDT"}
                                isDisabled
                            />
                        </FormControl>
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button
                        isLoading={loading}
                        onClick={() => movePrefund()}
                        colorScheme='blue' mr={3}
                    >
                        Add To Prefunds
                    </Button>
                    <Button
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </ModalFooter>

            </ModalContent>
        </Modal>

    )
}

export default AddPrefundModal;