import React, { useState, useEffect } from 'react';
import Loader from "react-js-loader";
import { capitalize, startCase, get } from 'lodash';
import {
    Stat, StatLabel, StatNumber, Accordion, AccordionItem,
    AccordionButton, Box, AccordionIcon, AccordionPanel
} from '@chakra-ui/react';
import { callApiWithToken } from '../../../utils/utils';
import TimezoneFormatter from "../../../utils/TimezoneFormatter";

export default function CryptoMonitoringDetails(props) {
    const { orderId } = props || {};
    const [loading, setLoading] = useState(false);
    const [cryptoMonitoringData, setCryptoMonitoringData] = useState({});

    useEffect(() => {
        getCryptoMonitoringDetails();
    }, [orderId]);

    const getCryptoMonitoringDetails = async () => {
        setLoading(true);
        const response = await callApiWithToken("GET", `/api/orders/getCryptoMonitoringDetails?orderId=${orderId}`) || {};
        const { data = {} } = response;
        setCryptoMonitoringData(data);
        setLoading(false);
    }

    const rowComponent = (label, value) => {
        return <>
            <div style={{ display: 'inline-flex', width: '100%' }}>
                <div style={{ float: 'left', width: '50%' }}>
                    <Stat float={'left'}>
                        <StatLabel>{label}</StatLabel>
                    </Stat>
                </div>
                <div style={{ float: 'right', width: '50%', marginRight: '10px' }}>
                    <Stat float={'right'}>
                        <StatLabel><b>{value === null || value === undefined ? 'N/A' : value}</b></StatLabel>
                    </Stat>
                </div>
            </div>
        </>
    }

    return !loading ? <>
        <div style={{ width: '100%' }}>
            <Stat float={'left'} marginBottom={'20px'}>
                <StatNumber fontSize={'md'}>Monitoring Details</StatNumber>
            </Stat>
            {rowComponent('Date', cryptoMonitoringData?.time ? <TimezoneFormatter date={cryptoMonitoringData?.time} format={"lll"} /> : "N/A")}
            {rowComponent('Wallet Address', cryptoMonitoringData?.address)}
            {rowComponent('Risk Level', cryptoMonitoringData?.riskLevel ? capitalize(cryptoMonitoringData?.riskLevel) : "N/A")}
            {rowComponent('Risk Score', cryptoMonitoringData?.riskscore)}
            {rowComponent('Risk Volume', cryptoMonitoringData?.riskyVolume)}
            {rowComponent('Status', capitalize(cryptoMonitoringData?.status))}
            <br />
            <br />
            <Accordion borderRadius={12} borderColor="transparent" size={'xl'} boxShadow="0 0 5px 0 #1F29371F" allowToggle>
                <AccordionItem>
                    <h2>
                        <AccordionButton _expanded={{ bg: 'rgb(74, 205, 237)', color: 'white' }}>
                            <Box as="span" flex='1' textAlign='left'>
                                Crypto Monitoring Symbols
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel py={4}>
                        {cryptoMonitoringData?.signals ? Object.keys(cryptoMonitoringData?.signals).map(signal => {
                            return rowComponent(startCase(signal), get(cryptoMonitoringData?.signals, signal, 'N/A'))
                        }) : <></>}
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </div>
    </> :
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={'#FFFFFF'} size={100} />
}
