import React, { useState, useRef, useContext, useEffect } from "react";
import Loader from "react-js-loader";
import { Box, Button, useDisclosure, useToast, } from "@chakra-ui/react";
import { HiPlusSm } from "react-icons/hi";
import { BiRefresh, BiReset } from "react-icons/bi";
import { FiFilter } from "react-icons/fi";
import { Link } from "react-router-dom";
import moment from "moment";

import TransfiGrid from "../../utils/TransfiGrid";
import { TimezoneContext } from "../../context/TimezoneContext";
import BalanceTransferModal from "./BalanceTransferModal"
import { getCxRoles } from "../App/useToken";
import { DownloadIcon } from "@chakra-ui/icons";
import SettlementFilter from "./SettlementFilter";
import { NavBarContext } from "../../context/NavBarContext";
import { callApiWithToken } from "../../utils/utils";
import { isArray, round } from "lodash";

export default function Settlement() {
	//ref
	const gridRef = useRef();

	const toast = useToast();

	const { selectedOrg } = useContext(NavBarContext);
	const { timezone } = useContext(TimezoneContext);

	useEffect(() => {
        	refreshGrid();
    	}, [timezone]);
	
	//disclosures
	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: isOpenFilterModal,
		onOpen: onOpenFilterModal,
		onClose: onCloseFilterModal,
	} = useDisclosure({ defaultIsOpen: false });

	//variables
	const defaultDate = { startDate: moment(new Date()).subtract(2, "months"), endDate: moment(new Date()) };
	const defaultFilters = { dateRange: defaultDate, currency: "ALL" }

	//state variables
	const [loading, setLoading] = useState(false);
	const [inputData, setInputData] = useState({currency: "ALL"});
	const [formValues, setFormValues] = useState({});
	const [filters, setFilters] = useState(defaultFilters);
	const [dateRange, setDateRange] = useState(defaultDate);

	const refreshGrid = () => {
		gridRef.current.refreshGrid();
	};
	
	const cxRole = getCxRoles();


	const columns = [
		{
			Header: "Date",
			accessor: "date",
			type: "date",
		},
		{
			Header: "Fiat Currency",
			accessor: "currency",
			width: "30",
		},
		{
			Header: "Opening Balance",
			accessor: "openingBalance",
			width: "30",
			Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Txns(Buy)",
			accessor: "buy.totalOrders",
			width: "30",
			color: "info",
			Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Gross Fiat Amt(Buy)",
			accessor: "buy.totalAmount",
			width: "150",
			color: "info",
			Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Processing Fee Amt(Buy)",
			accessor: "buy.totalFee",
			width: "150",
			color: "info",
			Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Txns(Sell)",
			accessor: "sell.totalOrders",
			width: "30",
			color: "warning",
			Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Gross Fiat Amt(Sell)",
			accessor: "sell.totalAmount",
			width: "150",
			color: "warning",
			Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Processing Fee Amt(Sell)",
			accessor: "sell.totalFee",
			width: "150",
			color: "warning",
			Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Net Balance(Day)",
			accessor: "currentDayBalance",
			width: "30",
			color: "info",
			info: "Gross Fiat Amt(Buy) - Gross Fiat Amt(Sell)",
			Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Closing Balance",
			accessor: "closingBalance",
			width: "30",
			color: "info",
			info: "Opening Balance + Net Balance(Day)",
			Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Prefunded",
			accessor: "prefunds",
			width: "30",
			color: "info",
			Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Amount Settled (fiat)",
			accessor: "settled",
			width: "150",
			color: "info",
			Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Settlement ID",
			accessor: "settlementId",
			width: "30",
			Cell: ({ value }) => {
				return (
					<Link style={{ color: "#4ACDED", fontWeight: "bold" }} to={`/settlements/batch/${value}`}>
						{value}
					</Link>
				);
			},
			color: "info",
		},
		{
			Header: "Net Balance",
			accessor: "netBalance",
			width: "30",
			color: "warning",
			Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Prefunding Balance",
			accessor: "prefundingBalance",
			width: "30",
			color: "warning",
			info: "Today's Prefunded + Previous day Prefunding Balance",
			Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Settlement Currency",
			accessor: "settlementCurrency",
			width: "30",
			color: "info",
		},
		{
			Header: "Conversion Rate",
			accessor: "conversionRate",
			width: "30",
			color: "info",
			Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Settlement Amount In Settlement Currency",
			accessor: "settlementInUSDT",
			width: "250",
			color: "info",
			Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
		}
	];

	const handleOnReset = () => {
		setDateRange(defaultDate);
		setFilters(defaultFilters);
		setFormValues({});
		setInputData({});
	};

	const downloadReport = async () => {
		const org = !isArray(selectedOrg) ? [selectedOrg] : selectedOrg;
        let body = {
            gridFilters: { impersonatedOrg: org, filters }
        };

        const response = await callApiWithToken("POST", '/api/balance/report', body);
        if (response?.message) {
            toast({
                title: 'Unable to schedule report',
                status: 'error',
                isClosable: true,
                position: 'top-right',
                duration: 5000,
            });
        } else {
            toast({
                title: 'Report Scheduled Successfully, You will get email shortly.',
                status: 'success',
                isClosable: true,
                position: 'top-right',
                duration: 5000,
            });
        }
    }

	return !loading ? (
		<Box style={{ background: "white" }} heading="Binance Balance">

			<div style={{ float: "right", marginTop: "2%" }}>
				<Button
					leftIcon={<HiPlusSm />}
					colorScheme="blue"
					style={{ margin: "0 8px" }}
					size="sm"
					onClick={onOpen}
					isDisabled={cxRole != "admin" ? true : false}
				>
					Balance Transfer
				</Button>
				<Button
					leftIcon={<BiReset style={{ fontSize: "20px" }} />}
					variant={"outline"}
					colorScheme="blue"
					onClick={() => handleOnReset()}
					style={{ margin: "0 8px" }}
					size="sm"
				>
					Reset
				</Button>
				<Button
					leftIcon={<BiRefresh style={{ fontSize: "20px" }} />}
					onClick={() => refreshGrid()}
					variant={"outline"}
					colorScheme="blue"
					style={{ margin: "0 0px" }}
					size="sm"
				>
					Refresh
				</Button>
				<Button
					leftIcon={<FiFilter style={{ fontSize: "20px" }} />}
					onClick={onOpenFilterModal}
					variant={"outline"}
					colorScheme="blue"
					style={{ margin: "0 8px" }}
					size="sm"
				>
					Filter
				</Button>
				<Button
					leftIcon={<DownloadIcon />}
					onClick={() => downloadReport()}
					variant={"outline"}
					colorScheme="blue"
					style={{ margin: "0 8px" }}
					size="sm"
				>
					Download Report
				</Button>
			</div>
			<TransfiGrid
				gridName="Settlements"
				ref={gridRef}
				columns={columns}
				dataUrl={"/api/balance/list"}
				selectRow={false}
				filters={{ filters, impersonatedOrg: selectedOrg }}
			/>
			<BalanceTransferModal
				isOpen={isOpen}
				onOpen={onOpen}
				onClose={onClose}
				refreshGrid={refreshGrid}
			/>
			<SettlementFilter
				isOpen={isOpenFilterModal}
				onClose={onCloseFilterModal}
				inputData={inputData}
				setInputData={setInputData}
				dateRange={dateRange}
				setDateRange={setDateRange}
				filters={filters}
				setFilters={setFilters}
				formValues={formValues}
				setFormValues={setFormValues}
				handleOnReset={handleOnReset}
				timezone={timezone} 
			/>
		</Box >
	) : (
		<Loader
			type="spinner-cub"
			bgColor={"#FFFFFF"}
			title={"Loading..."}
			color={"cyan.400"}
			size={100}
		/>
	);
}
