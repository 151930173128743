import React, { useEffect, useState } from "react";

//Custom Components
import OTPVerify from "./OTPVerify";
import EmailForNewPassword from "./EmailForNewPassword";
import VerifyMFA from "./VerifyMFA";
import SetNewPassword from "./SetNewPassword";

const NewPassword = (props) => {
    const { reset = false } = props || {};
    const searchParams = new URLSearchParams(location?.search);
    const email = searchParams.get('email');

    const [userEmail, setUserEmail] = useState(false);
    const [otpVerified, setOTPVerified] = useState(false);
    const [mfaStatus, setMfaStatus] = useState(false);

    return <>
        {(!userEmail && !otpVerified && !mfaStatus) && <EmailForNewPassword urlEmail={email} setUserEmail={setUserEmail} reset={reset} setMfaStatus={setMfaStatus} />}
        {(userEmail && !otpVerified && !mfaStatus) && <OTPVerify urlEmail={email} email={userEmail} setMfaStatus={setMfaStatus} setOTPVerified={setOTPVerified} reset={reset}/>}
        {(!otpVerified && mfaStatus) && <VerifyMFA email={userEmail} setOTPVerified={setOTPVerified}/>}
        {(otpVerified) && <SetNewPassword email={userEmail} reset={reset}/>}
    </>;
};

export default NewPassword;
