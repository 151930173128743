import React, { useEffect, useState } from "react";
import { includes, isEmpty, unset, startCase, split, uniqBy, concat } from "lodash";

import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent } from "@chakra-ui/react";
import { DrawerCloseButton, Input, Button } from "@chakra-ui/react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import DateRangePicker from "../../Common/DateRangePicker";
import { statusFilterConfigs } from "../../../utils/config";
import { callApiWithToken } from "../../../utils/utils";

const OrderFilter = (props) => {
    const { t } = useTranslation();
    const { isOpen, onClose, countryOptions, paymentOptions, setFilters, setDateRange, dateRange, type } = props;
    const { formValues, setFormValues, inputData, setInputData, filters, handleOnReset, fiatCurrency } = props;
    const [cryptoCurrency, setCryptoCurrency] = useState([]);
    const [filterData, setFilterData] = useState({});

    const getCryptoCurrency = async () => {
        const response = await callApiWithToken("GET", `/api/widget/configList?name=crypto&module=buy_crypto`);
        const data = response.data[0].config;
        const cryptoList = data.map((crypto) => {
            return { value: crypto.formattedSymbol, label: crypto.formattedSymbol };
        });
        setCryptoCurrency(uniqBy(cryptoList, (obj) => obj.value));
    };

    useEffect(() => {
        setFormValues(filters);
    }, [filters]);

    useEffect(() => {
        getCryptoCurrency();
        setFormValues(filters);
    }, []);

    const handleOnChanges = (key, value) => {
        let newValue;
        setInputData({
            ...inputData,
            [key]: value,
        });
        if (key === "status") {
            const type = includes(value.value, "Onramp") ? "buy" : "sell";
            setInputData({
                ...inputData,
                type: [{ label: startCase(type), value: type }],
                status: value
            });
            setFormValues({
                ...formValues,
                type: [type],
            });
            newValue = statusFilterConfigs[value.value];
        } else {
            if (key === 'orderId') newValue = value && (split(value, ',')).map(str => str.trim());
            else if (Array.isArray(value)) newValue = value.map((data) => data.value || data);
            else if (key === 'settlementStatus') newValue = value.value
            else if (key === 'filterData') newValue = value.value
            else if (key !== 'orderId') newValue = value.trim();
        }
        setFormValues({
            ...formValues,
            [key]: newValue,
        });
    };

    const handleOnClear = () => {
        onClose();
        handleOnReset();
    };

    const handleOnFilter = () => {
        Object.keys(formValues).map((key) => {
            if (isEmpty(formValues[key])) unset(formValues, key);
        });
        setFilters(formValues);
        onClose();
    };

    const onChangeDateRange = (start, end) => {
        setDateRange({ startDate: start, endDate: end });
        setFormValues({
            ...formValues,
            ["dateRange"]: { startDate: start, endDate: end },
        });
    };
    const orgId = sessionStorage.getItem('orgId');
    const grpOrgId = sessionStorage.getItem('grpOrgId');

    let onrampStatusOptions = [
        { label: ("Onramp Initiated"), value: "Onramp Initiated" },
        { label: ("Onramp OnHold"), value: "Onramp OnHold" },
        { label: ("Onramp Manual Review"), value: "Onramp Manual Review" },
        { label: ("Onramp Fiat Processing"), value: "Onramp Fiat Processing" },
        { label: ("Onramp Crypto Initiated"), value: "Onramp Crypto Initiated" },
        { label: ("Onramp Fiat Not Received"), value: "Onramp Fiat Not Received" },
        { label: ("Onramp Successful"), value: "Onramp Successful" },
        { label: ("Onramp Expired"), value: "Onramp Expired" },
    ];
    if(filterData?.value === "order_completed_by"){
        onrampStatusOptions = [
            { label: ("Onramp Fiat Not Received"), value: "Onramp Fiat Not Received" },
            { label: ("Onramp Successful"), value: "Onramp Successful" },
            { label: ("Onramp Expired"), value: "Onramp Expired" },
        ];
    }
    let offrampStatusOptions = [
        { label: ("Offramp Initiated"), value: "Offramp Initiated" },
        { label: ("Offramp Fiat Initiated"), value: "Offramp Fiat Initiated" },
        { label: ("Offramp Manual Review"), value: "Offramp Manual Review" },
        { label: ("Offramp OnHold"), value: "Offramp OnHold" },
        { label: ("Crypto Mismatch"), value: "Crypto Mismatch" },
        { label: ("Offramp Successful"), value: "Offramp Successful" },
        { label: ("Offramp Crypto Refunded"), value: "Offramp Crypto Refunded" },
        { label: ("Offramp Expired"), value: "Offramp Expired" },
        { label: ("Offramp Cancelled"), value: "Offramp Cancelled" },
        { label: ("Offramp Failed"), value: "Offramp Failed" },
    ];

    if(filterData?.value === "order_completed_by"){
        offrampStatusOptions = [
            { label: ("Offramp Successful"), value: "Offramp Successful" },
            { label: ("Offramp Expired"), value: "Offramp Expired" },
            { label: ("Offramp Cancelled"), value: "Offramp Cancelled" }
        ];
    }

    const otcPayinStatusOptions = [
        { label: ("Onramp Initiated"), value: "Onramp Initiated" },
        { label: ("Onramp Fiat Received"), value: "Onramp Fiat Received" },
        { label: ("Onramp Successful"), value: "Onramp Successful" },
        { label: ("Onramp Approved"), value: "Onramp Approved" },
        { label: ("Onramp Rejected"), value: "Onramp Rejected" },
    ];

    const otcPayoutStatusOptions = [
        { label: ("Offramp Initiated"), value: "Offramp Initiated" },
        { label: ("Offramp Crypto Recieved"), value: "Offramp Crypto Recieved" },
        { label: ("Offramp Successful"), value: "Offramp Successful" },
        { label: ("Offramp Approved"), value: "Offramp Approved" },
        { label: ("Offramp Rejected"), value: "Offramp Rejected" },
    ];

    let statusOptions = [];
    if (type === 'buy') statusOptions = onrampStatusOptions
    if (type === 'sell') statusOptions = offrampStatusOptions;
    if (type === 'otc_payin') statusOptions = otcPayinStatusOptions;
    if (type === 'otc_payout') statusOptions = otcPayoutStatusOptions;
    if (grpOrgId === "OG-220923101501950") statusOptions = concat(onrampStatusOptions, offrampStatusOptions);

    const orderStatus = [
        { label: "Settled", value: "settled" },
        { label: "Unsettled", value: "unsettled" },
        { label: "All", value: "all" }
    ]

    const filterOptions = [
        { label: "Order Created By", value: "order_created_by" },
        { label: "Order Completed By", value: "order_completed_by" },
    ]

    return (
        <>
            <Drawer onClose={onClose} isOpen={isOpen} size={"sm"}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader style={{ color: "#3182ce", fontFamily: "arial" }}>Filters</DrawerHeader>
                    <DrawerBody>
                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isSearchable={true}
                                placeholder="Date Filter"
                                options={filterOptions}
                                value={inputData.filterData}
                                onChange={(e) => {
                                    handleOnChanges("filterData", e)
                                    setFilterData(e)
                                }}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <DateRangePicker
                                width={"100%"}
                                onChangeDateRange={onChangeDateRange}
                                start={dateRange.startDate}
                                end={dateRange.endDate}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Input
                                placeholder={t("transactions:order_id")}
                                style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                                type="text"
                                value={formValues.orderId}
                                onChange={(e) => handleOnChanges("orderId", e.target.value)}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Input
                                placeholder={t("default:email_id")}
                                style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                                type="text"
                                value={formValues.email}
                                onChange={(e) => handleOnChanges("email", e.target.value)}
                            />
                        </div>
                        {(orgId === "OG-220923101501950") && (
                            <div style={{ margin: "12px 0" }}>
                                <Input
                                    placeholder={t("transactions:transaction_owner")}
                                    style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                                    type="text"
                                    value={formValues.owner}
                                    onChange={(e) => handleOnChanges("owner", e.target.value)}
                                />
                            </div>
                        )}
                        {grpOrgId === "OG-220923101501950" && (
                            <div style={{ margin: "12px 0" }}>
                                <Input
                                    placeholder={t("transactions:external_orderId")}
                                    style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                                    type="text"
                                    value={formValues.externalOrderId}
                                    onChange={(e) => handleOnChanges("externalOrderId", e.target.value)}
                                />
                            </div>
                        )}
                        <div style={{ margin: "12px 0" }}>
                            <Input
                                placeholder={t("sub-accounts:api_key")}
                                style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                                type="text"
                                value={formValues.apiKey}
                                onChange={(e) => handleOnChanges("apiKey", e.target.value)}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isSearchable={true}
                                placeholder={t("transactions:status")}
                                options={statusOptions}
                                value={inputData.status}
                                onChange={(e) => handleOnChanges("status", e)}
                            />
                        </div>
                        {(orgId !== "OG-220923101501950" && grpOrgId !== "OG-220923101501950") && (
                            <div style={{ margin: "12px 0" }}>
                                <Select
                                    isMulti
                                    isSearchable={true}
                                    placeholder="Country"
                                    options={countryOptions}
                                    value={inputData.userCountry}
                                    onChange={(e) => handleOnChanges("userCountry", e)}
                                />
                            </div>
                        )}
                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isMulti
                                isSearchable={true}
                                placeholder={t("transactions:fiat_currency")}
                                options={fiatCurrency}
                                value={inputData.fiatTicker}
                                onChange={(e) => handleOnChanges("fiatTicker", e)}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isMulti
                                isSearchable={true}
                                placeholder={t("transactions:crypto_currency")}
                                options={cryptoCurrency}
                                value={inputData.cryptoTicker}
                                onChange={(e) => handleOnChanges("cryptoTicker", e)}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isMulti
                                isSearchable={true}
                                placeholder={t("transactions:payment_name")}
                                options={paymentOptions}
                                value={inputData.paymentName}
                                onChange={(e) => handleOnChanges("paymentName", e)}
                            />
                        </div>
                        {orgId !== "OG-220923101501950" && grpOrgId !== "OG-220923101501950" && (
                            <div style={{ margin: "12px 0" }}>
                                <Select
                                    isSearchable={false}
                                    placeholder={t("transactions:settlement_status")}
                                    options={orderStatus}
                                    value={inputData.settlementStatus}
                                    onChange={(e) => handleOnChanges("settlementStatus", e)}
                                />
                            </div>
                        )}
                        <div style={{ margin: "12px 0" }}>
                            <Input
                                placeholder={t("transactions:account_number")}
                                style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                                type="text"
                                value={formValues.paymentAccountNumber}
                                onChange={(e) => handleOnChanges("paymentAccountNumber", e.target.value)}
                            />
                        </div>
                        <div style={{ marginTop: "30px" }}>
                            <Button onClick={() => handleOnFilter()} variant={"outline"} colorScheme="blue">
                                {t("default:filter")}
                            </Button>
                            <Button
                                onClick={() => handleOnClear()}
                                variant={"outline"}
                                colorScheme="red"
                                style={{ marginLeft: "30px" }}
                            >
                                {t("default:clear")}
                            </Button>
                        </div>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default OrderFilter;
