/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Input,
    useToast,
    Stack
} from '@chakra-ui/react';

import { callApiWithToken } from "../../utils/utils";

const NoteModal = (props) => {
    const { isOpen, onClose, refreshGrid, batchId, initialDescription } = props;

    const toast = useToast();
    const [description, setDescription] = useState(initialDescription);
    const [isLoading, setIsLoading] = useState(false);
    console.log(description);

    const onSubmit = async () => {
        try {
            setIsLoading(true);
            const response = await callApiWithToken("POST", "/api/settlement/updateSettlement", {
                batchId,
                field: "description",
                value: description
            });
            if (response?.message) {
                toast({
                    title: 'Success',
                    description: "Settlement Note updated successfully",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
                setIsLoading(false);
                refreshGrid();
                onClose();
            } else {
                setIsLoading(false);
                throw Error("Failed to update note.")
            }
        } catch (err) {
            setIsLoading(false);
            toast({
                title: 'Error!',
                description: "Failed to update note.",
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right'
            })
        }
    }

    return (
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />

                <ModalContent>
                    <ModalHeader>Update note for {batchId}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>

                        <Stack direction={"column"} justifyContent="space-between" flexGrow={1}>
                            <FormControl
                                isRequired
                            >
                                <FormLabel htmlFor="name">Notes</FormLabel>
                                <Input
                                    id="description"
                                    type="text"
                                    required
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </FormControl>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            isLoading={isLoading}
                            onClick={() => onSubmit()}
                            colorScheme='blue' mr={3}
                            isDisabled={initialDescription === description}
                        >
                            Done
                        </Button>
                        <Button
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>
    )
}

export default NoteModal;