import React, { useState } from 'react';
import Loader from "react-js-loader";
import { Tab, TabList, Tabs, TabPanels, TabPanel } from '@chakra-ui/react';

import OrderDetails from './helper/OrderDetails';
import UserDetails from './helper/UserDetails';
import Comment from './helper/Comment';

export default function UserSpliViewContainer(props) {
    const { userId } = props || {};
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState([]);

    return !loading ? <>
        <Tabs colorScheme='cyan'>
            <TabList p={2} style={{ justifyContent: 'center' }}>
                <Tab>Details</Tab>
                <Tab>Orders</Tab>
                <Tab>Comments</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <UserDetails userId={userId} userDetails={userDetails} setUserDetails={setUserDetails} />
                </TabPanel>
                <TabPanel>
                    <OrderDetails userId={userId} />
                </TabPanel>
                <TabPanel>
                    <Comment userId={userId} />
                </TabPanel>
            </TabPanels>
        </Tabs>
    </> :
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={'#FFFFFF'} size={100} />
}
