import React, { useEffect, useState, useContext } from "react";
import Loader from "react-js-loader";
import moment from "moment";
import { startCase } from "lodash";

import { callApiWithToken } from "../../utils/utils";
import CustomTable from "../../utils/customTable";
import { getCxRoles, getUserEmail } from "../App/useToken";

import { Box, Button, useDisclosure, Badge } from "@chakra-ui/react";
import { AiOutlineUserAdd, AiTwotoneEdit } from "react-icons/ai";
import { EnvTypeContext } from "../../context/EnvTypeContext";
import InviteModal from "./InviteModal";
import EditModal from "./EditModal";
import TimezoneFormatter from "../../utils/TimezoneFormatter";
import { useTranslation } from "react-i18next";

export default function Team() {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedDetails, setSelectedDetails] = useState(null);
    const [disable, setDisable] = useState(false);

    const { env } = useContext(EnvTypeContext);

    const {
        isOpen: isOpenInviteModal,
        onOpen: onOpenInviteModal,
        onClose: onCloseInviteModal,
    } = useDisclosure();
    const {
        isOpen: isOpenEditModal,
        onOpen: onOpenEditModal,
        onClose: onCloseEditModal,
    } = useDisclosure();
    const cxRoles = getCxRoles();
    const userEmail = getUserEmail();

    useEffect(() => {
        getTeamData();
    }, [env]);

    const getTeamData = async () => {
        setLoading(true);
        const response =
            (await callApiWithToken("GET", "/api/customers/list")) || {};
        const { data = [] } = response;
        setData(data);
        setLoading(false);
    };

    const openEditModal = (row) => {
        onOpenEditModal();
        setSelectedDetails(row.values);
    };

    let columns = [
        {
            Header: t("users:created_at"),
            accessor: "createdAt",
            width: "200",
            Cell: ({ value }) => {
                return <TimezoneFormatter date={value} format={"lll"} />;
            },
        },
        {
            Header: t("users:email"),
            accessor: "email",
            width: "200",
        },
        {
            Header: t("users:status"),
            accessor: "status",
            width: "200",
            Cell: ({ value }) =>
                value === "active" ? (
                    <Badge colorScheme={"green"}>{startCase(value)} </Badge>
                ) : (
                    <Badge colorScheme={"gray"}>{startCase(value)}</Badge>
                ),
        },
        {
            Header: t("users:roles"),
            accessor: "appRole",
            width: "200",
            Cell: ({ value }) => {
                const formattedValue = value.join(",");
                return startCase(formattedValue);
            },
        },
    ];

    if (cxRoles.includes("admin") || cxRoles.includes("org_admin")) {
        columns.push({
            Header: t("users:edit"),
            accessor: "id",
            width: "130",
            Cell: ({ row }) => {
                return (
                    <Button
                        bg={"white"}
                        isDisabled={row?.original?.email === userEmail}
                        leftIcon={
                            <AiTwotoneEdit
                                style={{
                                    fontSize: "22px",
                                    color: "#2b6cb0",
                                    cursor: "pointer",
                                }}
                            />
                        }
                        onClick={() => openEditModal(row)}
                    >
                    </Button>
                );
            },
        });
    }

    const openInviteModal = () => {
        onOpenInviteModal();
    };

    return (
        <>
            {!loading ? (
                <Box style={{ background: "white" }} heading="Team">
                    <div style={{ float: "right", marginTop: "2%", marginRight: "5%" }}>
                        <Button
                            onClick={() => openInviteModal()}
                            style={{
                                borderRadius: "10px",
                                background: "#3182ce",
                                color: "white",
                            }}
                            variant="primary"
                            leftIcon={<AiOutlineUserAdd />}
                            isDisabled={(
                                cxRoles.includes("admin") ||
                                cxRoles.includes("org_admin"))
                                ? false
                                : true}
                        >
                            {t("users:invite_members")}
                        </Button>
                    </div>
                    <CustomTable columns={columns} data={data} />
                    {selectedDetails && (
                        <EditModal
                            isLoading={loading}
                            refreshGrid={getTeamData}
                            isOpen={isOpenEditModal}
                            onOpen={onOpenEditModal}
                            onClose={onCloseEditModal}
                            selectedDetails={selectedDetails}
                        />
                    )}
                    <InviteModal
                        isLoading={loading}
                        refreshGrid={getTeamData}
                        isOpen={isOpenInviteModal}
                        onOpen={onOpenInviteModal}
                        onClose={onCloseInviteModal}
                    />
                </Box>
            ) : (
                <Loader
                    type="spinner-cub"
                    bgColor={"#FFFFFF"}
                    title={"Loading..."}
                    color={"cyan.400"}
                    size={100}
                />
            )}
        </>
    );
}
