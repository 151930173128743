import React, { useState, useEffect } from 'react';
import {
    Textarea, Text, Button, Stack, VStack, Card, Box, CardBody,
    StackDivider, HStack, useToast
} from '@chakra-ui/react';
import moment from "moment";

import { callApiWithToken } from '../../../utils/utils';
import TimezoneFormatter from "../../../utils/TimezoneFormatter";

const Comment = (props) => {

    const { orderId } = props || {};

    const [description, setDescription] = useState("");
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(false);

    const toast = useToast();

    useEffect(() => {
        getComments();
    }, []);

    useEffect(() => {
        getComments();
    }, [orderId])

    const getComments = async () => {
        const response = await callApiWithToken("GET", `/api/comments/list?orderId=${orderId}`) || [];
        setComments(response?.data);
    }

    const onClickHandler = async () => {
        try {
            setLoading(true);
            const body = {
                entityId: orderId,
                entityType: "order",
                description: description,
                visibility: 'internal'
            };

            const response = await callApiWithToken("POST", '/api/comments/add', body) || {};

            toast({
                title: "Success",
                description: response.message,
                position: "top-right",
                status: 'success',
                duration: 9000,
                isClosable: true,
            });

            getComments();
            setDescription("");
            setLoading(false);
        }
        catch (err) {
            toast({
                title: "Failure",
                description: err.message,
                position: "top-right",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            setLoading(false);
        }
    }

    let handleInputChange = (e) => {
        let inputValue = e.target.value
        setDescription(inputValue);
    }

    const allComments = () => {
        return (<>
            {
                comments.length > 0 && <Card>
                    <CardBody>
                        <Stack divider={<StackDivider />} spacing='4' height='30vh' overflow='scroll'>
                            {comments.map((ele) =>
                                <Box>
                                    <Text pt='2' fontSize='sm'>
                                        {ele.description}
                                    </Text>
                                    <HStack justifyContent={"space-between"}>
                                        <Text fontSize="x-small" fontStyle="italic" align={"end"}>
                                            {ele.createdBy}
                                        </Text>
                                        <Text fontSize='x-small' fontStyle="italic" align={"end"}>
                                            <TimezoneFormatter date={ele.createdAt} format={"LLL"} />
                                        </Text>
                                    </HStack>
                                </Box>
                            )}
                        </Stack>
                    </CardBody>
                </Card >
            }
        </>
        )
    }

    return (
        <Stack>
            <VStack align="start" >
                <Textarea
                    value={description}
                    onChange={handleInputChange}
                    placeholder='Please add your comment.'
                    size='sm'
                />
            </VStack>
            <VStack align="end" >
                <Button
                    colorScheme='blue'
                    onClick={onClickHandler}
                >
                    Submit
                </Button>
            </VStack>
            {allComments()}
        </Stack>
    )
}

export default Comment;