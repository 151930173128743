import { Badge, Stack, Text, Box, HStack, Button } from "@chakra-ui/react";
import { useState, useContext, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { BiReset } from 'react-icons/bi'
import { startCase, round } from 'lodash';

import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

import Loader from '../../utils/Loader';
import { constants } from "../../constants";
import Grid from '../../utils/agTable';
import { NavBarContext } from '../../context/NavBarContext';
import { callApiWithTokenV2 } from '../../utils/utils';
import TimezoneFormatter from "../../utils/TimezoneFormatter";
import EditModal from "../../utils/EditModal";


const PrefundOrders = (props) => {
  const { prefundingStatusMap, colorMap } = constants;
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { selectedOrg } = useContext(NavBarContext);

  useEffect(() => {
    setRefreshGrid(!refreshGrid);
  }, [selectedOrg]);

  const USformatter = new Intl.NumberFormat('en-US');

  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var cellDate = new Date(cellValue);
      if (filterLocalDateAtMidnight.toLocaleDateString() === cellDate.toLocaleDateString()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2000,
  };

  const handleReset = () => {
    setRefreshGrid(!refreshGrid);
  };

  const getOrderData = async (filters) => {
    filters["type"] = { $in: ['fiat_to_fiat_prefund', 'crypto_to_crypto_prefund', 'fiat_to_crypto_prefund'] };
    const responseData = await callApiWithTokenV2.post(`/api/orders/tfpay/list?`, { impersonatedOrg: selectedOrg }, {
      params: {
        filters
      }
    })
    return responseData;
  }

  const columns = [
    {
      headerName: "Order ID TransFi",
      field: "orderId",
      getQuickFilterText: params => {
        return params.value.name;
      },
      customerVisibility: true,
      width: '250',
      cellRenderer: ({ value }) => {
        return <Link style={{ color: "#1A4FD6", fontWeight: "bold", fontSize: '14px' }} >{value}</Link>
      },
    },
    {
      headerName: "Customer Name", field: "customer.formattedName", enableRowGroup: true, customerVisibility: true, width: '200', cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'N/A'}</Text>
      )
    },
    { headerName: "User ID/Email", field: "email", enableRowGroup: true, customerVisibility: true, width: '300' },
    {
      headerName: "Status",
      field: "status",
      customerVisibility: true,
      enableRowGroup: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      editable: ({ data }) => data.status !== 'completed', 
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['initiated', 'fund_processing', 'fund_settled', 'failed', 'asset_deposited', 'completed'],
      },
      cellRenderer: ({ value }) => {
        const newStatus = prefundingStatusMap[value] || value;
        const color = colorMap[value];
        return <Badge width={"100%"} textAlign={"center"} colorScheme={color}>{newStatus}</Badge>;
      },
    },
    {
      headerName: "Initiated Date",
      field: "timestamps.initiatedAt",
      filterParams: filterParams,
      customerVisibility: true,
      enableRowGroup: false,
      cellDataType: 'date',
      width: '220',
      filter: 'agDateColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{value ? <TimezoneFormatter date={value} format={"DD-MMM-YYYY @ h:mm A"} /> : "N/A"}</Text>
      ),
    },
    {
      headerName: "Completed Date",
      field: "timestamps.fundSettledAt",
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      customerVisibility: true,
      enableRowGroup: false,
      cellDataType: 'date',
      width: '220',
      cellRenderer: ({ value, data }) => {return(
        <>
        {data?.status !== 'completed'?
        <EditModal value={value} columnName="Completed Date" data={data} field={'timestamps.fundSettledAt'} type={'prefunds'} handleReset={handleReset}/>
        : <Text>{value ? <TimezoneFormatter date={value} format={"DD-MMM-YYYY @ h:mm A"} /> : "N/A"}</Text>
        }</>
        )},   
    },
    {
      headerName: "Settlement Time",
      field: "timestamps.settlementSettledAt",
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      customerVisibility: true,
      enableRowGroup: false,
      cellDataType: 'date',
      width: '220',
      cellRenderer: ({ value, data }) => {return(
        <>
        {data?.status !== 'completed'?
        <EditModal value={value} columnName="Settlement Time" data={data} field={'timestamps.settlementSettledAt'} type={'prefunds'} handleReset={handleReset}/>
        : <Text>{value ? <TimezoneFormatter date={value} format={"DD-MMM-YYYY @ h:mm A"} /> : "N/A"}</Text>
        }</>
        )},
    },
    {
      headerName: "Transfer Currency", field: "depositCurrency", customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Partner Name", field: "paymentFormattedName", customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Date of Receipt",
      field: "timestamps.assetSettledAt",
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      customerVisibility: true,
      enableRowGroup: false,
      cellDataType: 'date',
      width: '220',
      cellRenderer: ({ value, data }) => {return(
        <>
        {data?.status !== 'completed'?
        <EditModal value={value} columnName="Date of Receipt" data={data} field={'timestamps.assetSettledAt'} type={'prefunds'} handleReset={handleReset}/>
        : <Text>{value ? <TimezoneFormatter date={value} format={"DD-MMM-YYYY @ h:mm A"} /> : "N/A"}</Text>
        }</>
        )},
    },
    {
      headerName: "ID", field: "partnerOrderId", editable: ({ data }) => data.status !== 'completed',  cellEditor: 'agTextCellEditor', customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Amount in Transfer Currency", field: "depositAmount", aggFunc: 'sum', cellDataType: 'number', filter: 'agNumberColumnFilter', customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Processing fees %", field: "baseFeeType.fee", filter: 'agNumberColumnFilter', cellDataType: 'number', customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? (value * 100) + '%' : '0'}</Text>
      )
    },
    {
      headerName: "Fixed/Hybrid Fee Value", field: "baseFeeType.fixedFee", filter: 'agNumberColumnFilter', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? value : '0'}</Text>
      )
    },
    {
      headerName: "Processing fees Amount", field: "processingFeeAmount", filter: 'agNumberColumnFilter', cellDataType: 'number', customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Net Amount", field: "netAmount", filter: 'agNumberColumnFilter', cellDataType: 'number', customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Prefunding Currency", field: "withdrawCurrency", customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Conversion Rate to Prefunding Currency", field: "conversionRateUsed", filter: 'agNumberColumnFilter', customerVisibility: true, cellDataType: 'number', cellRenderer: ({ value }) => {
        return <Text>{value ? round(value, 8) : '0'}</Text>
      }
    },
    {
      headerName: "FX Markup %", field: "fxMarkupType.fee", cellDataType: 'number', customerVisibility: false, filter: 'agNumberColumnFilter', cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(Math.abs(value) * 100) + "%" : '0'}</Text>
      )
    },
    {
      headerName: "Conversion rate to prefunding currency TransFi rate", field: "conversionRateToPrefundingCurrency", filter: 'agNumberColumnFilter', cellDataType: 'number', customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? round(value, 6) : '0'}</Text>
      )
    },
    {
      headerName: "Net Amount in Prefunding Currency", field: "netAmountInPrefundingCurrency", filter: 'agNumberColumnFilter', cellDataType: 'number', customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Conversion Rate to USD", field: "conversionRateToUsd", filter: 'agNumberColumnFilter', customerVisibility: true, cellDataType: 'number', cellRenderer: ({ value }) => {
        return <Text>{value ? 1/round(value, 8) : '0'}</Text>
      }
    },
    {
      headerName: "Amount in USD", field: "amountInUsd", filter: 'agNumberColumnFilter', aggFunc: 'sum', customerVisibility: true, cellDataType: 'number', cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Processing Fees in USD", field: "processingFeesAmountInUsd", filter: 'agNumberColumnFilter', aggFunc: 'sum', customerVisibility: true, cellDataType: 'number', cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Net Amount in USD", field: "netAmountInUsd", filter: 'agNumberColumnFilter', cellDataType: 'number', customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Forex Gain", field: "transfiForexGainUsd", filter: 'agNumberColumnFilter', aggFunc: 'sum', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Type", field: "transactionType", customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? value : 'N/A'}</Text>
      )
    }
  ];

  return (
    <Stack
      direction={"column"}
      justifyContent={"space-between"}
      flexGrow={1}
      p={5}
    >

      {!isLoading ? <Box >
        <HStack pb={5} justifyContent={"flex-end"}>
          <Button
            leftIcon={<BiReset style={{ fontSize: "20px" }} />}
            color={"#525C76"}
            variant={"outline"}
            borderColor={"#d2d2d2"}
            _hover={{ bg: "#ffffff" }}
            onClick={handleReset}
          >
            Reset
          </Button>
        </HStack>
        <Grid columns={columns} key={refreshGrid.toString()} getOrderData={getOrderData} />
      </Box> : <Loader />}
    </Stack>
  );
};

export default PrefundOrders;
