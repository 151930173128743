import React, { useEffect, useState, createContext } from "react";
export const NavBarContext = createContext();
import { getOrgId, getOrgRoles } from "../components/App/useToken";

export function NavBarContextProvider({ children }) {
    const orgId = getOrgId();
    const roles = getOrgRoles();
    const [kybStatusValue, setKybStatusValue] = useState("");
    const [selectedOrg, setSelectedOrg] = useState(roles.includes("transfi_admin") ? "all" : orgId);

    const onOrgChange = (val) => {
        setSelectedOrg(val);
    }

    const navbarStore = { kybStatusValue, setKybStatusValue, selectedOrg, onOrgChange };

    return (
        <NavBarContext.Provider value={navbarStore}>{children}</NavBarContext.Provider>
    );
}
