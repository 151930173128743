import React, { useState, useEffect } from 'react';
import Loader from "react-js-loader";
import { Link } from "react-router-dom";
import { isEmpty } from 'lodash';
import { Badge } from '@chakra-ui/react';
import { callApiWithToken } from '../../../utils/utils';
import CustomTable from '../../../utils/customTable';
import { statusMap, colorMap } from "../../../utils/config";
import TimezoneFormatter from "../../../utils/TimezoneFormatter";

export default function OrderDetails(props) {
    const { userId } = props || {};
    const [loading, setLoading] = useState(false);
    const [orderData, setOrderData] = useState([]);

    useEffect(() => {
        getOrdersData();
    }, [userId]);

    const getOrdersData = async () => {
        setLoading(true);
        const response = await callApiWithToken("GET", `/api/orders/listOrdersForUser?userId=${userId}`) || {};
        const { data = [] } = response;
        setOrderData(data);
        setLoading(false);
    }

    const columns = [
        {
            Header: "Created At",
            accessor: "createdAt",
            width: '200',
            Cell: ({ value }) => { return <TimezoneFormatter date={value} format={'lll'} /> }
        },
        {
            Header: "Order Id",
            accessor: "orderId",
            width: '200',
            Cell: ({ row }) => {
                const { orderId, type } = row?.original;
                const path = type === 'buy' ? `/buy-orders/${orderId}` : `/sell-orders/${orderId}`;
                return <Link style={{ color: '#4ACDED', fontWeight: 'bold' }} to={path} >{orderId}</Link>
            }
        },
        {
            Header: "Status",
            accessor: "status",
            width: '100',
            Cell: ({ row }) => {
                const { status, type } = row?.original;
                const newStatus = statusMap[type][status] || status;
                const color = colorMap[type][status] || "blue";
                return <Badge colorScheme={color}> {newStatus} </Badge>;
            }
        },
        {
            Header: "Fiat",
            accessor: "fiatAmount",
            Cell: ({ row }) => { return row?.original?.metaData?.fiatName + ' ' + row?.original?.fiatAmount }
        },
        {
            Header: "Crypto",
            accessor: "cryptoAmount",
            width: '250',
            Cell: ({ row }) => { return row?.original?.cryptoAmount + ' ' + row?.original?.cryptoTicker }
        },
        {
            Header: " Payment Method",
            accessor: "paymentType",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.paymentName)) {
                    return row?.original?.fiat?.paymentName;
                } else {
                    return row?.original?.paymentName;
                }
            }
        },
        {
            Header: "Wallet Address",
            accessor: "walletAddress"
        },
        {
            Header: "Transaction Hash",
            accessor: "txnHash",
            width: '200'
        },
    ];

    return !loading ? <>
        <CustomTable columns={columns} data={orderData} />
    </> :
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={'#FFFFFF'} size={100} />
}
