/* eslint-disable no-unused-expressions */
import React from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  chakra,
  Stack,
} from '@chakra-ui/react';

const ViewModal = (props) => {
  const { isOpen, onClose, selectedRow } = props;

  return (
    <chakra.form onSubmit={onClose} display="flex" flexGrow={1}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Bank Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>

            <Stack direction={"column"} justifyContent="space-between" flexGrow={1}>
              <FormControl>
                <FormLabel>Bank Name</FormLabel>
                <div style={{
                  border: '0px solid',
                  borderRadius: '4px',
                  backgroundColor: '#ebebeb',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '15px',
                  color: 'rgba(0,0,0,0.8)'
                }}
                >
                  {selectedRow?.bank?.bankName}
                </div>
              </FormControl>
              <FormControl>
                <FormLabel>Country</FormLabel>
                <div style={{
                  border: '0px solid',
                  borderRadius: '4px',
                  backgroundColor: '#ebebeb',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '15px',
                  color: 'rgba(0,0,0,0.8)'
                }}
                >
                  {selectedRow?.bank?.country}
                </div>
              </FormControl>
              <FormControl>
                <FormLabel>Acount Number</FormLabel>
                <div style={{
                  border: '0px solid',
                  borderRadius: '4px',
                  backgroundColor: '#ebebeb',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '15px',
                  color: 'rgba(0,0,0,0.8)'
                }}
                >
                  {selectedRow?.bank?.accountNumber}
                </div>
              </FormControl>
              <FormControl>
                <FormLabel>Beneficiary Name</FormLabel>
                <div style={{
                  border: '0px solid',
                  borderRadius: '4px',
                  backgroundColor: '#ebebeb',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '15px',
                  color: 'rgba(0,0,0,0.8)'
                }}
                >
                  {selectedRow?.bank?.beneficiaryName}
                </div>
              </FormControl>
              <FormControl>
                <FormLabel>Swift Code</FormLabel>
                <div style={{
                  border: '0px solid',
                  borderRadius: '4px',
                  backgroundColor: '#ebebeb',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '15px',
                  color: 'rgba(0,0,0,0.8)'
                }}
                >
                  {selectedRow?.bank?.swiftCode}
                </div>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>

        </ModalContent>
      </Modal>
    </chakra.form>
  )
}

export default ViewModal;