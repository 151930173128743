import React, { useRef, useState } from 'react';

import { callApiWithToken } from '../../../utils/utils';
import "../Login.css";
import { Spinner } from '@chakra-ui/react';
import { saveDataToSession } from '../../../utils/session-storing-utils';

const OTPInputBoxes = ({ setOtp }) => {
    const [inputs, setInputs] = useState(Array(5).fill(""));

    const refs = useRef(Array(5).fill(React.createRef()));

    const onInputChange = (e, index) => {
        const value = e.target.value.slice(-1);
        let newInputs = [...inputs];
        newInputs[index] = value;
        setInputs(newInputs);
        setOtp(newInputs.join(""));
        if (value !== "" && index < 5) {
            const nextInput = document.querySelector(`#pa-otp-inputs-${index + 1}`);
            nextInput.focus();
        }
    }

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace') {
            if (inputs[index] == "" && index > 0) {
                const prevInput = document.querySelector(`#pa-otp-inputs-${index - 1}`);
                prevInput.focus();
            }
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const clipboardData = e.clipboardData.getData('text');
        const newOtp = [...inputs];

        for (let i = 0; i < clipboardData.length && i < 6; i++) {
            if (/^\d*$/.test(clipboardData[i])) {
                newOtp[i] = clipboardData[i];
            }
        }
        setInputs(newOtp);
        setOtp(newOtp.join(""));
    }

    return <div style={{ display: 'flex', marginTop: '3rem', justifyContent: 'center', alignItems: 'center' }}>
        <input
            id={`pa-otp-inputs-0`}
            onKeyDown={(e) => handleKeyDown(e, 0)}
            ref={refs.current[0]}
            type="number"
            className="pa-otp-inputs"
            maxlength="1"
            value={inputs[0]}
            onChange={(e) => { onInputChange(e, 0) }}
            onPaste={(e) => handlePaste(e)}
        />
        <input
            id={`pa-otp-inputs-1`}
            onKeyDown={(e) => handleKeyDown(e, 1)}
            ref={refs.current[1]}
            type="number"
            className="pa-otp-inputs"
            maxlength="1"
            value={inputs[1]}
            onChange={(e) => { onInputChange(e, 1) }}
            style={{ marginLeft: '16px' }}
        />
        <input
            id={`pa-otp-inputs-2`}
            onKeyDown={(e) => handleKeyDown(e, 2)}
            ref={refs.current[2]}
            type="number"
            className="pa-otp-inputs"
            maxlength="1"
            value={inputs[2]}
            onChange={(e) => { onInputChange(e, 2) }}
            style={{ marginLeft: '16px' }}
        />
        <input
            id={`pa-otp-inputs-3`}
            onKeyDown={(e) => handleKeyDown(e, 3)}
            ref={refs.current[3]}
            type="number"
            className="pa-otp-inputs"
            maxlength="1"
            value={inputs[3]}
            onChange={(e) => { onInputChange(e, 3) }}
            style={{ marginLeft: '16px' }}
        />
        <input
            id={`pa-otp-inputs-4`}
            onKeyDown={(e) => handleKeyDown(e, 4)}
            ref={refs.current[4]}
            type="number"
            className="pa-otp-inputs"
            maxlength="1"
            value={inputs[4]}
            onChange={(e) => { onInputChange(e, 4) }}
            style={{ marginLeft: '16px' }}
        />
        <input
            id={`pa-otp-inputs-5`}
            onKeyDown={(e) => handleKeyDown(e, 5)}
            ref={refs.current[5]}
            type="number"
            className="pa-otp-inputs"
            maxlength="1"
            value={inputs[5]}
            onChange={(e) => { onInputChange(e, 5) }}
            style={{ marginLeft: '16px' }}
        />
    </div>
};

export default OTPInputBoxes;
