import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Center, HStack, Stack, Text } from '@chakra-ui/react';
import { BiReset } from 'react-icons/bi';
import Grid from '../../utils/agTable';
import Loader from '../../utils/Loader';
import { NavBarContext } from '../../context/NavBarContext';
import { callApiWithTokenV2 } from '../../utils/utils';

const CollectionsBalance = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [refreshGrid, setRefreshGrid] = useState(false);

    const { selectedOrg } = useContext(NavBarContext);

    const shouldRenderData = selectedOrg.length === 1 && selectedOrg[0] !== 'All';

    useEffect(() => {
        setRefreshGrid(!refreshGrid);
    }, [selectedOrg])

    const USformatter = new Intl.NumberFormat('en-US');

    const getBalanceData = async (filters) => {
        const responseData = await callApiWithTokenV2.post(`/api/balance/collections`, { impersonatedOrg: selectedOrg }, {
            params: {
                filters
            }
        })
        return responseData;
    }
    const handleReset = () => {
        setRefreshGrid(!refreshGrid);
    };

    var filterParams = {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            if (cellValue === null) return -1;
            let cellDate = new Date(cellValue);
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
        browserDatePicker: true
    }


    const columnsAgTable = [
        {
            headerName: "Fiat Currency",
            field: "currency",
            getQuickFilterText: params => {
                return params.value.name;
            },
            customerVisibility: true,
            width: '250',
        },
        {
            headerName: "Prefund amount",
            field: "totalPrefundAmount",
            cellDataType: 'number',
            getQuickFilterText: params => {
                return params.value.name;
            },
            aggFunc: 'sum',
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Collections net amount",
            field: "totalCollectionsAmount",
            cellDataType: 'number',
            getQuickFilterText: params => {
                return params.value.name;
            },
            aggFunc: 'sum',
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Payouts net amount",
            field: "totalPayoutAmount",
            cellDataType: 'number',
            getQuickFilterText: params => {
                return params.value.name;
            },
            aggFunc: 'sum',
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Total in-transit payout amount",
            field: "inTransitAmount",
            cellDataType: 'number',
            getQuickFilterText: params => {
                return params.value.name;
            },
            aggFunc: 'sum',
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Total in-transit payout fees",
            field: "inTransitFees",
            cellDataType: 'number',
            getQuickFilterText: params => {
                return params.value.name;
            },
            aggFunc: 'sum',
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Total settled amount",
            field: "totalSettledAmount",
            cellDataType: 'number',
            getQuickFilterText: params => {
                return params.value.name;
            },
            aggFunc: 'sum',
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Total unsettled amount",
            field: "totalUnsettledAmount",
            cellDataType: 'number',
            aggFunc: 'sum',
            getQuickFilterText: params => {
                return params.value.name;
            },
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        }
    ];

    return (
        <Stack
            direction={"column"}
            justifyContent={"space-between"}
            flexGrow={1}
            p={5}
        >
            <Box>
                <HStack pb={5} justifyContent={"flex-end"}>
                    <Button
                        leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                        color={"#525C76"}
                        variant={"outline"}
                        borderColor={"#d2d2d2"}
                        _hover={{ bg: "#ffffff" }}
                        onClick={handleReset}
                    >
                        Reset
                    </Button>

                </HStack>
                {shouldRenderData ?
                    <>
                        {!isLoading ?
                            <Grid columns={columnsAgTable} key={refreshGrid.toString()} getOrderData={getBalanceData} />

                            : <Loader />}
                    </> :
                    <Center>
                        <Text>Please select only one organization to see balance details</Text>
                    </Center>
                }
            </Box>
        </Stack>
    );
};

export default CollectionsBalance;