import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Text,
  Button,
  Input,
  useClipboard,
  Flex,
  HStack,
  Link,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
} from "@chakra-ui/react";
import Loader from "react-js-loader";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { callApiWithToken } from "../../utils/utils";
import { getKybStatus } from "../../apiService/kybForm-service";
import { NavBarContext } from "../../context/NavBarContext";
import { EnvTypeContext } from "./../../context/EnvTypeContext";
import { useTranslation } from "react-i18next";


export default function Developers() {
  const { t } = useTranslation();
  const [apiKey, setApiKey] = useState("");
  const [merchantId, setMerchantId] = useState("");
  const [merchantSecret, setMerchantSecret] = useState("");
  const [orgId, setOrgId] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { kybStatusValue, setKybStatusValue } = useContext(NavBarContext);
  const { selectedOrg } = useContext(NavBarContext);
  const { env } = useContext(EnvTypeContext);
  const grpOrgId = sessionStorage.getItem('grpOrgId');
  const isOceanGaming = (orgId === "OG-220923101501950" && grpOrgId === "OG-220923101501950");

  useEffect(() => {
    getApiSecrets();
    getKybStatusData();
    getOrgDetails();
  }, []);

  const getKybStatusData = async () => {
    const { kybStatus = "" } = await getKybStatus();
    setKybStatusValue(kybStatus);
  };

  const getOrgDetails = async () => {
    setLoading(true);
    const response =
      (await callApiWithToken("POST", "/api/organization/details", {
        impersonatedOrg: selectedOrg,
      })) || {};
    const { orgResponse = {}, webhookResponse = {} } = response;
    const { orgId = "" } = orgResponse;
    setOrgId(orgId);
    setMerchantId(orgResponse?.formattedName);
    setMerchantSecret(orgResponse?.merchantKey);
    setLoading(false);
  };

  const getApiSecrets = async () => {
    const response =
      (await callApiWithToken("GET", "/api/organization/secrets")) || {};
    const apiKey = response?.apiKey;
    setApiKey(apiKey);
  };

  return (
    loading ? <Loader
      type="spinner-cub"
      bgColor={"#FFFFFF"}
      title={"Loading..."}
      color={"cyan.400"}
      size={100}
    /> :
      <Box p={5}>
        <Flex direction="column" gap={5} width={"100%"}>
          <Box width={"100%"}>
            {!isOceanGaming && (
              <Text fontSize={"18px"}>
                To integrate with TransFi,{" "}
                <Link href="https://docs.transfi.com" color="#1A4FD6" target="_blank">
                  read the documentation
                </Link>
              </Text>
            )}
          </Box>
          {(kybStatusValue == "pending" && !isOceanGaming) &&
            (
              <Box width={"100%"} p="10" bg="#FFF4D7" borderRadius={"16px"}>
                <VStack gap={5} alignItems={"left"}>
                  <Heading as="h5" size="md">
                    Start your KYB verification{" "}
                  </Heading>
                  <Text fontSize={"18px"}>
                    Before going live in production you must provide your company
                    information and sign the agreement to get KYB process
                    implemented
                  </Text>
                  <Box textAlign={"center"}>
                    <Button
                      onClick={() => navigate("/developers/kyb-form")}
                      colorScheme="blue"
                      bg={"#1A4FD6"}
                      _hover={{ bg: "#232D95" }}
                      size="lg"
                      pl={10}
                      pr={10}
                    >
                      Proceed to your KYB form
                    </Button>
                  </Box>
                </VStack>
              </Box>
            )
          }
          {kybStatusValue == "completed" && (
            <Box width={"100%"} p="5" bg="#DAFFD7" borderRadius={"16px"}>
              <Text>{t("settings:go_live")}</Text>
            </Box>
          )}
          <Box width={"60%"}>
            <FormControl mt={4}>
              <FormLabel>{t("sub-accounts:api_key")}</FormLabel>
              <Input type="text" value={apiKey} readOnly />
            </FormControl>
            <HStack mt={"4"} gap={5}>
              <FormControl>
                <HStack spacing="5px" paddingBottom={"2"}>
                  <FormLabel>{t("settings:merchant_id")}</FormLabel>
                  {!isOceanGaming && (
                    <Popover placement="right">
                      <PopoverTrigger>
                        <InfoOutlineIcon
                          style={{ marginBottom: "6px", fontSize: "18px" }}
                        />
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody>
                          "It's your unique user name to create the authentication
                          token for using TransFi API."
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  )}
                </HStack>
                <Input type="text" value={merchantId} readOnly />
              </FormControl>

              <FormControl>
                <HStack paddingBottom={"2"}>
                  <FormLabel>{t("settings:merchant_secret")}</FormLabel>
                  {!isOceanGaming && (
                    <Popover placement="right">
                      <PopoverTrigger>
                        <InfoOutlineIcon
                          style={{ marginBottom: "6px", fontSize: "18px" }}
                        />
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody>
                          "It's your unique secret to create the authentication
                          token for using TransFi API."
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  )}
                </HStack>
                <Input type="text" value={merchantSecret} readOnly />
              </FormControl>
            </HStack>
          </Box>
        </Flex>
      </Box>
  );
}
