import {
  FormControl,
  FormLabel,
  Button,
  Divider,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  SimpleGrid,
  Stack,
  RadioGroup,
  Radio,
  Input,
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { concat, pickBy } from "lodash";
import { useState, useEffect } from "react";
import Select from "react-select";
import { callApiWithToken } from "../../utils/utils";
import { WarningIcon } from "@chakra-ui/icons";
import { product_type, user_journey } from "./docs";
import { CgInfo } from "react-icons/cg";
import { constants } from "../../constants";


const WidgetFilter = (props) => {
  const { isOpen, onClose, onSubmit, newCountry } = props;
  const [journey, setJourney] = useState();
  const [product, setProduct] = useState();
  const [country, setCountry] = useState();
  const [countryName, setCountryName] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [currency, setCurrency] = useState('');
  const [crypto, setCrypto] = useState('');
  const [wallet, setWallet] = useState();
  const [infoMessage, setInfoMessage] = useState();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [networkList, setNetworkList] = useState();
  const [countryList, setCountryList] = useState();
  const [currencyList, setCurrencyList] = useState();
  const [fiatValue, setFiatValue] = useState();
  const [language, setLanguage] = useState();

  const { LANGUAGE_OPTIONS } = constants;

  const data = {
    // journey,
    // product,
    // country,
    // countryName,
    // paymentMethod,
    currency,
    crypto,
    wallet,
    infoMessage,
    fiatValue,
    language,
  };
  const cleanedObj = pickBy(data, (v) => v !== undefined || "");

  useEffect(() => {
    getDataList();
    getCurrencyList(newCountry);
  }, [newCountry]);

  const getListPayments = async (search) => {
    const resp = await callApiWithToken(
      "GET",
      `/api/widget/paymentMethods?currency=${search.value}`
    );
    const { data = [] } = resp;
    let paymentOptions = data.map((pm) => {
      return { value: pm?.referenceName, label: pm?.name };
    });
    setPaymentMethods(paymentOptions);
  };

  const getCurrencyList = async (search) => {
    const response = await callApiWithToken(
      "GET",
      `/api/widget/configList?name=fiat&module=buy_crypto`
    );
    const coList = response.data[0].config;
    const list = coList.filter((list) => list?.symbol === search?.label);
    const countryList = list.map((ls) => {
      return { value: ls.description, label: ls.description };
    });
    setCurrencyList(countryList);
    setCurrency('');
    setLanguage('');
    setFiatValue('');
    setWallet('');
    setInfoMessage('');
  };

  const getDataList = async () => {
    const cryptoResponse = await callApiWithToken(
      "GET",
      "/api/widget/configList?name=crypto&module=buy_crypto"
    );
    const cryptoList = cryptoResponse.data[0].config;
    let cList = cryptoList.map((resp, idx) => {
      return {
        value: idx,
        label:
          resp.symbol +
          " - " +
          (resp.network === undefined ? resp.description : resp.network),
        ticker: resp.symbol,
        network: resp.network || "",
      };
    });
    setNetworkList(cList);
    setCrypto('');


    const countryResponse = await callApiWithToken(
      "GET",
      `/api/widget/configList?name=fiat&module=buy_crypto`
    );
    const coList = countryResponse.data[0].config;

    // const unique = [...new Set(coList.map(item => item.description))]
    // const counList = unique.map(ls => { return { value: ls, label: ls } })
    // setCurrencyList(counList)

    const asianCountries = coList.filter((ac) => ac.group === "trending");
    const otherCountries = coList.filter((ac) => ac.group === undefined);
    let asianlist = asianCountries.map((cl) => {
      return { value: cl.description, label: cl.symbol };
    });
    let otherlist = otherCountries.map((cl) => {
      return { value: cl.description, label: cl.symbol };
    });
    const list = [
      {
        label: "Trending",
        options: asianlist,
      },
      {
        label: "All Countries",
        options: otherlist,
      },
    ];
    setCountryList(list);
  };

  const onFilterSubmitHandler = () => {
    onClose();
    onSubmit(cleanedObj);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent mt={5} mb={5}>
        <ModalHeader>More Customization</ModalHeader>
        <ModalCloseButton top={4} />
        <Divider />
        <ModalBody>
          <SimpleGrid pt={5} pb={5} columns={2} gap={5}>
            {/* <FormControl
              border="1px"
              borderColor="#E7E7E7"
              borderRadius="12px"
              p="3"
            >
              <FormLabel>User Journey:</FormLabel>
              <Select
                options={user_journey}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                  }),
                }}
                value={journey}
                onChange={value => setJourney(value)}
              />
            </FormControl> */}
            {/* <FormControl
              p="3"
              border="1px"
              borderColor="#E7E7E7"
              borderRadius="12px"
            >
              <FormLabel>Product:</FormLabel>
              
                <Select
                  options={product_type}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                    }),
                  }}
                  value={product}
                  onChange={value => setProduct(value)}
                />
              
            </FormControl> */}
            {/* <FormControl
              p={3}
              border="1px"
              borderColor="#E7E7E7"
              borderRadius="12px"
            >
              <FormLabel>Country:</FormLabel>
              <Select
                options={countryList}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                  }),
                }}
                value={country}
                onChange={(value) => {
                  setCountry(value),
                    getListPayments(value),
                    getCurrencyList(value),
                    setPaymentMethod("");
                }}
              />
            </FormControl>
            <FormControl
              p={3}
              border="1px"
              borderColor="#E7E7E7"
              borderRadius="12px"
            >
              <FormLabel>Payment Methods: </FormLabel>
              <Select
                options={paymentMethods}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#E7E7E7E",
                  }),
                }}
                value={paymentMethod}
                onChange={(value) => {
                  setPaymentMethod(value), setPaymentLabel(e.label);
                }}
                isMulti
              />
            </FormControl> */}
            <FormControl
              p="3"
              border="1px"
              borderColor="#E7E7E7"
              borderRadius="12px"
            >
              <FormLabel>Crypto:</FormLabel>
              <Select
                options={networkList}
                // defaultValue={{ value: crypto, label: cryptoLabel }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#E7E7E7E",
                  }),
                }}
                value={crypto || ""}
                onChange={(value) => setCrypto(value)}
              />
            </FormControl>
            <FormControl
              p="3"
              border="1px"
              borderColor="#E7E7E7"
              borderRadius="12px"
            >
              <FormLabel>Currency:</FormLabel>
              <Select
                options={currencyList}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#E7E7E7E",
                  }),
                }}
                value={currency || ""}
                onChange={(value) => {
                  setCurrency(value), getListPayments(value);
                }}
              />
            </FormControl>

            <FormControl
              p="3"
              border="1px"
              borderColor="#E7E7E7"
              borderRadius="12px"
            >
              <FormLabel>Fiat Amount:</FormLabel>

              <Input
                type="number"
                value={fiatValue}
                onChange={(e) => setFiatValue(parseInt(e.target.value))}
              />
            </FormControl>

            <FormControl
              p="3"
              border="1px"
              borderColor="#E7E7E7"
              borderRadius="12px"
            >
              <FormLabel>Wallet Address: </FormLabel>
              <Input
                type="text"
                name="walletAddress"
                value={wallet || ""}
                onChange={(e) => setWallet(e.target.value)}
              />
            </FormControl>

            <FormControl
              p="3"
              border="1px"
              borderColor="#E7E7E7"
              borderRadius="12px"
            >
              <FormLabel>Language:</FormLabel>
              <Select
                options={LANGUAGE_OPTIONS}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#E7E7E7E",
                  }),
                }}
                value={language || ""}
                onChange={(value) => {
                  setLanguage(value)
                }}
              />
            </FormControl>


            <FormControl
              p="3"
              border="1px"
              borderColor="#E7E7E7"
              borderRadius="12px"
            >
              <FormLabel display={"inline-flex"} alignItems="center">
                Partner Context:{" "}
                <Tooltip
                  hasArrow
                  label="Partner context which will be returned in order webhooks."
                  bg="gray.100"
                  color="black"
                >
                  <WarningIcon ml={"10px"} width="15px" color='orange' />
                </Tooltip>
              </FormLabel>

              <Input
                type="text"
                name="message"
                value={infoMessage || ""}
                onChange={(e) => setInfoMessage(e.target.value)}
              />
            </FormControl>
          </SimpleGrid>
        </ModalBody>
        <Divider />
        <ModalFooter>
          <Button colorScheme="blue" mr="3" onClick={onFilterSubmitHandler}>
            Confirm
          </Button>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WidgetFilter;
