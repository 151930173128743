import React, { useState, useEffect, useContext } from "react";
import { notify } from "react-notify-toast";
import { Box, Center, Text, Stack, Button, FormControl, FormLabel, Input, HStack, useToast } from "@chakra-ui/react";
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { ModalCloseButton, Container, Divider, useDisclosure, useClipboard, Flex, Checkbox } from "@chakra-ui/react";
import { SketchPicker } from "react-color";
import { BiCopy } from "react-icons/bi";

import { getUserName } from "../App/useToken";
import { callApiWithToken } from "../../utils/utils";
import { NavBarContext } from "../../context/NavBarContext";
import { EnvTypeContext } from "../../context/EnvTypeContext";
import { TimezoneContext } from "../../context/TimezoneContext";
import Loader from "../../utils/Loader";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import { useTranslation } from "react-i18next";

import "../../Styles.css";

export default function Settings() {
    const { t } = useTranslation();
    const [language, setLanguage] = useState("");
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [secondaryColorPicker, setSecondaryColorPicker] = useState(false);
    const [webhookData, setWebhookData] = useState({});
    /* TransFi logo color default */
    const [colorCode, setColorCode] = useState("");
    const [secondaryColor, setSecondaryColor] = useState("");
    // const [walletAddress, setWalletAddress] = useState("");
    const [loading, setLoading] = useState(false);
    const [webhookUrl, setWebhookUrl] = useState("");
    const [redirectUrl, setRedirectUrl] = useState("");
    const [orgName, setOrgName] = useState("");
    const [isChecked, setIsChecked] = useState(true);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const toast = useToast();
    const { selectedOrg } = useContext(NavBarContext);
    const { env } = useContext(EnvTypeContext);
    const { defaultTimezone, setDefaultTimezone } = useContext(TimezoneContext);
    const [timezone, setTimezone] = useState(defaultTimezone);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isTimezoneOpen, onOpen: onTimezoneOpen, onClose: onTimezoneClose } = useDisclosure();
    const { onCopy, value, setValue, hasCopied } = useClipboard(webhookData.secret);
    const cxRole = window.sessionStorage.getItem("cxRoles");

    useEffect(() => {
        getCustomerData();
        getDefaultBrowserLanguage();
    }, [env]);

    const updateSettings = async () => {
        const payload = {
            impersonatedOrg: selectedOrg,
            language,
            // walletAddress,
            primaryColor: colorCode,
            secondaryColor,
            webhookUrl,
            redirectUrl,
            timezone: timezone,
            key: env === "Live" ? "webhook.prdUrl" : "webhook.url",
        };

        if (!validateForm()) {
            setLoading(true);
            const response = (await callApiWithToken("POST", "/api/organization/update", payload)) || {};
            notify.show("Updated Successfully..", "custom", 5000, { background: "#4BB543", text: "white" });
            setLoading(false);
        }
    };

    const onClickTimezoneUpdate = async () => {
        setLoading(true);
        try {
            const payload = { timezone };
            await callApiWithToken("POST", "/api/organization/updateTimezone", payload) || {};
            notify.show("Updated Successfully..", "custom", 5000, { background: "#4BB543", text: "white" });
            setDefaultTimezone(timezone);
            onTimezoneClose();
            sessionStorage.setItem('defaultTimezone', timezone);
            if (isChecked === true) window.location.reload();
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const getCustomerData = async () => {
        setLoading(true);
        const response =
            (await callApiWithToken("POST", "/api/organization/details", { impersonatedOrg: selectedOrg })) || {};
        const { orgResponse = {}, webhookResponse = {} } = response;
        const { primaryColor = "#1A4FD6", language, secondaryColor = "#232D95", formattedName } = orgResponse;
        const { webhook = {}, redirectUrl = "" } = webhookResponse || {};
        const newWebhookUrl = env === "Sandbox" ? webhook.url || "" : webhook.prdUrl || "";
        
        setWebhookData(webhook);
        setValue(webhook.secret);
        setColorCode(primaryColor);
        setSecondaryColor(secondaryColor);
        setLanguage(language);
        setLoading(false);
        setOrgName(formattedName);
        setRedirectUrl(redirectUrl);
        setWebhookUrl(newWebhookUrl);
    };

    const getDefaultBrowserLanguage = () => {
        /* Call in case default language is not set. This picks up browser language and uses it as default */
        const browserLangComplete = navigator.language || navigator.userLanguage;
        const browserLang = browserLangComplete.split("-")[0];

        if (browserLang && typeof browserLang === "string" && browserLang.toLowerCase() === "en") {
            setLanguage("english");
        }
    };

    const validateForm = () => {
        let isError = false;
        return isError;
    };

    const toggleColorPicker = () => setShowColorPicker(!showColorPicker);
    const toggleSecondaryColor = () => setSecondaryColorPicker(!secondaryColorPicker);

    const handleColorChange = ({ hex }) => {
        setColorCode(hex);
        setShowColorPicker(!showColorPicker);
    };
    const handleSecondaryColor = ({ hex }) => {
        setSecondaryColor(hex);
        setSecondaryColorPicker(!secondaryColorPicker);
    };

    const handleUrlChange = (event) => {
        setWebhookUrl(event.target.value);
    };

    const handleRedirectUrlChange = (event) => {
        setRedirectUrl(event.target.value);
    };

    const timezoneStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            width: '450px',
            height: "20px",
            fontSize: "14px",
            borderColor: "grey"
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: '14px',
        }),
    };

    return (
        loading ?
            <Loader /> :
            <Center py={5} bg="white.100">
                <Stack spacing={2} w="90%">
                    <Box bg="white" p={4}>
                        <Stack spacing={2}>
                            <Text fontSize="1xl" p={5} color="black" fontWeight={"bold"}>
                                {t("settings:welcome")}, {getUserName()}
                            </Text>
                            {/* <Divider /> */}
                            <Container
                                h="calc(60vh)"
                                border={"1px"}
                                borderColor="gray.200"
                                p={5}
                                borderRadius={10}
                                maxWidth={"full"}
                                width={"full"}
                            >
                                <Stack w="100%">
                                    <HStack alignItems={"center"}>
                                        <FormControl size="sm">
                                            <FormLabel htmlFor="text" pt={2}>
                                                {t("settings:primary_color")}
                                            </FormLabel>
                                            <HStack>
                                                <Button
                                                    variant={"ghost"}
                                                    iconSpacing={0}
                                                    size="sm"
                                                    onClick={toggleColorPicker}
                                                    bgColor={colorCode}
                                                    onClose={handleColorChange}
                                                ></Button>
                                                <Box className="colorPicker">
                                                    {showColorPicker ? (
                                                        <SketchPicker
                                                            disableAlpha={true}
                                                            color={colorCode}
                                                            onChangeComplete={handleColorChange}
                                                        />
                                                    ) : null}
                                                </Box>
                                                <Input
                                                    bg="gray.100"
                                                    value={colorCode}
                                                    size="sm"
                                                    isReadOnly
                                                    onFocus={toggleColorPicker}
                                                />
                                            </HStack>
                                        </FormControl>

                                        <FormControl size={"sm"}>
                                            <FormLabel htmlFor="text" pt={2}>
                                                {t("settings:secondary_color")}
                                            </FormLabel>
                                            <HStack>
                                                <Button
                                                    variant={"ghost"}
                                                    iconSpacing={0}
                                                    size="sm"
                                                    onClick={toggleSecondaryColor}
                                                    bg={secondaryColor}
                                                ></Button>
                                                <Box className="colorPicker">
                                                    {secondaryColorPicker ? (
                                                        <SketchPicker
                                                            disableAlpha={true}
                                                            color={secondaryColor}
                                                            onChangeComplete={handleSecondaryColor}
                                                        />
                                                    ) : null}
                                                </Box>
                                                <Input
                                                    bg="gray.100"
                                                    value={secondaryColor}
                                                    size="sm"
                                                    isReadOnly
                                                    onFocus={toggleSecondaryColor}
                                                />
                                            </HStack>
                                        </FormControl>
                                    </HStack>


                                    {/* {
                                        orgName === "binance" &&
                                        <HStack alignItems={"center"}>

                                            <FormControl size="sm">
                                                <FormLabel htmlFor="text" pt={2}>
                                                    Buy Fees(%)
                                                </FormLabel>

                                                <Input
                                                    disabled
                                                    value={1.8}
                                                    width={"80%"}
                                                    variant="outline"
                                                />

                                            </FormControl>

                                            <FormControl size="sm">
                                                <FormLabel htmlFor="text" pt={2}>
                                                    Sell Fees (%)
                                                </FormLabel>
                                                <Input
                                                    disabled
                                                    value={1.5}
                                                    width={"80%"}
                                                    variant="outline"
                                                />
                                            </FormControl>
                                        </HStack>
                                    } */}
                                    <FormControl size="sm">
                                        <FormLabel htmlFor="text" pt={2}>
                                            {t("settings:webhook_URL")}
                                        </FormLabel>
                                        <HStack>
                                            <Input
                                                value={webhookUrl}
                                                width={"80%"}
                                                variant="outline"
                                                placeholder="Enter webhook url"
                                                onChange={handleUrlChange}
                                            />
                                            <Button
                                                color="white"
                                                bg={"#3182CE"}
                                                _hover={{ bg: "#3182CE" }}
                                                style={{ marginLeft: "7%" }}
                                                onClick={onOpen}
                                            >
                                                {t("settings:show_key")}
                                            </Button>
                                        </HStack>
                                    </FormControl>
                                    <FormControl size="sm">
                                        <FormLabel htmlFor="text" pt={2}>
                                            {t("settings:redirect_URL")}
                                        </FormLabel>
                                        <HStack>
                                            <Input
                                                value={redirectUrl}
                                                width={"80%"}
                                                variant="outline"
                                                placeholder="Enter Redirect url"
                                                onChange={handleRedirectUrlChange}
                                            />
                                        </HStack>
                                    </FormControl>
                                    <Box style={{ marginTop: "1%" }}>
                                        <Flex display={"column"}>
                                            <FormLabel htmlFor="text" pt={2}>
                                                {t("settings:timezone")}
                                            </FormLabel>
                                            <HStack>
                                                <TimezoneSelect
                                                    styles={{ ...timezoneStyles }}
                                                    isDisabled={true}
                                                    value={defaultTimezone}
                                                    timezones={{ ...allTimezones }}
                                                />
                                                {["admin", "org_admin"].includes(cxRole) && (
                                                    <Button
                                                        variant='outline'
                                                        colorScheme='blue'
                                                        style={{ marginLeft: "7%" }}
                                                        onClick={onTimezoneOpen}
                                                    >
                                                        {t("settings:change_timezone")}
                                                    </Button>
                                                )}
                                            </HStack>
                                        </Flex>
                                    </Box>

                                    <HStack justifyContent={"center"} style={{ marginTop: "5%" }}>
                                        <Button
                                            isLoading={loading}
                                            variant={"primary"}
                                            bg={"#3182CE"}
                                            _hover={{ bg: '#3182CE' }}
                                            color="white"
                                            onClick={updateSettings}
                                        >
                                            {t("users:update")}
                                        </Button>
                                    </HStack>
                                </Stack>
                            </Container>
                        </Stack>

                        <Modal isOpen={isOpen} onClose={onClose} size="xl">
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>{t("settings:webhook_secret_key")}</ModalHeader>
                                <ModalCloseButton top={3} />
                                <Divider />
                                <ModalBody>
                                    <FormControl>
                                        <FormLabel>{t("settings:secret_key")}</FormLabel>
                                        <Input value={webhookData.secret} isReadOnly />
                                    </FormControl>
                                </ModalBody>
                                <Divider />
                                <ModalFooter>
                                    <HStack gap="5" width={"60%"}>
                                        <Button
                                            leftIcon={<BiCopy />}
                                            variant={"solid"}
                                            colorScheme="blue"
                                            onClick={onCopy}
                                            width={"full"}
                                        >
                                            {hasCopied ? t("settings:copied") : t("settings:copy")}
                                        </Button>
                                        <Button onClick={onClose} variant={"outline"} colorScheme="blue" width={"full"}>
                                            {t("settings:exit")}
                                        </Button>
                                    </HStack>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>

                        <Modal isOpen={isTimezoneOpen} onClose={onTimezoneClose} size="xl">
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader size={'sm'}>{t("settings:change_default_timezone")}</ModalHeader>
                                <ModalCloseButton top={3} />
                                <Divider />
                                <ModalBody>
                                    <Box style={{ marginTop: "2%" }} marginBottom={"3%"}>
                                        <FormControl>
                                            <FormLabel>{t("settings:timezone")}</FormLabel>
                                            <Flex>
                                                <TimezoneSelect
                                                    styles={{ ...timezoneStyles }}
                                                    value={timezone}
                                                    onChange={(value) => setTimezone(value?.value)}
                                                    timezones={{ ...allTimezones }}
                                                />
                                            </Flex>
                                        </FormControl>
                                    </Box>
                                    <Checkbox isChecked={isChecked} onChange={handleCheckboxChange}>{t("settings:refresh_the_dashboard_with_new_timezone")}</Checkbox>
                                </ModalBody>
                                <Divider />
                                <ModalFooter>
                                    <HStack gap="5" width={"60%"}>
                                        <Button
                                            variant={"solid"}
                                            colorScheme="blue"
                                            width={"full"}
                                            onClick={onClickTimezoneUpdate}
                                        >
                                            {t("users:update")}
                                        </Button>
                                        <Button onClick={onTimezoneClose} variant={"outline"} colorScheme="blue" width={"full"}>
                                            {t("settings:exit")}
                                        </Button>
                                    </HStack>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                    </Box>
                </Stack >
            </Center >
    );
}
