import React, { useEffect, useState, useRef } from "react";
import { get, round, startCase, upperCase } from "lodash";
import Loader from "react-js-loader";
import { Box, Badge, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, FormControl, FormLabel, Input, Button, Select, HStack, useToast } from "@chakra-ui/react";
import { BiRefresh, BiReset } from "react-icons/bi";
import { FiFilter } from "react-icons/fi";
import { AiTwotoneEdit } from "react-icons/ai";

import TransfiGrid from "../../../utils/TransfiGrid";
import { getCountryData } from "../../../utils/getCountries";
import { callApiWithToken } from "../../../utils/utils";
import LiveRateFilter from "./LiveRateFilter";

const LiveRates = (props) => {
    const [loading, setLoading] = useState(false);
    const [inputData, setInputData] = useState({});
    const [formValues, setFormValues] = useState({});
    const [filters, setFilters] = useState({});
    const [countryOptions, setCountryOptions] = useState([]);
    const [fiatCurrency, setFiatCurrency] = useState([]);
    const [rowData, setRowData] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [buyRateMarkup, setBuyRateMarkup] = useState("0");
    const [backupBuyRateMarkup, setBackupBuyRateMarkup] = useState("0");
    const [sellRateMarkup, setSellRateMarkup] = useState("0");
    const [backupSellRateMarkup, setBackupSellRateMarkup] = useState("0");
    const [exchangePartner, setExchangePartner] = useState("");
    const [backupExchangePartner, setBackupExchangePartner] = useState("");
    const { isOpen: isOpenEditModal, onOpen: onOpenEditModal, onClose: onCloseEditModal } = useDisclosure();

    const gridRef = useRef();
    const toast = useToast();

    useEffect(() => {
        refreshGrid;
    }, []);

    useEffect(() => {
        getCountryList();
    }, []);

    const refreshGrid = () => {
        gridRef.current.refreshGrid();
    };


    const handleOnReset = () => {
        setFilters({});
        setFormValues({});
        setInputData({});
    };

    const getCountryList = async () => {
        const { fiatList, countryNameList } = await getCountryData();
        setCountryOptions(countryNameList);
        setFiatCurrency(fiatList);
    };

    const handleEditClick = (rowData) => {
        setSelectedRowData(rowData?.original);
        const buyMarkup = get(rowData?.original, "buyMarkup", "0");
        const backupBuyMarkup = get(rowData?.original, "backupBuyMarkup", "0");
        const sellMarkup = get(rowData?.original, "sellMarkup", "0");
        const backupSellMarkup = get(rowData?.original, "backupSellMarkup", "0");
        const defaultPartner = get(rowData?.original, "defaultPartner", "xe");
        const backupPartner = get(rowData?.original, "backupPartner", "");
        const buyRate = (Number(buyMarkup) * 100).toFixed(2);
        const backupBuyRate = (Number(backupBuyMarkup) * 100).toFixed(2);
        const backupSellRate = (Number(backupSellMarkup) * 100).toFixed(2);
        const sellRate = (Number(sellMarkup) * 100).toFixed(2);
        setBuyRateMarkup(buyRate)
        setSellRateMarkup(sellRate);
        setExchangePartner(defaultPartner);
        setBackupBuyRateMarkup(backupBuyRate);
        setBackupSellRateMarkup(backupSellRate);
        setBackupExchangePartner(backupPartner);
        onOpenEditModal();
    };

    const closeModal = () => {
        setSelectedRowData(null);
        setBuyRateMarkup("0")
        setSellRateMarkup("0");
        setExchangePartner("")
        setBackupBuyRateMarkup('0');
        setBackupSellRateMarkup('0');
        setBackupExchangePartner('');
        onCloseEditModal();
    };

    const saveChanges = async () => {
        setLoading(true);
        try {
            const updatedData = {
                id: selectedRowData?._id,
                defaultPartner: selectedRowData?.defaultPartner ? selectedRowData?.defaultPartner : exchangePartner,
                buyMarkup: selectedRowData?.buyMarkup ? selectedRowData?.buyMarkup : String((buyRateMarkup / 100).toFixed(4)),
                sellMarkup: selectedRowData?.sellMarkup ? selectedRowData?.sellMarkup : String((sellRateMarkup / 100).toFixed(4)),
                backupPartner: selectedRowData?.backupPartner ? selectedRowData?.backupPartner : backupExchangePartner,
                backupBuyMarkup: selectedRowData?.backupBuyMarkup ? selectedRowData?.backupBuyMarkup : String((backupBuyRateMarkup / 100).toFixed(4)),
                backupSellMarkup: selectedRowData?.backupSellMarkup ? selectedRowData?.backupSellMarkup : String((backupSellRateMarkup / 100).toFixed(4))
            };
            const response = await callApiWithToken('POST', '/api/admin/finance/rateList/update', updatedData);
            setSelectedRowData(selectedRowData);
            const updatedRowData = rowData.map(row => {
                if (row._id === selectedRowData._id) {
                    return { ...row, ...selectedRowData };
                }
                return row;
            });
            setRowData(updatedRowData);
            if (response?.message) {
                toast({
                    title: 'Success',
                    description: response?.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
            }
        }
        catch (error) {
            toast({
                title: 'Error!',
                description: response?.message || "Failed to Update the live rate",
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right'
            })
        }
        setLoading(false);
        closeModal();
    };

    const columns = [
        {
            Header: "Currency",
            accessor: "currency",
            width: "150",
        },
        {
            Header: "Exchange Rate Partner",
            accessor: "defaultPartner",
            width: "150",
            Cell: ({ value }) => upperCase(value) || 'XE'
        },
        {
            Header: "Payin Markup",
            width: "150",
            Cell: ({ row }) => {
                const buyMarkup = get(row?.original, "buyMarkup", 0)
                return round(buyMarkup * 100, 2) + "%";
            }
        },
        {
            Header: "Payin Rate",
            width: "150",
            Cell: ({ row }) => {
                const buyExchangeRate = get(row?.original, "buyExchangeRate", 0)
                return round(buyExchangeRate, 2);
            }
        },
        {
            Header: "Payout Markup",
            width: "150",
            Cell: ({ row }) => {
                const sellMarkup = get(row?.original, "sellMarkup", 0)
                return round(sellMarkup * 100, 2) + "%";
            }
        },
        {
            Header: "Payout Rate",
            width: "150",
            Cell: ({ row }) => {
                const sellExchangeRate = get(row?.original, "sellExchangeRate", 0)
                return round(sellExchangeRate, 2);
            }
        },
        {
            Header: "Backup Exchange Rate Partner",
            accessor: "backupPartner",
            width: "200",
            Cell: ({ value }) => upperCase(value) || "-"
        },
        {
            Header: "Backup Payin Markup",
            width: "150",
            Cell: ({ row }) => {
                const buyMarkup = get(row?.original, "backupBuyMarkup", 0)
                return round(buyMarkup * 100, 2) + "%";
            }
        },
        {
            Header: "Backup Payout Markup",
            width: "150",
            Cell: ({ row }) => {
                const sellMarkup = get(row?.original, "backupSellMarkup", 0)
                return round(sellMarkup * 100, 2) + "%";
            }
        },
        {
            Header: "Edit",
            accessor: "id",
            width: "130",
            Cell: ({ row }) => {
                return (
                    <Button
                        bg={"white"}
                        leftIcon={
                            <AiTwotoneEdit
                                style={{
                                    fontSize: "22px",
                                    color: "#2b6cb0",
                                    cursor: "pointer",
                                }}
                            />
                        }
                        onClick={() => handleEditClick(row)}
                    >
                    </Button>
                );
            }
        }
    ];

    return !loading ? (
        <Box style={{ background: "white" }} heading="Live LiveRates">
            <div style={{ marginTop: "2%" }}>
                <div style={{ float: "right" }}>
                    <Button
                        leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                        onClick={() => handleOnReset()}
                        variant={"outline"}
                        colorScheme="blue"
                        style={{ margin: "0 8px" }}
                        size="sm"
                    >
                        Reset
                    </Button>
                    <Button
                        leftIcon={<BiRefresh style={{ fontSize: "20px" }} />}
                        onClick={() => refreshGrid()}
                        variant={"outline"}
                        colorScheme="blue"
                        style={{ margin: "0 0px" }}
                        size="sm"
                    >
                        Refresh
                    </Button>
                    <Button
                        leftIcon={<FiFilter style={{ fontSize: "20px" }} />}
                        onClick={onOpen}
                        variant={"outline"}
                        colorScheme="blue"
                        style={{ margin: "0 8px" }}
                        size="sm"
                    >
                        Filter
                    </Button>
                </div>
                <br />
            </div>
            <TransfiGrid
                ref={gridRef}
                columns={columns}
                dataUrl={"/api/admin/finance/rateList"}
                selectRow={false}
                filters={{ filters }}
            ></TransfiGrid>
            <LiveRateFilter
                isOpen={isOpen}
                onClose={onClose}
                inputData={inputData}
                setInputData={setInputData}
                filters={filters}
                setFilters={setFilters}
                formValues={formValues}
                setFormValues={setFormValues}
                handleOnReset={handleOnReset}
                countryOptions={countryOptions}
                fiatCurrency={fiatCurrency}
            />

            <Modal isOpen={isOpenEditModal} onClose={closeModal} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Live Rate Markup</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <HStack spacing={4} marginTop={4}>
                            <FormControl>
                                <FormLabel>Exchange Rate Partner</FormLabel>
                                <Select
                                    value={exchangePartner || ""}
                                    onChange={(e) => setSelectedRowData({ ...selectedRowData, defaultPartner: setExchangePartner(e.target.value) })}
                                >
                                    <option value="xe">XE</option>
                                    <option value="yellowcard">Yellowcard</option>
                                    <option value="linkio">LinkIo</option>
                                    <option value="bluedollar">Bluedollar</option>
                                </Select>
                            </FormControl>
                        </HStack>
                        <HStack spacing={4} marginTop={4}>
                            <FormControl>
                                <FormLabel>Buy Markup %</FormLabel>
                                <Input
                                    value={buyRateMarkup}
                                    onChange={(e) => setSelectedRowData({ ...selectedRowData, buyMarkup: setBuyRateMarkup(e.target.value) })}
                                />
                            </FormControl>
                        </HStack>
                        <HStack spacing={4} marginTop={4}>
                            <FormControl>
                                <FormLabel>Sell Markup %</FormLabel>
                                <Input
                                    value={sellRateMarkup}
                                    onChange={(e) => setSelectedRowData({ ...selectedRowData, sellMarkup: setSellRateMarkup(e.target.value) })}
                                />
                            </FormControl>
                        </HStack>
                                <HStack spacing={4} marginTop={4}>
                                    <FormControl>
                                        <FormLabel>Backup Exchange Rate Partner</FormLabel>
                                        <Select
                                            value={backupExchangePartner || ""}
                                            onChange={(e) => setSelectedRowData({ ...selectedRowData, backupPartner: setBackupExchangePartner(e.target.value) })}
                                        >
                                            <option value="" >
                                                Please select the backup partner
                                            </option>
                                            <option value="xe">XE</option>
                                            <option value="yellowcard">Yellowcard</option>
                                            <option value="linkio">LinkIo</option>
                                            <option value="bluedollar">Bluedollar</option>
                                        </Select>
                                    </FormControl>
                                </HStack>
                        <HStack spacing={4} marginTop={4}>
                            <FormControl>
                                <FormLabel>Backup Buy Markup %</FormLabel>
                                <Input
                                    value={backupBuyRateMarkup}
                                    onChange={(e) => setSelectedRowData({ ...selectedRowData, backupBuyMarkup: setBackupBuyRateMarkup(e.target.value) })}
                                />
                            </FormControl>
                        </HStack>
                        <HStack spacing={4} marginTop={4}>
                            <FormControl>
                                <FormLabel>Backup Sell Markup %</FormLabel>
                                <Input
                                    value={backupSellRateMarkup}
                                    onChange={(e) => setSelectedRowData({ ...selectedRowData, backupSellMarkup: setBackupSellRateMarkup(e.target.value) })}
                                />
                            </FormControl>
                        </HStack>
                        
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={saveChanges} isLoading={loading}>
                            Save
                        </Button>
                        <Button onClick={closeModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    ) : (
        <Loader
            type="spinner-cub"
            bgColor={"#FFFFFF"}
            title={"Loading..."}
            color={"cyan.400"}
            size={100}
        />
    );
};

export default LiveRates;
