import { useState, useContext, useEffect } from "react";
import { Box, Stack, Badge, Text, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, FormControl, FormLabel, Input, Button, Select, HStack, useToast } from "@chakra-ui/react";
import moment from "moment";
import { Link } from "react-router-dom";
import { BiReset } from 'react-icons/bi'
import { startCase, round } from 'lodash';

import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

import Loader from '../../utils/Loader';
import { constants } from "../../constants";
import Grid from '../../utils/agTable';
import { NavBarContext } from '../../context/NavBarContext';
import { callApiWithTokenV2 } from '../../utils/utils';
import TimezoneFormatter from "../../utils/TimezoneFormatter";
import EditModal from "../../utils/EditModal";


const FiatPayoutOrders = (props) => {
  const { fiatPayoutStatusMap, colorMap } = constants;
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [partners, setPartners] = useState([]);
  const { isOpen: isOpenEditModal, onOpen: onOpenEditModal, onClose: onCloseEditModal } = useDisclosure();
  const [selectedOrder, setSelctedOrder] = useState();
  const [bankReferenceNumber, setBankReferenceNumber] = useState();
  const [status, setStatus] = useState("fund_processing");
  const [completedTime, setCompletedTime] = useState();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [validation, setValidation] = useState(false);

  const { selectedOrg } = useContext(NavBarContext);

  useEffect(() => {
    setRefreshGrid(!refreshGrid);
  }, [selectedOrg])

  useEffect(()=>{
    getPayoutPartners();
  },[])

  const USformatter = new Intl.NumberFormat('en-US');

  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var cellDate = new Date(cellValue);
      if (filterLocalDateAtMidnight.toLocaleDateString() === cellDate.toLocaleDateString()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2000,
  };

  const handleReset = () => {
    setRefreshGrid(!refreshGrid);
  };

  const getOrderData = async (filters) => {
    filters["type"] = "fiat_payout";
    const responseData = await callApiWithTokenV2.post(`/api/orders/tfpay/list?`, { impersonatedOrg: selectedOrg }, {
      params: {
        filters
      }
    })
    return responseData;
  }

  const getPayoutPartners = async () => {
    try{
      const responseData = await callApiWithTokenV2.get(`/api/orders/tfpay/partner`)
      const data = responseData?.data?.paymentPartners;
      const partners = data?.map((d)=>d?.paymentGateway)
      setPartners(partners);
    }catch(error){
      console.log(error);
    }
  }

  const columns = [
    {
      headerName: "Order ID TransFi",
      field: "orderId",
      getQuickFilterText: params => {
        return params.value.name;
      },
      customerVisibility: true,
      width: '250',
      cellRenderer: ({ value, data }) => {
        return <Link onClick={() => {
          if (!['asset_processing', 'fund_processing'].includes(data.status)) return;
          setSelctedOrder(value);
          onOpenEditModal();
        }} style={{ color: "#1A4FD6", fontWeight: "bold", fontSize: '14px' }} >{value}</Link>
      },
    },
    //{ headerName: "Order ID Customer", field: "cxId", customerVisibility: false, width: '200' },
    {
      headerName: "Customer Name", field: "customer.formattedName", enableRowGroup: true, customerVisibility: false, width: '200', cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "User ID/Email", field: "customer.email", enableRowGroup: true, customerVisibility: true, width: '300', cellRenderer: ({ value }) => (
        <Text>{value ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Status",
      field: "status",
      customerVisibility: true,
      enableRowGroup: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      // editable: ({ data }) => data.status !== 'fund_settled',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['initiated','fund_settled','failed','asset_settled','asset_deposited','fund_processing','asset_processing','completed','approval_pending','rejected','expired','fund_settled','approved']
      },
      cellRenderer: ({ value }) => {
        const newStatus = fiatPayoutStatusMap[value] || value;
        const color = colorMap[value];
        return <Badge width={"100%"} textAlign={"center"} colorScheme={color}>{newStatus}</Badge>;
      },
    },
    { headerName: "Recipient Name", field: "contactName", enableRowGroup: true, customerVisibility: true, width: '200' },
    { headerName: "Recipient Email", field: "recipientEmail", enableRowGroup: true, customerVisibility: true, width: '200' },
    {
      headerName: "Purpose of Transaction", field: "purposeCode", enableRowGroup: true, customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Nature of Sender", field: "", enableRowGroup: true, customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'Individual'}</Text>
      )
    },
    {
      headerName: "Nature of Recipient", field: "contactList.type", enableRowGroup: true, customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Jurisdiction of Sender", field: "", enableRowGroup: true, customerVisibility: false, width: '200',
      cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'N/A'}</Text>
      )
    },
    { headerName: "Jurisdiction of Recipient", field: "contactList.country", enableRowGroup: true, customerVisibility: false, width: '200' },
    {
      headerName: "Initiated Date",
      field: "createdAt",
      filterParams: filterParams,
      customerVisibility: true,
      enableRowGroup: false,
      cellDataType: 'date',
      width: '220',
      filter: 'agDateColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{value ? <TimezoneFormatter date={value} format={"DD-MMM-YYYY @ h:mm A"} /> : "N/A"}</Text>
      ),
    },
    {
      headerName: "Completed Date",
      field: "timestamps.fundSettledAt",
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      customerVisibility: true,
      enableRowGroup: false,
      cellDataType: 'date',
      width: '220',
      cellRenderer: ({ value, data }) => {return(
        <>
        {data?.status !== 'fund_settled'?
        <EditModal value={value} columnName="Completed Date" data={data} field={'timestamps.fundSettledAt'} type={'fiat_payout'} handleReset={handleReset}/>
        : <Text>{value ? <TimezoneFormatter date={value} format={"DD-MMM-YYYY @ h:mm A"} /> : "N/A"}</Text>
        }</>
        )},
    },
    {
      headerName: "Cut off Time",
      field: "timestamps.cutOffAt",
      filterParams: filterParams,
      customerVisibility: true,
      enableRowGroup: false,
      cellDataType: 'date',
      width: '220',
      filter: 'agDateColumnFilter',
      cellRenderer: ({ value, data }) => {return(
        <>
        {data?.status !== 'fund_settled'?
        <EditModal value={value} columnName="Cut off Time" data={data} field={'timestamps.cutOffAt'} type={'fiat_payout'} handleReset={handleReset}/>
        : <Text>{value ? <TimezoneFormatter date={value} format={"DD-MMM-YYYY @ h:mm A"} /> : "N/A"}</Text>
        }</>
        )},
    },
    {
      headerName: "Batch Settlement Time",
      field: "timestamps.settlementSettledAt",
      filterParams: filterParams,
      customerVisibility: true,
      enableRowGroup: false,
      cellDataType: 'date',
      width: '220',
      filter: 'agDateColumnFilter',
      cellRenderer: ({ value, data }) => {return(
        <>
        {data?.status !== 'fund_settled'?
        <EditModal value={value} columnName="Batch Settlement Time" data={data} field={'timestamps.settlementSettledAt'} type={'fiat_payout'} handleReset={handleReset}/>
        : <Text>{value ? <TimezoneFormatter date={value} format={"DD-MMM-YYYY @ h:mm A"} /> : "N/A"}</Text>
        }</>
        )},
    },
    {
      headerName: "Payout Payment Partner", field: "paymentGateway", 
      // editable: ({ data }) => data.status !== 'fund_settled', 
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: partners },
      customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Partner Order ID", field: "fiat.orderId", 
      // editable: ({ data }) => data.status !== 'fund_settled', 
      cellEditor: 'agTextCellEditor', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Payment Method", field: "paymentFormattedName", 
      // editable: ({ data }) => data.status !== 'fund_settled', 
      cellEditor: 'agTextCellEditor', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Currency", field: "withdrawCurrency", customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? value : "N/A"}</Text>
      )
    },
    {
      headerName: "Payout Date",
      field: "timestamps.fundSettledAt",
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      customerVisibility: true,
      enableRowGroup: false,
      cellDataType: 'date',
      width: '220',
      cellRenderer: ({ value, data }) => {return(
        <>
        {data?.status !== 'fund_settled'?
        <EditModal value={value} columnName="Payout Date" data={data} field={'timestamps.fundSettledAt'} type={'fiat_payout'} handleReset={handleReset}/>
        : <Text>{value ? <TimezoneFormatter date={value} format={"DD-MMM-YYYY @ h:mm A"} /> : "N/A"}</Text>
        }</>
        )},
    },
    {
      headerName: "Payout amount", field: "withdrawAmount", aggFunc: 'sum', cellDataType: 'number', filter: 'agNumberColumnFilter', customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Payment Partner Fees", field: "fiat.partnerFee", filter: 'agNumberColumnFilter', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Amount Deducted from Partner", field: "fiat.orderAmount", filter: 'agNumberColumnFilter', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Processing fees %", field: "fiat.tfFee", filter: 'agNumberColumnFilter', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value * 100) + '%' : '0'}</Text>
      )
    },
    {
      headerName: "Fixed/Hybrid Fee Value", field: "fees.fixedFee", customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? `${value.totalFixedFees} ${value.fixedFeesCurrency}` : 'N/A'}</Text>
      )
    },
    {
      headerName: "Processing fees Amount", field: "processingFeeAmount", filter: 'agNumberColumnFilter', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "GTV Payout Currency", field: "gtvPayoutCurrency", filter: 'agNumberColumnFilter', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Conversion Rate to USD", field: "conversionRateToUsd", filter: 'agNumberColumnFilter', customerVisibility: false, cellDataType: 'number', cellRenderer: ({ value }) => {
        return <Text>{value ? round(value, 8) : '0'}</Text>
      }
    },
    {
      headerName: "Gross TXN Amount on USD if zero forex markup", field: "grossTxnIfZeroForexMarkup", filter: 'agNumberColumnFilter', customerVisibility: false, cellDataType: 'number', cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "FX Markup %", field: "fiat.markupFee", cellDataType: 'number', customerVisibility: false, filter: 'agNumberColumnFilter', cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(Math.abs(value) * 100) + "%" : '0'}</Text>
      )
    },
    {
      headerName: "Conversion rate Used by TransFi", field: "conversionRateUsed", filter: 'agNumberColumnFilter', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? round(value, 6) : '0'}</Text>
      )
    },
    {
      headerName: "Gross TXN Amount on USD", field: "grossTxnAmountOnUSD", filter: 'agNumberColumnFilter', aggFunc: 'sum', customerVisibility: true, cellDataType: 'number', cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "TransFi Forex gain USD", field: "transfiForexGainUsd", filter: 'agNumberColumnFilter', aggFunc: 'sum', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Fiat Delivered To User(In USD)", field: "fiatDeliveredToUserInUsd", filter: 'agNumberColumnFilter', aggFunc: 'sum', customerVisibility: true, cellDataType: 'number', cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Processing Fees Amount(In USD)", field: "processingFeesAmountInUsd", filter: 'agNumberColumnFilter', aggFunc: 'sum', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Payment Partner Fees(In USD)", field: "paymentPartnerFeesInUsd", filter: 'agNumberColumnFilter', aggFunc: 'sum', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Prefunding Currency", field: "depositCurrency", filter: 'agNumberColumnFilter', aggFunc: 'sum', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Conversion Rate USD to Prefunding Currency", field: "conversionRatePrefundCurrency", filter: 'agNumberColumnFilter', aggFunc: 'sum', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Amount Deducted from Prefunding balance", field: "depositAmount", filter: 'agNumberColumnFilter', aggFunc: 'sum', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? value : 'N/A'}</Text>
      )
    }
  ];

  const closeModal = () => {
    setBankReferenceNumber();
    setCompletedTime();
    onCloseEditModal();
    setStatus("fund_processing");
    setLoading(false);
    setValidation(false);
  };

  const saveChanges = async () => {
  try {
    if (!completedTime) {
      setValidation(true);
      return
    }
    setLoading(true);
    setValidation(false);
    const payload = {
      orderId: selectedOrder,
      status,
      bankReferenceNumber,
      completedTime: new Date(completedTime).toISOString(),
    }
    const responseData = await callApiWithTokenV2.post(`/api/orders/tfpay/orders`, payload);
    const { status: responseStatus } = responseData;
    if (responseStatus === 200) {
      toast({
        position: 'top-right',
        title: "Order updated successfully",
        status: 'success',
        duration: 5000,
        isClosable: true
      });
      handleReset();
      closeModal();
    } else {
      toast({
        position: 'top-right',
        title: "Failed to update order",
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
    setLoading(false);
  } catch (error) {
    toast({
      position: 'top-right',
      title: "Failed to update order",
      status: 'error',
      duration: 5000,
      isClosable: true
    });
    setValidation(false);
    setLoading(false);
    console.log(error);
  }
};

  const statusMap = {
    "fund_processing": "Fund Released",
    "fund_settled": "Completed",
    "fund_failed": "Failed",
  }

  return (
    <Stack
      direction={"column"}
      justifyContent={"space-between"}
      flexGrow={1}
      p={5}
    >

      {!isLoading ? <Box >
        <HStack pb={5} justifyContent={"flex-end"}>
          <Button
            leftIcon={<BiReset style={{ fontSize: "20px" }} />}
            color={"#525C76"}
            variant={"outline"}
            borderColor={"#d2d2d2"}
            _hover={{ bg: "#ffffff" }}
            onClick={handleReset}
          >
            Reset
          </Button>
        </HStack>

        <Modal isOpen={isOpenEditModal} onClose={closeModal} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Order</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <HStack spacing={4} marginTop={4}>
                            <FormControl isRequired={true}>
                                <FormLabel>Order Id</FormLabel>
                                <Input
                                    value={selectedOrder}
                                    disabled={true}
                                    // onChange={(e) => ()}
                                />
                            </FormControl>
                        </HStack>
                        <HStack spacing={4} marginTop={4}>
                            <FormControl>
                                <FormLabel>Bank reference number</FormLabel>
                                <Input
                                    value={bankReferenceNumber}
                                    onChange={(e) => setBankReferenceNumber(e.target.value) }
                                />
                            </FormControl>
                        </HStack>
                        <HStack spacing={4} marginTop={4}>
                            <FormControl isRequired={true}>
                                <FormLabel>Status</FormLabel>
                                 <Select
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value) }
                                >
                                    <option value="fund_processing">Fund Released</option>
                                    <option value="fund_settled">Completed</option>
                                    <option value="fund_failed">Failed</option>
                                </Select>
                            </FormControl>
                        </HStack>
                        <HStack spacing={4} marginTop={4}>
                            <FormControl isRequired={true} isInvalid={validation}>
                                <FormLabel>{status ? (`${statusMap[status]} At`) : "Time"}</FormLabel>
                                <Input placeholder='Select Date and Time' size='md' type='datetime-local' onChange={(e) => setCompletedTime(e.target.value) } />
                            </FormControl>
                        </HStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} 
                        isLoading={loading}
                        onClick={() => saveChanges()}
                        >
                            Save
                        </Button>
                        <Button onClick={closeModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        <Grid columns={columns} key={refreshGrid.toString()} getOrderData={getOrderData} type="fiat_payout" />
      </Box> : <Loader />}
    </Stack>
  );
};

export default FiatPayoutOrders;
