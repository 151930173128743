import React, { useState, useRef, useContext } from "react";
import Loader from "react-js-loader";
import { Box, Button, useDisclosure, useToast, } from "@chakra-ui/react";
import { BiRefresh } from "react-icons/bi";
import { Link } from "react-router-dom";
import moment from "moment";

import TransfiGrid from "../../utils/TransfiGrid";

import { getCxRoles, getOrgRoles } from "../App/useToken";
import { NavBarContext } from "../../context/NavBarContext";
import { round } from "lodash";

export default function CxRevshareSettlements() {
	//ref
	const gridRef = useRef();

	const { selectedOrg } = useContext(NavBarContext);
	const roles = getOrgRoles();

	//variables
	const defaultDate = { startDate: moment(new Date()).subtract(2, "months"), endDate: moment(new Date()) };
	const defaultFilters = { dateRange: defaultDate, currency: "ALL" }

	//state variables
	const [loading, setLoading] = useState(false);
	const [inputData, setInputData] = useState({ currency: "ALL" });
	const [formValues, setFormValues] = useState({});
	const [filters, setFilters] = useState(defaultFilters);
	const [dateRange, setDateRange] = useState(defaultDate);

	const refreshGrid = () => {
		gridRef.current.refreshGrid();
	};

	const cxRole = getCxRoles();


	const columns = [
		{
			Header: "Date",
			accessor: "date",
			type: "date",
		},
		{
			Header: "Customer Name",
			accessor: "name",
			width: "30",
			show: roles.includes("transfi_admin") ? true : false,
		},
		{
			Header: "Transaction Fiat Currency",
			accessor: "currency",
			width: "150",
		},
		{
			Header: "Opening Balance",
			accessor: "openingBalance",
			width: "30",
			Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Txns(Buy)",
			accessor: "buy.totalOrders",
			width: "30",
			color: "info",
			Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Total Fiat Amt(Buy)",
			accessor: "buy.totalAmount",
			width: "150",
			color: "info",
			Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Customer Rev Share on fiat currency(Buy)",
			accessor: "buy.totalCxFee",
			width: "250",
			color: "info",
			Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Txns(Sell)",
			accessor: "sell.totalOrders",
			width: "30",
			color: "warning",
			Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Total Fiat Amt(Sell)",
			accessor: "sell.totalAmount",
			width: "150",
			color: "warning",
			Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Customer Rev Share on fiat currency(Sell)",
			accessor: "sell.totalCxFee",
			width: "250",
			color: "warning",
			Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Total customer rev share",
			accessor: "totalRevShare",
			width: "200",
			color: "info",
			// info: "Gross Fiat Amt(Buy) - Gross Fiat Amt(Sell)",
			Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Closing Balance",
			accessor: "closingBalance",
			width: "30",
			color: "info",
			Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Amount to be settled in fiat",
			accessor: "amountSettled",
			width: "200",
			color: "info",
			Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Net balance in fiat currency",
			accessor: "netBalance",
			width: "150",
			color: "info",
			Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Customer rev share settlement id",
			accessor: "settlementId",
			width: "30",
			Cell: ({ value }) => {
				return (
					<Link style={{ color: "#4ACDED", fontWeight: "bold" }} to={`/cx-revshare-settlements/batch/${value}`}>
						{value}
					</Link>
				);
			},
			color: "info",
		},
		{
			Header: "Settlement Currency",
			accessor: "settlementCurrency",
			width: "30",
			color: "warning",
			Cell: ({ value }) => value ? value : "-"
		},
		{
			Header: "Revshare Paid",
			accessor: "revsharePaid",
			width: "30",
			color: "warning",
			Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
		},
		{
			Header: "Settlement Date",
			accessor: "settlementDate",
			width: "30",
			color: "warning",
			Cell: ({ value }) => value || "-"
		},
		{
			Header: "Note",
			accessor: "note",
			width: "250",
			color: "warning",
			Cell: ({ value }) => value || "-"
		}
	];

	const handleOnReset = () => {
		setDateRange(defaultDate);
		setFilters(defaultFilters);
		setFormValues({});
		setInputData({});
	};

	return !loading ? (
		<Box style={{ background: "white" }} heading=" Customer Revshare ">

			<div style={{ float: "right", marginTop: "2%" }}>
				<Button
					leftIcon={<BiRefresh style={{ fontSize: "20px" }} />}
					onClick={() => refreshGrid()}
					variant={"outline"}
					colorScheme="blue"
					style={{ margin: "0 0px" }}
					size="sm"
				>
					Refresh
				</Button>
			</div>
			<TransfiGrid
				ref={gridRef}
				columns={columns}
				dataUrl={"/api/settlement/revShare/list"}
				selectRow={false}
				filters={{ filters, impersonatedOrg: selectedOrg, }}
			/>
		</Box >
	) : (
		<Loader
			type="spinner-cub"
			bgColor={"#FFFFFF"}
			title={"Loading..."}
			color={"cyan.400"}
			size={100}
		/>
	);
}
