/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import {
    Button,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
} from '@chakra-ui/react';

import { callApiWithToken } from '../../utils/utils';

export default function AddComments(props) {
    const { isOpen, onClose, refreshData, id, bulkUpdate = false, selectedRows = [] } = props;
    const finalRef = React.useRef(null);

    const [source, setSource] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const onClickHandler = async () => {
        setIsLoading(true);
        let userIdsList = selectedRows.map(val => val?.userId);
        await callApiWithToken(
            'POST',
            '/api/users/updateSource', { source, userId: id, bulkUpdate, userIds: userIdsList }) || {};
        setIsLoading(false);
        refreshData();
        onClose();
    }

    return <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Update Source</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
                <FormControl>
                    <FormLabel>Source</FormLabel>
                    <Select placeholder='Select status' onChange={(e) => setSource(e.target.value)}>
                        <option value='transfi_user'>TransFi User</option>
                        <option value='upwork_user'>Upwork User</option>
                        <option value='real_user'>Real User</option>
                    </Select>
                </FormControl>
            </ModalBody>
            <ModalFooter>
                <Button isLoading={isLoading} isDisabled={!source} onClick={onClickHandler} colorScheme='blue' mr={3}>
                    Add
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
}