/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import {
    Button,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Input,
    useToast,
    Stack
} from '@chakra-ui/react';

import { callApiWithToken } from "../../utils/utils";

const PartialPrefundModal = (props) => {
    const { isOpen, onClose, isLoading, refreshGrid, batchId, data } = props;

    const toast = useToast();
    const [conversionRate, setConversionRate] = useState({});
    const [description, setDescription] = useState("");
    const [settlementAmount, setSettlementAmount] = useState({});
    const [prefundAmount, setPrefundAmount] = useState({});
    const [loading, setLoading] = useState(false)

    const onSubmit = async () => {
        try {
            setLoading(true);
            const response = await callApiWithToken("POST", "/api/settlement/partialPrefund", {
                settlementId: batchId,
                description,
                currency: data?.fiatCurrency,
                orgId: data?.orgId,
                conversionRate,
                prefundAmount,
                settlementAmount
            });
            if (response?.message) {
                toast({
                    title: response.message,
                    status: 'success',
                    isClosable: true,
                    position: 'top-right',
                    duration: 5000,
                });
                setLoading(false);
                setLoading();
                onClose();
            } else {
                setLoading(false);
                throw Error("Failed to update note.")
            }
        } catch (err) {
            setLoading(false);
            toast({
                title: 'Error!',
                description: response?.message || "Failed to fetch order details.",
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right'
            });
            refreshGrid();
            onClose();
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>Partial Prefund {batchId}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>

                    <Stack direction={"column"} justifyContent="space-between" flexGrow={1}>
                        <FormControl
                            isRequired
                        >
                            <FormLabel htmlFor="name">Total Amount</FormLabel>
                            <Input
                                id="totalAmount"
                                type="number"
                                value={data?.amount}
                                isDisabled
                            />
                        </FormControl>
                        <FormControl
                            isRequired
                        >
                            <FormLabel htmlFor="name">Prefunded Amount</FormLabel>
                            <Input
                                id="prefundAmount"
                                type="number"
                                value={prefundAmount}
                                onChange={(e) => {
                                    setPrefundAmount(e.target.value);
                                    const amount = Number(data?.amount) - Number(e.target.value)
                                    setSettlementAmount(amount)
                                }}
                            />
                        </FormControl>
                        <FormControl
                            isRequired
                        >
                            <FormLabel htmlFor="name">Settlement Amount</FormLabel>
                            <Input
                                id="settlementAmount"
                                type="number"
                                value={settlementAmount}
                                isDisabled
                            //   onChange={(e) => {

                            //   }}
                            />
                        </FormControl>
                        <FormControl
                            isRequired
                        >
                            <FormLabel htmlFor="name">Conversion Rate (1 USDT)</FormLabel>
                            <Input
                                id="conversionRate"
                                type="number"
                                value={conversionRate}
                                onChange={(e) => setConversionRate(e.target.value)}
                            />
                        </FormControl>

                        <FormControl
                        >
                            <FormLabel htmlFor="name">Notes</FormLabel>
                            <Input
                                id="description"
                                type="text"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </FormControl>
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button
                        isLoading={loading}
                        onClick={() => onSubmit()}
                        colorScheme='blue' mr={3}
                    >
                        Approve
                    </Button>
                    <Button
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </ModalFooter>

            </ModalContent>
        </Modal>
    )
}

export default PartialPrefundModal;