import React, { useState } from "react";
import {
    Flex,
    Icon,
    Text,
    useColorModeValue,
    Radio,
} from "@chakra-ui/react";
import { MdTimer } from "react-icons/md";
import moment from "moment";
import { isEmpty } from "lodash";

import { callApiWithToken } from "../../utils/utils";
import approvedIcon from '../../icons/notification/approved.svg';
import cursorIcon from '../../icons/notification/cursor.svg';
import emailIcon from '../../icons/notification/email.svg';
import pendingIcon from '../../icons/notification/pending.svg';
import submitIcon from '../../icons/notification/submit.svg';
import warningIcon from '../../icons/notification/warning.svg';
import rejectIcon from '../../icons/notification/rejected.svg';

function NotificationDropdown(props) {
    const { setUnreadNotificationsCount, notificationData } = props;
    const { title, description, createdAt, readStatus = false, _id: id, hyperlink, metaData = {} } = notificationData;
    const { icon = '' } = metaData;

    const iconsObject = {
        'approved': approvedIcon,
        'cursor': cursorIcon,
        'email': emailIcon,
        'pending': pendingIcon,
        'submit': submitIcon,
        'warning': warningIcon,
        'rejected': rejectIcon,
    }

    const [notificationStatus, setNotificationStatus] = useState(readStatus);

    let boxBg = useColorModeValue("white !important", "#111c44 !important");
    let mainText = useColorModeValue("gray.800", "white");

    const differenceInDays = moment(new Date()).diff(moment(createdAt), 'd');
    const differenceInHours = moment(new Date()).diff(moment(createdAt), 'h');
    const differenceInMins = moment(new Date()).diff(moment(createdAt), 'm');

    const getTime = () => {
        if (differenceInDays) {
            const dayUnit = differenceInDays > 1 ? 'days' : 'day';
            return `${differenceInDays} ${dayUnit} ago`;
        } else if (differenceInHours) {
            const hourUnit = differenceInHours > 1 ? 'hours' : 'hour';
            return `${differenceInHours} ${hourUnit} ago`;
        } else {
            const minUnit = differenceInMins > 1 ? 'mins' : 'min';
            return `${differenceInMins} ${minUnit} ago`;
        }
    }

    const updateReadStatus = async () => {
        setNotificationStatus(!notificationStatus);
        const response = await callApiWithToken("POST", '/api/notifications/updateRead', { status: !notificationStatus, id }) || {};
        const { unreadNotifications = 0 } = response;
        setUnreadNotificationsCount(unreadNotifications);
    }

    return (
        <Flex
            borderRadius='20px'
            bg={boxBg}
            w={{ base: "315px", md: "345px" }}
            direction='column'>
            <Flex
                position={'relative'}
                w='100%'
                p='20px'
                height='100%'
                direction='column'>
                <Flex>
                    {iconsObject[icon] ? <img src={iconsObject[icon]} height="15px" width="15px" style={{ marginRight: "8px" }} /> : <></>}
                    <Text
                        color='black'
                        lineHeight='24px'
                        pe='40px'
                        fontWeight='600'
                        mb='auto'>
                        {title}
                    </Text>
                </Flex>
                <Text
                    onClick={() => !isEmpty(hyperlink) && window.open(hyperlink, '_self')}
                    textDecoration='underline'
                    fontSize='sm'
                    color='gray.500'
                    lineHeight='24px'
                    pe='40px'
                    fontWeight='500'
                    mb='auto'>
                    {description}
                </Text>
                <Flex>
                    <Flex mt='10px'>
                        <Icon as={MdTimer} w='20px' h='20px' me='6px' color='green.400' />
                        <Text color={mainText} fontSize='sm' my='auto' fontWeight='500'>
                            {getTime()}
                        </Text>
                    </Flex>
                </Flex>
                <Radio title={!notificationStatus ? 'Mark as read' : 'Mark as unread'} isChecked={!notificationStatus} onClick={updateReadStatus} position={'absolute'} right={'20px'} top={'20px'} ></Radio>
            </Flex>
        </Flex>
    );
}

export default NotificationDropdown;