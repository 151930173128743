import React, { useEffect } from "react";
import { isEmpty, unset } from "lodash";
import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent } from "@chakra-ui/react";
import { DrawerCloseButton, Input, Button } from "@chakra-ui/react";
import Select from "react-select";

import DateRangePicker from "../Common/DateRangePicker";

const SettlementFilter = (props) => {
    const { isOpen, onClose, setFilters, setDateRange, dateRange, handleOnReset, timezone } = props;
    const { inputData, setInputData, setFormValues, formValues, filters = {}, } = props;

    useEffect(() => {
        setFormValues(filters);
    }, [filters, timezone]);

    useEffect(() => {
        setFormValues(filters);
    }, []);

    const handleOnChanges = (key, value) => {
        setInputData({
            ...inputData,
            [key]: value,
        });
        let newValue;
        if (Array.isArray(value)) newValue = value.map((data) => data.value);
        else newValue = value.trim();
        setFormValues({
            ...formValues,
            [key]: newValue,
        });
    };

    const onChangeDateRange = (start, end) => {
        setDateRange({ startDate: start, endDate: end });
        setFormValues({
            ...formValues,
            ["dateRange"]: { startDate: start, endDate: end },
        });
    };

    const handleOnClear = () => {
        onClose();
        handleOnReset();
    };

    const handleOnFilter = () => {
        Object.keys(formValues).map((key) => {
            if (isEmpty(formValues[key])) unset(formValues, key);
        });
        setFilters(formValues);
        onClose();
    };

    const transferCurrencyOptions = [
        { label: "ALL", value: "ALL" },
        { label: "PHP", value: "PHP" },
        { label: "IDR", value: "IDR" },
        { label: "GHS", value: "GHS" }, 
        { label: "TZS", value: "TZS" }, 
        { label: "ZMW", value: "ZMW" }, 
        { label: "UGX", value: "UGX" }
    ];

    return (
        <>
            <Drawer onClose={onClose} isOpen={isOpen} size={"sm"}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader style={{ color: "#3182ce", fontFamily: "arial" }}>Filters</DrawerHeader>
                    <DrawerBody>
                        <div style={{ margin: "12px 0" }}>
                            <DateRangePicker
                                width={"100%"}
                                onChangeDateRange={onChangeDateRange}
                                start={dateRange?.startDate}
                                end={dateRange?.endDate}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Input
                                placeholder="Settlement ID"
                                style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                                type="text"
                                value={formValues?.settlementId}
                                onChange={(e) => handleOnChanges("settlementId", e.target.value)}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isSearchable={false}
                                placeholder="Transfer Fiat Currency"
                                options={transferCurrencyOptions}
                                value={{label: inputData?.currency, value: inputData?.currency}}
                                onChange={(e) => handleOnChanges("currency", e?.value)}
                            />
                        </div>

                        <div style={{ marginTop: "30px" }}>
                            <Button onClick={() => handleOnFilter()} variant={"outline"} colorScheme="blue">
                                Filter
                            </Button>
                            <Button
                                onClick={() => handleOnClear()}
                                variant={"outline"}
                                colorScheme="red"
                                style={{ marginLeft: "30px" }}
                            >
                                Clear
                            </Button>
                        </div>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default SettlementFilter;
