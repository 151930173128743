import { Flex } from "@chakra-ui/react";
import loader from "../icons/loader.gif";

const Loader = () => {
    return (
        <Flex
            position="fixed"
            top={0}
            left={0}
            bottom={0}
            right={0}
            justifyContent="center"
            alignItems="center"
            backgroundColor="rgba(256,256,256,0.1)"
            zIndex="9999"
        >
            <img style={{width: "60px"}} src={loader} />
        </Flex>
    );
}

export default Loader;