import React, { useEffect } from "react";
import { isEmpty, unset, find } from "lodash";
import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent } from "@chakra-ui/react";
import { DrawerCloseButton, Input, Button } from "@chakra-ui/react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import DateRangePicker from "../../Common/DateRangePicker";

const SettlementFilter = (props) => {
    const { t } = useTranslation();
    const { isOpen, onClose, setFilters, setDateRange, dateRange, handleOnReset } = props;
    const { setFormValues, formValues, filters = {}, } = props;

    useEffect(() => {
        setFormValues(filters);
    }, [filters]);

    useEffect(() => {
        setFormValues(filters);
    }, []);

    const onChangeDateRange = (start, end) => {
        setDateRange({ startDate: start, endDate: end });
        setFormValues({
            ...formValues,
            ["dateRange"]: { startDate: start, endDate: end },
        });
    };

    const handleOnClear = () => {
        onClose();
        handleOnReset();
    };

    const handleOnFilter = () => {
        Object.keys(formValues).map((key) => {
            if (isEmpty(formValues[key])) unset(formValues, key);
        });
        setFilters(formValues);
        onClose();
    };

    const handleOnChanges = (key, value) => {
        if (["status"].includes(key)) {
            setFormValues({
                ...formValues,
                [key]: value.value,
            });
        } else {
            setFormValues({
                ...formValues,
                [key]: value,
            });
        }
    };

    const statusList = [
        { label: "Processed", value: "settlement_completed" },
        { label: "Processing", value: "settlement_created" },
    ];

    return (
        <>
            <Drawer onClose={onClose} isOpen={isOpen} size={"sm"}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader style={{ color: "#3182ce", fontFamily: "arial" }}>{t("default:filter")}</DrawerHeader>
                    <DrawerBody>
                        <div style={{ margin: "12px 0" }}>
                            <DateRangePicker
                                width={"100%"}
                                onChangeDateRange={onChangeDateRange}
                                start={dateRange?.startDate}
                                end={dateRange?.endDate}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Input
                                placeholder={t("default:email_id")}
                                style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                                type="text"
                                value={formValues.email}
                                onChange={(e) => handleOnChanges("email", e.target.value)}
                            />
                        </div>

                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isSearchable={true}
                                placeholder={t("transactions:status")}
                                options={statusList}
                                value={find(statusList, { value: formValues.status })}
                                onChange={(e) => handleOnChanges("status", e)}
                            />
                        </div>

                        <div style={{ marginTop: "30px" }}>
                            <Button onClick={() => handleOnFilter()} variant={"outline"} colorScheme="blue">
                                {t("default:filter")}
                            </Button>
                            <Button
                                onClick={() => handleOnClear()}
                                variant={"outline"}
                                colorScheme="red"
                                style={{ marginLeft: "30px" }}
                            >
                                {t("default:clear")}
                            </Button>
                        </div>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default SettlementFilter;
