import React, { useEffect, useState, useRef, useContext } from "react";
import { startCase, isEmpty } from "lodash";
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, HStack } from "@chakra-ui/react";
import { Heading, ModalCloseButton, Divider, useDisclosure, Button, Box, useClipboard, Badge } from "@chakra-ui/react";
import ReactJson from "react-json-view";

import { BiShow, BiCopy } from "react-icons/bi";
import { VscJson } from "react-icons/vsc";
import TransfiGrid from "../../utils/TransfiGrid";
import { EnvTypeContext } from "../../context/EnvTypeContext";

export default function Webhook() {
    const [jsonData, setJsonData] = useState({});
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { onCopy, value, setValue, hasCopied } = useClipboard("");
    const { env } = useContext(EnvTypeContext);
    const gridRef = useRef();

    useEffect(() => {
        refreshGrid;
    }, [env]);

    const refreshGrid = () => {
        gridRef.current.refreshGrid();
    };

    const handleOnClick = (value) => {
        onOpen();
        setJsonData(value);
        setValue(JSON.stringify(value));
    };

    const columns = [
        {
            Header: "Object Id",
            accessor: "_id",
            width: "100",
            Cell: ({ value }) => <strong>{!isEmpty(value) ? value : "NA"}</strong>,
        },
        {
            Header: "View JSON Data",
            accessor: "id",
            width: "130",
            Cell: ({ row }) => {
                return (
                    <BiShow
                        style={{
                            fontSize: "22px",
                            color: "#2b6cb0",
                            cursor: "pointer",
                            margin: "0 20%",
                        }}
                        onClick={() => handleOnClick(row?.original)}
                    ></BiShow>
                );
            },
        },
        {
            Header: "Entity Type",
            accessor: "entityType",
            width: "200",
            Cell: ({ value }) => <strong>{!isEmpty(value) ? startCase(value) : "NA"}</strong>,
        },
        {
            Header: "Entity Id",
            accessor: "entityId",
            Cell: ({ value }) => <strong>{!isEmpty(value) ? value : "NA"}</strong>,
        },
        {
            Header: "Status",
            accessor: "status",
            width: "130",
            Cell: ({ value }) => <Badge colorScheme={"purple"}>{startCase(value)}</Badge>,
        },
    ];

    return (
        <>
            <Box>
                <Heading as="h6" size="md" mt="5" ml="2" color={"#3182ce"}>
                    Webhook Events
                </Heading>

                <TransfiGrid
                    ref={gridRef}
                    columns={columns}
                    dataUrl={"/api/webhooks/eventsList"}
                    selectRow={false}
                ></TransfiGrid>

                <Modal isOpen={isOpen} onClose={onClose} size="3xl">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            <HStack>
                                <VscJson />
                                <span>JSON Format</span>
                            </HStack>
                        </ModalHeader>
                        <ModalCloseButton top={3} />
                        <Divider />
                        <ModalBody>
                            <div
                                style={{
                                    height: "600px",
                                    overflow: "auto",
                                    border: "1px solid #ccc",
                                    background: "#F4F4F4",
                                    padding: "20px",
                                }}
                            >
                                <ReactJson src={jsonData} displayDataTypes={false} displayObjectSize={true} />
                            </div>
                        </ModalBody>
                        <Divider />
                        <ModalFooter>
                            <HStack gap="5" width={"65%"}>
                                <Button
                                    leftIcon={<BiCopy />}
                                    variant={"solid"}
                                    colorScheme="blue"
                                    onClick={onCopy}
                                    width={"25%"}
                                >
                                    {hasCopied ? "Copied" : "Copy"}
                                </Button>
                                <Button onClick={onClose} variant={"outline"} colorScheme="blue" width={"25%"}>
                                    Exit
                                </Button>
                            </HStack>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
        </>
    );
}
