import { callApiWithToken, callApiWithTokenMultipart } from '../../utils/utils';
import { getOrgId } from '../../components/App/useToken';

/* Before making the POST call, get the latest step status value, and set flag `skipUpdateStepCount` based on 1st time save or update */
const checkStepStatus = async () => {
    const { kybStatus, lastSavedStep } = await getKybStatus();

    const stepStatus = { skipUpdateStepCount: false };
    if (lastSavedStep === 4) {
        stepStatus["skipUpdateStepCount"] = true;
    }
    return stepStatus;
}

export const getKYBBusinessDetails = async () => {
    return callApiWithToken("GET", '/api/developers/kyb/businessDetails')
        .catch((responseError) => {
            return responseError;
        });
};

export const postKYBBusinessDetails = async (data) => {
    const stepState = await checkStepStatus();
    return callApiWithToken("POST", '/api/developers/kyb/businessDetails', { ...data, ...stepState })
        .catch((responseError) => {
            return responseError;
        });
};

export const getKybCompanyDetails = () => {
    return callApiWithToken("GET", '/api/developers/kyb/companyDetails')
        .catch((responseError) => {
            return responseError;
        });
};
export const postKybCompanyDetails = async (data) => {
    const stepState = await checkStepStatus();
    return callApiWithToken("POST", '/api/developers/kyb/companyDetails', { ...data, ...stepState })
        .catch((responseError) => {
            return responseError;
        });
};


export const getKybShareHolders = () => {
    return callApiWithToken("GET", '/api/developers/kyb/shareHolders')
        .catch((responseError) => {
            return responseError;
        });
};

export const postKybShareHolders = async (data) => {
    const stepState = await checkStepStatus();
    return callApiWithToken("POST", '/api/developers/kyb/shareHolders', { shareHoldersDetails: data, ...stepState })
        .catch((responseError) => {
            return responseError;
        });
}

export const getKybStatus = () => {
    return callApiWithToken("GET", '/api/customers/kybStatus')
        .catch((responseError) => {
            return responseError;
        });
};

export const getKybBusinessDocs = () => {
    return callApiWithToken("GET", '/api/developers/kyb/businessDocs')
        .catch((responseError) => {
            return responseError;
        });
};

export const postKybBusinessDocs = async (data) => {
    const stepState = await checkStepStatus();
    return callApiWithToken("POST", '/api/developers/kyb/businessDocs', { ...data, ...stepState })
        .catch((responseError) => {
            return responseError;
        });
}

export const uploadDocs = (formData) => {
    return callApiWithTokenMultipart("POST", '/api/upload', formData)
        .catch((responseError) => {
            return responseError;
        });
}

export const deleteDocs = (data) => {
    const { fileName } = data;
    const orgId = getOrgId();

    return callApiWithToken("DELETE", `/api/upload/${orgId}/${fileName}`)
        .catch((responseError) => {
            return responseError;
        });
}