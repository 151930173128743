import React, { useState, useContext } from "react";
import { upperCase } from "lodash";
import Loader from "react-js-loader";
import { Box, Text } from "@chakra-ui/react";

import { Link } from "react-router-dom";
import { BsFillCartPlusFill, BsCartDashFill } from "react-icons/bs";
import { SettingsIcon } from "@chakra-ui/icons";

export default function ComplianceCards() {
	const [loading, setLoading] = useState(false);

	const ordersDashboards = [
		{ dashboard: "Buy Orders", icon: BsFillCartPlusFill, link: "/compliance/buy-order" },
		{ dashboard: "Sell Orders", icon: BsCartDashFill, link: "/compliance/sell-order" },
		{ dashboard: "Chainalysis Pre-Screnning", icon: SettingsIcon, link: "/compliance/chainalysis-pre-screening" },
	];

	const OrdersDashboard = (data) => {
		const details = data?.data;
		return <>

			<div className="balance-card-zoom" style={{ width: '250px', padding: '15px 20px', boxShadow: '0 4px 10px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)', borderRadius: '4px', height: '130px' }} >
				<Link to={`${details.link}`}>
					<div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px' }} >

						<div>
							<h3 style={{ fontSize: '20px', fontWeight: '600' }}>{upperCase(details.dashboard)}</h3>
						</div>
						<div style={{ width: '70px', height: '70px', borderRadius: '50%', display: 'flex', justifyContent: 'center' }}><details.icon style={{ fontSize: '50px' }} /></div>
					</div>
				</Link>

			</div>

		</>
	}

	const renderTransfiDashboards = () => {
		const transfiDashboards = Object.keys(ordersDashboards).sort();

		return <div style={{ display: 'flex', justifyContent: 'left', padding: '20px 10px', flexWrap: 'wrap', gap: '4.45%' }}>
			{transfiDashboards.map((value, idx) => {
				return <div key={idx} style={{ marginBottom: '40px' }}><OrdersDashboard data={ordersDashboards[value]} /></div>
			})}
		</div>
	}

	return !loading ? (
		<>
			<Box w="100%" p={4}>
				<div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '20px', padding: '0 10px', marginBottom: '5px' }}>
					<Text fontWeight={500}>Orders Dashboards</Text>

				</div>
				<hr style={{ margin: '0 8px', marginBottom: '16px' }} />
				{renderTransfiDashboards()}
			</Box>

		</>
	) : (
		<Loader
			type="spinner-cub"
			bgColor={"#FFFFFF"}
			title={"Loading..."}
			color={"cyan.400"}
			size={100}
		/>
	);
};
