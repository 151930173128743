import React, { useContext, useEffect, useState } from 'react'
import { useToast, FormControl,Input,Button, FormLabel, Flex, Text } from "@chakra-ui/react";
import Select from 'react-select'
import { useFormik } from 'formik';
import { callApiWithToken } from "../../../utils/utils";
import { NavBarContext } from '../../../context/NavBarContext';
import { useNavigate } from 'react-router-dom';
import { getEnv } from '../../App/useToken';


const AddNewCurrency = () => {

    const [show,setShow]=useState(false)
    const [feeMode,setFeeMode]=useState(false)
    const [currencies,setCurrencies]=useState([])
    const [paymentCodes,setPaymentCodes]=useState([])
    const [showPaymentTypes, setShowPaymentTypes] = useState(false); // State variable to track whether currency is selected
    const [percentage, setPercentage]=useState(false)
    const [fiatTickerValue, setFiatTickerValue] = useState('');
    const [finalCurrencies,setFinalCurrencies]=useState([])
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const { selectedOrg } = useContext(NavBarContext);
    const navigate=useNavigate();
    const env = getEnv();
    const shouldRenderData = env !== "production" ? selectedOrg.length === 1 && selectedOrg[0] !== 'All' : selectedOrg !== "all";




       
    const typesOptions=[
        {
            value:"buy",label:"Buy"
        },
        {
            value:"sell",label:"Sell"
        },
     
    ]


    const feeModeOptions = [
        { value: 'fixed', label: 'Fixed' },
        { value: 'hybrid', label: 'Hybrid' },
        { value: 'percentage', label: 'Percentage' }
    ];

    const paymentTypesOptions=[
        {
            value:'local',label:"Local",
            
        },
        {
            value:'bank',label:"Bank",

        },
        {
            value:'card',label:"Card",

        }
    ]

       // Function to handle changes in the Fiat ticker input
       const handleFiatTickerChange = (event) => {
        const { value } = event.target;
        setFiatTickerValue(value);
    };


    const handleCurrencySelection = async (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        addNewCurrency.setFieldValue('currencies', selectedValues);
        setShowPaymentTypes(selectedValues.length > 0); // Show payment types when currencies are selected
        setFinalCurrencies(selectedValues);
    };
    
    const handlePaymentTypeSelection = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        addNewCurrency.setFieldValue('paymentTypes', selectedValues);
    };
    
    const handlePaymentCodeSelection = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        addNewCurrency.setFieldValue('paymentCodes', selectedValues);
    };

         //Api call for adding new currency
         const addNewCurrency = useFormik({
            initialValues:{
                fxRate:"",
                feeMode:"",
                baseFee:"",
                fixedCxFee:"",
                tfFee:"",
                cxFee:"",
                discount:"",
                fiatTicker:"",
                type:"",
                fixedTfFee:"",
                fixedBaseFee:"",
                currencies:[],
                paymentCodes:[],
                paymentTypes:[]
            },


            onSubmit: async (formValues) => {

                if (
                    !formValues?.feeMode ||
                    !formValues?.tfFee ||
                    !formValues?.type ||
                    !formValues?.currencies.length ||
                    (formValues?.feeMode === "hybrid" && (
                        !formValues?.fixedTfFee
                    )) || 
                    (formValues?.feeMode !== "percentage" && !fiatTickerValue)
                ) {
                    // Show alert for the required fields

                    toast({
                        title: 'Error!',
                        description: 'Please fill all the required fields',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                        position: 'top-right'
                    });
                    return; // Exit the onSubmit function
                }
            

                try {
                    const response = await callApiWithToken("POST", "/api/admin/finance/feeConfigs/addNewCurrency", {
                        baseFee: formValues?.feeMode === "fixed" ? String(Number(formValues?.cxFee) + Number(formValues?.tfFee)) : String((Number(formValues?.cxFee + formValues?.tfFee)/100)),
                        ...(formValues?.feeMode === "hybrid" && {
                            fixedBaseFee: String(formValues?.fixedCxFee + formValues?.fixedTfFee),
                            fixedCxFee: String(formValues?.fixedCxFee),
                            fixedTfFee: String(formValues?.fixedTfFee)
                        }),
                        cxFee: formValues?.feeMode === "fixed" ? String(formValues?.cxFee) : String((Number(formValues?.cxFee) / 100)),
                        tfFee: formValues?.feeMode === "fixed" ? String(formValues?.tfFee) : String((Number(formValues?.tfFee) / 100)),
                        fxRate: String((Number(formValues?.fxRate) / 100)),
                        discount: String((Number(formValues?.discount) / 100)),
                        paymentCodes: formValues?.paymentCodes,
                        paymentTypes: formValues?.paymentTypes,
                        feeMode: formValues?.feeMode,
                        type: formValues?.type,
                        currencies: formValues?.currencies,
                        ...(formValues?.feeMode !== "percentage" && { fiatTicker: fiatTickerValue.toUpperCase() }),
                        id:Math.floor(Math.random() * 1000),
                        selectedOrg,
                    });
    
                    if(!response?.status || !response?.message){
    
                        toast({
                            title: 'Error!',
                            description: response?.message || "Data with this id already exists",
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                            position: 'top-right'
                        });
    
    
                    }
        
                    if (response?.message) {
                        toast({
                            title: 'Success',
                            description: response?.message,
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                            position: 'top-right'
                        });
                    }
                            addNewCurrency?.resetForm()
                            navigate("/admin/pricing")
        
                } catch (err) {
                    toast({
                        title: 'Error!',
                        description: err?.message || "Failed to fetch",
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                        position: 'top-right'
                    });
        
                }
            },
            enableReinitialize: true
        });
    

    useEffect(() => {
        if (addNewCurrency.values.feeMode === "hybrid") {

            setShow(true)

        }else{
            setShow(false)
        }
    }, [addNewCurrency.values.feeMode]);

    useEffect(() => {
        if (addNewCurrency.values.feeMode === "percentage" ) {

            setFeeMode(false)

        }else{
            setFeeMode(true)
        }
    }, [addNewCurrency.values.feeMode]);

    useEffect(() => {
        if (addNewCurrency.values.feeMode === "percentage" || addNewCurrency.values.feeMode === "hybrid"  ) {

            setPercentage(false)

        }else{
            setPercentage(true)
        }
    }, [addNewCurrency.values.feeMode]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await callApiWithToken("GET", "/api/admin/finance/feeConfigs/getAllCurrencies");
                setCurrencies(response?.data);
                setLoading(false);
            } catch (err) {
                console.error(err); // Log the error for debugging
                toast({
                    title: 'Error!',
                    description: err?.message || "Failed to fetch order details.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                });
                setLoading(false);
            }
        };
    
        fetchData(); // Invoke the async function immediately
    }, []);
   
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await callApiWithToken("POST", "/api/admin/finance/feeConfigs/getAllPaymentCodes", {
                    currencies: finalCurrencies
                });
                setPaymentCodes(response?.data);
                setLoading(false);
            } catch (err) {
                console.error(err); // Log the error for debugging
                toast({
                    title: 'Error!',
                    description: err?.message || "Failed to fetch order details.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                });
                setLoading(false);
            }
        };
    
        fetchData();
    }, [finalCurrencies]); // Include addNewCurrency and currenciesObj in the dependency array
    
    

    let currenciesObj = [];
    const currencySet = new Set();
    
    currencies[0]?.forEach(currency => {
        if (!currencySet.has(currency)) {
            currencySet.add(currency);
            currenciesObj.push({
                label: currency,
                value: currency
            });
        }
    });
    

    let paymentCodesArray = [];
    let uniquePaymentCodesSet = new Set(paymentCodes[0]);
    
    uniquePaymentCodesSet.forEach(code => {
        paymentCodesArray.push({
            label: code,
            value: code
        });
    });

    useEffect(()=>{

        addNewCurrency?.resetForm()

    },[selectedOrg])


    console.log(fiatTickerValue)

  return shouldRenderData ?  <div className='p-[2rem] px-[3rem] w-[80%]'>
         
  <h2 className='text-2xl font-semibold my-8 uppercase '>Add new currency</h2>

 <div className='grid grid-cols-3 gap-6'>

<FormControl isRequired >
    <FormLabel>Currencies</FormLabel>
<Select
              id="currencies"
              placeholder="Select currencies"
              options={currenciesObj}
              onChange={handleCurrencySelection}
              value={currenciesObj.find(option => addNewCurrency?.initialValues?.currencies?.includes(option.value))}
              isMulti
          />
</FormControl>


<FormControl isRequired>
<FormLabel> Product </FormLabel>
          <Select
              id="type"
              placeholder="Select type"
              options={typesOptions}
              onChange={(selectedOption) => addNewCurrency.setFieldValue('type', selectedOption.value)}
              value={typesOptions.find(option => option.value === addNewCurrency.values.type)}
          />
      </FormControl>


      <FormControl isRequired  >
      <FormLabel>FeeMode</FormLabel>

          <Select
              id="feeMode"
              options={feeModeOptions}
              placeholder="Select fee mode"
              onChange={(selectedOption) => addNewCurrency.setFieldValue('feeMode', selectedOption.value)}
              value={feeModeOptions.find(option => option.value === addNewCurrency.values.feeMode)}
          />
      </FormControl>

      {showPaymentTypes && (
              <FormControl >
                    <FormLabel>Payment Types</FormLabel>

                  <Select
                      id="paymentTypes"
                      placeholder="Select payment types"
                      options={paymentTypesOptions}
                      onChange={handlePaymentTypeSelection}
                      value={paymentTypesOptions.find(option => addNewCurrency?.initialValues?.paymentTypes?.includes(option.value))}
                      isMulti
                  />
              </FormControl>
          )}

      

      {showPaymentTypes && (
              <FormControl>
                    <FormLabel>Payment Codes</FormLabel>

                  <Select
                      id="paymentCodes"
                      placeholder="Select payment codes"
                      options={paymentCodesArray}
                      onChange={handlePaymentCodeSelection}
                      value={paymentCodesArray.find(option => addNewCurrency?.initialValues?.paymentCodes?.includes(option.value))}
                      isMulti
                  />
              </FormControl>
          )}

 </div>

<div className='space-y-4 my-4'>



<Flex>
<FormControl mr={4}>
  <FormLabel> Fee to customer </FormLabel>
  <Flex>
      <Input borderColor={'gray.400'}  id="cxFee" type='number' onChange={addNewCurrency.handleChange} color={'black'}  />
      {
        !percentage ? <Input  type='text' value={"%"} color={'black'} isDisabled ml={2} borderColor={'transparent'} /> : <Input  type='text' placeholder='Fiat Ticker'  onChange={(e)=>setFiatTickerValue(e.target.value)} borderColor={'gray.400'} value={fiatTickerValue.toUpperCase()} color={'black'}  ml={2} />
      }
  </Flex>
</FormControl>

{show && (
  <FormControl>
      <FormLabel> Fixed cx fee </FormLabel>
      <Flex>
          <Input borderColor={'gray.400'}  id="fixedCxFee" type='number' onChange={addNewCurrency.handleChange} color={'black'}  />
          <Input  id="" type='text' placeholder='Fiat Ticker'  borderColor={'gray.400'} onChange={(e)=>setFiatTickerValue(e.target.value)} value={fiatTickerValue.toUpperCase()} color={'black'} ml={2}  />
      </Flex>
  </FormControl>
)}
      </Flex>

      


      <Flex>
<FormControl mr={4} isRequired>
  <FormLabel> Fee to transfi </FormLabel>
  <Flex>
      <Input borderColor={'gray.400'}  id="tfFee" type='number' onChange={addNewCurrency.handleChange} color={'black'}  />
      {
        !percentage ? <Input  type='text' value={"%"} color={'black'} isDisabled ml={2} borderColor={'transparent'} /> : <Input  type='text' placeholder='Fiat Ticker' isDisabled   borderColor={'transparent'} value={fiatTickerValue.toUpperCase()} color={'black'}  ml={2} />
      }  </Flex>
</FormControl>

{show && (
  <FormControl isRequired>
      <FormLabel> Fixed tf fee </FormLabel>
      <Flex>
          <Input borderColor={'gray.400'}  id="fixedTfFee" type='number' onChange={addNewCurrency.handleChange} color={'black'}  />
          <Input  id="" type='text' placeholder='Fiat Ticker' isDisabled  borderColor={'transparent'} value={fiatTickerValue.toUpperCase()} color={'black'} ml={2} />
      </Flex>
  </FormControl>
)}
      </Flex>


     
<Flex>
<FormControl mr={4}>
  <FormLabel> Fee to user </FormLabel>
  <Flex>
      <Input   id="baseFee" type='number' onChange={addNewCurrency.handleChange} color={'black'} isDisabled borderColor={'transparent'} placeholder='Auto Calculated' />
      {
        !percentage ? <Input  type='text' value={"%"} color={'black'} isDisabled ml={2} borderColor={'transparent'} /> : <Input  type='text' placeholder='Fiat Ticker'  isDisabled value={fiatTickerValue.toUpperCase()} color={'black'} borderColor={'transparent'}  ml={2} />
      }  </Flex>
</FormControl>

{show && (
  <FormControl>
      <FormLabel> Fixed Base fee </FormLabel>
      <Flex>
          <Input  id="fixedBaseFee" type='number' onChange={addNewCurrency.handleChange} color={'black'} isDisabled borderColor={'transparent'} placeholder='Auto Calculated' />
          <Input  id="" type='text' placeholder='Fiat Ticker'  borderColor={'transparent'} isDisabled value={fiatTickerValue.toUpperCase()} color={'black'} ml={2} />
      </Flex>
  </FormControl>
)}
      </Flex>

 



      <Flex>
      <FormControl >
          <FormLabel> Treasury markup </FormLabel>
          <Flex>
              <Input borderColor={'gray.400'}  id="fxRate" type='number' onChange={addNewCurrency.handleChange} color={'black'} />
              <Input  value="%" isDisabled color={'black'} borderColor={'transparent'} />
          </Flex>
      </FormControl>
      

      <FormControl >
          <FormLabel> Discount </FormLabel>
          <Flex>
              <Input borderColor={'gray.400'}  id="discount" type='number' onChange={addNewCurrency.handleChange} color={'black'} />
              <Input  value="%" isDisabled color={'black'} borderColor={'transparent'} />
          </Flex>
      </FormControl>
      </Flex>

      




</div>

<Button
  type="submit"
  isLoading={loading}
  colorScheme='blue'
  mr={3}
  onClick={addNewCurrency.handleSubmit}
>
  Add
</Button>

</div> : <p className='m-6 flex items-center justify-center'>Please select only one organisation to manage the payment routes configuration..</p>
}

export default AddNewCurrency;
