import {
    chakra,
    Divider,
    Modal,
    ModalContent,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    VStack,
    Box,
    Center,
    Flex,
    Heading,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { callApiWithToken } from "../../utils/utils";

const BinanceWidgetModal = (props) => {
    const { isOpen, onClose, paymentUrl, orderId } = props;
    const [eventDetails, setEventDetails] = useState([]);

    useEffect(() => {
        const interval = setInterval(() => {
            getEventsForOrder();
        }, 20000);

        return () => clearInterval(interval);
    }, []);

    const getEventsForOrder = async () => {
        const response = await callApiWithToken("GET", `/api/widget/getEventDetails?orderId=${orderId}`) || {};
        setEventDetails(response?.data || []);
    }

    const WebhookEvent = () => {
        return <>
            {eventDetails.map(event => {
                if (event?.status === "fund_settled") {
                    return <Alert status='success' mt={2} borderRadius="10px">
                        <AlertIcon />
                        <Box>
                            <AlertTitle>Success Event</AlertTitle>
                            <AlertDescription>
                                Payment Successful
                            </AlertDescription>
                        </Box>
                    </Alert>
                } else if (event.status === "fund_failed") {
                    return <Alert status='error' mt={2} borderRadius="10px">
                        <AlertIcon />
                        <Box>
                            <AlertTitle>Failure Event</AlertTitle>
                            <AlertDescription>
                                Payment Failed
                            </AlertDescription>
                        </Box>
                    </Alert>
                } else {
                    return <Alert status='info' mt={2} borderRadius="10px">
                        <AlertIcon />
                        <Box>
                            <AlertTitle>Info Event</AlertTitle>
                            <AlertDescription>
                                Process Going On
                            </AlertDescription>
                        </Box>
                    </Alert>
                }
            })}
        </>
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="6xl">
            <ModalOverlay />
            <ModalContent mt={2}>
                <ModalHeader>Payment Widget</ModalHeader>
                <ModalCloseButton top={4} />
                <Divider />
                <Flex>
                    <VStack bg="#fafafa" width={"75%"} height="100%">
                        <Box
                            w={"75%"}
                            h={"750px"}
                            p={"8px"}
                            width={"100%"}
                        >
                            <chakra.iframe
                                flexGrow={1}
                                width="full"
                                height="100%"
                                src={paymentUrl}
                            ></chakra.iframe>
                        </Box>
                    </VStack>
                    <Center height='750px' paddingLeft={4}>
                        <Divider orientation='vertical' />
                    </Center>
                    <div style={{ width: "25%", display: "block" }}>
                        <div style={{ textAlign: "center", backgroundColor: "#e2e8f0", paddingTop: "5px", paddingBottom: "5px" }}>
                            <Heading size='md'>Webhook Events</Heading>
                            <Divider />
                        </div>
                        <div style={{ display: "flex", flexDirection: 'column-reverse', height: '700px', padding: '10px' }}>
                            <WebhookEvent />
                        </div>
                    </div>
                </Flex>
                <ModalBody>
                </ModalBody>
            </ModalContent >
        </Modal >
    );
};

export default BinanceWidgetModal;
