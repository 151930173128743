import React from 'react';
import Loader from "react-js-loader";
import moment from 'moment';
import { startCase } from 'lodash';
import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useColorModeValue } from '@chakra-ui/react';

export default function ViewComments(props) {
    const { isOpen, onOpen, onClose, comments, isLoading } = props;
    const finalRef = React.useRef(null);

    let mainText = useColorModeValue("gray.800", "white");

    return <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} scrollBehavior={'inside'} size={'2xl'}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>View Comments</ModalHeader>
            <ModalCloseButton />
            {isLoading ? <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={'#FFFFFF'} size={100} />
                :
                <ModalBody pb={6} style={{ maxWidth: '80vh' }}>
                    {comments.map(comment =>
                        <Flex
                            margin={'5'}
                            borderRadius='20px'
                            bg="gray.100"
                            direction='column'>
                            <Flex
                                position={'relative'}
                                w='100%'
                                p='20px'
                                height='100%'
                                direction='column'>
                                <Flex>
                                    <Text
                                        color='black'
                                        lineHeight='24px'
                                        pe='40px'
                                        fontWeight='600'
                                        mb='auto'>
                                        {startCase(comment.status)}
                                    </Text>
                                    <Flex position={'absolute'} right={'20px'} top={'20px'}>
                                        <Text color={mainText} fontSize='sm' my='auto' fontWeight='500'>
                                            {moment(comment.createdAt).format('lll')}
                                        </Text>
                                    </Flex>

                                </Flex>
                                <Text
                                    fontSize='sm'
                                    color='gray.500'
                                    lineHeight='24px'
                                    pe='40px'
                                    fontWeight='500'
                                    mb='auto'>
                                    {comment.description}
                                </Text>
                            </Flex>
                        </Flex>
                    )}
                </ModalBody>}
            <ModalFooter>
                <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
}