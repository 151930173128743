import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Divider,
} from "@chakra-ui/react";

function ViewPayloadModal({ isOpen, onClose, jsonPayload, redirectionUrl, webhookUrl }) {
    let payload = jsonPayload;
    if (redirectionUrl !== "") {
        payload = { ...payload, redirectionUrl };
    }

    if (webhookUrl !== "") {
        payload = { ...payload, webhookUrl };
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Payload</ModalHeader>
                    <ModalCloseButton top={4} />
                    <Divider />
                    <ModalBody marginBottom={4}>
                        <pre
                            style={{
                                backgroundColor: "#f7fafc",
                                borderRadius: "sm",
                                padding: "0.5rem",
                                overflowX: "scroll",
                            }}
                        >{JSON.stringify(payload, null, 2)}</pre>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default ViewPayloadModal;