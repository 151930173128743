/* eslint-disable no-unused-expressions */
import React, { use, useEffect } from 'react';
import {
    Button,
    Divider,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useClipboard
} from '@chakra-ui/react';
import { VscJson } from 'react-icons/vsc';
import ReactJson from 'react-json-view';
import { BiCopy } from 'react-icons/bi';
import { startCase } from 'lodash';

const ViewModal = (props) => {
    const { isOpen, onClose, jsonData } = props;
    const { onCopy, value, setValue, hasCopied } = useClipboard("");

    useEffect(() => { setValue(jsonData) }, [jsonData])
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <HStack>
                        <VscJson />
                        <span>{`${startCase(jsonData.module)} - ${startCase(jsonData.name)}`}</span>
                    </HStack>
                </ModalHeader>
                <ModalCloseButton top={3} />
                <Divider />
                <ModalBody>
                    <div
                        style={{
                            height: "600px",
                            overflow: "auto",
                            border: "1px solid #ccc",
                            background: "#F4F4F4",
                            padding: "20px",
                        }}
                    >
                        <ReactJson src={jsonData} displayDataTypes={false} displayObjectSize={true} />
                    </div>
                </ModalBody>
                <Divider />
                <ModalFooter>
                    <HStack gap="5" width={"65%"}>
                        <Button
                            leftIcon={<BiCopy />}
                            variant={"solid"}
                            colorScheme="blue"
                            onClick={onCopy}
                            width={"25%"}
                        >
                            {hasCopied ? "Copied" : "Copy"}
                        </Button>
                        <Button onClick={onClose} variant={"outline"} colorScheme="blue" width={"25%"}>
                            Exit
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default ViewModal;