/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import {
    Button,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Input,
    useToast,
    Stack,
    Grid,
    Divider,
    Heading,
    chakra,
    FormErrorMessage
} from '@chakra-ui/react';
import Select from "react-select";
import { capitalize, isEmpty } from "lodash";
import { useFormik } from 'formik';
import * as Yup from "yup";

import { callApiWithToken } from '../../../utils/utils';

const OtcPayoutOrdersModal = (props) => {
    const { isOpen, onClose, refreshGrid } = props;

    const [currencyList, setCurrencyList] = useState("");
    const [emailList, setEmailList] = useState("");
    const [cryptoList, setCryptoList] = useState("");
    const [orgList, setOrgList] = useState("");
    const [validation, setValidation] = useState({});

    const [loading, setLoading] = useState(false)
    const toast = useToast();

    useEffect(() => {
        getCurrencyList();
        getCryptoList();
        getIndividualList();
        getOrganizationList();
    }, []);

    const cryptoPartnerList = [
        { label: "OKX", value: "okx" },
        { label: "Huobi", value: "huobi" },
        { label: "Binance", value: "binance" },
        { label: "Bitpace", value: "bitpace" },
        { label: "HNI", value: "hni" },
        { label: "Jupiter", value: "jupiter" },
    ];

    const getCurrencyList = async (search) => {
        const response = await callApiWithToken(
            "GET",
            `/api/orders/configList?name=supported_otc_currencies&module=orders`
        );
        const coList = response?.data?.supportedOtcCurrencies;
        const countryList = coList.map((ls) => {
            return { value: ls, label: ls };
        });
        setCurrencyList(countryList);
    };

    const getCryptoList = async (search) => {
        const response = await callApiWithToken(
            "GET",
            `/api/orders/configList?name=crypto&module=otc`
        );
        const coList = response?.data?.crypto;
        const countryList = coList.map((ls) => {
            return { value: ls.formattedSymbol, label: ls.symbol + "-" + ls.description + (!isEmpty(ls.network) ? (`(${ls.network})`) : "") };
        });
        setCryptoList(countryList);
    };

    const getIndividualList = async (search) => {
        const response = await callApiWithToken(
            "GET",
            `/api/orders/usersList`
        );
        const coList = response?.data;
        const emails = coList.map((ls) => {
            return { value: ls.email, label: ls.email };
        });
        setEmailList(emails);
    };

    const getOrganizationList = async (search) => {
        const response = await callApiWithToken(
            "GET",
            `/api/orders/orgList`
        );
        const coList = response?.data;
        const organization = coList.map((ls) => {
            return { value: ls.orgId, label: ls.name };
        });
        setOrgList(organization);
    };

    const typeList = [
        { label: "Individual", value: "individual" },
        { label: "Business-Self", value: "business-self" },
        { label: "Business-Customer", value: "business-customer" }
    ]

    const closeModal = () => {
        formik.resetForm();
        onClose();
        setLoading(false);
    }

    const formik = useFormik({
        initialValues: {
            date:'',
            cryptoPartner: '',
            currency:'',
            cryptoOrderId:'',
            email: '',
            cryptoAmount:'',
            txnHash:'',
            cryptoTicker: '',
            walletAddress:'',
            type: '',
            org: '',
            cxOrg: ''
        },
        validationSchema: Yup.object().shape({
            date:Yup.string().required("Date is required"),
            cryptoPartner: Yup.object().required("Crypto Partner is required"),
            currency:Yup.object().required("Fiat Currency is required"),
            cryptoOrderId:Yup.string().required("Crypto Order Id is required"),
            cryptoAmount:Yup.string().required("Crypto Amount is required"),
            txnHash:Yup.string().required("Transaction Hash is required"),
            cryptoTicker: Yup.object().required("Crypto Currency is required"),
            walletAddress:Yup.string().required("Wallet Address is required"),
            type: Yup.object().required("Type is required"),
            ...validation
        }),
        onSubmit: async (formValues) => {
            try {
                setLoading(true);
                const response = await callApiWithToken("POST", "/api/orders/otc/sell/create", {
                date:formValues?.date,
                fiatTicker: formValues?.currency?.value,
                cryptoOrderId: formValues?.cryptoOrderId,
                email: formValues?.email?.value,
                cryptoAmount : formValues?.cryptoAmount,
                cryptoTicker: formValues?.cryptoTicker?.value,
                walletAddress: formValues?.walletAddress,
                type: formValues?.type?.value,
                org: formValues?.org?.value,
                customerOrg: formValues?.cxOrg?.value,
                txnHash: formValues?.txnHash ,
                cryptoPartner: formValues?.cryptoPartner?.value
                });
                if (response?.message) {
                    toast({
                        title: 'Success',
                        description: response?.message,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                        position: 'top-right'
                    })
                }
                refreshGrid();
                closeModal();
            } catch (err) {
                setLoading(false);
                toast({
                    title: 'Error!',
                    description: response?.message || "Failed to fetch order details.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
            }
        },
    });

    return (
        <chakra.form onSubmit={formik.handleSubmit} display="flex" flexGrow={1}>
        <Modal isOpen={isOpen} onClose={() => closeModal()} size={'5xl'}>
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>OTC Orders</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={12}>
                    <Stack direction={"column"} justifyContent="space-between" flexGrow={1}>
                        <Grid templateColumns='repeat(3, 1fr)' gap={5}>
                            <FormControl isRequired isInvalid={formik.touched.type && formik.errors.type}>
                                <FormLabel>Type</FormLabel>
                                <Select
                                    id="type"
                                    isSearchable={true}
                                    options={typeList}
                                    value={formik.values.type}
                                    placeholder='Select Type'
                                    onChange={(value) => {
                                        formik.setFieldValue("type", value);
                                        if(value.value === "individual"){
                                            setValidation({email:Yup.object().required("Email is required")})
                                        }
                                        if(value.value === "business-self"){
                                            setValidation({org:Yup.object().required("Organization is required")})
                                        }
                                        if(value.value === "business-customer"){
                                            setValidation({org:Yup.object().required("Organization is required"),
                                        cxOrg:Yup.object().required("Initiator's Organization is required")})
                                        }
                                    }}
                                />
                                 <FormErrorMessage>{formik.errors.type}</FormErrorMessage>
                            </FormControl>
                            { formik?.values?.type?.value === "individual" ? <>
                                <FormControl isRequired isInvalid={formik.touched.email && formik.errors.email}>
                                    <FormLabel>Email</FormLabel>
                                    <Select
                                        id="email"
                                        isSearchable={true}
                                        options={emailList}
                                        value={formik.values.email}
                                        placeholder='Select Email'
                                        onChange={(value) => {
                                            formik.setFieldValue("email", value);
                                        }}
                                    />
                                     <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                                </FormControl>
                            </> : <></>}
                            {formik?.values?.type?.value === "business-self" ? <>
                                <FormControl isRequired isInvalid={formik.touched.org && formik.errors.org}>
                                    <FormLabel>Organization</FormLabel>
                                    <Select
                                        id="org"
                                        isSearchable={true}
                                        options={orgList}
                                        value={formik.values.org}
                                        placeholder='Select Organization'
                                        onChange={(value) => {
                                            formik.setFieldValue("org", value);
                                        }}
                                    />
                                     <FormErrorMessage>{formik.errors.org}</FormErrorMessage>
                                </FormControl>
                            </> : <></>}
                            {formik?.values?.type?.value === "business-customer" ? <>
                                <FormControl isRequired isInvalid={formik.touched.org && formik.errors.org}>
                                    <FormLabel>Organization</FormLabel>
                                    <Select
                                        id="org"
                                        isSearchable={true}
                                        options={orgList}
                                        value={formik.values.org}
                                        placeholder='Select Organization'
                                        onChange={(value) => {
                                            formik.setFieldValue("org", value);
                                        }}
                                    />
                                     <FormErrorMessage>{formik.errors.org}</FormErrorMessage>
                                </FormControl>
                                <FormControl isRequired isInvalid={formik.touched.cxOrg && formik.errors.cxOrg}>
                                    <FormLabel>Initiator's Organization</FormLabel>
                                    <Select
                                        id="cxOrg"
                                        isSearchable={true}
                                        options={orgList}
                                        value={formik.values.cxOrg}
                                        placeholder='Select Initiators Organization'
                                        onChange={(value) => {
                                            formik.setFieldValue("cxOrg", value);
                                        }}
                                    />
                                     <FormErrorMessage>{formik.errors.cxOrg}</FormErrorMessage>
                                </FormControl>
                            </> : <></>}
                        </Grid>
                        <Divider opacity={1} />
                        <Grid templateColumns='repeat(4, 1fr)' gap={3}>
                        <FormControl isRequired isInvalid={formik.touched.date && formik.errors.date}>
                                <FormLabel htmlFor="name">Date</FormLabel>
                                <Input
                                    id="date"
                                    type="datetime-local"
                                    value={formik.values.date}
                                    onChange={ formik.handleChange }
                                />
                                 <FormErrorMessage>{formik.errors.date}</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formik.touched.cryptoTicker && formik.errors.cryptoTicker}>
                                <FormLabel>Crypto Currency</FormLabel>
                                <Select
                                    id="cryptoTicker"
                                    isSearchable={true}
                                    options={cryptoList}
                                    value={formik.values.cryptoTicker}
                                    placeholder='Select Currency'
                                    onChange={(value) => {
                                        formik.setFieldValue("cryptoTicker", value);
                                    }}
                                />
                                 <FormErrorMessage>{formik.errors.cryptoTicker}</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formik.touched.cryptoAmount && formik.errors.cryptoAmount}>
                                <FormLabel>Crypto Amount</FormLabel>
                                <Input
                                    id="cryptoAmount"
                                    type="number"
                                    value={formik.values.cryptoAmount}
                                    onChange={formik.handleChange}
                                />
                                 <FormErrorMessage>{formik.errors.cryptoAmount}</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formik.touched.walletAddress && formik.errors.walletAddress}>
                                <FormLabel>Senders Wallet Address</FormLabel>
                                <Input
                                    id="walletAddress"
                                    type="text"
                                    value={formik.values.walletAddress}
                                    onChange={formik.handleChange}
                                />
                                <FormErrorMessage>{formik.errors.walletAddress}</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formik.touched.txnHash && formik.errors.txnHash}>
                                <FormLabel>Transaction Hash</FormLabel>
                                <Input
                                    id="txnHash"
                                    type="text"
                                    value={formik.values.txnHash}
                                    onChange={formik.handleChange}
                                />
                                <FormErrorMessage>{formik.errors.txnHash}</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formik.touched.currency && formik.errors.currency}>
                                <FormLabel>Fiat Currency</FormLabel>
                                <Select
                                    id="currency"
                                    isSearchable={true}
                                    options={currencyList}
                                    value={formik.values.currency}
                                    placeholder='Select Currency'
                                    onChange={(value) => {
                                        formik.setFieldValue("currency", value);
                                    }}
                                />
                                 <FormErrorMessage>{formik.errors.currency}</FormErrorMessage>
                            </FormControl>
                        </Grid>
                        <Divider opacity={1} />
                        {/* <Heading size={"sm"} marginTop="6" >Crypto Details:</Heading> */}
                        <Grid templateColumns='repeat(4, 1fr)' gap={3}>
                        <FormControl isRequired isInvalid={formik.touched.cryptoPartner && formik.errors.cryptoPartner}>
                                <FormLabel>Crypto Partner</FormLabel>
                                <Select
                                    id="cryptoPartner"
                                    isSearchable={true}
                                    options={cryptoPartnerList}
                                    value={formik.values.cryptoPartner}
                                    placeholder='Select Crypto Partner'
                                    onChange={(value) => {
                                        formik.setFieldValue("cryptoPartner", value);
                                    }}
                                />
                                <FormErrorMessage>{formik.errors.cryptoPartner}</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formik.touched.cryptoOrderId && formik.errors.cryptoOrderId}>
                                <FormLabel>Crypto Order Id</FormLabel>
                                <Input
                                    id="cryptoOrderId"
                                    type="text"
                                    value={formik.values.cryptoOrderId}
                                    onChange={formik.handleChange}
                                />
                                 <FormErrorMessage>{formik.errors.cryptoOrderId}</FormErrorMessage>
                            </FormControl>
                        </Grid>
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button
                        isLoading={loading}
                        onClick={formik.handleSubmit}
                        colorScheme='blue' mr={3}
                    >
                        Create
                    </Button>
                    <Button
                        onClick={() => closeModal()}
                    >
                        Cancel
                    </Button>
                </ModalFooter>

            </ModalContent>
        </Modal>
        </chakra.form>
    )
}

export default OtcPayoutOrdersModal;