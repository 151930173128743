import React, { useEffect, useState, createContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import NavBar from '../src/components/Nav/NavBarV2';
import { useToken } from './components/App/useToken';
import { callApiWithToken } from './utils/utils';
import Notifications from 'react-notify-toast';
import Router from "./Router";
import { LicenseManager } from 'ag-grid-enterprise';
import './Styles.css';
import { getDataFromSession, saveDataToSession } from './utils/session-storing-utils';
import { EnvTypeProvider } from './context/EnvTypeContext';
import { NavBarContextProvider } from './context/NavBarContext';
import { TimezoneProvider } from './context/TimezoneContext';
import { LanguageProvider } from './context/LanguageContext';
import UnauthorizedRoutes from './UnauthorizedRoutes';
import Loader from './utils/Loader';


function App() {
  const { token, setToken } = useToken();
  const [loading, setLoading] = useState(true);
  LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY);

  useEffect(() => {
    const token = localStorage.getItem("token") || "";
    const refreshToken = localStorage.getItem("refreshToken") || "";

    async function getcustomerDetails() {
      try {
        const response = await callApiWithToken("GET", "/api/customers/getCustomer") || {};
        const { data: customerData = {} } = response;
        const { status = '', defaultTimezone = '' } = customerData;
        await setToken(customerData);
        saveDataToSession('connectionData', 'envType', status);
        saveDataToSession("userData", "status", status);
        saveDataToSession("connectionData", "timezone", defaultTimezone);
        setLoading(false);
      } catch (error) {
        setError(
          error.message || "An error occurred while fetching data."
        );
      }
    }

    if (token && refreshToken) {
      getcustomerDetails();
      setInterval(async () => {
        await callApiWithToken("GET", '/api/customers/heartBeat');
      }, 300000);
    }
  }, []);

  if (!token) {
    return <BrowserRouter>
      <UnauthorizedRoutes setToken={setToken} />
    </BrowserRouter>
  }

  if (loading) {
    return <Loader />
  }

  return (
    <EnvTypeProvider>
      <LanguageProvider>
        <TimezoneProvider>
          <BrowserRouter>
            <div className='parentRouterDiv'>
              <Notifications />
              <NavBarContextProvider>
                <div className='menu'>
                  <NavBar />
                </div>
                <Router />
              </NavBarContextProvider>
            </div>
          </BrowserRouter>
        </TimezoneProvider>
      </LanguageProvider>
    </EnvTypeProvider>
  );
}

export default App;