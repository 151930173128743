import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Grid, GridItem } from '@chakra-ui/react';

import TransfiGrid from './TransfiGrid';

function SplitView(props, ref) {
    const { condensed, children, ...gridProps } = props;

    const gridRef = useRef();

    useImperativeHandle(ref, () => ({
        ...gridRef && gridRef.current
    }))

    return (
        <div style={{backgroundColor: 'white', padding: '1rem', height:'100%'}}>
            <div style={{ backgroundColor: 'white', width: '100%', height: '100%'}}>
                <Grid templateColumns='repeat(3, 1fr)'>
                    <GridItem colSpan={condensed ? 1 : 3}>
                        <TransfiGrid
                            {...gridProps}
                            ref={gridRef}
                            compactView={condensed}
                        />
                    </GridItem>
                    {condensed ? <GridItem colSpan={2}><div style={{ zIndex: 1, position: 'relative', backgroundColor: 'white', width: '100%', height: '100%' }}>{children}</div></GridItem> : <></>}
                </Grid>
            </div>
        </div>
    )
}

export default forwardRef(SplitView);
