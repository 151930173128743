/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import {
    Button,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Input,
    useToast,
    Stack,
    Grid,
    chakra,
    FormErrorMessage
} from '@chakra-ui/react';
import Select from "react-select";
import { capitalize, isEmpty } from "lodash";
import { useFormik } from 'formik';
import * as Yup from "yup";

import { callApiWithToken } from '../../../utils/utils';

const EditOtcPayoutModal = (props) => {
    const { isOpen, onClose, refreshGrid, selectedRow } = props;

    const { orderId, fiatTicker,fiatAmount } = selectedRow;

    const [paymentPartnerList, setPaymentPartnerList] = useState("");
    const [paymentMethodList, setPaymentMethodList] = useState("");
    const [loading, setLoading] = useState(false)
    const toast = useToast();

    useEffect(() => {
        getPaymentPartnerList();
    }, [fiatTicker]);

    const getPaymentPartnerList = async () => {
        const currency = fiatTicker
        const response = await callApiWithToken(
            "GET",
            `/api/orders/paymentPartner?currency=${currency}&product=otc_payout`
        );
        const { data = [] } = response;
        let paymentOptions = data.map((pm) => { return { value: pm, label: capitalize(pm) } })
        setPaymentPartnerList(paymentOptions);
    };

    const getPaymentMethodList = async (value) => {
        const paymentGateway = value.value
        const currencyOptions = fiatTicker;
        const response = await callApiWithToken(
            "GET",
            `/api/orders/paymentMethod?currency=${currencyOptions}&product=otc_payout&paymentGateway=${paymentGateway}`
        );
        const { data = [] } = response;
        let paymentOptions = data.map((pm) => { return { value: pm.name, label: pm.name } })
        setPaymentMethodList(paymentOptions);
    };

    const closeModal = () => {
        formik.resetForm();
        onClose();
        setLoading(false);
    }

    const formik = useFormik({
        initialValues: {
            receivedDate: '',
                    date: '',
                    paymentPartner:'',
                    paymentMethod:'',
                    processingFee: '',
                    partnerOrderId: '' ,
                    feeCharged: '',
                    processingFeeAmount:'',
        },
        validationSchema: Yup.object().shape({
            date:Yup.string().required("Date is required"),
            receivedDate:Yup.string().required("Sub Account recieved date is required"),
            paymentPartner: Yup.object().required("Payment Partner is required"),
            paymentMethod: Yup.object().required("Payment Method is required"),
            processingFee:Yup.string().required("Processing Fee is required"),
            processingFeeAmount:Yup.string().required("Processing Amount is required"),
            partnerOrderId:Yup.string().required("Payment Partner Order Id is required"),
            feeCharged:Yup.string().required("Conversion fees is required"),          
        }),
        onSubmit: async (formValues) => {
            try {
                setLoading(true);
                const response = await callApiWithToken("POST", "/api/orders/otc/sell/update", {
                    orderId,
                    receivedDate: formValues?.receivedDate,
                    date: formValues?.date,
                    paymentPartner:formValues?.paymentPartner.value,
                    paymentMethod:formValues?.paymentMethod.value,
                    processingFee: formValues?.processingFee,
                    partnerOrderId: formValues?.partnerOrderId ,
                    feeCharged: formValues?.feeCharged,
                    fiatAmount,
                    fiatTicker,
                    processingFeeAmount: formValues?.processingFeeAmount
                });
                if (response?.message) {
                    toast({
                        title: 'Success',
                        description: response?.message,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                        position: 'top-right'
                    })
                }
                refreshGrid();
                closeModal();
            } catch (err) {
                setLoading(false);
                toast({
                    title: 'Error!',
                    description: response?.message || "Failed to fetch order details.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
                refreshGrid();
                closeModal();
            }
        },
    });

    return (
        <Modal isOpen={isOpen} onClose={() => closeModal()} size={'5xl'}>
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>OTC Orders : {orderId} </ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={12}>
                    <Stack direction={"column"} justifyContent="space-between" flexGrow={1}>
                        <Grid templateColumns='repeat(3, 1fr)' gap={3}>
                        <FormControl isRequired isInvalid={formik.touched.date && formik.errors.date}>
                                <FormLabel htmlFor="name">Order Completed Date</FormLabel>
                                <Input
                                    id="date"
                                    type="datetime-local"
                                    value={formik.values.date}
                                    onChange={ formik.handleChange }
                                />
                                 <FormErrorMessage>{formik.errors.date}</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formik.touched.receivedDate && formik.errors.receivedDate}>
                                <FormLabel htmlFor="name">Sub Account Delivered Date</FormLabel>
                                <Input
                                    id="receivedDate"
                                    type="datetime-local"
                                    value={formik.values.receivedDate}
                                    onChange={ formik.handleChange }
                                />
                                 <FormErrorMessage>{formik.errors.receivedDate}</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formik.touched.paymentPartner && formik.errors.paymentPartner}>
                                <FormLabel>Payment Partner</FormLabel>
                                <Select
                                    id="paymentPartner"
                                    isSearchable={true}
                                    value={formik.values.paymentPartner}
                                    placeholder='Select Payment Partner'
                                    options={paymentPartnerList}
                                    onChange={(value) => {
                                        formik.setFieldValue("paymentPartner", value);
                                        getPaymentMethodList(value);
                                    }}
                                />
                                 <FormErrorMessage>{formik.errors.paymentPartner}</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formik.touched.paymentMethod && formik.errors.paymentMethod}>
                                <FormLabel>Payment Method</FormLabel>
                                <Select
                                    id="paymentMethod"
                                    isSearchable={true}
                                    value={formik.values.paymentMethod}
                                    placeholder='Select Method'
                                    options={paymentMethodList}
                                    onChange={(value) => {
                                        formik.setFieldValue("paymentMethod", value);
                                    }}
                                />
                                 <FormErrorMessage>{formik.errors.paymentMethod}</FormErrorMessage>
                            </FormControl>
                            {/* <FormControl isRequired isInvalid={formik.touched.fiatAmount && formik.errors.fiatAmount}>
                                <FormLabel htmlFor="name">Gross Fiat Amount</FormLabel>
                                <Input
                                    id="fiatAmount"
                                    type="number"
                                    value={formik.values.fiatAmount}
                                    onChange={formik.handleChange}
                                />
                                 <FormErrorMessage>{formik.errors.fiatAmount}</FormErrorMessage>
                            </FormControl> */}
                            <FormControl isRequired isInvalid={formik.touched.processingFee && formik.errors.processingFee}>
                                <FormLabel>Processing Fee(%)</FormLabel>
                                <Input
                                    id="processingFee"
                                    type="text"
                                    value={formik.values.processingFee}
                                    onChange={formik.handleChange}
                                />
                                 <FormErrorMessage>{formik.errors.processingFee}</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formik.touched.processingFeeAmount && formik.errors.processingFeeAmount}>
                                <FormLabel>Processing Fee Amount</FormLabel>
                                <Input
                                    id="processingFeeAmount"
                                    type="text"
                                    value={formik.values.processingFeeAmount}
                                    onChange={formik.handleChange}
                                />
                                 <FormErrorMessage>{formik.errors.processingFeeAmount}</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formik.touched.partnerOrderId && formik.errors.partnerOrderId}>
                                <FormLabel>Payment Partner Order Id</FormLabel>
                                <Input
                                    id="partnerOrderId"
                                    type="text"
                                    value={formik.values.partnerOrderId}
                                    onChange={formik.handleChange}
                                />
                                 <FormErrorMessage>{formik.errors.partnerOrderId}</FormErrorMessage>
                            </FormControl>
                            {/* <FormControl isRequired isInvalid={formik.touched.partnerFees && formik.errors.partnerFees}>
                                <FormLabel htmlFor="name">Payment Partner Fees(%)</FormLabel>
                                <Input
                                    id="partnerFees"
                                    type="text"
                                    value={formik.values.partnerFees}
                                    onChange={formik.handleChange}
                                />
                                <FormErrorMessage>{formik.errors.partnerFees}</FormErrorMessage>
                            </FormControl> */}
                            <FormControl isRequired isInvalid={formik.touched.feeCharged && formik.errors.feeCharged}>
                                <FormLabel htmlFor="name">Fees Charged On Convert Crypto</FormLabel>
                                <Input
                                    id="feeCharged"
                                    type="text"
                                    value={formik.values.feeCharged}
                                    onChange={formik.handleChange}
                                />
                                 <FormErrorMessage>{formik.errors.feeCharged}</FormErrorMessage>
                            </FormControl>
                        </Grid>
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button
                        isLoading={loading}
                        onClick={formik.handleSubmit}
                        colorScheme='blue' mr={3}
                    >
                        Approve
                    </Button>
                    <Button
                        onClick={() => closeModal()}
                    >
                        Cancel
                    </Button>
                </ModalFooter>

            </ModalContent>
        </Modal>
    )
}

export default EditOtcPayoutModal;