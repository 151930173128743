import React, { useState, useContext } from "react";
import { upperCase } from "lodash";
import Loader from "react-js-loader";
import {
	Box,
	Text
} from "@chakra-ui/react";

import { EnvTypeContext } from "../../context/EnvTypeContext";
import { Link } from "react-router-dom";
import { FaHandsHelping, FaHandshake } from "react-icons/fa";
import { MdAccountBalanceWallet, MdOutlineAccountBalanceWallet, MdOutlineAccountBalance } from "react-icons/md";
import { PiHandshakeFill } from "react-icons/pi";
import { RiRefund2Line } from "react-icons/ri";
import { getOrgRoles, getOrgName, getOrgId, getEnv } from "../App/useToken";

export default function PartnerOverview() {

	const [loading, setLoading] = useState(false);

	const env = getEnv();
	const orgRoles = getOrgRoles();
	const orgName = getOrgName();
	const allowableBinanceOrgs = ["binance", "binance_demo", "binance_qa", "binance_prod", "binance_africa", "openpayd"];

	const financeDashboards = [
		{ dashboard: "Balance Overview", icon: MdAccountBalanceWallet, link: "/balance-overview", visible: orgRoles.includes("transfi_admin") },
		{ dashboard: "Balance Dashboard", icon: MdOutlineAccountBalanceWallet, link: "/balance-dashboard", visible: orgRoles.includes("transfi_admin") },
		{ dashboard: "Customer Revshare", icon: FaHandsHelping, link: "/cx-revshare-settlements", visible: !allowableBinanceOrgs.includes(orgName) },
		{ dashboard: "Collections Balance", icon: MdOutlineAccountBalanceWallet, link: "/collections-balance", visible: !allowableBinanceOrgs.includes(orgName) },
		// { dashboard: "My Settlements", icon: FaHandshake, link: "/my-settlements", visible:(!allowableBinanceOrgs.includes(orgName) && !orgRoles.includes("transfi_admin")) },
	];

	const deepIntegrationCxDashbboards = [
		{ dashboard: "Prefunds", icon: MdOutlineAccountBalance, link: "/prefunds", visible: (allowableBinanceOrgs.includes(orgName) || orgRoles.includes("transfi_admin")) },
		{ dashboard: "Settlements", icon: FaHandsHelping, link: "/settlements", visible: (allowableBinanceOrgs.includes(orgName) || orgRoles.includes("transfi_admin")) },
		{ dashboard: "Collections Settlements", icon: FaHandsHelping, link: "/settlements/collections", visible: (allowableBinanceOrgs.includes(orgName) || orgRoles.includes("transfi_admin")) },
		{ dashboard: "Refunds", icon: RiRefund2Line, link: "/refunds", visible: (allowableBinanceOrgs.includes(orgName) || orgRoles.includes("transfi_admin")) },
		{ dashboard: "Crypto Settlement Master", icon: PiHandshakeFill, link: "/crypto-settlement-master", visible: orgRoles.includes("transfi_admin") },
		{ dashboard: "Fiat Settlement Master", icon: PiHandshakeFill, link: "/fiat-settlement-master", visible: orgRoles.includes("transfi_admin") && env != "production" },
	]

	const FinanceDashboard = (data) => {
		const details = data?.data;
		return <>

			{details?.visible && <div className="balance-card-zoom" style={{ width: '250px', padding: '15px 20px', boxShadow: '0 4px 10px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)', borderRadius: '4px', height: '130px' }} >
				<Link to={`${details.link}`}>
					<div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px' }} >

						<div>
							<h3 style={{ fontSize: '20px', fontWeight: '600' }}>{upperCase(details.dashboard)}</h3>
						</div>
						<div style={{ width: '70px', height: '70px', borderRadius: '50%', display: 'flex', justifyContent: 'center' }}><details.icon style={{ fontSize: '50px' }} /></div>
					</div>
				</Link>

			</div>
			}

		</>
	}

	const FinanceDeepIntegrated = (data) => {
		const details = data?.data;
		return <>

			{details?.visible && <div className="balance-card-zoom" style={{ width: '250px', padding: '15px 20px', boxShadow: '0 4px 10px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)', borderRadius: '4px', height: '130px' }} >
				<Link to={`${details.link}`}>
					<div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px' }} >
						<div>

							<h3 style={{ fontSize: '20px', fontWeight: '600' }}>{upperCase(details.dashboard)}</h3>

						</div>
						<div style={{ width: '70px', height: '70px', borderRadius: '50%', display: 'flex', justifyContent: 'center' }}><details.icon style={{ fontSize: '50px' }} /></div>
					</div>
				</Link>

			</div>
			}

		</>
	}

	const renderTransfiDashboards = () => {
		const transfiDashboards = Object.keys(financeDashboards).sort();

		return <div style={{ display: 'flex', justifyContent: 'left', padding: '20px 10px', flexWrap: 'wrap', gap: '4.45%' }}>
			{transfiDashboards.map(value => {
				return <div style={{ marginBottom: '40px' }}><FinanceDashboard data={financeDashboards[value]} /></div>
			})}
		</div>
	}
	const renderDeepIntegrationDashboards = () => {
		const transfiDashboards = Object.keys(deepIntegrationCxDashbboards).sort();

		return <div style={{ display: 'flex', justifyContent: 'left', padding: '20px 10px', flexWrap: 'wrap', gap: '4.45%' }}>
			{transfiDashboards.map(value => {
				return <div style={{ marginBottom: '40px' }}><FinanceDeepIntegrated data={deepIntegrationCxDashbboards[value]} /></div>
			})}
		</div>
	}

	return !loading ? (
		<>
			<Box w="100%" p={4}>
				<div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '20px', padding: '0 10px', marginBottom: '5px' }}>
					<Text fontWeight={500}>Finance Dashboards</Text>

				</div>
				<hr style={{ margin: '0 8px', marginBottom: '16px' }} />
				{!allowableBinanceOrgs.includes(orgName) && renderTransfiDashboards()}
				{(allowableBinanceOrgs.includes(orgName) || orgRoles.includes("transfi_admin")) && renderDeepIntegrationDashboards()}
			</Box>

		</>
	) : (
		<Loader
			type="spinner-cub"
			bgColor={"#FFFFFF"}
			title={"Loading..."}
			color={"cyan.400"}
			size={100}
		/>
	);
};
