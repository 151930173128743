import React, { useContext } from "react";
import moment from "moment";
require("moment-timezone");
import { TimezoneContext } from "../context/TimezoneContext";

const TimezoneFormatter = (props) => {
    const { date, format } = props;
    const { timezone } = useContext(TimezoneContext);

    const defaultDate = date ? moment(date) : "NA";
    if (defaultDate !== "NA") {
        const convertedDate = defaultDate.tz(timezone);
        const formattedDate = format ? moment(convertedDate).format(format) : moment(convertedDate).format();
        return formattedDate;
    } else {
        return "NA";
    }
}

export default TimezoneFormatter;
