import React, { useEffect, useState } from "react";
import { Box, useClipboard, useToast } from "@chakra-ui/react";

//Custom Components and functions
import { callApi } from "../../../utils/utils";
import Loader from "../../../utils/Loader";

//css
import "../Login.css"
import "./PasswordLogin.css"

//icons
import png from '../../../icons/background.png';
import Logo from "./icons/Logo.png";
import CopyIcon from "./icons/CopyIcon.png";

const MFASetup = (props) => {
    const toast = useToast();
    const { value, setValue, hasCopied, onCopy } = useClipboard("");

    const { email, setMfaStatus } = props || {};
    const [isLoading, setIsLoading] = useState(false);

    const handleToast = (title, type) => {
        toast({
            title,
            status: type,
            duration: 5000,
            isClosable: true
        });
    };

    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [base32Code, setBase32Code] = useState('');

    useEffect(() => {
        fetchQrCodeUrl();
    }, []);

    const fetchQrCodeUrl = async () => {
        try {
            setIsLoading(true);
            const response = await callApi("POST", '/api/customers/getMfaCode', JSON.stringify({ email })) || {};
            const { status, qrImage, secret, message } = response;
            if (status) {
                setQrCodeUrl(qrImage);
                setBase32Code(secret);
                setValue(secret);
            } else {
                throw new Error(message);
            }
            setIsLoading(false);
        } catch (e) {
            handleToast(e?.message || "Something went wrong", 'error');
            setIsLoading(false);
        }
    }

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(base32Code);
        } catch (err) {
            handleToast("Failed to copy text", 'error');
        }
    };

    return (
        isLoading ? <Loader /> :
            <Box className="pa-login-container" backgroundImage={png}>
                <div className="pa-login-card">
                    <div style={{ textAlign: 'left' }}><p className="pa-card-heading" style={{ marginLeft: '40px' }}>Setup 2FA</p></div>
                    <img className="pa-login-logo-trouble" style={{ marginTop: '84px' }} src={Logo} />
                    <p className="pa-login-heading">Setup 2FA</p>
                    <p className="pa-login-sub-heading">Scan this code in your Authenticator App.</p>
                    <img className="pa-qr-image" src={qrCodeUrl} alt="QR code" />
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '32px' }}>
                        <div className="pa-horizontal-line" style={{ width: '81.5px' }}></div>
                        <span className="pa-horizontal-line-text">or enter the code manually</span>
                        <div className="pa-horizontal-line" style={{ width: '81.5px' }}></div>
                    </div>
                    <div style={{ textAlign: 'center', marginTop: '30px' }}>
                        <div className="pa-input-box-div" style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}>
                            <p className="pa-mfa-code">{base32Code}</p>
                            {hasCopied ? <span className="pa-copy-icon" style={{width: '60px', marginLeft: '10px'}}>Copied</span> : <img src={CopyIcon} className="pa-copy-icon" onClick={() => onCopy()}/>}
                        </div>
                    </div>
                    <button className="pa-button" style={{ marginTop: '30px' }} onClick={() => { setMfaStatus(true) }}>
                        Verify
                    </button>
                    <div className="pa-transfi-banner" style={{ marginTop: '72px' }}>
                        <p className="pa-powered-by">Powered by</p>
                        <img style={{ width: '24px', height: '17px', marginLeft: '8px', marginRight: '8px' }} src={Logo} />
                        <p className="pa-powered-transfi">TransFi</p>
                    </div>
                </div>
            </Box>
    );
};

export default MFASetup;
