import React, { useState, useRef, useContext } from "react";
import Loader from "react-js-loader";
import { Box, Button, useDisclosure, useToast, Badge, Text, HStack, Tooltip } from "@chakra-ui/react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";
import { BiEdit } from "react-icons/bi";
import { startCase, join, slice } from "lodash";
import moment from "moment";

import TransfiGrid from "../../utils/TransfiGrid";
import TimezoneFormatter from "../../utils/TimezoneFormatter";

import { getCxRoles, getOrgId } from "../App/useToken";
import { round } from "lodash";
import SettlementModal from "./helper/SettlementModal";
import NotesUpdateModal from "./helper/NotesUpdateModal";
import StatusUpdateModal from "./helper/StatusUpdateModal";
import SettlementFilter from "./helper/SettlementFilter";
import { useTranslation } from "react-i18next";

export default function OceanGamingSettlement() {
	const { t } = useTranslation();
	//ref
	const gridRef = useRef();

	const toast = useToast();

	const orgId = getOrgId();

	//disclosures
	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: isOpenFilterModal,
		onOpen: onOpenFilterModal,
		onClose: onCloseFilterModal,
	} = useDisclosure({ defaultIsOpen: false });
	const {
		isOpen: isOpenEditNotesModal,
		onOpen: onOpenEditNotesModal,
		onClose: onCloseEditNotesModal,
	} = useDisclosure({ defaultIsOpen: false });
	const {
		isOpen: isOpenEditModal,
		onOpen: onOpenEditModal,
		onClose: onCloseEditModal,
	} = useDisclosure({ defaultIsOpen: false });


	//variables
	const defaultDate = { startDate: moment(new Date()).subtract(2, "months"), endDate: moment(new Date()) };
	const defaultFilters = { dateRange: defaultDate }

	//state variables
	const [loading, setLoading] = useState(false);
	const [formValues, setFormValues] = useState({});
	const [details, setDetails] = useState({});
	const [data, setData] = useState({});
	const [filters, setFilters] = useState(defaultFilters);
	const [dateRange, setDateRange] = useState(defaultDate);

	const refreshGrid = () => {
		gridRef.current.refreshGrid();
	};

	const handleOnReset = () => {
		setDateRange(defaultDate);
		setFilters(defaultFilters);
		setFormValues({});
	};

	const handleOnClickNotes = (value) => {
		onOpenEditNotesModal();
		setDetails(value);
	};

	const handleOnClick = (value) => {
		onOpenEditModal();
		setData(value);
	};


	const columns = [
		{
			Header: t("default:email_id"),
			accessor: "email",
			width: "250",
		},
		{
			Header: t("transactions:date_initiated"),
			width: "150",
			Cell: ({ row }) => {
				return <TimezoneFormatter date={row?.original.createdAt} format={"MM/DD/YYYY"} />
			},
		},
		{
			Header: t("transactions:time_initiated"),
			width: "150",
			Cell: ({ row }) => {
				return <TimezoneFormatter date={row?.original.createdAt} format={'LT'} />
			},
		},
		{
			Header: t("transactions:date_approval"),
			width: "150",
			Cell: ({ row }) => {
				return row?.original.settledAt ? <TimezoneFormatter date={row?.original?.settledAt} format={"MM/DD/YYYY"} /> : "N/A"
			},
		},
		{
			Header: t("transactions:time_approval"),
			width: "150",
			Cell: ({ row }) => {
				return row?.original.settledAt ? <TimezoneFormatter date={row?.original?.settledAt} format={"LT"} /> : "N/A"
			},
		},
		{
			Header: t("sub-accounts:currency"),
			accessor: "currency",
			width: "180",
			Cell: ({ value }) => value || "N/A"
		},
		{
			Header: t("transactions:amount"),
			accessor: "amount",
			width: "100",
			Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
		},
		{
			Header: t("transactions:status"),
			accessor: "status",
			width: "180",
			Cell: ({ row }) => {
				return (
					<>
						{row?.original?.status === "settlement_completed" ? (
							<Badge colorScheme={"green"} >{startCase("processed")} </Badge>
						) : (
							<HStack>
								<Badge colorScheme={"yellow"} >{startCase("processing")}</Badge>
								{orgId === "OG-220923101501950" && <BiEdit
									style={{
										fontSize: "22px",
										color: "#2b6cb0",
										cursor: "pointer",
									}}
									onClick={() => handleOnClick(row?.original)}
								></BiEdit>}
							</HStack >

						)}
					</>
				)
			}

		},
		{
			Header: t("transactions:wallet_address"),
			accessor: "walletAddress",
			width: "250",
			Cell: ({ value }) => value || "N/A"
		},

		{
			Header: t("sub-accounts:additional_note"),
			width: "250",
			Cell: ({ row }) => {
				const note1 = slice(row?.original.description, 0, 25).join("");
				const joinednote = row?.original.description ? join([note1, "..."], "") : "N/A";
				return (
					<HStack>
						<Tooltip
							label={row?.original.description}
							fontSize="0.8rem"
							placeContent={'top'}
							hasArrow
							bgColor={'black'}
						>
							<Text style={{ width: "90%" }} >
								{joinednote}</Text>
						</Tooltip>
						{orgId === "OG-220923101501950" && <BiEdit
							style={{
								fontSize: "22px",
								color: "#2b6cb0",
								cursor: "pointer",
							}}
							onClick={() => handleOnClickNotes(row?.original)}
						></BiEdit>}
					</HStack>
				)
			}
		},
	];

	return !loading ? (
		<Box style={{ background: "white" }} heading="Settlement">
			<div style={{ float: "right", marginTop: "2%", marginRight: "5%" }}>
				{orgId === "OG-220923101501950" && <Button
					leftIcon={<AiOutlinePlusCircle />}
					colorScheme="blue"
					style={{ margin: "0 8px" }}
					size="sm"
					onClick={onOpen}
				>
					{t("transactions:create_new")}
				</Button>}
				<Button
					leftIcon={<FiFilter style={{ fontSize: "20px" }} />}
					onClick={onOpenFilterModal}
					variant={"outline"}
					colorScheme="blue"
					style={{ margin: "0 8px" }}
					size="sm"
				>
					{t("default:filter")}
				</Button>
			</div>
			<TransfiGrid
				// gridName="Settlements"
				ref={gridRef}
				columns={columns}
				dataUrl={"/api/settlement/wpay/list"}
				selectRow={false}
				filters={{ filters }}
			/>
			<SettlementModal
				isOpen={isOpen}
				onOpen={onOpen}
				onClose={onClose}
				refreshGrid={refreshGrid}
			/>
			{details && <NotesUpdateModal
				isLoading={loading}
				isOpen={isOpenEditNotesModal}
				onOpen={onOpenEditNotesModal}
				onClose={onCloseEditNotesModal}
				refreshGrid={refreshGrid}
				details={details}
			/>}
			{data && <StatusUpdateModal
				isLoading={loading}
				isOpen={isOpenEditModal}
				onOpen={onOpenEditModal}
				onClose={onCloseEditModal}
				refreshGrid={refreshGrid}
				details={data}
			/>}
			<SettlementFilter
				isOpen={isOpenFilterModal}
				onClose={onCloseFilterModal}
				dateRange={dateRange}
				setDateRange={setDateRange}
				filters={filters}
				setFilters={setFilters}
				formValues={formValues}
				setFormValues={setFormValues}
				handleOnReset={handleOnReset}
			/>
		</Box >
	) : (
		<Loader
			type="spinner-cub"
			bgColor={"#FFFFFF"}
			title={"Loading..."}
			color={"cyan.400"}
			size={100}
		/>
	);
}
