import React, { useState, useEffect } from "react"
import {
    Flex, Stack,
} from "@chakra-ui/react"
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

import SumsubWebSdk from '@sumsub/websdk-react';

import { callApiWithToken } from "../../utils/utils";
import { getThemeForKYCWidget } from "../../utils/kybFormcss";


export default function KYBForm() {
    const navigate = useNavigate();
    const [accessToken, setAccessToken] = useState();

    useEffect(() => {
        getKybAccessToken();
    }, []);

    const getKybAccessToken = async () => {
        try {
            const resp = await callApiWithToken("GET", `/api/organization/initiateKyb`);
            const { accessToken } = resp;
            setAccessToken(accessToken);
        } catch (err) {
            return err;
        }
    }

    return (
        <Flex flexDir="column">
            <BiArrowBack size={"2em"} style={{ margin: "35px", float: "right", cursor: 'pointer' }} onClick={() => navigate("/developers")} />
            <Stack direction={"column"} justifyContent="space-between" flexGrow={1}>
                {accessToken ?
                    <SumsubWebSdk
                        accessToken={accessToken}
                        updateAccessToken={() => console.log("updateAccessToken")}
                        expirationHandler={() => Promise.resolve(accessToken)}
                        config={{
                            lang: 'en',
                            i18n: {
                                document: {
                                    subTitles: {
                                        IDENTITY: "Upload a document that proves your identity"
                                    }
                                }
                            },
                            onMessage: (type, payload) => {
                                console.log("WebSDK onMessage", type, payload);
                            },
                            uiConf: {
                                customCssStr:
                                    getThemeForKYCWidget('#1A4FD6', 'none')
                            },
                            onError: (error) => {
                                console.error("WebSDK onError", error);
                            }
                        }}
                        options={{ addViewportTag: false, adaptIframeHeight: true }}
                        onError={(data) => console.log("onError", data)}
                    />
                    : <></>}
            </Stack>
        </Flex>
    );
}