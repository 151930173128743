import React, { useEffect } from "react";
import { isEmpty, unset } from "lodash";
import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent } from "@chakra-ui/react";
import { DrawerCloseButton, Button } from "@chakra-ui/react";
import Select from "react-select";

const LiveRateFilter = (props) => {
    const { isOpen, onClose, setFilters, handleOnReset } = props;
    const { inputData, setInputData, setFormValues, formValues, filters = {}, countryOptions, fiatCurrency } = props;

    useEffect(() => {
        setFormValues(filters);
    }, [filters]);

    useEffect(() => {
        setFormValues(filters);
    }, []);

    const handleOnChanges = (key, value) => {
        setInputData({
            ...inputData,
            [key]: value,
        });
        let newValue;
        if (Array.isArray(value)) newValue = value.map((data) => data.value);
        else newValue = value.trim();
        setFormValues({
            ...formValues,
            [key]: newValue ,
        });
    };

    const handleOnClear = () => {
        onClose();
        handleOnReset();
    };

    const handleOnFilter = () => {
        Object.keys(formValues).map((key) => {
            if (isEmpty(formValues[key])) unset(formValues, key);
        });
        setFilters(formValues);
        onClose();
    };

    const statusOptions = [
        { label: "XE", value: "xe" },
        { label: "Yellow Card", value: "yellowcard" },
        { label: "Linkio", value: "linkio" },
        { label: "Bluedollar", value: "bluedollar" }
    ];

    return (
        <>
            <Drawer onClose={onClose} isOpen={isOpen} size={"sm"}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader style={{ color: "#3182ce", fontFamily: "arial" }}>Filters</DrawerHeader>
                    <DrawerBody>
                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isMulti
                                isSearchable={true}
                                placeholder="Currency"
                                options={fiatCurrency}
                                value={inputData.currency}
                                onChange={(e) => handleOnChanges("currency",  e)}
                            />
                        </div>

                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isMulti
                                isSearchable={false}
                                placeholder="Exchange Rate Partner"
                                options={statusOptions}
                                value={inputData.defaultPartner}
                                onChange={(e) => handleOnChanges("defaultPartner",  e)
                                }
                            />
                        </div>

                        <div style={{ marginTop: "30px" }}>
                            <Button onClick={() => handleOnFilter()} variant={"outline"} colorScheme="blue">
                                Filter
                            </Button>
                            <Button
                                onClick={() => handleOnClear()}
                                variant={"outline"}
                                colorScheme="red"
                                style={{ marginLeft: "30px" }}
                            >
                                Clear
                            </Button>
                        </div>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default LiveRateFilter;
