import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  HStack,
  chakra,
  VStack,
  Flex,
  FormControl,
  FormLabel,
  Button,
  Divider,
  useDisclosure,
  Input,
  InputGroup,
  InputRightElement,
  useClipboard,
  Spinner
} from "@chakra-ui/react";
import { pickBy, uniqBy, concat } from "lodash";

import { EnvTypeContext } from "../../context/EnvTypeContext";
import { urlMap } from "../../../src/utils/config";
import { HiChevronDown, HiOutlineRefresh } from "react-icons/hi";
import Select from "react-select";
import { user_journey } from "./docs";
import { callApiWithToken } from "../../utils/utils";
import WidgetDetails from "./WidgetDetails";
import Instruction from "./Instruction";
import WidgetLink from "./WidgetLink";
import WidgetFilter from "./WidgetFilter";
import coinBuy from "../../icons/CoinBuy.svg";
import NotificationBadge from "react-notification-badge";
import { BiCopy } from "react-icons/bi";
import BinanceWidget from "./BinanceWidget";
import { getEnv, getOrgId } from "../App/useToken";

const Widget = (props) => {
  const environment = getEnv();
  const { env } = useContext(EnvTypeContext);
  const [widgetUrl, setWidgetUrl] = useState();
  const apiKey = sessionStorage.getItem("apiKey");
  const { onCopy, value, setValue, hasCopied } = useClipboard(widgetUrl)

  const [country, setCountry] = useState();
  const [countryName, setCountryName] = useState();
  const [userJourney, setUserJourney] = useState();
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [demoEmail, setDemoEmail] = useState();
  const [widDetails, setWidDetails] = useState(false);
  const [countryList, setCountryList] = useState([])
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [filterCount, setFilterCount] = useState();
  const { isOpen, onOpen: onFiterOpen, onClose } = useDisclosure();
  const { isOpen: isLinkOpen, onOpen: onLinkOpen, onClose: onLinkClose } = useDisclosure();

  const [newUser, setNewUser] = useState();
  const [newCountry, setNewCountry] = useState();
  const [newPayment, setNewPayment] = useState();
  const [currencyConfigs, setCurrencyConfigs] = useState();
  const [orgName, setOrgName] = useState();

  const [filterOpt, setFilterOpt] = useState();
  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    const url =
      env === "Sandbox"
        ? `${urlMap[window.location.host].sandboxWidget}?apiKey=${apiKey}`
        : `${urlMap[window.location.host].widget}?apiKey=${apiKey}`;

    const sandboxUrl = `${urlMap[window.location.host].sandboxWidget
      }?apiKey=${apiKey}`; // always load sandbox in widget
    setWidgetUrl(sandboxUrl);
    setValue(sandboxUrl);
    getCurrencyList();
    getOrgDetails();
  }, [env]);

  const getOrgDetails = async () => {
    try {
      const orgId = getOrgId()
      const orgData = (await callApiWithToken("POST", `/api/organization/details`, { orgId })) || {};
      const { formattedName } = orgData.orgResponse || {};
      setOrgName(formattedName);
      setIsPageLoading(false)
    } catch (err) {
      setIsPageLoading(false)
    }
  };

  const getCurrencyList = async () => {
    const resp = await callApiWithToken("GET", `/api/widget/configList?name=fiat&module=buy_crypto`);
    const cList = resp.data[0].config;
    const asianCountries = cList.filter((ac) => ac.group === 'trending')
    const otherCountries = cList.filter((ac) => ac.group === undefined)
    let asianlist = asianCountries.map((cl) => { return { value: cl.symbol, label: cl.symbol } })
    let otherlist = otherCountries.map((cl) => { return { value: cl.symbol, label: cl.symbol } })
    otherlist = uniqBy(otherlist, 'label');
    const currencyList = concat(asianCountries, otherCountries);
    setCurrencyConfigs(currencyList);


    const list = [
      {
        label: 'Trending',
        options: asianlist,
      },
      {
        label: 'All Countries',
        options: otherlist,
      },
    ];
    setCountryList(list);
  }

  const getPaymentMethods = async (value) => {
    const currency = currencyConfigs.filter((cl) => cl.symbol === value.value)
    const resp = await callApiWithToken("GET", `/api/widget/paymentMethods?currency=${currency[0].description}`)
    const { data = [] } = resp;
    let paymentOptions = data.map((pm) => { return { value: pm.referenceName, label: pm.name } })
    setPaymentMethods(paymentOptions);
  }

  const widgetHandler = () => {
    if (widDetails) {
      return (
        <>
          <WidgetDetails email={demoEmail} />
          {/* <PaymentInstruction paymentMethod={paymentMethod} country={country} /> */}
        </>
      )
    } else {
      return <Instruction />
    }
  }

  const onSubmitHandler = async () => {
    const resp = await callApiWithToken("GET", `/api/widget/user?currency=${newCountry.value}&type=${newUser.value}`);

    if (resp) {
      setWidDetails(true);
      setDemoEmail(resp.email)
    }

    let dynamicObj = {
      fiatTicker: filterOpt?.currency?.value !== undefined ? filterOpt?.currency?.value : newCountry.value,
      country: newCountry.label,
      cryptoTicker: filterOpt?.crypto?.ticker,
      cryptoNetwork: filterOpt?.crypto?.network,
      walletAddress: filterOpt?.wallet,
      fiatAmount: filterOpt?.fiatValue,
      partnerContext: filterOpt?.infoMessage,
    };
    const cleanedobj = pickBy(dynamicObj, (v) => v !== undefined || "");
    const dynamicUrl = new URLSearchParams(cleanedobj).toString();
    const sandboxUrl = `${urlMap[window.location.host].sandboxWidget}/${filterOpt.language ? filterOpt.language.value : ""}?apiKey=${apiKey}&${dynamicUrl}`;

    setWidgetUrl(sandboxUrl);
    setValue(sandboxUrl);
  }

  const onFilterApplied = (filterData) => {
    setFilterCount(Object.keys(filterData).length)
    setFilterOpt(filterData);
  }

  if (isPageLoading) {
    return (
      <HStack justifyContent={'center'} h="full">
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
      </HStack>)
  }

  return (
    (['binance', 'binance_demo', 'binance_qa', 'binance_prod', 'binance_africa'].includes(orgName))
      ? <BinanceWidget />
      : <>
        <Flex
          justifyContent={"space-evenly"}
          alignItems={"flex-end"}
          gap="5"
          p={5}
          width="100%"
        >
          <Box width={'full'}>
            <FormControl>
              <FormLabel>User Journey:</FormLabel>
              <Select
                options={user_journey}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,

                  }),
                }}
                value={newUser}
                onChange={value => setNewUser(value)}
              />
            </FormControl>
          </Box>
          <Box width={'full'}>
            <FormControl>
              <FormLabel>Country:</FormLabel>
              <Select
                options={countryList}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                  }),
                }}
                value={newCountry}
                onChange={value => { setNewCountry(value), getPaymentMethods(value), setNewPayment(''), setCountry(value) }}
              />
            </FormControl>
          </Box>
          <Box width={'full'}>
            <FormControl>
              <FormLabel>Payment Methods:</FormLabel>
              <Select
                options={paymentMethods}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    overflow: 'hidden',
                    width: '300px'
                  }),
                  valueContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    flexWrap: 'nowrap',
                    overflow: 'scroll'

                  })
                }}
                value={newPayment || ''}
                onChange={value => setNewPayment(value)}
                isMulti
              />
            </FormControl>
          </Box>
          <Box>
            <Button variant='outline' colorScheme='blue' leftIcon={<HiChevronDown size='20px' />} onClick={e => onFiterOpen()} >
              More Filter
              <NotificationBadge count={filterCount} style={{ top: "-10px", right: "-15px", bordeRadius: '12px', padding: '5px 7px', backgroundColor: '#1A4FD6' }} />
            </Button>
            <WidgetFilter isOpen={isOpen} onClose={onClose} onSubmit={onFilterApplied} newCountry={newCountry} />
          </Box>
          <Box>
            <Button
              // isDisabled={newUser === undefined || newPayment === undefined || newCountry === undefined}
              onClick={e => onSubmitHandler()} colorScheme="blue"
              leftIcon={<HiOutlineRefresh size="20px" />}
            >
              Refresh
            </Button>
          </Box>
        </Flex>

        {widDetails && <Flex padding={3} alignContent={'flex-end'}>
          {/* <WidgetLink isOpen={isLinkOpen} onClose={onLinkClose} link={widgetUrl} /> */}
          <FormControl display='inline-flex' alignItems="center" >
            <FormLabel mt={'8px'} >Widget Integration URL:</FormLabel>
            <InputGroup width='500px' >
              <Input type='text' value={widgetUrl} isDisabled background='gray.200' color={'black'} />
              <InputRightElement cursor='pointer' color={hasCopied ? 'green' : 'blue'} onClick={onCopy} children={<BiCopy fontSize={"20px"} />} />
            </InputGroup>
          </FormControl>

        </Flex>}


        <Divider opacity={1} />
        <HStack spacing={2} alignItems="inherit" pt={5}>
          <VStack bg="#fafafa" width={"50%"} height="100%">
            <Box
              bg="#fafafa"
              h={"830px"}
              display="flex"
              p={"16px"}
              width={"100%"}
              mt="0 !important"
              flexDirection={"column"}
              alignItems="center"
            >
              <chakra.iframe
                flexGrow={1}
                width="full"
                height="100%"
                src={widgetUrl}
              ></chakra.iframe>
            </Box>
          </VStack>
          <VStack color={"black"} width={"50%"} p="5" spacing={5}>

            {widgetHandler()}
          </VStack>
        </HStack>
      </>
  );
};

export default Widget;
