import React, { useEffect, useState } from "react";
import { startCase, isEmpty, unset } from "lodash";

import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent } from "@chakra-ui/react";
import { DrawerCloseButton, Input, Button } from "@chakra-ui/react";
import Select from "react-select";

import DateRangePicker from "../../Common/DateRangePicker";

const TransactionFilter = (props) => {
    const { isOpen, onClose, setFilters, setDateRange, dateRange } = props;
    const { formValues, setFormValues, inputData, setInputData, filters, handleOnReset } = props;
    const [filterData, setFilterData] = useState({});

    useEffect(() => {
        setFormValues(filters);
    }, [filters]);

    useEffect(() => {
        setFormValues(filters);
    }, []);

    const handleOnChanges = (key, value) => {
        setInputData({
            ...inputData,
            [key]: value,
        });
        let newValue;
        if (Array.isArray(value)) newValue = value.map((data) => data.value);
        else if (typeof value === 'object') newValue = value?.value;
        else if (key === 'filterData') newValue = value.value
        else newValue = value.trim();
        setFormValues({
            ...formValues,
            [key]: newValue,
        });
    };

    const handleOnClear = () => {
        onClose();
        handleOnReset();
    };

    const handleOnFilter = () => {
        Object.keys(formValues).map((key) => {
            if (isEmpty(formValues[key])) unset(formValues, key);
        });
        setFilters(formValues);
        onClose();
    };

    const onChangeDateRange = (start, end) => {
        setDateRange({ startDate: start, endDate: end });
        setFormValues({
            ...formValues,
            ["dateRange"]: { startDate: start, endDate: end },
        });
    };

    const typeOptions = [
        { label: "Buy", value: "buy" },
        { label: "Sell", value: "sell" },
    ];

    const fiatCurrencyOptions = [
        { label: "PHP", value: "PHP"},
        { label: "IDR", value: "IDR"},
        { label: "TZS", value: "TZS"},
        { label: "ZMW", value: "ZMW"},
        { label: "UGX", value: "UGX"},
        { label: "GHS", value: "GHS"},
    ]

    const orderStatus = [
        { label: "Settled", value: "settled" },
        { label: "Unsettled", value: "unsettled" },
        { label: "All", value: "all" }
    ]

    const refundStatus = [
        { label: "Completed", value: "completed" },
        { label: "Pending", value: "pending" },
        { label: "Not Applicable", value: "not_applicable" },
        { label: "All", value: "all" }

    ]

    let statusOptions = [
        { label: startCase("initiated"), value: "initiated" },
        { label: startCase("fund_settled"), value: "fund_settled" },
        { label: startCase("fund_processing"), value: "fund_processing" },
        { label: startCase("fund_failed"), value: "fund_failed" },
        { label: startCase("expired"), value: "expired" },
    ];

    const filterOptions = [
        { label: "Order Created By", value: "order_created_by" },
        { label: "Order Completed By", value: "order_completed_by" },
    ];

    if(filterData?.value === "order_completed_by"){
        statusOptions = [
            { label: startCase("fund_settled"), value: "fund_settled" },
            { label: startCase("expired"), value: "expired" },
            { label: startCase("fund_failed"), value: "fund_failed" },
        ];
    }

    return (
        <>
            <Drawer onClose={onClose} isOpen={isOpen} size={"sm"}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader style={{ color: "#3182ce", fontFamily: "arial" }}>Filters</DrawerHeader>
                    <DrawerBody>
                    <div style={{ margin: "12px 0" }}>
                            <Select
                                isSearchable={true}
                                placeholder="Date Filter"
                                options={filterOptions}
                                value={inputData.filterData}
                                onChange={(e) => {
                                    handleOnChanges("filterData", e)
                                    setFilterData(e)
                                }}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <DateRangePicker
                                width={"100%"}
                                onChangeDateRange={onChangeDateRange}
                                start={dateRange.startDate}
                                end={dateRange.endDate}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Input
                                placeholder="Transfi Order Id"
                                style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                                type="text"
                                value={formValues.orderId}
                                onChange={(e) => handleOnChanges("orderId", e.target.value)}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Input
                                placeholder="Binance Order Id"
                                style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                                type="text"
                                value={formValues.customer?.orderId}
                                onChange={(e) => handleOnChanges("customer.orderId", e.target.value)}
                            />
                        </div>
                        {/* <div style={{ margin: "12px 0" }}>
                            <Input
                                placeholder="Email Id"
                                style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                                type="text"
                                value={formValues.email}
                                onChange={(e) => handleOnChanges("email", e.target.value)}
                            />
                        </div> */}
                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isMulti
                                isSearchable={false}
                                placeholder="Transaction Status"
                                options={statusOptions}
                                value={inputData.status}
                                onChange={(e) => handleOnChanges("status", e)}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isMulti
                                isSearchable={false}
                                placeholder="Fiat Currency"
                                options={fiatCurrencyOptions}
                                value={inputData.fiatTicker}
                                onChange={(e) => handleOnChanges("fiatTicker", e)}
                            />
                        </div>
                        {/* <div style={{ margin: "12px 0" }}>
                            <Select
                                isMulti
                                isSearchable={false}
                                placeholder="Country"
                                options={countryOptions}
                                value={inputData.userCountry}
                                onChange={(e) => handleOnChanges("userCountry", e)}
                            />
                        </div> */}
                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isMulti
                                isSearchable={false}
                                placeholder="Product"
                                options={typeOptions}
                                value={inputData.type}
                                onChange={(e) => handleOnChanges("type", e)}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isSearchable={false}
                                placeholder="Settlement Status"
                                options={orderStatus}
                                value={inputData.settlementStatus}
                                onChange={(e) => handleOnChanges("settlementStatus", e)}
                            />
                        </div>


                        <div style={{ margin: "0px 0" }}>
                            <Select
                                isSearchable={false}
                                placeholder="Refund Status"
                                options={refundStatus}
                                value={inputData.refundStatus}
                                onChange={(e) => handleOnChanges("refundStatus", e)}
                            />
                        </div>
                        {/* <div style={{ margin: "12px 0" }}>
                            <Select
                                isMulti
                                isSearchable={true}
                                placeholder="Payment Name"
                                options={paymentOptions}
                                value={inputData.paymentName}
                                onChange={(e) => handleOnChanges("paymentName", e)}
                            />
                        </div> */}

                        <div style={{ marginTop: "30px" }}>
                            <Button onClick={() => handleOnFilter()} variant={"outline"} colorScheme="blue">
                                Filter
                            </Button>
                            <Button
                                onClick={() => handleOnClear()}
                                variant={"outline"}
                                colorScheme="red"
                                style={{ marginLeft: "30px" }}
                            >
                                Clear
                            </Button>
                        </div>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default TransactionFilter;
