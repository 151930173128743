import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
// import markerSDK from '@marker.io/browser';
import { Step, Steps, useSteps } from "chakra-ui-steps";
import { StepsTheme as StepsConfg } from "chakra-ui-steps";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./i18n.js";
import { HelmetProvider } from "react-helmet-async";

const colors = {
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
  },
};

const CustomSteps = {
  ...StepsConfg,
  baseStyle: (props) => {
    return {
      ...StepsConfg.baseStyle(props),
      stepContainer: {
        ...StepsConfg.baseStyle(props).stepContainer,
        flexDirection: "column",
      },
      labelContainer: {
        ...StepsConfg.baseStyle(props).labelContainer,
        marginTop: "1em",
      },
    };
  },
};

const theme = extendTheme({
  components: {
    colors,
    Steps: CustomSteps,
  },
});

const container = document.getElementById("root");
const root = createRoot(container);

// (async () => {
//   const widget = await markerSDK.loadWidget({
//     destination: '627a35b5ae6f8d296b97ae19', // <- Your unique destination ID
//   });
// })();

root.render(
  // <React.StrictMode>
  <ChakraProvider theme={theme}>
    <HelmetProvider>
      <Suspense fallback="loading">
        <GoogleOAuthProvider
          clientId={
            process.env.REACT_APP_GOOGLE_CLIENTID ||
            "apps.googleusercontent.com"
          }
        >
          <App />
        </GoogleOAuthProvider>
      </Suspense>
    </HelmetProvider>
  </ChakraProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
