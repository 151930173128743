import React, { useEffect, useState, useContext, useRef } from "react";
import { isEmpty, result, startCase, uniqBy } from "lodash";
import moment from "moment";
import { Box, Badge, useDisclosure, Heading, Icon, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, FormControl, FormLabel, Input, Button , HStack,Spinner, Stack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { BiRefresh } from "react-icons/bi";
import { DownloadIcon, AddIcon } from "@chakra-ui/icons";
import { BsUpload } from 'react-icons/bs';
import Select from 'react-select';

import { callApiWithToken } from "../../../utils/utils";
import { EnvTypeContext } from "../../../context/EnvTypeContext";
import { NavBarContext } from "../../../context/NavBarContext";
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

export default function Rules() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [rowData, setRowData] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paymentGateway, setPaymentGateway] = useState([]);
    const [parametersVisible, setParametersVisible] = useState(false);
    const [currencies,setCurrencies] = useState([]);
    const [colDefs, setColDefs]= useState([
        { headerName: "Rule Name", field: "name", filter: true },
        { headerName: "Rule Type", field: "type", filter: true },
        { headerName: "Product", field: "buy", filter: true },
        { headerName: "Currency", field: "currency", filter: true },
        { headerName: "Reference Name", field: "referenceName", filter: true },
        { headerName: "Payment Gateway", field: "paymentGateway", filter: true },
        { headerName: "Conditions", field: "conditions", filter: true },
      ]);
    const [parameters, setParameters] = useState([{ name: "", type: "", value: "" }]);
    let paymentoptions = {};
    let gatewayoptions = {};
    let currencyoptions = {};
    useEffect(() => {
        async function fetchData() {
            const response = await callApiWithToken("GET", `/api/admin/rulesdata`);
            if (response.status){
                paymentoptions = response.data.paymentMethods;
                paymentoptions = paymentoptions.map((meth, idx) => {
                    if (idx === 0) return { value: "", label: "Select" };
                    return { value: meth.label, label: meth.value };
                  });
                
                gatewayoptions = response.data.paymentGateway;
                gatewayoptions = gatewayoptions.map((meth,idx) => {
                    if(idx === 0 ) return { value : "" , label: "Select"};
                    return { value: meth.value , label: meth.label};
                })
                currencyoptions = response.data.currencies;
                
                currencyoptions = currencyoptions.map((meth,idx) => {
                    if(idx === 0 ) return { value : "" , label: "Select"};
                    return { value: meth.value , label: meth.label};
                })
                setPaymentMethods(paymentoptions);
                setCurrencies(currencyoptions)
                setPaymentGateway(gatewayoptions)
            }
            
        }
        fetchData();
    },[]);

      const addParameterField = () => {
        setParametersVisible(true);
        setParameters([...parameters, { name: "", type: "", value: "" }]);
    };

    const handleParameterChange = (index, field, value) => {
        const updatedParameters = [...parameters];
        updatedParameters[index][field] = value;
        setParameters(updatedParameters);
    };

    const removeParameterField = (index) => {
        const updatedParameters = [...parameters];
        updatedParameters.splice(index, 1);
        setParameters(updatedParameters);
    };
      return (
        <Box  heading="Rules">
            <div style={{ marginTop: "2%", marginRight: "5%" }}>
                <div style={{ float: "right" }}>
                <Button
                        leftIcon={<AddIcon style={{ fontSize: "20px" }} />}
                        onClick={onOpen}
                        variant={"outline"}
                        colorScheme="blue"
                        style={{ margin: "0 4px" }}
                        size="sm"
                      >
                        Add
                      </Button>
                </div>
                <br></br>
            </div>
            <div className="ag-theme-quartz" style={{ height: 700, width: '100%', marginTop: '20px' }}>
                <AgGridReact
                rowData={rowData}
                columnDefs={colDefs}
                pagination={true}
                paginationPageSize ={500}
                paginationPageSizeSelector ={[50,100,200,500]}
                // onGridReady={onGridReady}
                />
            </div>
            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay>
                    <ModalContent>
                        <ModalHeader>Add Rules</ModalHeader>
                        <ModalCloseButton/>
                        <ModalBody>
                            <FormControl>
                                <FormLabel> Name </FormLabel>
                                <Input></Input>
                            </FormControl>
                            <FormControl>
                                <FormLabel> Type </FormLabel>
                                <Input></Input>
                            </FormControl>
                            <FormControl>
                                <FormLabel> Product </FormLabel>
                                <Select
                                    options = {
                                        [
                                            { value: '', label: 'Select' },
                                            { value: 'buy', label: 'Buy' },
                                            { value: 'sell', label: 'Sell' }
                                        ]
                                    }
                                />    
                            </FormControl>
                            <FormControl>
                                <FormLabel> Currencies </FormLabel>
                                    <Select
                                        options = {currencies}
                                    />
                            </FormControl>
                            <FormControl>
                                <FormLabel> Payment Methods </FormLabel>
                                    <Select 
                                        isMulti
                                        options ={paymentMethods}
                                    />
                                        
                            </FormControl>
                            <FormControl>
                                <FormLabel> Gateway Names </FormLabel>
                                <Select 
                                    isMulti 
                                            options = {paymentGateway}
                                />
                            </FormControl>
                            <br></br>
                            {parametersVisible && (
                                <Box border="1px" borderRadius="md" p="4" mb="4">
                                    {parameters.map((parameter, index) => (
                                        <div key={index}>
                                            <FormControl>
                                                <FormLabel> Parameter Name </FormLabel>
                                                <Select
                                                    options = {
                                                        [
                                                            { value: '', label: 'Select' },
                                                            { value: 'maxLimit', label: 'Max Limit' },
                                                            { value: 'minLimit', label: 'Min Limit' },
                                                            { value: 'fees', label: 'Fees'  },
                                                            { value: 'succesRate', label: 'Success Rate'}
                                                        ]
                                                    }
                                                /> 
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel> Parameter Type </FormLabel>
                                                <Select
                                                    options = {
                                                        [
                                                            { value: '', label: 'Select' },
                                                            { value: 'lessThan', label: 'Less Than' },
                                                            { value: 'lessThanEqualTo', label: 'Less Than Equal To' },
                                                            { value: 'greaterThan', label: 'Greater Than'  },
                                                            { value: 'greaterThanEqualTo', label: 'Greater Than Equal To'}
                                                        ]
                                                    }
                                                /> 
                                                
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel> Parameter Value </FormLabel>
                                                <Input
                                                    type ="number"
                                                />
                                            </FormControl>
                                            <br></br>
                                            <Button onClick={() => removeParameterField(index)}>Remove</Button>
                                            <br></br>
                                        </div>
                                    ))}
                                    
                                </Box>
                                
                            )}
                            <br></br>
                            <Button onClick={addParameterField}>Add Parameter</Button>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3}>
                                Add
                            </Button>
                            <Button onClick={onClose}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </ModalOverlay>
            </Modal>
        </Box>
      )

}