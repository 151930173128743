import React, { useState, useContext, useRef, useEffect } from "react";
import Loader from "react-js-loader";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import { Badge, useDisclosure, Button } from "@chakra-ui/react";
import { uniqBy, isEmpty, get, round } from "lodash";
import { Box } from "@chakra-ui/react";
import { BiRefresh, BiReset } from "react-icons/bi";
import { FiFilter } from "react-icons/fi";
import { useTranslation } from "react-i18next";

import { TimezoneContext } from "../../context/TimezoneContext";
import OrderFilter from "./helper/OrderFilter";
import { statusMap, colorMap } from "../../utils/config";
import { callApiWithToken } from "../../utils/utils";
import { getOrgName } from "../App/useToken";
import TimezoneFormatter from "../../utils/TimezoneFormatter";
import TransfiGrid from "../../utils/TransfiGrid";
import { getCountryData } from "../../utils/getCountries";

export default function WpayOrders() {
    const { t } = useTranslation();
    const { orderId } = useParams();
    const [loading, setLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [countryOptions, setCountryOptions] = useState([]);
    const [fiatCurrency, setFiatCurrency] = useState([]);
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [formValues, setFormValues] = useState({});
    const [inputData, setInputData] = useState({});
    const defaultDate = { startDate: moment().subtract(2, "months"), endDate: moment().format("YYYY-MM-DD 23:59:59") };
    const [filters, setFilters] = useState({ dateRange: defaultDate });
    const [dateRange, setDateRange] = useState(defaultDate);

    const orgName = getOrgName();
    const { timezone } = useContext(TimezoneContext);
    const gridRef = useRef();
    const orgId = sessionStorage.getItem('orgId');

    useEffect(() => {
        getCountryList();
        getPaymentList();
    }, []);

    useEffect(() => {
        refreshGrid();
    }, [timezone]);

    const refreshGrid = () => {
        gridRef.current && gridRef.current.refreshGrid();
    };

    const getCountryList = async () => {
        const { countryList, fiatList } = await getCountryData();
        setCountryOptions(countryList);
        setFiatCurrency(fiatList);
    };

    const getPaymentList = async () => {
        const response = await callApiWithToken("GET", `/api/orders/getPaymentMethods`);
        const data = response.data;
        let list = data.map((country) => {
            return { value: country.name, label: country.name };
        });
        setPaymentOptions(uniqBy(list, (obj) => obj.value));
    };

    const numberFormatter = (value) => {
        const number = round(value, 2).toFixed(2)
        const result = parseFloat(number).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
        return result === "NaN" ? "0.00" : result;
    };

    const columns = [
        {
            Header: t("transactions:transaction_owner"),
            accessor: "customer.email",
            width: "150",
            color: "info",
            zIndex: 1,
            position: 'sticky',
            Cell: ({ value }) => {
                return value || "NA"
            },
        },
        {
            Header: t("transactions:product"),
            accessor: "type",
            width: "100",
            color: "info",
            Cell: ({ value }) => {
                return <Badge colorScheme={value === "buy" ? "green" : "purple"}> {value === "buy" ? "deposit" : "withdrawal"} </Badge>;
            }
        },
        {
            Header: t("transactions:order_id"),
            accessor: "orderId",
            width: "200",
            color: "info",
            Cell: ({ value }) => {
                return value;
            },
            textAlign: 'center',
        },
        {
            Header: "External Order Id",
            accessor: "partnerContext",
            width: "230",
            color: "info",
            Cell: ({ value }) => {
                return value?.orderId || "NA"
            }
        },
        {
            Header: t("transactions:status"),
            accessor: "status",
            width: "130",
            color: "info",
            Cell: ({ row }) => {
                const { status, type } = row?.original;
                const newStatus = statusMap[type][status] || status;
                const color = colorMap[type][status] || "blue";
                return <Badge colorScheme={color}> {newStatus} </Badge>;
            },
        },
        {
            Header: t("transactions:date_initiated"),
            width: "120",
            color: "info",
            Cell: ({ row }) => {
                return <TimezoneFormatter date={row?.original.createdAt} format={"MM/DD/YYYY"} />
            },
        },
        {
            Header: t("transactions:time_initiated"),
            width: "120",
            color: "info",
            Cell: ({ row }) => {
                return <TimezoneFormatter date={row?.original.createdAt} format={'LT'} />
            },
        },
        {
            Header: t("transactions:date_completed"),
            width: "100",
            color: "info",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.timestamps)) {
                    if (row?.original?.status === "asset_settled") {
                        return <TimezoneFormatter date={row?.original?.timestamps?.assetSettledAt} format={"MM/DD/YYYY"} />
                    } else {
                        return "NA"
                    }
                } else return "NA";
            },
        },
        {
            Header: t("transactions:time_completed"),
            width: "100",
            color: "info",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.timestamps)) {
                    if (row?.original?.status === "asset_settled") {
                        return <TimezoneFormatter date={row?.original?.timestamps?.assetSettledAt} format={"LT"} />
                    } else {
                        return "NA"
                    }
                }
                else return 'NA';
            },
        },
        {
            Header: t("default:email_id"),
            accessor: "email",
            color: "info",
            width: "230",
            Cell: ({ value }) => {
                return value || "NA"
            }
        },
        {
            Header: t("transactions:wallet_address"),
            width: "200",
            color: "info",
            show: orgName === "ocean_gaming" ? true : false,
            Cell: ({ row }) => {
                if (row?.original?.type === "sell") {
                    if (!isEmpty(row?.original?.metaData?.walletAddressData)) {
                        return row?.original?.metaData?.walletAddressData?.addr;
                    } else {
                        return "NA";
                    }
                }
                else {
                    if (!isEmpty(row?.original?.walletAddress)) {
                        return row?.original?.walletAddress;
                    } else return "NA";
                }
            }
        },
        {
            Header: t("transactions:fiat_payment_method"),
            width: "150",
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.paymentName)) {
                    return row?.original?.fiat?.paymentName;
                } else {

                    return row?.original?.paymentName;
                }
            }
        },
        {
            Header: t("transactions:fiat_currency"),
            width: "120",
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.currency)) {
                    return row?.original?.fiat?.currency;
                } else {

                    return row?.original?.fiatTicker;
                }
            }
        },
        {
            Header: t("transactions:fiat_amount"),
            width: "230",
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.orderAmount)) {
                    return numberFormatter(row?.original?.fiat?.orderAmount)
                } else {
                    return numberFormatter(row?.original?.fiatAmount)
                }
            }
        },
        {
            Header: t("transactions:crypto_currency"),
            width: "100",
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.crypto?.currency)) {
                    return row?.original?.crypto?.currency;
                } else {
                    return row?.original?.cryptoTicker;
                }
            },
        },
        {
            Header: t("transactions:crypto_amount"),
            width: "230",
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.crypto?.orderAmount)) {
                    return row?.original?.crypto?.orderAmount;
                } else {
                    return row?.original?.cryptoAmount;
                }
            }
        },
        {
            Header: t("transactions:processing_fees_amt"),
            width: "180",
            color: "warning",
            Cell: ({ row }) => {
                if (orgId === "OG-220923101501950") {
                    const tfFee = Number(get(row.original, "fiat.tfFee", 0));
                    return numberFormatter(tfFee)
                } else {
                    const cxFee = Number(get(row.original, "fiat.cxFee", 0));
                    return numberFormatter(cxFee)
                }
            }
        },
        {
            Header: t("sub-accounts:api_key"),
            accessor: "apiKey",
            width: "150",
            color: "warning",
            Cell: ({ value }) => {
                return value || "NA"
            }
        },
    ];

    const handleOnReset = () => {
        setDateRange(defaultDate);
        setFilters({ dateRange: defaultDate });
        setFormValues({});
        setInputData({});
    };

    return !loading ? (
        <Box style={{ background: "white" }} heading="Orders">
            {orderId ? (
                <></>
            ) : (
                <div style={{ marginTop: "2%" }}>
                    <div style={{ float: "right", marginRight: "5%" }}>
                        <Button
                            leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                            onClick={() => handleOnReset()}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 8px" }}
                            size="sm"
                        >
                            {t("default:reset")}
                        </Button>
                        <Button
                            leftIcon={<BiRefresh style={{ fontSize: "20px" }} />}
                            onClick={() => refreshGrid()}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 0px" }}
                            size="sm"
                        >
                            {t("default:refresh")}
                        </Button>
                        <Button
                            leftIcon={<FiFilter style={{ fontSize: "20px" }} />}
                            onClick={onOpen}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 8px" }}
                            size="sm"
                        >
                            {t("default:filter")}
                        </Button>
                    </div>
                    <br />
                </div>
            )}
            <TransfiGrid
                ref={gridRef}
                columns={columns}
                dataUrl={"/api/orders/wpay/list"}
                condensedUrl={"/buy-orders"}
                filters={{ impersonatedOrg: "all", filters, type: "buy" }}
                downloadReportUrl={"/api/report"}
                collection={"orders"}
                database={"transfi-nucleus"}
                gridFilters={{ "type": "buy", filters }}
                headerDetails={{ types: { "createdAt": "date" } }}
                gridName={"Buy Orders"}
            >
            </TransfiGrid>

            <OrderFilter
                onClose={onClose}
                isOpen={isOpen}
                countryOptions={countryOptions}
                fiatCurrency={fiatCurrency}
                paymentOptions={paymentOptions}
                formValues={formValues}
                setFormValues={setFormValues}
                filters={filters}
                setFilters={setFilters}
                dateRange={dateRange}
                setDateRange={setDateRange}
                inputData={inputData}
                setInputData={setInputData}
                handleOnReset={handleOnReset}
                type={"buy"}
            ></OrderFilter>
        </Box>
    ) : (
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={"cyan.400"} size={100} />
    );
}