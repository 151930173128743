import React, { useState, useEffect } from 'react';
import Loader from "react-js-loader";
import {    Button,HStack,Divider, useDisclosure, Modal, ModalBody, ModalContent,ModalOverlay,ModalHeader, ModalFooter, Badge,useClipboard,ModalCloseButton} from '@chakra-ui/react';
import { startCase } from 'lodash';
import moment from "moment";
import ReactJson from "react-json-view";
import { BiShow, BiCopy} from "react-icons/bi"
import { VscJson } from 'react-icons/vsc'
import { callApiWithToken } from '../../../utils/utils';
import CustomTable from '../../../utils/customTable';

const Webhook = (props) => {

    const { orderId } = props || {};
    const{isOpen, onOpen, onClose} = useDisclosure();
    const { onCopy, value, setValue, hasCopied } = useClipboard("");
    const[jsonData,setJsonData]= useState();
    const [webhooks, setWebhooks] = useState([]);
    const[loading,setLoading]= useState(false);

    useEffect(() => {
        getWebhooks();
    }, [orderId])   

    const getWebhooks = async () => {
        setLoading(true);
        const response = await callApiWithToken ("POST", '/api/webhooks/list', { orderId }) || {}
        setWebhooks(response?.data);
        setLoading(false);
    }
    const handleOnClick =(value) => {
        onOpen();
        setJsonData(value);
        setValue(JSON.stringify(value))
    };
    const resendWebhookHandler = async () => {
        const {_id} = webhooks[0];
      await callApiWithToken ("POST",'/api/webhooks/resend', {orderId,_id})
      getWebhooks();
    };

    let columns = [
        {
            Header: "Entity ID",
            accessor: "entityId",
            width: "200",
        },
        {
            Header: "Entity Type",
            accessor: "entityType",
            width: "70",
        },
        {
            Header: "Status",
            accessor: "status",
            width: "70",
            Cell: ({ value }) => 
                value === 'processed' ? (<Badge colorScheme={"green"}>{startCase(value)} </Badge> ) :
                (<Badge colorScheme={"red"}>{startCase(value)} </Badge>)           
        },    
        {
            Header: "Data",
            accessor: "data",
            width: "50",
            Cell: ({ row }) => {
                return (
                    <BiShow
                        style={{
                            fontSize: "22px",
                            color: "#2b6cb0",
                            cursor: "pointer",
                            margin: "0 20%",
                        }}
                        onClick={() => handleOnClick(row?.original)}
                    ></BiShow>
                );
            },
        },
        {
            Header: "Partner",
            accessor: "partner",
            width: "70",
        },
        {
            Header: "Created At",
            accessor: "createdAt",
            width: "170",
            Cell: ({ value }) => { return moment(value).format('lll') }  
        },
        {
            Header: "Updated At",
            accessor: "updatedAt",
            width: "170",
            Cell: ({ value }) => { return moment(value).format('lll') }   
        },
        {
            Header:"Resend Webhook",
            width:"70",
            Cell:({value}) => {
                return <Button onClick={resendWebhookHandler}>Resend</Button>
            }
        },
    ];

    return !loading ? <>

<Modal isOpen={isOpen} onClose={onClose} size="3xl">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            <HStack>
                                <VscJson />
                                <span>JSON Format</span>
                            </HStack>
                        </ModalHeader>
                        <ModalCloseButton top={3} />
                        <Divider />
                        <ModalBody>
                            <div
                                style={{
                                    height: "600px",
                                    overflow: "auto",
                                    border: "1px solid #ccc",
                                    background: "#F4F4F4",
                                    padding: "20px",
                                }}
                            >
                                <ReactJson src={jsonData?.data} displayDataTypes={false} displayObjectSize={true} />
                            </div>
                        </ModalBody>
                        <Divider />
                        <ModalFooter>
                            <HStack gap="5" width={"65%"}>
                                <Button
                                    leftIcon={<BiCopy />}
                                    variant={"solid"}
                                    colorScheme="blue"
                                    onClick={onCopy}
                                    width={"25%"}
                                >
                                    {hasCopied ? "Copied" : "Copy"}
                                </Button>
                                <Button onClick={onClose} variant={"outline"} colorScheme="blue" width={"25%"}>
                                    Exit
                                </Button>
                            </HStack>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

    <CustomTable columns={columns} data={webhooks} showPagination= {false} />
</> :
    <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={'#FFFFFF'} size={100} />
   }

export default Webhook;
