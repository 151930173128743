/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import {
    Button,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Input,
    useToast,
    Stack,
    Grid,
    chakra,
    FormErrorMessage
} from '@chakra-ui/react';
import Select from "react-select";
import { useFormik } from 'formik';
import * as Yup from "yup";

import { callApiWithToken } from '../../../utils/utils';

const EditOtcPayinModal = (props) => {
    const { isOpen, onClose, refreshGrid,selectedRow} = props;

    const{orderId,fiatAmount, fiatTicker, cryptoTicker} = selectedRow;

    const [loading, setLoading] = useState(false)
    const toast = useToast();

    const cryptoPartnerList = [
        { label: "OKX", value: "okx" },
        { label: "Huobi", value: "huobi" },
        { label: "Binance", value: "binance" },
        { label: "Bitpace", value: "bitpace" },
        { label: "HNI", value: "hni" },
        { label: "Jupiter", value: "jupiter" },
    ];
    const closeModal = () => {
        formik.resetForm();
        onClose();
        setLoading(false);
    }

    const formik = useFormik({
        initialValues: {
            feeCharged:''
        },
        validationSchema: Yup.object().shape({
            feeCharged:Yup.string().required("Fee Charged is required"),
        }),
        onSubmit: async (formValues) => {
            try {
                setLoading(true);
                const response = await callApiWithToken("POST", "/api/orders/otc/buy/update", {
                    orderId,
                    feeCharged: formValues?.feeCharged,
                });
                if (response?.message) {
                    toast({
                        title: 'Success',
                        description: response?.message,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                        position: 'top-right'
                    })
                }
                refreshGrid();
                closeModal();
            } catch (err) {
                setLoading(false);
                toast({
                    title: 'Error!',
                    description: response?.message || "Failed to fetch order details.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
                refreshGrid();
                closeModal();
            }
        },
    });

    return (
        <chakra.form onSubmit={formik.handleSubmit} display="flex" flexGrow={1}>
        <Modal isOpen={isOpen} onClose={() => closeModal()} size={'xl'}>
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>OTC Orders : {orderId} </ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={12}>
                    <Stack direction={"column"} justifyContent="space-between" flexGrow={1}>
                    <FormControl >
                                <FormLabel>Other Charges </FormLabel>
                                <Input
                                    id="feeCharged"
                                    type="text"
                                    value={formik.values.feeCharged}
                                    onChange={formik.handleChange}
                                />
                            </FormControl>
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button
                        isLoading={loading}
                        onClick={formik.handleSubmit}
                        colorScheme='blue' mr={3}
                    >
                        Update
                    </Button>
                    <Button
                        onClick={() => closeModal()}
                    >
                        Cancel
                    </Button>
                </ModalFooter>

            </ModalContent>
        </Modal>
        </chakra.form>
    )
}

export default EditOtcPayinModal;