import { Box, Text, FormControl, HStack, FormLabel, InputGroup, Input, InputRightElement, useClipboard, color } from '@chakra-ui/react'
import { BiCopy } from 'react-icons/bi';


const WidgetDetails = (props) => {
  const { email } = props
  const { onCopy: onEmailCopy, hasCopied: emailCopied } = useClipboard(email);
  const { onCopy: onOtpCopy, hasCopied: otpCopied} = useClipboard('135790');
  const { onCopy: onWalletAddressCopy, hasCopied: walletAddressCopied } = useClipboard('2N3oefVeg6stiTb5Kh3ozCSkaqmx91FDbsm');
  const {onCopy, hasCopied} = useClipboard('BTC');

  return (
    <>
    <Box boxShadow={"md"} width={"100%"} p={5} borderRadius="10">
            <Text fontWeight="bold">First Time User Information</Text>
            <HStack spacing={5} pt="5">
              <Box flexGrow={1}>
                <FormControl>
                  <FormLabel>Email ID</FormLabel>
                  <InputGroup>
                  <Input
          placeholder={'email'}
          value={email}
          mr={2}
        />
                    <InputRightElement onClick={onEmailCopy} 
                      children={<BiCopy fontSize={"25px"} color={emailCopied ? 'green' : 'blue'}  />}
                    />
                  </InputGroup>
                </FormControl>
              </Box>

              <Box>
                <FormControl>
                  <FormLabel>OTP</FormLabel>
                  <InputGroup>
                    <Input type="number" value="135790" />
                    <InputRightElement onClick={onOtpCopy}
                      children={<BiCopy fontSize={"25px"} color={otpCopied ? 'green' : 'blue'}  />}
                    />
                  </InputGroup>
                </FormControl>
              </Box>
            </HStack>
          </Box>

          <Box boxShadow={"md"} width={"100%"} p={5} borderRadius="10">
            <Text fontWeight="bold">Wallet Addresses for your test Orders</Text>
            <HStack spacing={5} pt="5">
              <Box>
                <FormControl>
                  <FormLabel>Crypto</FormLabel>
                  <InputGroup>
                    <Input type="text" value="BTC" />
                    <InputRightElement onClick={onCopy}
                      children={<BiCopy fontSize={"25px"} color={hasCopied ? 'green' : 'blue'}  />}
                    />
                  </InputGroup>
                </FormControl>
              </Box>
              <Box flexGrow={1}>
                <FormControl>
                  <FormLabel>Wallet Address</FormLabel>
                  <InputGroup>
                    <Input type="text" value="2N3oefVeg6stiTb5Kh3ozCSkaqmx91FDbsm" />
                    <InputRightElement onClick={onWalletAddressCopy}
                      children={<BiCopy fontSize={"25px"} color={walletAddressCopied ? 'green' : 'blue'}  />}
                    />
                  </InputGroup>
                </FormControl>
              </Box>
            </HStack>
          </Box>
    </>
  )
}

export default WidgetDetails