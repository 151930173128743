import React, { useState } from 'react';
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Input,
  Flex,
  Center,
  Alert,
  AlertIcon
} from '@chakra-ui/react';
import TimezoneFormatter from './TimezoneFormatter'; 
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import { callApiWithTokenV2 } from './utils';
import './EditModal.css';

const EditModal = ({ value, columnName, data, field, type, handleReset }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [editValue, setEditValue] = useState(value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : '');
  const [validationMessage, setValidationMessage] = useState('');

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setValidationMessage('');
  };

  const validateTime = (timeString) => {
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
    return timePattern.test(timeString);
  };
  const handleDateChange = (val) => {
    const sanitizedValue = moment(val).format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, ' ').trim(); 
    setEditValue(sanitizedValue);

    const timePart = sanitizedValue.split(' ')[1];
    if (!validateTime(timePart)) {
      setValidationMessage('Please enter the Date & Time in the correct format (YYYY-MM-DD HH:mm:ss).'); 
    } else {
      setValidationMessage(''); 
    }
  };

  const handleSave = async () => {

      if (validationMessage) {
        return; 
      }


    try {
      const formattedValue = moment(editValue).format();
      const updatedData = {
        field: field,
        newValue: formattedValue,
        type,
        prefundType: data?.type,
        customerEmail: data?.customer?.email
      };
      const response = await callApiWithTokenV2.post(`/api/orders/tfpay/update?orderId=${data?.orderId}`, updatedData);
      if (response) {
        setIsOpen(false);
        handleReset();
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Text onClick={openModal} cursor="pointer">
        {value ? <TimezoneFormatter date={value} format={"DD-MMM-YYYY @ h:mm A"} /> : "N/A"}
      </Text>
      <Modal isOpen={isOpen} onClose={closeModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader><Center>{`Edit ${columnName}`}</Center></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DateTime
              value={editValue}
              onChange={handleDateChange}
              dateFormat="YYYY-MM-DD"
              timeFormat="HH:mm:ss"
              inputProps={{ style: { width: '100%' } }}
            />
            {validationMessage && (
              <Alert status="error" mt={4}>
                <AlertIcon />
                {validationMessage} 
              </Alert>
            )}
          </ModalBody>
          <ModalFooter>
           <Flex justify="center" w="100%"> 
            <Button colorScheme="blue" mr={3} onClick={handleSave}>
              Save
            </Button>
            <Button onClick={closeModal}>Cancel</Button>
           </Flex> 
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default EditModal;