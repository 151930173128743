import React, { useContext ,useState, useEffect} from "react";
import { Box, Center, useToast,Stack, Link ,Modal,ModalCloseButton , FormControl,chakra ,InputGroup,Input, HStack, useDisclosure,ModalFooter, ModalBody, ModalHeader, ModalOverlay, ModalContent,Button, FormLabel, Flex, Text, Select } from "@chakra-ui/react";
import { join, startCase } from "lodash";
import { getEnv } from "../../App/useToken";
import TransfiGrid from "../../../utils/TransfiGrid";
import { NavBarContext } from "../../../context/NavBarContext";
import { Form, Field, useFormik, FormikProvider } from "formik";
import * as XLSX from 'xlsx';
import moment from 'moment';
import * as Yup from "yup";
import { callApiWithToken } from '../../../utils/utils';
import { AddIcon, DeleteIcon, DownloadIcon } from "@chakra-ui/icons";
import { BiRefresh, BiEdit } from "react-icons/bi";
export default function Pricing() {
    const gridRef = React.useRef();
    const { selectedOrg } = useContext(NavBarContext);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);
    const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
    const toast = useToast();
    const env = getEnv();
    const refreshGrid = () => {
        gridRef.current && gridRef.current.refreshGrid();
    };
    const shouldRenderData = selectedOrg.length === 1 && selectedOrg[0] !== 'All';
    const deleteRoute = async () => {
        try{
            setLoading(true);
            const response = await callApiWithToken("POST", "/api/admin/deletePaymentRoute", {
                _id:selectedRowData?._id
            });
            if (response?.message) {
                toast({
                    title: 'Success',
                    description: response?.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
            }
            setLoading(false);
            setDeleteConfirmationModalOpen(false);
            refreshGrid();
        }
        catch(err){
            toast({
                title: 'Error!',
                description: response?.message || "Failed to fetch order details.",
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right'
            })
            refreshGrid();
        }
    }
    const { isOpen: isOpenAddModal, onOpen: onOpenAddModal, onClose: onCloseAddModal } = useDisclosure();
    const { isOpen: isOpenEditModal, onOpen: onOpenEditModal,onClose:onCloseEditModal}= useDisclosure({ defaultIsOpen: false });
    const columns = [
        {
            Header: "Product",
            accessor: "product",
            width: 100,
            Cell: ({ value }) => startCase(value),
        },
        {
            Header: "Currency",
            accessor: "currency",
            width: 100,
            Cell: ({ value }) => startCase(value),
        },
        {
            Header: "Payment Gateway",
            accessor: "paymentGateway",
            width: 100,
            Cell: ({ value }) => startCase(value),
        },
        {
            Header: "Reference Name",
            accessor: "referenceName",
            width: 100,
            Cell: ({ value }) => value ? join(value.map((b) => startCase(b)), ", ") : "-",
        },
        {
            Header: "Payment Type",
            accessor: "paymentType",
            width: 100,
            Cell: ({ value }) => value ? join(value.map((b) => startCase(b)), ", ") : "-",
        },{
            Header: "Action",
            accessor: "index",
            width: 100,
            Cell: ({ row }) => (
                <Flex>
                <Button onClick={() => {handleRowClick(row?.original)}} style={{ marginRight: "4px" }} leftIcon={<BiEdit/>}>
                    Edit
                </Button>
                <Button onClick={() => {confirmDelete(row?.original)}} colorScheme="red" leftIcon={<DeleteIcon/>}>
                    Delete
                </Button>
                </Flex>
            ),
        }
    ];

    const closeModal = () => {
        formik.resetForm();
        onCloseEditModal();
        onCloseAddModal();
        setLoading(false);
    }
    
    const handleRowClick = (rowData) => {
        setSelectedRowData(rowData);
        onOpenEditModal();
        
    };
    const confirmDelete = async (rowData) => {
        setSelectedRowData(rowData);
        setDeleteConfirmationModalOpen(true);
      };
    const formik = useFormik({
        initialValues: {
                    _id: selectedRowData?._id,
                    formattedName: selectedRowData?.formattedName,
                    currency: selectedRowData?.currency,
                    product: selectedRowData?.product,
                    paymentGateway: selectedRowData?.paymentGateway,
                    referenceName: selectedRowData?.referenceName,
                    paymentType: selectedRowData?.paymentType ,
        },
        validationSchema: Yup.object().shape({
            formattedName:Yup.string().required("formattedName is required"),
            currency:Yup.string().required("Currency is required"),
            product: Yup.string().required("Product is required"),
            paymentGateway: Yup.string().required("Payment Gateway is required"),
            referenceName:Yup.array().nullable(),
            paymentType:Yup.array().nullable(),
        }),
        onSubmit: async (formValues) => {
            try {
                setLoading(true);
                let product = formValues?.product
                let paymentTypes = [];
                if(product === "sell")
                {
                    if (formValues?.paymentType === "" || formValues?.paymentType === "both") {
                        paymentTypes = ['local_wallet', 'bank_transfer'];
                    }
                    else if(Array.isArray(formValues?.paymentType)){
                        paymentTypes = formValues.paymentType;
                    }
                    else{
                        paymentTypes = formValues?.paymentType.split(',');
                    }
                const referenceNames = formValues?.referenceName ? 
                Array.isArray(formValues?.referenceName) ? formValues?.referenceName : formValues?.referenceName?.split(',')
                : [];
                const response = await callApiWithToken("POST", "/api/admin/updatePaymentRoute", {
                    _id: selectedRowData?._id,
                    formattedName: formValues?.formattedName,
                    currency: formValues?.currency,
                    product: product,
                    paymentGateway: formValues?.paymentGateway,
                    referenceName: referenceNames,
                    paymentType: paymentTypes,
                });
                if (response?.message) {
                    toast({
                        title: 'Success',
                        description: response?.message,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                        position: 'top-right'
                    })
                }
                closeModal();
                refreshGrid();
            }
            } catch (err) {
                toast({
                    title: 'Error!',
                    description: response?.message || "Failed to fetch order details.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
                closeModal();
            }
        },
        enableReinitialize:true,
    });
    const formikAddModal = useFormik({
        initialValues: {
            
            currency: "",
            paymentGateway: "",
            product: "",
            referenceName: "",
            paymentType: "",
        },
        validationSchema: Yup.object().shape({
            currency:Yup.string().required("Currency is required"),
            product: Yup.string().required("Product is required"),
            paymentGateway: Yup.string().required("Payment Gateway is required"),
            referenceName:Yup.string().nullable(),
            paymentType:Yup.string().nullable(),
        }),
        onSubmit: async (formValues, {resetForm}) => {
            try{
            let paymentTypes = [];
            if (formValues?.product === "sell") {
                if (formValues?.paymentType === "" || formValues?.paymentType === "both") {
                    paymentTypes = ['local_wallet', 'bank_transfer'];
                }
                else {
                    paymentTypes = formValues?.paymentType.split(',');
                }
            }
                const response = await callApiWithToken("POST", "/api/admin/addPaymentRoute", {
                    
                    formattedName: selectedOrg[0],
                    currency: formValues?.currency,
                    product:formValues?.product,
                    paymentGateway:formValues?.paymentGateway,
                    referenceName: formValues?.referenceName ? formValues?.referenceName.split(',') : [],
                    paymentType: paymentTypes,
                });
                if (response?.message) {
                    toast({
                        title: 'Success',
                        description: response?.message,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                        position: 'top-right'
                    })
                }
                resetForm();
                closeModal();
                refreshGrid();
                } catch (err) {
                toast({
                    title: 'Error!',
                    description: response?.message || "Wrong Details",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
                setLoading(false);
            }
        },
        enableReinitialize:true,
    });

    const downloadAsXLSX = async () => {
        setLoading(true);
        const orgFormattedName = selectedOrg;
        const response = await callApiWithToken("GET", `/api/admin/get-all-payment-methods-by-route?orgFormattedName=${orgFormattedName}`);
        const paymentRoutes = response.data;
        
        const headers = [
            "payment_method",
            "product",
            "currency",
            "payment_gateway",
            "PaymentType",
            "type_code"
        ];

        const excelData = paymentRoutes.map(route => ({
            "payment_method": startCase(route.referenceName || "NA"),
            "product": startCase(route.product || "NA"),
            "currency": (route.currencies || []).join(", ") || "-",
            "payment_gateway": startCase(route.paymentGateway || "NA"),
            "PaymentType": startCase(route.type || "NA"),
            "type_code":(route.type || "NA")
        }));

        const worksheet = XLSX.utils.json_to_sheet(excelData, { header: headers });

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Payment Routes");

        const timestamp = moment().format('DD-MM-YYYY');
        const filename = `${orgFormattedName}_routes_${timestamp}.xlsx`;
        
        XLSX.writeFile(workbook, filename);
        setLoading(false);
        
    };

    return (
        <Center py={5} bg="white.100">
            {shouldRenderData ? (
            <Stack spacing={2} w="90%">
                <Box textAlign="right" mb={4}>
                    <Button
                            onClick={() => refreshGrid()}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 4px" }}
                            leftIcon ={< BiRefresh style={{ fontSize: "20px" }} />}
                            size="sm"
                        >
                            Refresh
                    </Button>
                    <Button
                            onClick={downloadAsXLSX}
                            variant={"outline"}
                            isLoading={loading}
                            colorScheme="blue"
                            style={{ margin: "0 4px" }}
                            leftIcon={< DownloadIcon style={{ fontSize: "20px" }} />}
                            size="sm"
                        >
                            Download XLSX
                    </Button>
                    <Button variant={"outline"}
                        colorScheme="blue"
                        onClick={onOpenAddModal}
                        style={{ margin: "0 4px" }}
                        leftIcon ={< AddIcon style={{ fontSize: "16px" }} />}
                        size="sm"
                    >
                        Add Payment Routes
                    </Button>
                </Box>
                    <TransfiGrid
                        showPagination={true}
                        ref={gridRef}
                        columns={columns}
                        dataUrl={"/api/admin/paymentRoutes"}
                        filters={{ selectedOrg }}
                        selectRow={false}
                    ></TransfiGrid>
            </Stack >
            ) : (
                <Text>Please select only one organisation to manage the payment routes configuration..</Text>
            )}
            <Modal isOpen={deleteConfirmationModalOpen} onClose={() => setDeleteConfirmationModalOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Confirm Delete</ModalHeader>
                <ModalCloseButton />
                <ModalBody>Are you sure you want to delete this route?</ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={deleteRoute} isLoading={loading}>
                    Confirm
                    </Button>
                    <Button onClick={() => setDeleteConfirmationModalOpen(false)}>Cancel</Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isOpenAddModal} onClose={onCloseAddModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Payment Routes</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormikProvider value={formikAddModal}>
                            <FormControl isRequired isInvalid={formikAddModal.touched.product && formikAddModal.errors.product}>
                                <FormLabel> Product </FormLabel>  
                                <Select
                                    id="product"
                                    color="black"
                                    onChange={formikAddModal.handleChange}
                                >
                                    <option value ="">Select</option>
                                    <option value="buy">Buy</option>
                                    <option value="sell">Sell</option>
                                </Select>
                            
                            </FormControl>
                            <FormControl isRequired isInvalid={formikAddModal.touched.currency && formikAddModal.errors.currency}>
                                <FormLabel> Currency </FormLabel>
                                
                                <Input id="currency" type='text'  color={'black'} onChange={formikAddModal.handleChange} />
                            
                            </FormControl>
                            <FormControl isRequired isInvalid={formikAddModal.touched.paymentGateway && formikAddModal.errors.paymentGateway}>
                                <FormLabel> Payment Gateway </FormLabel>
                                
                                <Input id="paymentGateway" type='text'   color={'black'} onChange={formikAddModal.handleChange} />
                            
                            </FormControl>
                            {formikAddModal.values.product === 'sell' && <>
                                                    <Field name='paymentType' >
                                                    {({ field, form }) => (
                                                            <FormControl isRequired isInvalid={form.errors.paymentType && form.touched.paymentType}>
                                                                <FormLabel>
                                                                    Payment Type
                                                                </FormLabel>
                                                                <Select
                                                                    id="sell"
                                                                    color="black"
                                                                    onChange={(e) => {
                                                                        form.setFieldValue('paymentType', e.target.value);
                                                                        field.onChange(e);
                                                                    }}
                                                                    value={field.value}
                                                                >
                                                                    <option value ="both">Both</option>
                                                                    <option value="local_wallet">Local Wallet</option>
                                                                    <option value="bank_transfer">Bank Transfer</option>
                                                                </Select>
                                                            </FormControl>
                                                    )}
                                                    </Field>
                                                    </>}

                                                    <Field name='referenceName' >
                                                        {({ field, form }) => (
                                                            <FormControl isRequired={formikAddModal.values.product === 'buy'} isInvalid={form.errors.referenceName && form.touched.referenceName}>
                                                                <FormLabel>
                                                                    Reference Name
                                                                </FormLabel>
                                                                <Input id="referenceName" type='text' coloe ={'black'} onChange={formikAddModal.handleChange} placeholder="Enter Comma seperated Value" />
                                                            </FormControl>
                                                        )}
                                                        </Field>
                        </FormikProvider>
                    </ModalBody>
                    <ModalFooter>
                    <Button
                        type ="submit"
                        isLoading={loading}
                        onClick={formikAddModal.handleSubmit}
                        colorScheme='blue' mr={3}
                    >
                        Submit
                    </Button>
                    <Button
                        onClick={() => closeModal()}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isOpenEditModal} onClose={onCloseEditModal} onOpen={onOpenEditModal} >
            <ModalOverlay/>
            <ModalContent>
            <ModalHeader>Edit Routes</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <FormControl isRequired isInvalid={formik.touched.product && formik.errors.product}>
                <FormLabel> Product </FormLabel>  
                <Input id="product" type='text' value={startCase(formik?.values.product)} isDisabled  color={'black'} onChange={formik.handleChange} />
            
            </FormControl>
            <FormControl isRequired isInvalid={formik.touched.currency && formik.errors.currency}>
                <FormLabel> Currency </FormLabel>
                
                <Input id="currency" type='text' value={formik?.values.currency} isDisabled color={'black'} onChange={formik.handleChange} />
            
            </FormControl>
            <FormControl isRequired isInvalid={formik.touched.paymentGateway && formik.errors.paymentGateway}>
                <FormLabel> Payment Gateway </FormLabel>
                
                <Input id="paymentGateway" type='text' value={formik?.values.paymentGateway} isDisabled color={'black'} onChange={formik.handleChange} />
            
            </FormControl>
            {formik.values.product==="sell" && 
            <FormControl>
                <FormLabel>Payment Type</FormLabel>
                <Select
                id="paymentType"
                value={formik.values.paymentType}
                onChange={(e) => formik.setFieldValue('paymentType', e.target.value)}
                >
                <option value="local_wallet,bank_transfer">Both</option>
                <option value="local_wallet">Local Wallet</option>
                <option value="bank_transfer">Bank Transfer</option>
                </Select>
            </FormControl>
            }
            <FormControl isRequired={formik.values.product === 'buy'} isInvalid={formik.touched.referenceName && formik.errors.referenceName}>
                <FormLabel> Reference Name </FormLabel>
                <Input id="referenceName" type='text' value={formik?.values.referenceName}  onChange={formik.handleChange} />
            </FormControl>
            
            </ModalBody>
            <ModalFooter>
                    <Button
                        type ="submit"
                        isLoading={loading}
                        onClick={formik.handleSubmit}
                        colorScheme='blue' mr={3}
                    >
                        Submit
                    </Button>
                    <Button
                        onClick={() => closeModal()}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
            </Modal>
        </Center >
    );
}
