import React, { useState, useContext } from "react";
import { upperCase } from "lodash";
import Loader from "react-js-loader";
import {
	Box,
	Text
} from "@chakra-ui/react";

import { Link } from "react-router-dom";
import { BsFillCartPlusFill, BsCartDashFill, BsCartPlus, BsCartDash } from "react-icons/bs";
import { AiOutlineTransaction } from "react-icons/ai";
import { MdAccountBalanceWallet, MdOutlineAccountBalanceWallet } from "react-icons/md";

export default function Orders() {

	const [loading, setLoading] = useState(false);

	const ordersDashboards = [
		{ dashboard: "Buy Orders", icon: BsFillCartPlusFill, link: "/buy-orders" },
		{ dashboard: "Sell Orders", icon: BsCartDashFill, link: "/sell-orders" },
		{ dashboard: "OTC Orders(Payin)", icon: BsCartPlus, link: "/otc-payin-orders" },
		{ dashboard: "Collections", subText: "Fiat to stablecoin", icon: MdAccountBalanceWallet, link: "/payin-orders" },
		{ dashboard: "Payout", subText: "Stablecoin to Fiat", icon: MdOutlineAccountBalanceWallet, link: "/payout-orders" },
		{ dashboard: "Pay Orders", subText: "Fiat to Fiat", icon: MdOutlineAccountBalanceWallet, link: "/pay-orders" },
		{ dashboard: "OTC Orders(Payout)", icon: BsCartDash, link: "/otc-payout-orders" },
		{ dashboard: "Fiat Payout", subText: "PAYOUT", icon: MdOutlineAccountBalanceWallet, link: "/fiat-payout-orders" },
		{ dashboard: "Crypto Payout", subText: "PAYOUT", icon: MdOutlineAccountBalanceWallet, link: "/crypto-payout-orders" },
		{ dashboard: "Prefunds", icon: MdOutlineAccountBalanceWallet, link: "/prefund-orders" },
		{ dashboard: "Prefund Summary", subText: "PAYOUT", icon: MdOutlineAccountBalanceWallet, link: "/prefund-summary" },
		{ dashboard: "Transactions", icon: AiOutlineTransaction, link: "/transactions" },
		{ dashboard: "Collections", subText: "Platform", icon: MdAccountBalanceWallet, link: "/collections-orders" },
	];

	const OrdersDashboard = (data) => {
		const details = data?.data;
		const { dashboard = '', subText = '', icon = '', link = '' } = details;
		return <>

			<div className="balance-card-zoom" style={{ width: '250px', padding: '15px 20px', boxShadow: '0 4px 10px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)', borderRadius: '4px', height: '130px' }} >
				<Link to={`${link}`}>
					<div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px' }} >

						<div>
							<h3 style={{ fontSize: '20px', fontWeight: '600' }}>{upperCase(dashboard)}</h3>
						</div>
						<div style={{ width: '70px', height: '70px', borderRadius: '50%', display: 'flex', justifyContent: 'center' }}><details.icon style={{ fontSize: '50px' }} /></div>
					</div>
				</Link>
				<div style={{ position: 'relative', marginTop: '25px' }}>
					<h6 style={{
						fontSize: '16px', fontWeight: '600', position: "absolute",
						bottom: 0,
						right: 0,
					}} >{subText}</h6>
				</div>
			</div>

		</>
	}

	const renderTransfiDashboards = () => {
		const transfiDashboards = Object.keys(ordersDashboards).sort();

		return <div style={{ display: 'flex', justifyContent: 'left', padding: '20px 10px', flexWrap: 'wrap', gap: '4.45%' }}>
			{transfiDashboards.map(value => {
				return <div style={{ marginBottom: '40px' }}><OrdersDashboard data={ordersDashboards[value]} /></div>
			})}
		</div>
	}

	return !loading ? (
		<>
			<Box w="100%" p={4}>
				<div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '20px', padding: '0 10px', marginBottom: '5px' }}>
					<Text fontWeight={500}>Orders Dashboards</Text>

				</div>
				<hr style={{ margin: '0 8px', marginBottom: '16px' }} />
				{renderTransfiDashboards()}
			</Box>

		</>
	) : (
		<Loader
			type="spinner-cub"
			bgColor={"#FFFFFF"}
			title={"Loading..."}
			color={"cyan.400"}
			size={100}
		/>
	);
};
